import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import classnames from 'classnames';
import React from 'react';
import Icon from './Icon';

interface RequiredProps {
  label: string;
}

interface OptionalProps {
  onClickCross?: () => void;
}

type Props = RequiredProps & OptionalProps;

const defaultProps: OptionalProps = {
  onClickCross: undefined,
};

function Chip(props: Props) {
  return (
    <div
      className={classnames(
        'flex h-[35px] flex-none items-center rounded border border-concrete font-medium',
        {
          'pl-2': typeof props.onClickCross === 'function',
          'px-2': typeof props.onClickCross !== 'function',
        }
      )}
    >
      {props.label}
      {props.onClickCross && (
        <button className="h-8 w-8" type="button" onClick={props.onClickCross}>
          <Icon
            is={CancelOutlinedIcon}
            size="1rem"
            className="-translate-y-px"
          />
        </button>
      )}
    </div>
  );
}

Chip.defaultProps = defaultProps;

export default Chip;
