import { USER_REALM_KEY, B2C_REALM } from '../constants';

const useCheckUserIsPartner = () => {
  const realm = window.localStorage.getItem(USER_REALM_KEY);

  if (realm === B2C_REALM) {
    return true;
  } else {
    return false;
  }
};

export default useCheckUserIsPartner;
