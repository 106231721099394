import { Table } from 'dexie';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import { SearchScope } from '../../../types';

const usePredictiveSuggestions = (
  scopeToTableMapping: Record<
    Exclude<SearchScope, 'everywhere'>,
    [Table, string[]]
  >
): [string[], boolean] => {
  const activeSearch = useAppSelector(selectActiveSearch);

  const [loading, setLoading] = useState(false);
  const [filteredPredictiveSuggestions, setFilteredPredictiveSuggestions] =
    useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const searchTerm = activeSearch?.searchTerm ?? '';
      if (searchTerm === '') {
        setFilteredPredictiveSuggestions([]);
        return;
      }

      setLoading(true);
      const suggestions: string[] = [];

      for (const [scope, tableAndColumn] of Object.entries(
        scopeToTableMapping
      )) {
        if (
          activeSearch?.scope === scope ||
          activeSearch?.scope === 'everywhere'
        ) {
          const [table, searchColumn] = tableAndColumn;
          let query = table
            .where(searchColumn[0])
            .startsWithIgnoreCase(searchTerm);
          if (searchColumn.length > 1) {
            searchColumn.forEach((column: any) => {
              query = query.or(column).startsWithIgnoreCase(searchTerm);
            });
          }
          query
            .limit(10)
            .each((item: any) => {
              for (const column of searchColumn) {
                if (
                  item[column] !== '' &&
                  item[column] !== null &&
                  item[column] !== undefined &&
                  item[column]
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase()) &&
                  !suggestions.find((obj) => obj === item[column])
                ) {
                  suggestions.push(item[column]);
                }
              }
            })
            .then(() => {
              setFilteredPredictiveSuggestions(suggestions);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }
      }
    })();
  }, [activeSearch]);

  return [filteredPredictiveSuggestions, loading];
};

export default usePredictiveSuggestions;
