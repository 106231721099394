import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';
import { usePage } from '../../PageProvider';
import { NavBarTitleTransKey, TIcon } from '../../types';
import Icon from '../Icon';

interface Props {
  to: To;
  icon: TIcon;
  titleTransKey: NavBarTitleTransKey;
  disabled?: boolean;
  onClick?: () => void;
  badgeContent?: string | number;
  badgeColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

function NavBarButton(props: Props) {
  const page = usePage();

  const { t } = useTranslation();

  return (
    <Link
      className={classnames('nav-bar-button', {
        'active': props.titleTransKey === page.activeNavBarItem,
        'text-gray-400': props.disabled,
      })}
      to={props.to}
      onClick={
        props.disabled ? (event) => event.preventDefault() : props.onClick
      }
    >
      <Icon
        is={props.icon}
        className="nav-bar-button-icon"
        badgeContent={props.badgeContent}
        badgeColor={props.badgeColor}
      />
      <span className="ml-1 truncate text-sm">{t(props.titleTransKey)}</span>
    </Link>
  );
}

export default NavBarButton;
