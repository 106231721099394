import SimpleText from '../../components/SimpleText';

interface Props {
  raDescription: string;
}

function RepairActionDescription({ raDescription }: Props) {
  return <SimpleText description={raDescription} />;
}

export default RepairActionDescription;
