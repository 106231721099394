import { GroupParts } from "./GroupParts";

export interface IProduct {
  ProductId: string;
  ProductNumber: string;
  Description: string;
  AvailabilityDate: string;
  ProductPic: string;
  GroupParts: GroupParts[];
}

export class Product implements IProduct {
  ProductId: string;
  ProductNumber: string;
  Description: string;
  AvailabilityDate: string;
  ProductPic: string;
  GroupParts: GroupParts[];
  constructor(
    productId: string,
  productNumber: string,
  description: string,
  availabilityDate: string,
  productPic: string,
  groupParts: GroupParts[]
  ) {
    this.ProductId = productId;
    this.ProductNumber = productNumber;
    this.Description = description;
    this.AvailabilityDate = availabilityDate;
    this.GroupParts = groupParts;
    this.ProductPic = productPic;
  }

  GetHeading(): string {
    return `${this.ProductId}`;
  }
}

export default Product;
