import { useNavigate } from 'react-router-dom';

import useUnreadAppNotifications from '../../hooks/useUnreadAppNotifications';
import db from '../../index-db';

import { useState } from 'react';
import PopupModal from '../../components/PopupModal';
import Button from '../../components/Button';

const NotificationPopups = () => {
  const notificationData = useUnreadAppNotifications();
  const navigate = useNavigate();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(true);
  const [currentActiveIndex, setCurrentIndex] = useState(0);
  if (!notificationData?.length) {
    return <></>;
  }
  const currentItemId = notificationData[currentActiveIndex].id;
  const onButtonClick = (id: string) => () => {
    setIsMessageModalOpen(false);
    const lastActionTaken = new Date().valueOf();
    db.notifications.update(id, { isRead: 1, lastActionTaken });
    navigate(`/notifications/${id}`);
  };
  const onDismissClick = (id: string) => () => {
    const lastActionTaken = new Date().valueOf();
    if (currentActiveIndex < notificationData.length - 1) {
      setCurrentIndex((index) => index++);
    } else {
      setIsMessageModalOpen(false);
    }
    db.notifications.update(id, {
      lastActionTaken,
    });
  };

  const title = notificationData[currentActiveIndex]?.text;
  const subtitle = notificationData[currentActiveIndex]?.description;

  const subtitleHtml = subtitle.replace(/<[^>]*>/g, ' ');

  return (
    <PopupModal isModalOpen={isMessageModalOpen}>
      <div
        className={
          'flex w-3/5 flex-col items-center rounded-lg bg-white px-5 py-4'
        }
      >
        <div className="mb-2 w-full ">
          <h1 className="text-xl font-bold">{title}</h1>

          <p className="line-clamp-3  text-lg">{subtitleHtml}</p>
        </div>
        <div className="flex gap-0.5">
          <Button onClick={onButtonClick(currentItemId)} styling="primary">
            <span>Ok</span>
          </Button>

          <Button onClick={onDismissClick(currentItemId)} styling="primary">
            <span>Dismiss</span>
          </Button>
        </div>
      </div>
    </PopupModal>
  );
};

export default NotificationPopups;
