import { SelectChangeEvent } from '@mui/material/Select';
import Dropdown from './form/Dropdown';

export interface IOption {
  serialNumber: string;
  terminalId: string;
  endpointId: string;
  customerName: string;
}

interface IDropdownProps {
  label?: string;
  options: IOption[];
  value?: string;
  displayTime?: string;
  onChange: (e: SelectChangeEvent) => void;
  selectId?: string;
  selectLabel?: string;
  selectLabelId?: string;
  variant: 'outlined' | 'standard' | 'filled';
}

const EndpointDropdown = (props: IDropdownProps) => {
  const { displayTime, ...dropdownProps } = props;

  const renderValue = (selected: unknown) => {
    const selectedOption = props.options.find(
      (option) => option.endpointId === selected
    );
    if (selectedOption) {
      return (
        <>{`${selectedOption?.serialNumber} - ${selectedOption?.terminalId}`}</>
      );
    } else {
      return <>{selected}</>;
    }
  };
  const optionsRenderStructure = (options: IOption) => {
    const { serialNumber, terminalId, customerName } = options;
    return `${serialNumber} - ${terminalId} - ${customerName}`;
  };
  return (
    <>
      <div className="m-2 min-w-[224px] pl-1">
        <Dropdown
          {...dropdownProps}
          renderValue={renderValue}
          valueIdentifier="endpointId"
          optionsRenderStructure={optionsRenderStructure}
        />
      </div>
      <div className="mt-1 px-4">
        <label className="w-56 font-medium">{displayTime}</label>
      </div>
    </>
  );
};

EndpointDropdown.defaultProps = {
  selectLabel: 'select-label',
  selectId: 'select-standard-id',
  selectLabelId: 'select-standard-label-id',
};

export default EndpointDropdown;
