import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { RadioButtonInterface } from '../Models/ReusableComponents';

interface Props extends RadioButtonInterface {
  groupLabel?: string;
  radioGroupProps?: RadioGroupProps;
  formControlProps?: FormControlProps;
  formControlLabelProps?: FormControlLabelProps;
}

const FormControlLabelStyled = styled(FormControlLabel)<FormControlLabelProps>({
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
  },
});

function CustomRadioGroup(props: Props) {
  const {
    onChoiceChange,
    defaultChoice,
    data,
    groupLabel,
    radioGroupProps,
    formControlProps,
    formControlLabelProps,
  } = props;
  return (
    <FormControl {...formControlProps}>
      <RadioGroup
        aria-labelledby={groupLabel}
        defaultValue={defaultChoice}
        name={groupLabel}
        onChange={onChoiceChange}
        {...radioGroupProps}
      >
        {data.map((radio) => (
          <FormControlLabelStyled
            value={radio.value}
            control={<Radio />}
            label={radio.label}
            {...formControlLabelProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
CustomRadioGroup.defaultProps = {
  groupLabel: 'radio-buttons-group-label',
  radioGroupProps: {
    row: true,
  },
  formControlProps: {},
  formControlLabelProps: {},
};

export default CustomRadioGroup;
