import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useTranslation } from 'react-i18next';
import { LeadingActions, SwipeAction } from 'react-swipeable-list';
import SwipeActionCard from './SwipeActionCard';
import FeedbackScreen from '../Feedback/FeedbackScreen';
import { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import db from '../../index-db';
import LogParams from '../../Models/LogParams';
import useLogging from '../../hooks/useLogging';
import { useToast } from '../../components/toast/ToastProvider';
import { LogType } from '../../enums/LogType';
import { FbType } from '../../enums/FbType';
import TimelineEvent from '../../Models/TimeLineEvent';
import * as uuid from 'uuid';
import { TimeLineEventType } from '../../Models/Enumerations';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import useSaveTimeLineEvent from '../../hooks/useSaveTimeLineEvent';
import { LAST_ACCESSED_ENDPOINT } from '../../constants';

interface Props {
  raId: number;
  efcId: number;
  efcCode: string;
  endpointId: string;
  serialNumber: string;
}

interface IRepairActionData {
  raDescription: string;
  raName: string;
  moduleId: number;
}

function RepairActionCardLeadingActions({
  raId,
  efcId,
  efcCode,
  endpointId,
  serialNumber,
}: Props) {
  const api = useApi();
  const { t } = useTranslation();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const logging = useLogging();
  const { pushToast } = useToast();
  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';

  function logFeedback(feedback: string) {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Fb,
      },
      {
        key: LogType.FbType,
        value: FbType.RepairAction,
      },
      {
        key: LogType.Value,
        value: feedback,
      },
      {
        key: LogType.RaId,
        value: raId.toString(),
      },
      {
        key: LogType.EfcCode,
        value: efcCode ?? '',
      },
      {
        key: LogType.SerialNumber,
        value: serialNumber ?? '',
      },
      {
        key: LogType.EndpointId,
        value: endpointId ?? '',
      },
    ];
    logging('info', '', '', false, logParams, true);
    pushToast({
      message: t(`Your feedback has been submitted`),
      type: 'success',
    });

    if (endpointId !== t('No Endpoint')) {
      SaveTimeLineEvent(feedback);
    }
  }

  const repairActionsData = useLiveQuery(async () => {
    const raRecord = await db.raactions.get(raId);
    if (raRecord) {
      const repairActionData = {} as IRepairActionData;
      repairActionData.raDescription = raRecord.Description;
      repairActionData.raName = raRecord.Name;
      repairActionData.moduleId = raRecord.ModuleId;
      return repairActionData;
    }
  });

  const SaveTimeLine = useSaveTimeLineEvent();

  async function SaveTimeLineEvent(feedback: string) {
    const currentTime = new Date().toISOString();
    const timelineEventProperties: Record<string, string> = {
      efcId: efcId.toString(),
      feedback: feedback,
    };

    const timelineEvent = new TimelineEvent(
      uuid.v4().toString(),
      repairActionsData?.raDescription ?? '',
      lastAccessedEndpoint,
      raId.toString(),
      currentTime,
      TimeLineEventType[TimeLineEventType.Repair],
      timelineEventProperties
    );

    SaveTimeLine(timelineEvent);
  }

  return (
    <>
      <FeedbackScreen
        feedbackType="repairAction"
        isOpen={isFeedbackOpen}
        handleClose={() => setIsFeedbackOpen(false)}
        handleSubmit={(raPerformed: boolean) => {
          setIsFeedbackOpen(false);
          if (
            raPerformed &&
            lastAccessedEndpoint !== '' &&
            lastAccessedEndpoint !== 'No Endpoint'
          ) {
            SaveTimeLineEvent('Feedback');
          }
        }}
        repairActionId={raId}
        moduleId={repairActionsData?.moduleId}
        repairActionDescription={repairActionsData?.raDescription}
        repairActionName={repairActionsData?.raName}
        efcId={efcId}
        efcCode={efcCode}
      />
      <LeadingActions>
        <SwipeAction
          onClick={() => {
            setIsFeedbackOpen(true);
          }}
        >
          <SwipeActionCard
            bgColor="bg-steel"
            cardText={t('Leave feedback')}
            icon={CommentOutlinedIcon}
          />
        </SwipeAction>
        <SwipeAction onClick={() => logFeedback('Not relevant')}>
          <SwipeActionCard
            bgColor="bg-dnred"
            cardText={t('Not relevant')}
            icon={ThumbDownOutlinedIcon}
          />
        </SwipeAction>
        <SwipeAction onClick={() => logFeedback('Relevant')}>
          <SwipeActionCard
            bgColor="bg-cobalt"
            cardText={t('Relevant')}
            icon={ThumbUpOutlinedIcon}
          />
        </SwipeAction>
        <SwipeAction onClick={() => logFeedback('Fixed my issue')}>
          <SwipeActionCard
            bgColor="bg-purple"
            cardText={t('Fixed my issue')}
            icon={VerifiedOutlinedIcon}
          />
        </SwipeAction>
      </LeadingActions>
    </>
  );
}

export default RepairActionCardLeadingActions;
