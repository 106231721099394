import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { useToast } from '../../components/toast/ToastProvider';
import useLogging from '../../hooks/useLogging';
import Task from '../../Models/Task';
import ServiceTaskNote from './ServiceTaskNote';
import TaskNote from '../../Models/TaskNote';
import db from '../../index-db';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';

interface Props {
  endpointId: string;
}

function Notes(props: Props) {
  const { t } = useTranslation();
  const api = useApi();
  const logging = useLogging();
  const toast = useToast();
  const [serviceTaskNotes, setTaskNotes] = useState([] as TaskNote[]);

  const fetchServiceTaskHistory = async (): Promise<Task[]> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceTask/getServiceTasksHistoryByUser`
    )) as Task[];
  };

  const syncServiceTaskHistory = () => {
    let taskNotes = new Array<TaskNote>();
    fetchServiceTaskHistory()
      .then((taskHistory) => {
        taskHistory.forEach((task) => {
          if (task.endpointId === props.endpointId) {
            task.serviceTaskNotes?.forEach((taskNote) => {
              taskNotes.push(taskNote);
            });
          }
        });
        taskNotes.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        setTaskNotes(taskNotes);
      })
      .catch(async (error) => {
        logging(
          'warn',
          'Unable to retrieve Service Task history',
          error,
          false,
          null
        );
        toast.pushToast({
          type: 'error',
          message: t(
            'Unable to retrieve Service Task history. Falling back to offline mode. Notes for open Service tasks is available. '
          ),
        });
        const tasks = await db.tasks.toArray();
        let taskNotes = new Array<TaskNote>();
        tasks.forEach((task) => {
          if (task.endpointId === props.endpointId) {
            task.serviceTaskNotes?.forEach((taskNote) => {
              taskNotes.push(taskNote);
            });
          }
        });
        taskNotes.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        setTaskNotes(taskNotes);
      });
  };

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Notes,
      },
      {
        key: LogType.EndpointId,
        value: props.endpointId,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  useEffect(() => {
    syncServiceTaskHistory();
  }, []);

  return (
    <>
      {serviceTaskNotes && (
        <div className="w-full py-4">
          {serviceTaskNotes.map((serviceTaskNote, index) => (
            <ServiceTaskNote key={index} taskNote={serviceTaskNote} />
          ))}
        </div>
      )}
    </>
  );
}

export default Notes;
