import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en';
import zh from './locales/zh';
import es from './locales/es';
import fr from './locales/fr';
import de from './locales/de';
import pt from './locales/pt';
import tr from './locales/tr';
import pl from './locales/pl';
import ru from './locales/ru';
import id from './locales/id';
import th from './locales/th';
import vn from './locales/vn';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: false,
    resources: {
      en: { translation: en },
      zh: { translation: zh },
      es: { translation: es },
      fr: { translation: fr },
      de: { translation: de },
      pt: { translation: pt },
      tr: { translation: tr },
      pl: { translation: pl },
      ru: { translation: ru },
      id: { translation: id },
      th: { translation: th },
      vn: { translation: vn },
    },
  });
