import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { useTranslation } from 'react-i18next';
import { SwipeAction, TrailingActions } from 'react-swipeable-list';
import { useToast } from '../../components/toast/ToastProvider';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import SwipeActionCard from './SwipeActionCard';

interface Props {
  repairActionId: number;
  efcId: number;
}

function RepairActionCardTrailingActions({ repairActionId, efcId }: Props) {
  const { pushToast } = useToast();
  const { t } = useTranslation();
  const handleClick = async () => {
    //Check if already saved
    if (
      (
        await db.savedItems
          .where({ IdGeneric: repairActionId, IdType: IDType.REPAIR_ACTION })
          .toArray()
      ).length === 0
    ) {
      //Get the last saved id
      const lastSavedId = await db.savedItems
        .orderBy('id')
        .last()
        .then((lastItem) => {
          return lastItem?.id ?? 1;
        });
      db.savedItems
        .add({
          IdGeneric: repairActionId,
          IdType: IDType.REPAIR_ACTION,
          id: lastSavedId + 1,
          Context: efcId.toString(),
        })
        .then(() =>
          pushToast({
            message: t(`{{item}} has been saved`, {
              item: `${t('Repair')} ${repairActionId}`,
            }),
          })
        );
    } else {
      pushToast({
        message: t(`{{item}} is already saved`, {
          item: `${t('Repair')} ${repairActionId}`,
        }),
      });
    }
  };

  return (
    <TrailingActions>
      <SwipeAction onClick={handleClick}>
        <SwipeActionCard
          bgColor="bg-pine"
          cardText={t('Save item')}
          icon={BookmarkOutlinedIcon}
        />
      </SwipeAction>
    </TrailingActions>
  );
}

export default RepairActionCardTrailingActions;
