import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectActiveSearch,
  selectRecentSearches,
  selectSavedSearches,
} from '../../../app/selectors';
import { searchActions } from '../../../features/search/searchSlice';
import { searchesAreEqual } from '../searchUtils';

/**
 * This hook updates the saved/recent search when it changes by comparing the previous
 * serialized value to the new serialized value and overriding the search at
 * the index where the previous search used to be.
 */
const useUpdateSearchInCollection = (
  collection: 'recentSearches' | 'savedSearches'
) => {
  const dispatch = useAppDispatch();
  const activeSearch = useAppSelector(selectActiveSearch);

  const recentSearches = useAppSelector(selectRecentSearches);
  const savedSearches = useAppSelector(selectSavedSearches);
  const searches = { recentSearches, savedSearches }[collection];

  const previousActiveSearch = useRef(activeSearch);
  useEffect(() => {
    if (activeSearch && previousActiveSearch.current) {
      const indexInSavedSearches = searches.findIndex((search) =>
        searchesAreEqual(search, previousActiveSearch.current)
      );

      if (
        indexInSavedSearches > -1 &&
        !searchesAreEqual(searches[indexInSavedSearches], activeSearch) &&
        activeSearch.searchTerm.trim() !== ''
      ) {
        dispatch(
          searchActions.replaceSearchInCollection({
            collection,
            index: indexInSavedSearches,
            search: activeSearch,
          })
        );
      }
    }

    previousActiveSearch.current = activeSearch;
  }, [activeSearch, searches]);
};

export default useUpdateSearchInCollection;
