import { IconProps, styled } from '@mui/material';
import Icon from '@mui/material/Icon';

type FetchingIconProps = IconProps & {
  contrastType: 'primary' | 'secondary';
  component: {
    muiName: string;
  };
};
const CustomFetchingIcon = styled(Icon, {
  shouldForwardProp: () => true,
})<FetchingIconProps>(({ contrastType }) => ({
  ...(contrastType === 'primary' && {
    '&': {
      color: 'white',
    },
  }),
  ...(contrastType === 'secondary' && {
    '&': {
      color: 'red',
    },
  }),
}));

const FetchingIcon = (props: FetchingIconProps) => {
  return <CustomFetchingIcon {...props} />;
};
export default FetchingIcon;
