import Feedback from './Feedback';

export class FailedFeedback {
  id: number;
  feedback: Feedback;

  constructor(id: number, feedback: Feedback) {
    this.id = id;
    this.feedback = feedback;
  }
}

export default FailedFeedback;
