import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import { Search } from '../../../types';

const useMatchingSearches = (searches: Search[]) => {
  const activeSearch = useAppSelector(selectActiveSearch);
  const searchTerm = activeSearch?.searchTerm ?? '';
  const scope = activeSearch?.scope ?? 'everywhere';

  const [filteredSavedSearches, setFilteredSavedSearches] = useState<Search[]>(
    []
  );

  useEffect(() => {
    const searchTermLowerCased = searchTerm.toLowerCase();
    setFilteredSavedSearches(
      searches.filter((search) => {
        return (
          search.scope === scope &&
          search.searchTerm.toLowerCase().includes(searchTermLowerCased)
        );
      })
    );
  }, [searchTerm, scope, searches]);

  return filteredSavedSearches;
};

export default useMatchingSearches;
