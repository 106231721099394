import CallToActionSettings from './CallToActionSettings';

export class FailedCallToActionSettings {
  callToActionSettings: CallToActionSettings;
  Id?: number;

  constructor(callToActionSettings: CallToActionSettings, Id?: number) {
    this.callToActionSettings = callToActionSettings;
    this.Id = Id;
  }
}

export default FailedCallToActionSettings;
