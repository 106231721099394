import Dexie from 'dexie';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import useLogging from '../hooks/useLogging';
import { DATABASE_INIT_TIMESTAMP_KEY, DATABASE_NAME } from '../index-db';

function ErrorFallback({ error }: FallbackProps) {
  const resetDatabase = async () => {
    await Dexie.delete(DATABASE_NAME);
    window.localStorage.removeItem(DATABASE_INIT_TIMESTAMP_KEY);
    window.location.reload();
  };

  const logging = useLogging();
  useEffect(() => {
    logging(
      'error',
      `Uncaught Exception ${error.message} ${error.stack}`,
      error,
      false,
      null
    );
  }, [error, logging]);

  return (
    <div>
      <p>An error occurred. {error.message}</p>

      {/* {process.env.NODE_ENV === 'development' && (
        <p>
          In case of possible Indexed DB error:{' '}
          <button
            className="text-cobalt font-medium"
            type="button"
            onClick={resetDatabase}
          >
            Reset database
          </button>
        </p>
      )} */}
    </div>
  );
}

export default ErrorFallback;
