import { useEffect } from 'react';
import EndPoint from '../../../Models/EndPoint';

import { LAST_ACCESSED_ENDPOINT } from '../../../constants';
import { useApi } from '../../../acfs-apis/dwar-api-provider';
import db from '../../../index-db';
import ManualEndPoint from '../../../Models/ManualEndPoint';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../components/toast/ToastProvider';

const useSetLastAccessedEnpoint = (serialNumber: string) => {
  const api = useApi();
  const { t } = useTranslation();
  const toast = useToast();
  const fetchEndPoints = async (ssnr: string): Promise<EndPoint[]> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointByFilter/?serialNumber=${ssnr}`
    )) as EndPoint[];
  };

  useEffect(() => {
    const setLastAccessedEndpoint = async (ssnr?: string) => {
      if (ssnr) {
        const endPoint = await fetchEndPoints(ssnr.toUpperCase());

        if (endPoint[0]?.id) {
          const endpoint = new ManualEndPoint(
            endPoint[0].id,
            endPoint[0].serialNumber,
            endPoint[0].prodDesc,
            endPoint[0].terminalId,
            endPoint[0].customerName,
            endPoint[0].preventiveInsights,
            endPoint[0].serviceInsights,
            endPoint[0]?.chronicDetails,
            endPoint[0].generatedSummary,
            endPoint[0]?.chronicIndicator,
            undefined,
            true
          );

          try {
            await db.manualEndPoints.add(endpoint);
          } catch (e) {
            console.log();
          } finally {
            window.localStorage.setItem(LAST_ACCESSED_ENDPOINT, endPoint[0].id);
          }
        } else {
          toast.pushToast({
            type: 'error',
            message: t(
              `No equipment data found for serial number {{number}}.`,
              {
                number: serialNumber,
              }
            ),
          });
        }
      }
    };
    setLastAccessedEndpoint(serialNumber);
  }, [serialNumber]);
};

export default useSetLastAccessedEnpoint;
