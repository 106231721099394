import { useTranslation } from 'react-i18next';
import Sheet from 'react-modal-sheet';
import Task from '../../Models/Task';
import ServiceTaskNote from './ServiceTaskNote';

interface Props {
  serviceTask: Task;
  isOpen: boolean;
  handleClose: () => void;
}

function ServiceTaskModal(props: Props) {
  const { t } = useTranslation();
  let effectiveDateTime = '';

  if (props.serviceTask.serviceTaskNotes) {
    props.serviceTask.serviceTaskNotes.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  if (
    props.serviceTask.effectiveDate &&
    props.serviceTask.effectiveDate !== ''
  ) {
    const effdate = new Date(props.serviceTask.effectiveDate);
    const hours =
      effdate.getHours() < 10
        ? `0${effdate.getHours()}`
        : effdate.getHours().toString();
    const minutes =
      effdate.getMinutes() < 10
        ? `0${effdate.getMinutes()}`
        : effdate.getMinutes().toString();
    const seconds =
      effdate.getSeconds() < 10
        ? `0${effdate.getSeconds()}`
        : effdate.getSeconds().toString();

    effectiveDateTime = `${hours}:${minutes}:${seconds} ${effdate.toDateString()}`;
  }

  return (
    <>
      <Sheet
        isOpen={props.isOpen}
        onClose={props.handleClose}
        snapPoints={[670, 500, 100, 10]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="max-h-max overflow-y-auto">
              <div className="mt-4 flex items-start justify-between px-4">
                <h2
                  className={'text-left text-lg font-bold text-cobalt'}
                  onClick={() => props.handleClose()}
                >
                  {t('Cancel')}
                </h2>
              </div>
              <div className="mt-4 flex items-start justify-between px-4">
                <h2 className={'text-left text-xl font-bold text-iron'}>
                  {t('Service Task Details')}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Task Number')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.taskNumber)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Task Status')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.taskStatus)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Task Owner')}:
                </h2>
                <h2
                  className={
                    'w-80 break-all text-left text-lg font-medium text-iron'
                  }
                >
                  {t(props.serviceTask.taskOwner)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Problem')}:
                </h2>
                <h2
                  className={
                    'w-80 overflow-auto text-left text-lg font-medium text-iron'
                  }
                >
                  {t(props.serviceTask.problem)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Task Type')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.taskType)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Customer')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.customer)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Effective Date')}:
                </h2>
                {props.serviceTask.effectiveDate && (
                  <h2
                    className={'w-80 text-left text-lg font-medium text-iron'}
                  >
                    {t(effectiveDateTime)}
                  </h2>
                )}
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Device Description')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.deviceDescription)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('Device Serial Number')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.deviceSerialNumber)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t('EndPoint Id')}:
                </h2>
                <h2 className={'w-80 text-left text-lg font-medium text-iron'}>
                  {t(props.serviceTask.endpointId)}
                </h2>
              </div>
              {props.serviceTask.serviceTaskNotes && (
                <div>
                  <div className="mt-4 mb-4 flex items-start justify-between px-4">
                    <h2
                      className={'w-80 text-left text-lg font-medium text-iron'}
                    >
                      {t(`Service Task Notes {{length}}.`, {
                        length: props.serviceTask.serviceTaskNotes.length,
                      })}
                    </h2>
                  </div>
                  <div>
                    {props.serviceTask.serviceTaskNotes.map(
                      (serviceTaskNote, index) => (
                        <ServiceTaskNote
                          key={index}
                          taskNote={serviceTaskNote}
                        />
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default ServiceTaskModal;
