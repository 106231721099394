import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionHeading from '../../components/SectionHeading';
import useTasks from '../../hooks/useTasks';

import MyTask from './MyTask';
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/css/controller';

import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Button from '../../components/Button';

import db from '../../index-db';
import { howManyDaysPassed } from '../../utils';

const deleteAudioAiSummaryPast2Days = async () => {
  await db.transaction('rw', db.audioAiSummary, async () => {
    const aiSummary = await db.audioAiSummary.toArray();
    let audioSummaryTobeDeleted: number[] = [];
    aiSummary.forEach((summary) => {
      const daysPassed = howManyDaysPassed(summary.lastLoadTime);
      if (daysPassed >= 2) {
        audioSummaryTobeDeleted.push(summary.key);
      }
    });
    db.audioAiSummary.bulkDelete(audioSummaryTobeDeleted);
  });
};
function MyTasks() {
  const { t } = useTranslation();

  const [selectedTask, setSelectedTask] = useState<number>(1);
  const [tasks, loading, forceReloadTasks] = useTasks();

  setTimeout(() => {
    deleteAudioAiSummaryPast2Days();
  }, 1000 * 60 * 60);
  const syncTasks = () => {
    if (!loading) {
      forceReloadTasks();
      setSelectedTask(tasks?.length ? 1 : 0);
    }
  };

  return (
    <div className="relative">
      <div className="ml-4 mb-0.5 flex items-center justify-between">
        <SectionHeading
          title={
            t('My tasks') +
            (!loading
              ? ` (${tasks?.length ? selectedTask : 0} of ${tasks?.length})`
              : '')
          }
          withMargins={false}
        />
        <div className="absolute right-0">
          <Button
            styling="tertiary"
            icon={SyncOutlinedIcon}
            onClick={syncTasks}
          >
            {t('Sync')}
          </Button>
        </div>
      </div>

      <div className="mb-2">
        {loading && (
          <div className="mx-4 h-[108px] rounded-lg border border-antartica" />
        )}
        {!loading && (
          <div>
            <Swiper
              className="swiper"
              modules={[Pagination]}
              slidesPerView={1}
              loop={false}
              onSlideChange={(swiperCore) => {
                setSelectedTask(swiperCore.activeIndex + 1);
              }}
              spaceBetween={0}
              grabCursor
              pagination={{ clickable: true, el: '.my-custom-pagination-div' }}
              touchEventsTarget="container"
            >
              <div className="flex w-full gap-x-2 overflow-x-auto px-4">
                {tasks?.map((task, index) => (
                  <SwiperSlide
                    className="pl-4 pr-4"
                    key={task.id}
                    virtualIndex={index}
                  >
                    {index <= selectedTask + 3 && (
                      <MyTask key={task.id} task={task} />
                    )}
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
            <div className="pt-1 text-center">
              <div className="my-custom-pagination-div pl-4" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyTasks;
