import React from 'react';
import { To, useNavigate } from 'react-router-dom';
import ActionableListItem from '../../components/ActionableListItem';
import {
  RadioButtonInterface,
  InputTextBoxInterface,
} from '../../Models/ReusableComponents';

interface Props {
  title: string;
  radioButtons?: RadioButtonInterface;
  inputTextBox?: InputTextBoxInterface;
  input?: boolean;
  value?: string;
  to?: To;
  hideRightArrowIcon?: boolean;
}

function SettingRow(props: Props) {
  const navigate = useNavigate();

  return (
    <ActionableListItem
      title={props.title}
      subText={props.value}
      subTextPosition={'right of title'}
      primaryAction="navigate"
      onClick={() => navigate(props.to as string)}
      appearance="setting"
      hidePrimaryActionIcon={props.hideRightArrowIcon}
      radioButtons={props?.radioButtons}
      inputTextbox={props?.inputTextBox}
    />
  );
}

SettingRow.defaultProps = {
  hideRightArrowIcon: false,
  to: '',
  value: '',
  dynamicData: <></>,
};
export default SettingRow;
