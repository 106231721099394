import React from 'react';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/Icon';
import TaskNote from '../../Models/TaskNote';
import { t } from 'i18next';

interface Props {
  taskNote: TaskNote;
}

function ServiceTaskNote({ taskNote }: Props) {
  const { t } = useTranslation();
  let currentDate = new Date(Date.now());
  let timestamp = new Date(taskNote.date);

  const diffInTime = currentDate.getTime() - timestamp.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  const hours =
    timestamp.getHours() < 10
      ? `0${timestamp.getHours()}`
      : timestamp.getHours().toString();
  const minutes =
    timestamp.getMinutes() < 10
      ? `0${timestamp.getMinutes()}`
      : timestamp.getMinutes().toString();
  const seconds =
    timestamp.getSeconds() < 10
      ? `0${timestamp.getSeconds()}`
      : timestamp.getSeconds().toString();

  return (
    <div className="my-task-card-equipment mb-2 w-full pl-4 pr-4">
      <div className="flex w-6 shrink-0 items-center justify-center rounded-l-lg bg-steel">
        <Icon is={NoteOutlinedIcon} size="1rem" colorClass="text-white" />
      </div>
      <div className="w-full min-w-0 rounded-r-lg border-2 border-antartica bg-white pl-2">
        <div className="text-left">
          <div className="overflow-ellipsis pt-2 font-bold text-steel">
            {diffInDays} {'days ago'}
          </div>
          <div className="flex items-start justify-between">
            <div className="overflow-ellipsis">
              {hours}:{minutes}:{seconds}
              {' ('}
              {timestamp.toDateString()}
              {') '} {taskNote.creator}
            </div>
          </div>
          <div className="break-words pt-2">{t(taskNote.note)}</div>
        </div>
      </div>
    </div>
  );
}
export default ServiceTaskNote;
