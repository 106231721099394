import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VerifiedOutlined from '@mui/icons-material/Verified';

const useGetRAPerformedIcon = (feedback: string) => {
  let fixedIconColor = '';
  let fixedIcon;
  switch (feedback) {
    case 'Relevant':
      fixedIconColor = 'mt-1 rounded-full text-center bg-cobalt';
      fixedIcon = ThumbUpOutlinedIcon;
      break;
    case 'Not relevant':
      fixedIconColor = 'mt-1 rounded-full text-center bg-dnred';
      fixedIcon = ThumbDownOutlinedIcon;
      break;
    case 'Fixed my issue':
      fixedIconColor = 'mt-1 rounded-full text-center bg-purple';
      fixedIcon = VerifiedOutlinedIcon;
      break;
    case 'Feedback':
      fixedIconColor = 'mt-1 rounded-full text-center bg-steel';
      fixedIcon = CommentOutlinedIcon;
      break;
    default:
      fixedIconColor = 'mt-1 rounded-full text-center bg-cobalt';
      fixedIcon = VerifiedOutlined;
  }

  return { fixedIcon, fixedIconColor };
};

export default useGetRAPerformedIcon;
