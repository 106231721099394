import React from 'react';
import { IServiceSiteMessage } from '../../Models/ServiceSiteMessage';

interface Props {
  index: number;
  siteMessage: IServiceSiteMessage;
  title: string;
}

function SiteMessage(props: Props) {
  return (
    <div className={'mt-4 flex flex-row items-start justify-between px-4'}>
      <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
        {props?.title}
      </div>
      <div
        className={'w-1/2 break-words text-left text-lg font-medium text-iron'}
      >
        {props?.siteMessage?.note}
      </div>
    </div>
  );
}

export default SiteMessage;
