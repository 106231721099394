import { useLiveQuery } from 'dexie-react-hooks';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { searchActions } from '../../../features/search/searchSlice';
import useNavigateToSearchResults from '../../../hooks/useNavigateToSearchResults';
import db from '../../../index-db';
import { Search } from '../../../types';
import useLogging from '../../../hooks/useLogging';
import LogParams from '../../../Models/LogParams';
import { LogType } from '../../../enums/LogType';

import useSetLastAccessedEnpoint from './useSetLastAccessedEnpoint';

function ModuleResults() {
  const dispatch = useAppDispatch();
  const logging = useLogging();
  const [searchParams] = useSearchParams();
  const navigateToSearchResults = useNavigateToSearchResults();

  const moduleabbrev = searchParams.get('moduleabbrev') as string;
  const keyword = searchParams.get('keyword') as string;
  const serialNumber = searchParams.get('ssnr') as string;
  useSetLastAccessedEnpoint(serialNumber);
  const logParams: LogParams[] = [
    {
      key: LogType.Page,
      value: LogType.ModuleResults,
    },
    {
      key: LogType.ModuleAbbreviation,
      value: moduleabbrev,
    },
    {
      key: LogType.KeyWord,
      value: keyword,
    },
    {
      key: LogType.ExternalSource,
      value: 'true',
    },
  ];
  logging('info', '', '', false, logParams);
  useLiveQuery(async () => {
    const search: Search = {
      scope: 'efc codes',
      searchTerm: keyword === null ? '' : keyword,
      filters: {},
      timestamp: Date.now(),
    };
    if (moduleabbrev) {
      const module = await db.modules
        .where('Abbreviation')
        .equals(moduleabbrev)
        .first();
      if (module) {
        search.filters = {
          Module: { value: module.Id, label: module.ModuleName },
        };
      } else {
        search.filters = {
          Module: {
            value: 'invalid',
            label: `${moduleabbrev}(Invalid Module abbreviation)`,
          },
        };
      }
    }
    (() => {
      dispatch(searchActions.changeActiveSearch(search));
      navigateToSearchResults({ awaitUpdatedSearchTerm: true });
    })();
  }, [moduleabbrev, keyword]);
  return <></>;
}

export default ModuleResults;
