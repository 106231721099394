import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../components/toast/ToastProvider';
import LogParams from '../Models/LogParams';
import { LogType } from '../enums/LogType';
import useLogging from './useLogging';
import db from '../index-db';

const useNavigateToPart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const logging = useLogging();
  return (id: string, selectedPartId?: string) => {
    (async () => {
      const part = await db.parts.where('ManufactureNumber').equals(id)
      .or('MaterialId').equals(id).and(part => part.IsDsaPartMissing === false).first();
      if (part === undefined || part?.IsDsaPartMissing) {
        let dsaPartMissingMsg = t(
          `Part {{partId}} cannot be found in DNSpares data`,
          {
            partId: id,
          }
        );
        toast.pushToast({
          message: dsaPartMissingMsg,
        });
        const logParams: LogParams[] = [
          {
            key: LogType.Page,
            value: LogType.Part,
          },
          {
            key: LogType.PartNumber,
            value: id,
          },
        ];
        logging('info', dsaPartMissingMsg, '', false, logParams);
      } else {
        selectedPartId != undefined
          ? navigate(`/parts/${id}/${selectedPartId}`)
          : navigate(`/parts/${part.MaterialId}`);
      }
    })();
  };
};

export default useNavigateToPart;
