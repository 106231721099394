import { t } from 'i18next';
import { IIdentifiable } from './IIdentifiable';
import { RALinks } from './RALinks';
import { SubStep } from './SubStep';

export interface IRepairAction extends IIdentifiable {
  // Id: number;
  ModuleId: number;
  Name: string;
  Description: string;
  TimeToFix: number;
  Language: number;
  TellerRepairable: number;
  RemotelyRepairable: number;
  RepairComplexity: string | null;
  PartNumbers: number[] | null;
  FamilyIds: number[] | null;
  ModelIds: number[] | null;
  SubSystemIds: number[] | null;
  SubSteps: SubStep[] | null;
  RepairActionLinks: RALinks[] | null;
  LastUpdate: string;
  LastAction: number;
  Rating: number;
}

export class RepairAction implements IRepairAction {
  Id: number;
  ModuleId: number;
  Name: string;
  Description: string;
  TimeToFix: number;
  Language: number;
  TellerRepairable: number;
  RemotelyRepairable: number;
  RepairComplexity: string | null;
  PartNumbers: number[] | null;
  FamilyIds: number[] | null;
  ModelIds: number[] | null;
  SubSystemIds: number[] | null;
  SubSteps: SubStep[] | null;
  RepairActionLinks: RALinks[] | null;
  LastUpdate: string;
  LastAction: number;
  Rating: number;

  constructor(
    moduleId: number,
    id: number,
    name: string,
    description: string,
    timeToFix: number,
    language: number,
    tellerRepairable: number,
    remotelyRepairable: number,
    repairComplexity: string | null,
    PartNumbers: number[] | null,
    familyIds: number[] | null,
    modelIds: number[] | null,
    subSystemIds: number[] | null,
    subSteps: SubStep[] | null,
    repairActionLinks: RALinks[] | null,
    lastUpdate: string,
    lastAction: number,
    rating: number
  ) {
    this.Id = id;
    this.ModuleId = moduleId;
    this.Name = name;
    this.Description = description;
    this.TimeToFix = timeToFix;
    this.Language = language;
    this.TellerRepairable = tellerRepairable;
    this.RemotelyRepairable = remotelyRepairable;
    this.RepairComplexity = repairComplexity;
    this.PartNumbers = PartNumbers;
    this.FamilyIds = familyIds;
    this.ModelIds = modelIds;
    this.SubSystemIds = subSystemIds;
    this.SubSteps = subSteps;
    this.RepairActionLinks = repairActionLinks;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
    this.Rating = rating;
  }

  GetHeading(): string {
    return `${t('Repair')} ${this.Id}`;
  }
}

export default RepairAction;
