import { useTranslation } from 'react-i18next';

type Transformation = 'primary' | 'secondary';

const useLanguageTransformer = (transformation: Transformation = 'primary') => {
  const { i18n } = useTranslation();
  let lang;

  switch (i18n.language) {
    case 'de':
      lang = 'de-DE';
      break;
    case 'pl':
      lang = 'pl-PL';
      break;
    case 'id':
      lang = 'id-ID';
      break;
    case 'ru':
      lang = 'ru-RU';
      break;
    case 'pt':
      lang = 'pt-PT';
      break;
    case 'fr':
      lang = 'fr-FR';
      break;
    case 'tr':
      lang = 'tr-TR';
      break;
    case 'es':
      lang = 'es-ES';
      break;
    case 'vn':
      lang = transformation === 'primary' ? 'vn-VN' : 'vi-VN';
      break;
    case 'th':
      lang = 'th-TH';
      break;
    case 'zh':
      lang = transformation === 'primary' ? 'zh-ZH' : 'zh-CN';
      break;
    default:
      lang = transformation === 'primary' ? 'en' : 'en-US';
  }
  return lang;
};

export default useLanguageTransformer;
