import classnames from 'classnames';
import React from 'react';

interface RequiredProps {
  description: string;
}

interface OptionalProps {
  appearance?: 'regular' | 'setting' | 'selectable';
}

const defaultProps: OptionalProps = {
  appearance: 'regular',
};

type Props = RequiredProps & OptionalProps;

function SimpleText(props: Props) {
  return (
    <div
      className={classnames(
        'flex h-auto items-center gap-x-3 bg-ice py-4 px-4 text-left',
        {
          'border-b border-antartica': props.appearance !== 'setting',
          'mb-px': props.appearance === 'setting',
        }
      )}
    >
      <div className={classnames('min-w-0 flex-1')}>
        <p className={classnames('text-lg font-bold')}>{props.description}</p>
      </div>
    </div>
  );
}

SimpleText.defaultProps = defaultProps;

export default SimpleText;
