import db from '../index-db';
import { IDType } from '../Models/Enumerations';

const useAddToRecentlyViewed = () => {
  return async (
    idGeneric: string | number,
    idType: IDType,
    context?: string
  ) => {
    db.transaction('rw', db.recentlyViewed, async () => {
      if (idGeneric) {
        const recentRecord = await db.recentlyViewed
          .where({
            IdGeneric: idGeneric,
            IdType: idType,
          })
          .first();
        if (recentRecord) {
          db.recentlyViewed.update(recentRecord.id, {
            timestamp: Date.now(),
            Context: context ?? '',
          });
        } else {
          //Get the last saved id
          const lastItem = await db.recentlyViewed.orderBy('id').last();
          const lastSavedId = lastItem?.id ?? 1;
          db.recentlyViewed.put({
            IdGeneric: idGeneric,
            IdType: idType,
            id: lastSavedId + 1,
            timestamp: Date.now(),
            Context: context ?? '',
          });
        }
      }
    });
  };
};

export default useAddToRecentlyViewed;
