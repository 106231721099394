import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  children: JSX.Element;
  onContentVisible: () => void;
  lastItemIndex: boolean;
}
const LazyLoader = (props: Props) => {
  const { children, onContentVisible, lastItemIndex } = props;
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      onContentVisible();
    }
  }, [inView, onContentVisible]);

  return <div ref={lastItemIndex ? ref : null}>{children}</div>;
};

export default LazyLoader;
