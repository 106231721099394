import { useNavigate } from 'react-router-dom';

const useNavigateToEfc = () => {
  const navigate = useNavigate();
  return (
    id: string,
    fullFeedback: boolean = false,
    externalSource: boolean = false,
    isReplace?: boolean
  ) => {
    navigate(`/efc/${id}`, {
      state: {
        externalSource: externalSource,
        fullFeedback: fullFeedback,
      },
      replace: isReplace,
    });
  };
};

export default useNavigateToEfc;
