import { useLiveQuery } from 'dexie-react-hooks';
import db from '../index-db';
import { IDType } from '../Models/Enumerations';
import GenericModel from '../Models/GenericModel';
import GenericModelData, {
  TGenericModelDataFactory,
} from '../Models/GenericModelData';
import { InitOptions as PageInitOptions, usePage } from '../PageProvider';

const factoryMethodsMap: Record<IDType, TGenericModelDataFactory> = {
  [IDType.EFC]: GenericModelData.CreateFromEFC,
  [IDType.SEARCH]: GenericModelData.CreateFromSearchItem,
  [IDType.PARTS]: GenericModelData.CreateFromPart,
  [IDType.REPAIR_ACTION]: GenericModelData.CreateFromRepairAction,
  [IDType.PRODUCTS]: GenericModelData.CreateFromProduct,
};

const dbTypeMap = {
  [IDType.EFC]: db.efcs,
  [IDType.SEARCH]: db.searchedItems,
  [IDType.PARTS]: db.parts,
  [IDType.PRODUCTS]: db.products,
  [IDType.REPAIR_ACTION]: db.raactions,
};

const useGenericDataListingPage = (
  pageInitOptions: PageInitOptions,
  genericModels: GenericModel[] | undefined
) => {
  usePage(() => pageInitOptions, [pageInitOptions]);

  const genericModelDataMap = useLiveQuery(async () => {
    const entitiesByTypeMap = new Map<Number, GenericModelData[]>();

    entitiesByTypeMap.set(IDType.EFC, []);
    entitiesByTypeMap.set(IDType.PRODUCTS, []);
    entitiesByTypeMap.set(IDType.PARTS, []);
    entitiesByTypeMap.set(IDType.REPAIR_ACTION, []);

    await Promise.resolve(
      // Dexie requires Promise.resolve, see https://dexie.org/docs/dexie-react-hooks/useLiveQuery()#calling-non-dexie-apis-from-querier
      Promise.all(
        (genericModels ?? []).map(async (genericModel) => {
          const idType: IDType = genericModel.IdType;
          const factoryMethod = factoryMethodsMap[idType];
          const res = await dbTypeMap[idType].get(genericModel.IdGeneric);
          if(res !== undefined)
          {
            entitiesByTypeMap
              .get(idType)
              ?.push(
                factoryMethod(
                  res,
                  genericModel.id,
                  genericModel.Context
                )
              );
          }
        })
      )
    );

    return entitiesByTypeMap;
  }, [genericModels]);

  return {
    genericModelDataMap,
  };
};

export default useGenericDataListingPage;
