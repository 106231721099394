import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useComponentWillUnmount = (cleanupCallback = () => {}) => {
  // Borrowed from https://pgarciacamou.medium.com/component-will-unmount-react-hook-e826741452c6
  const callbackRef = useRef(cleanupCallback);
  callbackRef.current = cleanupCallback;
  useEffect(() => {
    return () => callbackRef.current();
  }, []);
};
