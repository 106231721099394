import ClearAllOutlined from '@mui/icons-material/History';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import { useLiveQuery } from 'dexie-react-hooks';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import CollapsibleCollection from '../../components/collapsible/CollapsibleCollection';
import PageActions from '../../components/PageActions';
import { useToast } from '../../components/toast/ToastProvider';
import { searchActions } from '../../features/search/searchSlice';
import useGenericDataListingPage from '../../hooks/useGenericDataListingPage';
import useInitiallyExpandedCategory from '../../hooks/useInitiallyExpandedCategory';
import useRecentSearchData from '../../hooks/useRecentSearchData';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import { LogType } from '../../enums/LogType';
import GenericModel from '../../Models/GenericModel';
import { InitOptions as PageInitOptions } from '../../PageProvider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import Category from './Category';
import useCheckUserIsPartner from '../../hooks/useCheckUserIsPartner';

function RecentlyViewed() {
  const dispatch = useAppDispatch();
  const { pushToast } = useToast();
  const { t } = useTranslation();
  const logging = useLogging();

  const isPartner = useCheckUserIsPartner();

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.RecentlyViewed,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  const pageInitOptions = useMemo<PageInitOptions>(
    () => ({
      getTitle: () => t('Recently viewed'),
      belongsToNavBarItem: 'Home',
      showBackButton: true,
    }),
    [t]
  );

  const recentlyViewedRecords = useLiveQuery<GenericModel[]>(() => {
    return db.recentlyViewed.toArray();
  });

  const { genericModelDataMap } = useGenericDataListingPage(
    pageInitOptions,
    recentlyViewedRecords
  );

  const recentSearchData = useRecentSearchData();

  const clearAll = async () => {
    db.recentlyViewed.clear();
    dispatch(searchActions.deleteAllRecentSearches());
    pushToast({ type: 'success', message: t('All recent items cleared') });
  };

  const { allCollapsed, setAllCollapsed, getStartCollapsed } =
    useInitiallyExpandedCategory();

  return (
    <>
      <PageActions
        actionsProps={[
          {
            icon: ClearAllOutlined,
            title: t('Clear All'),
            onClick: clearAll,
          },
          {
            icon: allCollapsed
              ? UnfoldMoreOutlinedIcon
              : UnfoldLessOutlinedIcon,
            title: allCollapsed ? t('Expand all') : t('Collapse all'),
            onClick: () => setAllCollapsed(!allCollapsed),
          },
        ]}
      />

      {genericModelDataMap && (
        <div className="mt-3">
          <CollapsibleCollection collapsed={allCollapsed}>
            <Category
              recentlyViewedData={recentSearchData ?? []}
              title={t('Search results')}
              startCollapsed={getStartCollapsed(IDType.SEARCH)}
            />
            <Category
              recentlyViewedData={genericModelDataMap.get(IDType.EFC) ?? []}
              title={t('EFC codes')}
              startCollapsed={getStartCollapsed(IDType.EFC)}
            />
            <Category
              recentlyViewedData={
                genericModelDataMap.get(IDType.REPAIR_ACTION) ?? []
              }
              title={t('Repairs')}
              startCollapsed={getStartCollapsed(IDType.REPAIR_ACTION)}
            />
            {!isPartner && (
              <Category
                recentlyViewedData={genericModelDataMap.get(IDType.PARTS) ?? []}
                title={t('Parts')}
                startCollapsed={getStartCollapsed(IDType.PARTS)}
              />
            )}
          </CollapsibleCollection>
        </div>
      )}
    </>
  );
}

export default RecentlyViewed;
