import { useTranslation } from 'react-i18next';
import SectionHeading from '../../../components/SectionHeading';
import SystemsCard from '../../../components/cards/SystemsCard';
import GenericModalSheet from '../../../components/GenericModalSheet';
import { useState } from 'react';
import AudioAiSummary from '../../../components/AudioAISummary';
import useLogging from '../../../hooks/useLogging';
import { LogType } from '../../../enums/LogType';
import { CallToActionType } from '../../../Models/Enumerations';
import useHandleCtaRead from '../../../hooks/useHandleCtaRead';

interface AiSummaryContentProps {
  summaryText?: string;
  endpointId: string;
  taskNumber?: string;
  isRead: boolean;
}

const AiSummaryContent = (props: AiSummaryContentProps) => {
  const { t } = useTranslation();
  const { summaryText, endpointId, taskNumber, isRead } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const logging = useLogging();
  const [isAiSummaryRead, saveCallToActionSetting] = useHandleCtaRead(isRead);
  if (!summaryText) {
    return null;
  }
  const onSuccessfulSave = () => {
    if (endpointId && isAiSummaryRead === 'primary') {
      saveCallToActionSetting(
        CallToActionType.AiSummary,
        endpointId,
        'Audio-Text',
        taskNumber
      );
    }
  };
  const DynamicComp = summaryText ? (
    <AudioAiSummary
      summaryText={summaryText}
      onSuccessfulPlay={onSuccessfulSave}
      readState={isAiSummaryRead}
    />
  ) : (
    <></>
  );
  const data = [
    {
      title: t('AI Summary'),
      value: summaryText,
      extraComp: DynamicComp,
    },
  ];
  const onSheetClose = () => {
    setIsModalOpen(false);
  };

  const onCardClick = () => {
    onSuccessfulSave();
    setIsModalOpen(true);
    logging(
      'info',
      'User viewed Ai summary from the Endpoint page',
      undefined,
      true,
      [
        {
          key: LogType.AiSummary,
          value: 'User viewed Ai summary from the Endpoint page',
        },
      ]
    );
  };

  return (
    <>
      <div className="mt-6 mb-2 flex flex-row">
        <SectionHeading title={t('AI Summary')} withMargins={false} />
      </div>
      <SystemsCard
        iconColor="steel"
        clickableType="secondary"
        onCardClick={onCardClick}
        readUnreadIconType={isAiSummaryRead}
        ExtraComp={DynamicComp}
      >
        <div className=" max-w-[200px] truncate ">{summaryText}</div>
      </SystemsCard>

      <GenericModalSheet
        isBottomsheetOpen={isModalOpen}
        data={data}
        onSheetClose={onSheetClose}
      />
    </>
  );
};

export default AiSummaryContent;
