const translation = {
  'You switched to {{ language }}': 'Geçiş yapıyorsunuz {{ language }}',
  'Turkish': 'Türkçe',
  'Home': 'Ana sayfa',
  'Search': 'Arama',
  'Saved': 'Kaydedildi',
  'Me': 'Ben',
  'Menu': 'Menü',
  'English': 'İngilizce',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': 'Ayarlar',
  'Language': 'Dil',
  'Saved Items': 'Kaydedilmiş Öğeler',
  'My tasks': 'Görevlerim',
  'Parts': 'Bölümler',
  'Saved items': 'Kaydedilmiş Öğeler',
  'Notifications': 'Bildirimler',
  'Help & Feedback': 'Yardım & Geri Bildirim',
  'Logout': 'Çıkış',
  'Add to equipment list': 'Ekipman listesine ekle',
  'Download for offline': 'Çevrimdışı olarak indirin',
  'Repair Action': 'Onarım işlemi',
  'Repair action': 'Onarım işlemi',
  'Part': 'Bölüm',
  'Refreshed {{time}} min ago': 'Yenilenmiş {{time}} dakika önce',
  'Recently viewed': 'Son görüntülenenler ',
  'View all': 'Tümünü görüntüle',
  'Clear All': 'Tümünü temizle',
  'Expand all': 'Tümünü genişlet',
  'Collapse all': 'Tümünü daralt',
  'Search results': 'Arama sonuçları',
  'Repairs': 'Onarımlar',
  'Downloading content...': 'İçerik indiriliyor',
  'Bookmarks': 'Yer imleri',
  'Downloads': 'İndirmeler',
  'Recent': 'Güncel',
  'Recent codes': 'Güncel kodlar',
  'Saved codes': 'Kaydedilen kodlar ',
  'Updates': 'Güncellemeler',
  'Clear': 'Temizle',
  'Updated Content for {{item}}.': 'Öğe için Güncellenmiş {{item}}.',
  '{{item}} has been saved': '{{item}} kaydedildi  ',
  '{{item}} could not be saved': '{{item}} kaydedilemedi',
  '{{item}} has been removed from saved': '{{item}} kaydedilenlerden çıkarıldı',
  '{{item}} could not be removed from saved':
    '{{item}} kaydedilenlerden çıkarılamadı',
  'Difficulty': 'Zorluk',
  'Repair time': 'Onarım süresi',
  'min_one': '1 dakika',
  'min_other': '{{count}} dakika ',
  'min_zero': '0 dakika',
  'Rating': 'Değerlendirme',
  'Thank you for the feedback': 'Geri bildiriminiz için teşekkürler',
  'Not relevant': 'Ilgili değil',
  'Relevant': 'Ilgili',
  'Fixed my issue': 'Sorunum çözüldü',
  'Leave feedback': 'Geri bildirimden ayrılın',
  'Save item': 'Öğeyi kaydet',
  'Low': 'Düşük seviye',
  'Medium': 'Orta seviye',
  'High': 'Yüksek seviye',
  'This was relevant info': 'Bu ilgili bir bilgiydi',
  'This was not relevant info': 'Bu ilgili bir bilgi değildi',
  'Add to saved items': 'Kayıtlı öğelere ekle',
  'Remove from downloads': 'İndirilenlerden kaldır',
  'Search AllConnect Field Support': 'AllConnect Field Support Ara',
  'Filters': 'Filtreler',
  'Page not found': 'Sayfa bulunamadı',
  'Return home': 'Ana sayfaya dön',
  'Clear all bookmarks': 'Tüm yer imlerini temizle',
  'Search all EFC codes': 'Tüm EFC kodlarını ara',
  'Search all parts': 'Tüm bölümleri ara',
  'Results ({{resultsCount}})': 'Sonuçlar ({{resultsCount}})',
  '{{item}} is already saved': '{{item}} kaydedilmiştir',
  'Details': 'Detaylar',
  'Equipment': 'Ekipman',
  'Notes': 'Notlar',
  'Preventive Insights': 'Önleyici öngörüler',
  'Service Insights': 'Hizmet içgörüleri',
  'Equipment Information': 'Ekipman bilgileri',
  'Equipment ID': 'Ekipman ID',
  'Model': 'Model',
  'Terminal ID': 'Terminal ID',
  'Device serial number': 'Cihaz seri numarası',
  'EFC codes': 'EFC kodları',
  'Steps': 'Adımlar',
  'Module content': 'Modül içeriği',
  'Repair action content': 'Onarım eylem içeriği',
  'Fixed issue': 'Düzeltilen sorun',
  'Part Number {{partId}} copied to the clipboard':
    'Bölüm numarası {{partId}} panoya kopyalandı',
  'Relationship tree': 'İlişki ağacı',
  'Potential substitutions': 'Potansiyel ikameler',
  'Loading': 'Yükleniyor',
  'Removed from saved searches': 'Kayıtlı aramalardan kaldırıldı',
  'Added to saved searches': 'Kayıtlı aramalara eklendi',
  'All bookmarks cleared': 'Tüm yer imleri temizlendi',
  'No bookmarks to clear': 'Temizlenecek yer imi yok',
  'All recent items cleared': 'Tüm yeni öğeler temizlendi',
  'No recent items to clear': 'Temizlenecek yeni öğe yok',
  'You have no recently view history.': 'Son görüntüleme geçmişiniz yok',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    'Dil değiştirme 2 aşamada yapılır. Etiketler ve başlıklar gibi statik veriler hemen değiştirilecektir. İçerik verileri için çevrimiçi bağlantı gerekir ve yeni dil seçiminin yenilenmesi bağlantınıza bağlı olarak bir dakika veya daha uzun sürebilir.  İçerik indirilene kadar uygulama içerik için önceki dili göstermeye devam edecektir.',
  'Signing you in': 'Giriş yapıyorsunuz',
  'Signing you out': 'Çıkışınız yapiliyor',
  'Oops': 'Tekrar deneyin',
  'Switching language to {{ language }}': 'Dil değiştirme {{ language }}',
  'Data has been fetched in': 'Veriler şu adrese getirilmiştir',
  'Failed to fetch data in': 'Veri getirilemedi',
  'No results': 'Sonuç yok',
  'URL': 'URL adresi',
  'User Name': 'Kullanıcı adı',
  'ACFS App': 'ACFS uygulaması',
  'ACFS UI': 'ACFS kullanıcı arayüzü',
  'Offline Data': 'Çevrimdışı veri',
  'Info': 'Bilgi',
  'Repair': 'Onarım',
  'RepairActionCount 1': '1 Onarım işlemi',
  'RepairActionCount {{count}}': '{{count}} Onarım işlemleri',
  'RepairActionCount 0': '0 Onarım işlemi',
  'All': 'Tümü',
  'Sync': 'Senkronizasyon',
  'Last 30 days': 'Son 30 gün',
  'Not yet implemented': 'Henüz uygulanmadı',
  'Categories': 'Kategoriler',
  'Repair actions': 'Onarım işlemleri',
  'Module': 'Modül',
  'Preferences': 'Tercihler',
  'Service Manuals': 'Servis kılavuzları',
  'Service Bulletins': 'Servis bültenleri',
  'FCOs': 'Tam evrişimli tek aşamalı nesne algılama',
  'FAQs': 'Sıkça sorulan sorular (SSS)',
  'Training Content': 'Eğitim İçeriği',
  'Your feedback has been sent': 'Geri bildiriminiz gönderildi',
  'Failed to send your feedback': 'Geri bildiriminiz gönderilemedi',
  'Comments are required': 'Yorumlar gereklidir',
  'Comments should not be more than 100 characters':
    'Yorumlar 100 harften fazla olmamalıdır',
  'Max {{numOfPhotos}} photos can be uploaded':
    'Maksimum {{numOfPhotos}} fotoğraf yüklenebilir',
  'Add this Task - {{taskNumber}}': 'Bu görevi ekleyin - {{taskNumber}}',
  "Other '{{inputValue}}'": "Diğer '{{inputValue}}'",
  'Other (Type to add) / Blank': 'Diğer (Type to add) / Boş',
  'General feedback': 'Genel geri bildirim',
  'Feedback: EFC code - {{efcCode}}': 'Geri bildirim: EFC kodu - {{efcCode}}',
  'Module: {{moduleName}}': 'Modül: {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    'Belirli bir EFC Kodu, Onarım işlemi veya kısım için geri bildirim, bu belirli girişlerde bulunan geri bildirim seçeneği kullanılarak gönderilmelidir.',
  'Corrective action required': 'Düzeltici işlem gerekli',
  'Yes': 'Evet',
  'No': 'Hayır',
  'Task number': 'Görev numarası',
  'Select or Type to add': 'Eklemek için seçin veya yazın',
  'Comments': 'Yorumlar',
  'Delete': 'Silme',
  'Open Camera': 'Kamerayı açın',
  'Upto {{maxFiles}} images can be selected':
    'En fazla {{maxFiles}} resim seçilebilir',
  'Cancel': 'Iptal',
  'Add': 'Ekle',
  'Add equipment': 'Ekipman ekleyin',
  'Serial number': 'Seri numarası',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    'Ekipman kodunu okutun veya seri numarasını girerek söz konusu ekipmana ilişkin bilgileri ve ayrıntıları görüntüleyin.',
  'Last synced: {{time}} min ago': 'Son senkronizasyon: {{time}} dakika önce',
  'Send': 'Gönder',
  "Task Number must be provided if 'Corrective action required' is Yes":
    "'Düzeltici işlem gerekli' Evet ise Görev Numarası verilmelidir",
  'Time to fix (minutes)': 'Düzeltme zamanı (dakika)',
  'Time to fix should not be more than 999':
    "Düzeltme süresi 999'dan fazla olmamalıdır",
  'Please enter positive numbers only': 'Lütfen sadece pozitif sayılar yazınız',
  'Please enter numbers only': 'Lütfen sadece sayı yazınız',
  'Clear filters': 'Filtreleri temizle',
  'Show results': 'Sonuçları göster',
  'Your feedback has been submitted': 'Geri bildiriminiz gönderildi',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    'Bu EFC şu anda aktif olan bir artırma bağlantısıyla ilgilidir.Lütfen artırmanın ilgili olup olmadığını bize bildirin (gerçekleştirilecek uygun işlem).Ek geri bildirim bırakmak istiyorsanız "Geri bildirim bırak" seçeneğini seçin',
  'Products': 'Ürünler',
  'Product Number {{productId}} copied to the clipboard':
    'Ürün Numarası {{productId}} panoya kopyalandı',
  'View Groups': 'Grupları görüntüle',
  'Search all products': 'Tüm ürünleri ara',
  'Current': 'Mevcut',
  'History': 'Geçmiş',
  'No equipment data found for serial number {{number}}.':
    'Seri numarası için ekipman verisi bulunamadı {{number}}.',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    'Seri numarası için ekipman verileri alınamıyor {{number}}.',
  'Unable to retrieve equipment data. Falling back to offline data.':
    'Ekipman verileri alınamıyor. Çevrimdışı verilere geri dönülüyor.',
  'Unable to retrieve service task. Falling back to offline data.':
    'Hizmet görevi alınamıyor. Çevrimdışı verilere geri dönülüyor.',
  'Please wait while data is being loaded in {{language}}':
    'Veri yüklenirken lütfen bekleyin {{language}}',
  'Errors': 'Hatalar',
  'Tasks': 'Görevler',
  'History Parts': 'Geçmiş Bölümler',
  'Unable to retrieve Service Task history.':
    'Hizmet Görevi geçmişi alınamıyor.',
  'Recommended Parts': 'Önerilen Kısımlar',
  'Service Task Details': 'Hizmet görevi ayrıntıları',
  'Task Number': 'Görev Numarası',
  'Task Status': 'Görev Statüsü',
  'Problem': 'Sorun',
  'Task Type': 'Görev türü',
  'Customer': 'Müşteri',
  'Effective Date': 'Yürürlük Tarihi',
  'Device Description': 'Cihaz açıklaması',
  'Device Serial Number': 'Cihaz seri numarası',
  'EndPoint Id': 'Uç nokta Id',
  'Service Task Notes {{length}}.': 'Hizmet görev notları {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    'Bölüm {{partId}} DNSpares verilerinde bulunamıyor',
  'Number': 'Sayı',
  'Site Number': 'Site numarası',
  'Address': 'Adres',
  'Contact Date': 'İletişim tarihi',
  'Contact Name': 'İletişim adı',
  'Contact Email': 'İletişim E-posta',
  'Contact Phone': 'İletişim telefonu',
  'Contract Hours': 'Kontrat saatleri',
  'Site Messages:': 'Site mesajları:',
  'Type': 'Tür',
  'Product': 'Ürün',
  'Desc': 'Iniş',
  'Task Number should not be more than 30 characters':
    'Görev numarası 30 harften fazla olmamalıdır',
  'Select Photos': 'Fotoğrafları seçin',
  'Session Expired': 'Oturum süresi doldu',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    'Geçerli oturum çevrimdışıyken sona erdi. Lütfen cihazı tekrar çevrimiçi hale getirin ve oturum açma işlemini gerçekleştirmek ve sizi ana ekrana geri götürmek için aşağıdaki oturum Aç düğmesini seçin.',
  'Login': 'Oturum aç',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    'Belirli bir EFC veya onarım eylemi ile ilgili olmayan ACFS sitesindeki içeriği geliştirebilmek için lütfen geri bildirimde bulunun.',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    'Bu EFC kodunu takip eden içeriği geliştirmek için lütfen geri bildiriminizi sağlayın.',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    'Bu onarım eyleminde eksik bir şey var mı? Örneğin, yıldız derecelendirmesi veya düzeltme süresi ayarlanmalı mı? Lütfen bu özel onarım eyleminin içeriğini ve buna neden olan EFC ile ilişkisini geliştirebilmek için geri bildiriminizi sağlayın.',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    'Yöneticiler tarafından bir değişiklik yapılmasını öneriyorsanız "Evet" seçeneğini seçin.',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    "Geri bildirim zorunlu değildir, lütfen mümkünse İngilizce kullanınız. Geri bildirim, ACFS'daki içeriği geliştirmek için kullanılır. Kaliteyi sağlamak için geri bildirim yönetimle paylaşılabilir. Yöneticiler, içeriği veya diğer iş amaçlarını geliştirmek için gereken herhangi bir bilgiyi netleştirmeye yardımcı olmak için ek bilgi için bireylerle iletişime geçebilir",
  'OfflineMessage':
    'ACFS çevrimdışı moddayken, içeriğin doğası, güncelleme sıklığı ve son cihazdaki depolama kısıtlamaları nedeniyle kullanılamayacak bazı içerikler vardır.  Bunlar nedeniyle...',
  'OfflineMessage1':
    'Ana Sayfa ve Ben Sayfasında, Görevlerim bölümü yalnızca cihazda bulunan açık görevleri gösterecektir.',
  'OfflineMessage2':
    'Ekipmanla ilgili sayfalarda (Ekipman, Ayrıntılar, bölümler), ekipman ve servis görevi verileri cihazda bulunan verilerden gösterilecektir',
  'OfflineMessage3':
    'Notlar sayfası yalnızca ekipmana karşılık gelen cihazda mevcut olan kullanıcının açık servis görevlerinin notlarını görüntüleyecektir.',
  'OfflineMessage4':
    'Manuel ekipman, kullanıcı tarafından sağlanan seri numarasına göre getirilemediğinden, Ben sayfası ve ekipman sayfalarındaki Ekipman Ekle işlevi çalışmayacaktır.',
  'Feedback: Repair # {{repairActionId}}':
    'Geri bildirim: Onarım # {{repairActionId}}',
  'Training': 'Eğitim',
  'General Help': 'Genel yardım',
  'Offline Disclaimer': 'Çevrimdışı yasal uyarı',
  'Nothing here yet': 'Henüz burada bir şey yok',
  'Unable to retrieve tasks. Falling back to offline data.':
    'Görevler geri alınamıyor. Çevrimdışı verilere geri dönülüyor.',
  'Category': 'Kategori',
  'Family': 'Aile',
  'Skill level required': 'Gerekli beceri seviyesi',
  'Sort by': 'Göre sırala',
  'Subsystem': 'Alt sistem',
  'Recent records': 'Son kayitlar',
  'Systems': 'Sistemler',
  'Task - {{taskNumber}}': 'Görev {{taskNumber}} ',
  'Add New': 'Yeni Ekle',
  'Everywhere': 'Her yerde',
  'Keywords': 'Anahtar Kelimeler',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    'Ayrıntılar sayfası yalnızca bu ekipman için geçerli kullanıcıya atanmış aktif bir çağrı olduğunda içerik içerir. ',
  'OfflineMessage5':
    'Uygulamanın içeriğindeki EFC görüntüsü, onarım eylem adımları görüntüleri, parça görüntüleri gibi görüntüler, kullanıcı çevrimdışıysa görünmeyecektir',
  'Module(EFC only)': 'Modül (yalnızca EFC)',
  'German': 'Almanca',
  'Francias': 'Fransızca ',
  'Polish': 'Lehçe',
  'Espanol': 'İspanyolca',
  'Indonesian': 'Endonezce',
  'Chinese': 'Çince',
  'Thai': 'Tay',
  'Vietnamese': 'Vietnamca',
  'Portuguese': 'Portekizce',
  'If no stock, replaces': 'Stok yoksa, değiştirir',
  'If no stock, replaced by': 'Stok yoksa',
  'Replaces': 'Değiştirir',
  'Replaced by': 'Yerine',
  'Repair Id': 'Onarım Kimliği',
  'View Child Ids': 'Alt Kimlikleri Görüntüleme',
  'Device Id': 'Cihaz Kimliği',
  'Equipment with serial number {{number}} added Succesfully.':
    'Seri numarası {{number}} olan ekipman başarıyla eklendi.',
  'mfg': 'ürt',
  'Reporting System': 'Raporlama Sistemi',
  'Update': 'güncelleme',
  'No Endpoint': 'Uc Nokta Yok',
  'Repair Action performed': 'Onarım İşlemi Gerçekleştirildi',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    'Güncelleme: Kullanıcı adları artık görev geçmişinde görülebilir. Siz kullanıcıların adlarını göreceksiniz ve diğerleri de sizinkileri görecek. Bu değişiklik, önceki kullanıcıları tanımlamaya yardımcı olur ve gerektiğinde daha fazla bilgi için onlarla iletişim kurmayı kolaylaştırır.',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'ACDE Knowledgebase içeriği şu anda bu cihaza yükleniyor.  Verdiğimiz rahatsızlıktan dolayı özür dileriz.  İçerik yüklemesi tamamlandığında sayfa otomatik olarak yeniden yönlendirilecektir.',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    "Verdiğimiz rahatsızlıktan dolayı özür dileriz.  Kullanılan bağlantı ACFS'de geçerli bir konum değil",
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    'Repair Action (onarım eylemi) sayfasında seçilen uç nokta(endpoint) atanan aktif servis tasklarındaki uç noktalarından(endpoint) biri ile uyuşmuyor/eşleşmiyor.Devam etmek için Tamam’ı seçin. Değiştirmek için İptal’i seçin.',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    'Yöneticiler tarafından yeni bir Onarım Eylemi oluşturulmasını öneriyorsanız "Evet" seçeneğini seçin.',
  'Select this repair action template to specify the action completed':
    'Tamamlanan eylemi belirtmek için bu onarım eylemi şablonunu seçin',
  'Custom Repair Performed': 'Özel Onarım Gerçekleştirildi',
  'Submit': 'Gönder',
  'Needs reviewed for new RA': 'Yeni RA(Repair Action) için gözden geçirilmeli',
  'Title': 'Başlık',
  'Title of repair': 'Onarım başlığı',
  'Description of repair': 'Onarım açıklaması',
  'Duration to complete repair (minutes)':
    'Onarımın tamamlanma süresi (dakika)',
  'No results Or Multiple Results, please narrow your search':
    'Sonuç yok veya birden fazla sonuç ile eşleniyor. Lütfen aramanızı daraltın.',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    'Enter the serial number to view the insights and details for that piece of equipment.',
  'Open': 'Open',
  'Interrupt': 'Interrupt',
  'Incomplete': 'Incomplete',
  'Closed': 'Closed',
  'Cancelled': 'Cancelled',
  'Scanned successfully, Redirecting to the scanned link':
    'Scanned successfully, Redirecting to the scanned link',
  'Search Serial number': 'Seri numarasını ara',
  'No System is Selected': 'Hiçbir Sistem Seçilmedi',
  'Cancelled Tasks': 'İptal Edilen Görevler',
  'Allowed': 'İzin verildi',
  'Not Allowed': 'İzin Verilmedi',
  'Open Task UserId': 'Açık Görev Kullanıcı Kimliği',
  'Observations': 'Gözlemler',
  'GroupName or UserID': 'GrupAdı veya Kullanıcı Kimliği',
  'AI Summary': 'Yapay Zeka Özeti',
  'Chronic / Worst Performer Details':
    'Kronik / En Kötü Performans Gösteren Detaylar',
  'Triage Notes': 'Triyaj Notları',
  'Search By': 'Şuna Göre Ara',
  'Serial Number': 'Seri Numarası',
  'Tag': 'Etiket',
  'Temporarily add equipment': 'Geçici olarak ekipman ekleyin',
  'System': 'Sistem',
  'Machine Name': 'MakineAdı',
  'No data is available': 'Veri mevcut değil',
  'Connections': 'Bağlantılar',
  'Non-Connected': 'Bağlantısız',
  'Part Recommendations': 'Parça Önerileri',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    'Lütfen günlük güncelleme için bekleyin.  Tamamlandığında sayfa otomatik olarak yenilenecektir.',
  'Install Date': 'Kurulum Tarihi',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'ACDE KB günlük güncellemesi şu anda devam etmektedir. Parça navigasyonu mevcut değil. RepairAction göstergesi de mevcut değil. İçerik indirildikten sonra lütfen tekrar deneyin.',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    'Endpoint {{endPointId}} için Ekipman verisi mevcut değil.',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    "Yinelenen uç nokta tespit edildi. Geçmiş EFC'ler birden fazla uç noktadan gelmiş olabilir. Bu uç noktaların geçmişi incelenirken dikkatli olunmalıdır. Harvester'ın doğru uç nokta kaydıyla düzgün şekilde kurulduğunu doğrulayın.",
  'Duplicate Endpoint': 'Yinelenen Uç Nokta',
  'Inventory': 'Envanter',
  'Entitlements': 'İmtiyazlar',
  'Green': 'Yeşil',
  'Yellow': 'Sarı',
  'Red': 'Kırmızı',
  'Empty': 'Boş',
  'Entitlement is connected and sending data as expected.':
    'Yetkilendirme bağlı ve beklendiği gibi veri gönderiyor.',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    'Yetkilendirme bağlı, ancak beklendiği gibi veri göndermiyor.  Yetkilendirme ayrıntıları sayfasındaki ayrıntılı mesajı inceleyin. Nasıl çözüleceğine ilişkin ayrıntıları almak için karta tıklayın.',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    'Yetki bekleniyor, ancak bağlı değil ve veri yok.  Nasıl çözüleceğine ilişkin ayrıntıları almak için karta tıklayın.',
  'Entitlement does not send information as to the current status. No action is required.':
    'Yetki, mevcut duruma ilişkin bilgi göndermez. Herhangi bir işlem yapılması gerekmez.',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    "Bağlantısı kesilmiş bir sistem daha önce bağlanmış ve ACDE'ye veri gönderiyordu. Ancak, veri iletimi bu bağlantı için beklenen süreyi aşarak durmuştur. Verilerin en son ne zaman alındığını belirlemek için lütfen 'Son Veri' alanına bakın.",
  'Disconnected': 'Bağlantı kesildi',
};

export default translation;
