import React from 'react';
import classnames from 'classnames';
import { EquipmentMenuItem } from '../pages/Equipment/MenuLiterals';
import { useTranslation } from 'react-i18next';

export interface Props {
  menuItems: string[];
  activeMenuItem: string;
  onChange: (menu: string) => void;
}

function PageMenu({ menuItems, activeMenuItem, onChange }: Props) {
  const { t } = useTranslation();

  const activeClasses = 'text-cobalt underline underline-offset-8 decoration-2';
  const inactiveClasses = 'text-steel no-underline';

  return (
    <div className="relative flex h-14 bg-white">
      {menuItems.map((menuItem) => (
        <button
          key={menuItem}
          type="button"
          className={classnames(
            'flex h-full w-full items-center justify-center gap-x-1 px-4 text-lg font-bold',
            {
              [activeClasses]: menuItem === activeMenuItem,
              [inactiveClasses]: menuItem !== activeMenuItem,
            }
          )}
          onClick={() => onChange(menuItem)}
        >
          {t(menuItem)}
        </button>
      ))}
      <div className="absolute-shadow below" />
    </div>
  );
}

export default PageMenu;
