import { useEffect, useState } from 'react';
import Collapsible from '../../components/collapsible/Collapsible';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import RepairActionPart from './RepairActionPart';
import db from '../../index-db';
import { TimeLineEventType } from '../../Models/Enumerations';
import Part from '../../Models/Part';
import EndPoint from '../../Models/EndPoint';
import TimelineEvent from '../../Models/TimeLineEvent';
import RecommendedPart from '../../Models/RecommendedPart';
import { LAST_ACCESSED_ENDPOINT } from '../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  partsData: Part[];
  title: string;
}

function Parts(props: Props) {
  const api = useApi();
  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';
  const [recommendedParts, setRecommendedParts] = useState(
    [] as RecommendedPart[]
  );
  const [partTimeLineEvents, setPartTimeLineEvents] = useState(
    [] as TimelineEvent[]
  );
  const [selectedEndpoint, setSelectedEndpoint] = useState({} as EndPoint);
  const { t } = useTranslation();

  const fetchEndPoint = async (endpointId: string): Promise<EndPoint> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointById/?endPointId=${endpointId}`
    )) as EndPoint;
  };

  useEffect(() => {
    (async () => {
      if (
        lastAccessedEndpoint !== t('No Endpoint') &&
        lastAccessedEndpoint !== ''
      ) {
        try {
          const endpoint = await fetchEndPoint(lastAccessedEndpoint);
          setSelectedEndpoint(endpoint);
        } catch (error) {
          const endpoint = await db.endPoints.get(lastAccessedEndpoint);
          if (endpoint) {
            setSelectedEndpoint(endpoint);
          }
        }
      }
    })();
  }, [lastAccessedEndpoint]);

  useEffect(() => {
    (async () => {
      let recParts = new Array<RecommendedPart>();
      let timeLineEvents: TimelineEvent[] = [];

      if (selectedEndpoint && selectedEndpoint.serviceInsights) {
        selectedEndpoint.serviceInsights.forEach((svcInsight) => {
          svcInsight.recommendedParts.forEach((part) => {
            recParts.push(part);
          });
        });
      }

      if (selectedEndpoint && selectedEndpoint.timelineEventDetails) {
        timeLineEvents = selectedEndpoint.timelineEventDetails.filter(
          (timelineEvent) =>
            timelineEvent.type === TimeLineEventType[TimeLineEventType.Part]
        );
      }

      if (timeLineEvents && timeLineEvents.length > 0) {
        timeLineEvents.sort((a, b) => {
          return (
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        });
      }
      setRecommendedParts(recParts);
      setPartTimeLineEvents(timeLineEvents);
    })();
  }, [selectedEndpoint]);

  return (
    <>
      <Collapsible headingTitle={`${props.title} (${props.partsData.length})`}>
        {props.partsData.map((item) => (
          <RepairActionPart
            key={item.MaterialId}
            part={item}
            recommendedParts={recommendedParts}
            partTimelineEvents={partTimeLineEvents}
          />
        ))}
      </Collapsible>
    </>
  );
}

export default Parts;
