import Icon from '../../components/Icon';
import { TIcon } from '../../types';

interface Props {
  bgColor: string;
  cardText: string;
  icon: TIcon;
  onClick?: () => void;
}

function FeedbackActionCard({ bgColor, cardText, icon }: Props) {
  const classNames = 'px-3 mt-1 border-2 border-gray-600 ' + bgColor;
  return (
    <div className={classNames}>
      <div className="mt-4 h-[6rem] w-[4.5rem] text-center tracking-wide cursor-pointer">
        <Icon is={icon} colorClass="text-white" />
        <div className="mt-3 text-white">{cardText}</div>
      </div>
    </div>
  );
}
export default FeedbackActionCard;
