import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../components/toast/ToastProvider';

const useNotYetImplemented = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const stableHandler = useRef(() => {
    toast.pushToast({
      type: 'error',
      message: t('Not yet implemented'),
    });
  });

  return stableHandler.current;
};

export default useNotYetImplemented;
