import { useEffect, useState } from 'react';
import useSaveCallToActionSetting from './useSaveCallToActionSetting';
import { CallToActionType } from '../Models/Enumerations';

const useHandleCtaRead = (isRead: boolean) => {
  const [readState, setReadState] = useState<'primary' | 'secondary'>(
    'secondary'
  );
  useEffect(() => {
    if (isRead) {
      setReadState('secondary');
    } else {
      setReadState('primary');
    }
  }, [isRead]);
  const onSuccessfulCtaRegistration = () => {
    setReadState('secondary');
  };

  const saveCallToActionSetting = useSaveCallToActionSetting(
    onSuccessfulCtaRegistration
  );
  return [readState, saveCallToActionSetting] as [
    'primary' | 'secondary',
    (
      ctaType: CallToActionType,
      endpointId: string,
      value: string,
      serviceTaskId?: string | undefined
    ) => Promise<void>
  ];
};

export default useHandleCtaRead;
