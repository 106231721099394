export default {
  min_one: '1 min',
  min_other: '{{count}} mins',
  min_zero: '0 min',
  OfflineMessage:
    'When ACFS is in offline mode there is some content that will not be available due to the nature of the content, frequency of updates and storage restrictions within the end device.  Due to those...',
  OfflineMessage1:
    'On Home Page and Me Page, My tasks section will only show the open tasks available in the device.',
  OfflineMessage2:
    'On Equipment related pages (Equipment, Details, Parts), equipment and service task data will be shown from the data available in the device.',
  OfflineMessage3:
    'Notes page will only display the notes of the open service tasks of the user as available in the device corresonding to the equipment.',
  OfflineMessage4:
    'Add Equipment functionality on Me Page and Equipment pages will not work as the manual equipment cannot be fetched based on the serial number provided by the user.',
  OfflineMessage5:
    'The images in the content of the app like EFC image, Repair Action steps images, Parts images will not show up if the user is offline.',
};
