import { t } from 'i18next';
import { Filters } from './Enumerations';
import { IIdentifiable } from './IIdentifiable';

export interface ISearchedItems extends IIdentifiable {
  // Id: number;
  SearchString: string;
  Filter: number[] | null;
  IsSaved: boolean;
}

export class SearchedItems implements ISearchedItems {
  Id: number;
  SearchString: string;
  Filter: number[] | null;
  IsSaved: boolean;

  constructor(
    id: number,
    searchString: string,
    filter: number[] | null,
    isSaved: boolean
  ) {
    this.Id = id;
    this.SearchString = searchString;
    this.Filter = filter;
    this.IsSaved = isSaved;
  }

  GetFilterText(): string {
    if ((this.Filter ?? []).length === 0) {
      return '';
    }

    const filterTexts = [];
    for (const filter of this.Filter ?? []) {
      switch (filter) {
        case Filters.CATEGORY:
          filterTexts.push(t('Category'));
          break;
        case Filters.FAMILY:
          filterTexts.push(t('Family'));
          break;
        case Filters.MODEL:
          filterTexts.push(t('Model'));
          break;
        case Filters.MODULE:
          filterTexts.push(t('Module'));
          break;
        case Filters.SKILL_LEVEL_REQUIRED:
          filterTexts.push(t('Skill level required'));
          break;
        case Filters.SORT_BY:
          filterTexts.push(t('Sort by'));
          break;
        case Filters.SUBSYSTEM:
          filterTexts.push(t('Subsystem'));
          break;
      }
    }

    return filterTexts.join(',');
  }
}

export default SearchedItems;
