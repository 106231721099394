import { useEffect } from 'react';
import { BUILD_VERSION } from '../constants';
import { useApi } from '../acfs-apis/dwar-api-provider';
import { OIDCConfig } from '../types';

const useClearCache = () => {
  const api = useApi();
  const buildVersion = window.localStorage.getItem(BUILD_VERSION);

  useEffect(() => {
    (async () => {
      const oidcConfig: OIDCConfig = await api?.fetchOIDCConfig();
      if (
        buildVersion === null ||
        (oidcConfig && oidcConfig.BuildVersion > buildVersion)
      ) {
        window.localStorage.setItem(BUILD_VERSION, oidcConfig.BuildVersion);
        if (caches) {
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }
        window.location.reload();
      }
    })();
  });
};

export default useClearCache;
