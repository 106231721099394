import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ForwardedRef, ReactNode, RefObject, forwardRef } from 'react';
import { styled } from '@mui/material';

interface Props {
  label?: string;
  options: any[];
  value?: string;
  onChange: (e: any) => void;
  selectId?: string;
  selectLabel?: string;
  selectLabelId?: string;
  renderValue?: (value: unknown) => ReactNode;
  valueIdentifier: string;
  optionsRenderStructure: (val: any) => string;
  isDisabled?: boolean;
  name?: string;
  onBlur?: () => void;
  variant: 'outlined' | 'standard' | 'filled';
}
interface OptionItem {
  [key: string]: string;
}

const CustomSelect = styled(Select, {
  shouldForwardProp: () => true,
})(({ variant, disabled }) => ({
  ...(variant === 'outlined' && {
    'height': '40px',
    'padding': '8px',
    'width': '100%',
    'borderColor': ' rgb(96 111 123 / 1)',
    'borderWidth': '1px',
    'borderRadius': '8px',
    '& > .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
      {
        borderColor: 'transparent !important',
      },
    '&:focus': {
      borderColor: 'rgb(16,16,16)',
      borderWidth: '2px ',
    },
    '& .MuiSelect-select': {
      padding: '0px ',
    },
    ...(disabled === true && {
      '&.Mui-disabled': {
        borderColor: '#dddddd',
      },
    }),
  }),
}));

const Dropdown = (props: Props, ref: ForwardedRef<RefObject<unknown>>) => {
  const {
    options,
    value,
    onChange,
    selectId,
    selectLabel,
    selectLabelId,
    renderValue,
    valueIdentifier,
    optionsRenderStructure,
    isDisabled,
    name,
    onBlur,
    variant = 'standard',
  } = props;
  return (
    <CustomSelect
      name={name}
      labelId={selectLabelId}
      id={selectId}
      value={value}
      ref={ref}
      onChange={onChange}
      label={selectLabel}
      MenuProps={{
        PaperProps: {
          style: {
            height: 150,
            overflowY: 'scroll',
            overflowX: 'scroll',
          },
        },
      }}
      renderValue={renderValue}
      disabled={isDisabled}
      onBlur={onBlur}
      variant={variant}
    >
      {options.map((option: OptionItem) => {
        return (
          <MenuItem
            value={option[valueIdentifier]}
            key={option[valueIdentifier]}
          >
            {optionsRenderStructure(option)}
          </MenuItem>
        );
      })}
    </CustomSelect>
  );
};
export default forwardRef(Dropdown);
