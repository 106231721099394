import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ActionableListItem from '../../components/ActionableListItem';
import useNavigateToEfc from '../../hooks/useNavigateToEfc';
import GenericModelData from '../../Models/GenericModelData';

interface Props {
  efcItem: GenericModelData;
  onClick: () => void;
}

function EFCItem({ efcItem, onClick }: Props) {
  const navigateToEfc = useNavigateToEfc();

  return (
    <ActionableListItem
      key={efcItem.Id}
      icon={CloseOutlinedIcon}
      title={efcItem.Heading}
      subText={efcItem.Description}
      primaryAction="navigate"
      onClick={() => navigateToEfc(efcItem.IdGeneric)}
      onClickIcon={onClick}
    />
  );
}

export default EFCItem;
