import CardPopoverButton from '../CardPopoverButton';
import StarRating from '../StarRating';
import { PopoverMenuButton } from '../../PageProvider';
import { forwardRef } from 'react';
import classNames from 'classnames';

interface Props {
  popoverMenuButtons?: PopoverMenuButton[];
  rating?: number;
  title: string;
  description: string;
  onCardClick: () => void;
  afterPartitionPrimaryTitle?: string;
  afterPartitionSecondaryTitle?: string;
  afterPartitionPrimaryDescription?: string;
  afterPartitionSecondaryDescription?: string | number | null;
  ratingTitle?: string;
  blockLetter?: string | null;
  onPopoverShow?: () => void;
  onPopoverHide?: () => void;
  extraTitleSubtitle?: JSX.Element[] | JSX.Element;
  repairActionCompleteTime?: string;
}

const getComplexityLetterClassName = (
  complexity: string | null | undefined
) => {
  const notNullComplexity = complexity
    ? {
        'bg-cobalt': complexity === 'L',
        'bg-tangerine': complexity === 'M',
        'bg-dnred': complexity === 'H',
      }
    : {};
  return classNames(
    'h-11 w-[3.625rem] rounded-md flex items-center justify-center text-xl font-bold text-white',
    {
      ...notNullComplexity,
    }
  );
};

const CardPrimary = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const {
    popoverMenuButtons,
    onPopoverShow,
    onPopoverHide,
    rating,
    title,
    description,
    onCardClick,
    afterPartitionPrimaryTitle,
    afterPartitionSecondaryTitle,
    afterPartitionPrimaryDescription,
    afterPartitionSecondaryDescription,
    ratingTitle,
    blockLetter,
    extraTitleSubtitle,
    repairActionCompleteTime,
  } = props;
  return (
    <div
      className="relative mt-1 w-full rounded border-2 border-cobalt bg-white"
      ref={ref}
    >
      <button
        className="block w-full text-left"
        type="button"
        onClick={onCardClick}
      >
        <div className="flex items-center gap-x-2 bg-antartica pr-11">
          <div className="my-1 ml-4 flex-1 text-2xl font-bold">{title}</div>
        </div>
        <div className="px-4 pt-1 pb-2">
          {repairActionCompleteTime && (
            <div className="mb-1 border-b border-antartica pt-1 text-lg">
              {repairActionCompleteTime}
            </div>
          )}
          <div className="mb-1 border-b border-antartica pt-1 text-lg">
            {description}
          </div>
          <div className="flex justify-between">
            <div className={getComplexityLetterClassName(blockLetter)}>
              {blockLetter}
            </div>
            <div>
              <div className="text-center font-medium text-steel">
                {afterPartitionPrimaryTitle}
              </div>
              <div className="text-center text-lg font-bold">
                {afterPartitionPrimaryDescription}
              </div>
            </div>
            <div>
              <div className="text-center font-medium text-steel">
                {afterPartitionSecondaryTitle}
              </div>
              <div className="text-center text-lg font-bold">
                {afterPartitionSecondaryDescription}
              </div>
            </div>
            {rating && (
              <div>
                <div className="text-center font-medium text-steel">
                  {ratingTitle}
                </div>
                <StarRating rating={rating} />
              </div>
            )}
            {extraTitleSubtitle}
          </div>
        </div>
      </button>
      {popoverMenuButtons?.length && (
        <CardPopoverButton
          popoverMenuButtons={popoverMenuButtons}
          onShow={onPopoverShow}
          onHide={onPopoverHide}
        ></CardPopoverButton>
      )}
    </div>
  );
});

export default CardPrimary;
