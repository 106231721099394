import { useForm } from 'react-hook-form';
import MuiButton from '@mui/material/Button';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import EndPoint from '../../Models/EndPoint';
import { EquipmentMenuItem } from '../../types';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { useToast } from '../toast/ToastProvider';
import useLogging from '../../hooks/useLogging';
import useNavigateToEquipment from '../../hooks/useNavigateToEquipment';
import { useState } from 'react';

interface OnSubmitArgs {
  serialNumber: string;
}

interface Props {
  onSubmit: () => void;
  onDismiss: () => void;
  onModalClose: () => void;
  SearchLoadingStarted: (loading: boolean) => void;
  isEquipmentLoading: boolean;
  isModalOpen: boolean;
}

const SearchEquipmentModal = (props: Props) => {
  const { onSubmit, onDismiss, onModalClose, isModalOpen } = props;
  const { t } = useTranslation();
  const toast = useToast();
  const logging = useLogging();
  const api = useApi();
  const { register, handleSubmit, formState, reset, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      serialNumber: '',
    },
  });
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

  const fetchEndPoints = async (serialNumber: string): Promise<EndPoint[]> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointByFilter/?serialNumber=${serialNumber}`
    )) as EndPoint[];
  };
  const navigateToEquipment = useNavigateToEquipment();

  const onSearch = async (data: OnSubmitArgs) => {
    props.SearchLoadingStarted(true);
    setAreButtonsDisabled(true);
    const { serialNumber } = data;
    try {
      const endPoints = await fetchEndPoints(serialNumber);
      setAreButtonsDisabled(false);

      if (endPoints) {
        if (endPoints.length === 0) {
          logging(
            'error',
            `No equipment data for serial number ${serialNumber}`,
            '',
            false,
            null
          );
          props.SearchLoadingStarted(false);
          toast.pushToast({
            type: 'error',
            message: t(
              `No equipment data found for serial number {{number}}.`,
              {
                number: serialNumber,
              }
            ),
          });
        }

        onSubmit();
        navigateToEquipment(endPoints[0].id, 'Systems' as EquipmentMenuItem);
        onPopupClose();
      }
      setAreButtonsDisabled(false);
      reset();
    } catch (error) {
      logging(
        'error',
        `Unable to retrieve equipment data for serial number ${serialNumber}`,
        error,
        false,
        null
      );
      props.SearchLoadingStarted(false);
      toast.pushToast({
        type: 'error',
        message: t(
          `Unable to retrieve equipment data for the serial number ${serialNumber}.`
        ),
      });
    }
  };

  const onReject = () => {
    onDismiss();
    reset();
  };
  const onPopupClose = () => {
    onModalClose();
  };

  const onSearialNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue('serialNumber', event.target.value.toUpperCase(), {
      shouldValidate: true,
    });
  };

  return (
    <>
      <Modal show={isModalOpen} handleClose={onPopupClose}>
        <form onSubmit={handleSubmit(onSearch)} className="px-4">
          <div className="mt-4 mb-2 flex items-center justify-between gap-x-2 ">
            <MuiButton
              color="primary"
              disabled={areButtonsDisabled}
              size="large"
              sx={{ fontWeight: 700 }}
              style={{ textTransform: 'none' }}
              onClick={onReject}
            >
              {t('Cancel')}
            </MuiButton>

            <MuiButton
              color="primary"
              disabled={!formState.isValid || areButtonsDisabled}
              type="submit"
              size="large"
              sx={{ fontWeight: 700 }}
              style={{ textTransform: 'none' }}
            >
              {t('Search')}
            </MuiButton>
          </div>
          <div className="mt-4 ">
            <h2 className={'text-left text-xl font-medium text-iron'}>
              {t('Temporarily add equipment')}
            </h2>
            <p className="mt-2 text-left text-lg font-normal text-iron">
              {t(
                'Enter the serial number to view the insights and details for that piece of equipment.'
              )}
            </p>
          </div>
          <div className="mt-4 mb-4 ">
            <h2 className={'w-80 text-left text-base font-medium text-iron'}>
              {t('Serial Number') + '*'}
            </h2>
            <input
              type="text"
              className="mt-2 h-10 w-full rounded-lg border border-steel p-2"
              {...register('serialNumber', {
                required: 'Serial Number is required',
              })}
              onChange={onSearialNumberChange}
            />
            {formState.errors?.serialNumber && (
              <div className="text-tangerine">
                {formState.errors.serialNumber.message}
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

SearchEquipmentModal.defaultProps = {
  onSubmit: () => {},
};

export default SearchEquipmentModal;
