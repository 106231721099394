import {
  BrowserMultiFormatReader,
  DecodeHintType,
  DecodeContinuouslyCallback,
  Exception,
  Result,
  NotFoundException,
} from '@zxing/library';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';

import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import SwitchCheckbox from '../../../components/form/SwitchCheckbox';
import { useTorch } from '../../hooks/useTorch';
import Icon from '../../../components/Icon';
import useCheckDeviceType from '../../../hooks/useCheckDeviceType';

const DEFAULT_CONSTRAINTS: MediaStreamConstraints = {
  audio: false,
  video: { facingMode: 'environment' },
};
const deepCompareObjects = (a: any, b: any): boolean =>
  JSON.stringify(a) === JSON.stringify(b);

export interface UseZxingOptions {
  paused?: boolean;
  hints?: Map<DecodeHintType, any>;
  timeBetweenDecodingAttempts?: number;
  onDecodeResult?: (result: Result) => void;
  onDecodeError?: (error: Exception) => void;
  onError?: (error: unknown) => void;
}

export interface UseZxingOptionsWithConstraints extends UseZxingOptions {
  constraints?: MediaStreamConstraints;
}

export interface UseZxingOptionsWithDeviceId extends UseZxingOptions {
  deviceId: string;
}

interface Props {
  onResult: (result: string) => void;
  stopDecoding: boolean;
  onError: (error: unknown) => void;
  constraints?: MediaStreamConstraints;
  scanDelay?: number;
  className?: string;
}

const QrScanner = (props: Props) => {
  const {
    onResult,
    stopDecoding = false,
    onError,
    scanDelay = 500,
    constraints = DEFAULT_CONSTRAINTS,
    className,
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const isMounted = useRef(false);

  const reader = useMemo(
    () => new BrowserMultiFormatReader(undefined, scanDelay),
    [scanDelay]
  );
  const [statefulConstraints, setStatefulConstraints] = useState(constraints);
  const { init: torchInit, ...torch } = useTorch({
    resetStream: async () => {
      readerReset();
      await readerStart();
    },
  });
  useEffect(() => {
    const isEqual = deepCompareObjects(constraints, statefulConstraints);

    if (!isEqual) {
      setStatefulConstraints(constraints);
    }
  }, [constraints, statefulConstraints]);

  const readerStart = useCallback(async () => {
    const onDecode: DecodeContinuouslyCallback = (result, error) => {
      if (result) onResult(result.getText());
      if (error && !(error instanceof NotFoundException)) onError(error);
    };
    if (!videoRef.current) return;
    try {
      await reader.decodeFromConstraints(
        statefulConstraints,
        videoRef.current,
        onDecode
      );
      const mediaStream = videoRef.current.srcObject as MediaStream;
      const videoTrack = mediaStream.getVideoTracks()[0];
      if (videoTrack) torchInit(videoTrack);
    } catch (e) {
      onError(e);
    }
  }, [reader, statefulConstraints]);

  const readerReset = useCallback(() => {
    reader.reset();
  }, [reader]);
  const readerStop = useCallback(() => {
    reader.stopAsyncDecode();
  }, [reader]);

  useEffect(() => {
    isMounted.current = true;

    if (stopDecoding) {
      readerStop();
      return;
    }

    (async () => {
      await readerStart();

      if (!isMounted.current) {
        readerReset();
      }
    })();

    return () => {
      isMounted.current = false;
      readerReset();
    };
  }, [readerStart, readerReset, readerStop, stopDecoding]);
  const [isTorchOn, setIsTorchOn] = useState(false);
  const onTorchToggle = (status: boolean) => {
    setIsTorchOn(status);
    status ? torch.on() : torch.off();
  };
  const deviceType = useCheckDeviceType();

  return (
    <div className="flex flex-col items-center justify-center">
      {deviceType !== 'unknown' && (
        <div className="flex items-center justify-center">
          <Icon is={FlashlightOnIcon} className="text-blue-600" />
          <SwitchCheckbox
            onCustomCheckChange={onTorchToggle}
            value={isTorchOn}
          />
        </div>
      )}
      <video
        ref={videoRef}
        controls={false}
        poster="hee"
        className={className}
      />
    </div>
  );
};

export default QrScanner;
