import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import Collapsible from '../../components/collapsible/Collapsible';
import ModLinks from '../../Models/ModuleLinks';

interface Props {
  moduleLinksData: ModLinks[];
  title: string;
}

function ModuleLinks(props: Props) {
  const { t } = useTranslation();
  const launchModuleLink = (url: string) => {
    window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
  };

  return (
    <Collapsible headingTitle={props.title}>
      {props.moduleLinksData.map((item) => (
        <ActionableListItem
          key={item.Id}
          title={t(item.UrlType)}
          primaryAction="launch"
          appearance="nonBold"
          onClick={() => {
            launchModuleLink(item.Url);
          }}
        />
      ))}
    </Collapsible>
  );
}

export default ModuleLinks;
