import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import FeedbackActionCard from './FeedbackActionCard';
import { useTranslation } from 'react-i18next';
import FeedbackScreen from './FeedbackScreen';
import { useState } from 'react';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import { FbType } from '../../enums/FbType';
import useLogging from '../../hooks/useLogging';
import { useToast } from '../../components/toast/ToastProvider';

interface Props {
  open: boolean;
  onClose: () => void;
  efcCode: string;
  efcId: number;
  efcDesciption: string | undefined;
  moduleId: number | undefined;
  endpointId: string;
  serialNumber: string;
}

export default function EfcFeedbackDialog(props: Props) {
  const { open, onClose, efcCode, efcId, efcDesciption, moduleId } = props;
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const logging = useLogging();
  const { pushToast } = useToast();
  const handleClose = () => {
    onClose();
  };
  function logFeedback(feedback: string) {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.FeedBack,
      },
      {
        key: LogType.FbType,
        value: FbType.Efc,
      },
      {
        key: LogType.Value,
        value: feedback,
      },
      {
        key: LogType.EfcCode,
        value: efcCode,
      },
      {
        key: LogType.EndpointId,
        value: props.endpointId,
      },
      {
        key: LogType.SerialNumber,
        value: props.serialNumber,
      },
    ];
    logging('info', '', '', false, logParams, true);
    pushToast({
      message: t(`Your feedback has been submitted`),
      type: 'success',
    });
  }
  const { t } = useTranslation();
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {t(
            'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"'
          )}
        </DialogTitle>
        <div className="mb-2 flex justify-evenly">
          <div
            onClick={() => {
              handleClose();
              setFeedbackOpen(true);
            }}
          >
            <FeedbackActionCard
              bgColor="bg-steel"
              cardText={t('Leave feedback')}
              icon={CommentOutlinedIcon}
            />
          </div>
          <div
            onClick={() => {
              handleClose();
              logFeedback('Not relevant');
            }}
          >
            <FeedbackActionCard
              bgColor="bg-dnred"
              cardText={t('Not relevant')}
              icon={ThumbDownOutlinedIcon}
            />
          </div>
          <div
            onClick={() => {
              handleClose();
              logFeedback('Relevant');
            }}
          >
            <FeedbackActionCard
              bgColor="bg-cobalt"
              cardText={t('Relevant')}
              icon={ThumbUpOutlinedIcon}
            />
          </div>
        </div>
      </Dialog>
      <FeedbackScreen
        feedbackType="efc"
        efcCode={efcCode}
        efcId={efcId}
        efcDesciption={efcDesciption}
        moduleId={moduleId}
        isOpen={isFeedbackOpen}
        handleClose={() => setFeedbackOpen(false)}
        handleSubmit={() => setFeedbackOpen(false)}
      />
    </>
  );
}
