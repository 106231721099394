import React from 'react';
import ActionableListItem from '../../components/ActionableListItem';
import Task from '../../Models/Task';

interface Props {
  task: Task;
  onClick: () => void;
}

function TaskListItem(props: Props) {
  const date = new Date(props.task.effectiveDate);
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
  const minutes =
    date.getMinutes() < 10
      ? `0${date.getMinutes()}`
      : date.getMinutes().toString();
  const seconds =
    date.getSeconds() < 10
      ? `0${date.getSeconds()}`
      : date.getSeconds().toString();

  const taskTitle = `${props.task.taskNumber} (${date.toDateString()})`;
  const description = `${hours}:${minutes}:${seconds} ${props.task.taskType}. ${props.task.customer}`;

  return (
    <ActionableListItem
      primaryAction="navigate"
      onClick={props.onClick}
      title={taskTitle}
      subText={description}
      isChronic={props.task?.chronicIndicator?.toLowerCase() === 'yes'}
      image={
        props?.task?.hasEndPointServiceInsights
          ? './lightBulbBrain.png'
          : props?.task?.hasEndPointPreventiveInsights
          ? './lightBulbGear.png'
          : ''
      }
    />
  );
}

export default TaskListItem;
