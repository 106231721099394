import { useLiveQuery } from 'dexie-react-hooks';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigateToRepairAction from '../../hooks/useNavigateToRepairAction';
import db from '../../index-db';
import PageNotFound from '../../PageNotFound';

function RepairFromExternal() {
  const [isWrongURL, setIsWrongURL] = useState(false);
  const navigateToRepairAction = useNavigateToRepairAction();
  const params = useParams();
  const repairId = Number(params.id);
  const [searchParams] = useSearchParams();
  const externalSource = searchParams.get('externalSource') === 'true';
  const efcId = Number(searchParams.get('efcId'));
  useLiveQuery(async () => {
    if (repairId) {
      const repair = await db.raactions.get(repairId);
      if (repair && efcId) {
        const efc = await db.efcs.get(efcId);
        if (efc) {
          navigateToRepairAction(
            repairId.toString(),
            efcId,
            externalSource,
            true
          );
        } else {
          setIsWrongURL(true);
        }
      } else {
        setIsWrongURL(true);
      }
    } else {
      setIsWrongURL(true);
    }
  }, [repairId]);
  return <>{isWrongURL && <PageNotFound />}</>;
}
export default RepairFromExternal;
