import React from 'react';
import PageAction, { Props as PageActionProps } from './PageAction';

interface Props {
  actionsProps: PageActionProps[];
}

function PageActions(props: Props) {
  return (
    <div className="sticky top-0">
      <div className="flex h-14 divide-x divide-antartica bg-white">
        {props.actionsProps.map((actionProps, index) => (
          <div key={index} className="flex-1">
            <PageAction {...actionProps} />
          </div>
        ))}
      </div>
      <div className="relative">
        <div className="absolute-shadow below" />
      </div>
    </div>
  );
}

export default PageActions;
