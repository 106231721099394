export interface IGenericModel {
  id: number;
  IdGeneric: number | string;
  IdType: number;
  Context: string;
}

export default class GenericModel implements IGenericModel {
  id: number;
  IdGeneric: number | string;
  IdType: number;
  Context: string;

  constructor(
    id: number,
    idGeneric: number | string,
    idType: number,
    context: string
  ) {
    this.id = id;
    this.IdGeneric = idGeneric;
    this.IdType = idType;
    this.Context = context;
  }
}
