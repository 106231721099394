import { useCallback, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '../app/hooks';
import { escapeRegExp } from '../utils';
import { selectActiveSearch } from '../app/selectors';

import { SearchScope } from '../types';

import { wrap } from 'comlink';
import { PartSearchWorkerType } from '../worker/partSearch/searchWorker';
import { ProductSearchWorkerType } from '../worker/productSearch/searchWorker';
import { RepairSearchWorkerType } from '../worker/repairSearch/searchWorker';
import { EfcSearchWorkerType } from '../worker/efcSearch/searchWorker';

type WorkerNonNullTypes = PartSearchWorkerType &
  ProductSearchWorkerType &
  RepairSearchWorkerType &
  EfcSearchWorkerType;
interface Props<Type> {
  onResultLoaded: (count: number) => void;
  uniqueIdentifier: string;
  fetcher: (
    regex: RegExp,
    offset: number,
    worker: WorkerNonNullTypes | null,
    selectedFilter?: string
  ) => Promise<number | Type[] | undefined>;
  countFetcher: (regex: RegExp, selectedFilter?: string) => Promise<number>;
  from: Exclude<SearchScope, 'everywhere'>;
  shouldSearchLogicRun: boolean;
}

interface FetchedData {
  [key: string]: any;
}

const workerPath = (from: string) =>
  ({
    'parts': new Worker(
      new URL('../worker/partSearch/searchWorker.ts', import.meta.url),
      {
        name: 'partWorker',
        type: 'module',
      }
    ),
    'repairs': new Worker(
      new URL('../worker/repairSearch/searchWorker.ts', import.meta.url),
      {
        name: 'a',
        type: 'module',
      }
    ),
    'efc codes': new Worker(
      new URL('../worker/efcSearch/searchWorker.ts', import.meta.url),
      {
        name: 'b',
        type: 'module',
      }
    ),
    'products': new Worker(
      new URL('../worker/productSearch/searchWorker.ts', import.meta.url),
      {
        name: 'c',
        type: 'module',
      }
    ),
  }[from]);

const useSearchGetResults = <Type extends FetchedData>(props: Props<Type>) => {
  const {
    onResultLoaded,
    fetcher,
    countFetcher,
    uniqueIdentifier,
    shouldSearchLogicRun,
    from,
  } = props;
  const activeSearch = useAppSelector(selectActiveSearch);
  const [fetchedResults, setFetchedResults] = useState<Type[]>([]);
  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const selectedFilter = activeSearch?.filters?.Module?.value as
    | undefined
    | string;

  const isScopeMatching =
    activeSearch?.scope === from || activeSearch?.scope === 'everywhere';
  const shouldEfcSearch =
    isScopeMatching && from === 'efc codes' && selectedFilter;
  const shouldSearch =
    shouldSearchLogicRun &&
    isScopeMatching &&
    (activeSearch?.searchTerm?.trim()?.length ?? 0) >= 1;

  useEffect(() => {
    (async () => {
      setIsCountLoading(true);
      if (shouldSearch || shouldEfcSearch) {
        const regex = new RegExp(escapeRegExp(activeSearch?.searchTerm), 'i');
        const count = await countFetcher(regex, selectedFilter);
        setResultsCount(count);
      } else {
        setResultsCount(0);
      }
      setIsCountLoading(false);
    })();
  }, [selectedFilter, activeSearch?.searchTerm, shouldSearch]);
  const workerRef = useRef<WorkerNonNullTypes | null>(null);

  useEffect(() => {
    let worker: Worker;
    (async () => {
      setIsLoading(true);

      if (shouldSearch || shouldEfcSearch) {
        const regex = new RegExp(escapeRegExp(activeSearch?.searchTerm), 'i');

        worker = workerPath(from) as Worker;

        workerRef.current = wrap(worker) as WorkerNonNullTypes;

        const results = await fetcher(
          regex,
          offset,

          workerRef.current,
          selectedFilter
        );

        if (Array.isArray(results)) {
          setFetchedResults((currentFetchedResults) => {
            if (
              (results?.[0]?.[uniqueIdentifier] as keyof Type) !==
              (currentFetchedResults?.[currentFetchedResults.length - 1]?.[
                uniqueIdentifier
              ] as keyof Type)
            ) {
              return [...currentFetchedResults, ...results];
            } else {
              return currentFetchedResults;
            }
          });
        }
        worker?.terminate();
      } else {
        setFetchedResults([]);
      }
      setIsLoading(false);
    })();
    return () => {
      if (workerRef.current) {
        worker?.terminate();
        workerRef.current = null;
      }
    };
  }, [
    selectedFilter,
    offset,
    activeSearch?.searchTerm,
    uniqueIdentifier,
    shouldSearch,
  ]);

  const onContentVisible = useCallback(() => {
    setOffset(fetchedResults.length);
  }, [fetchedResults.length]);
  const isAllLoadComplete = !isLoading && !isCountLoading;

  useEffect(() => {
    if (resultsCount !== undefined && isAllLoadComplete) {
      onResultLoaded(resultsCount);
    }
  }, [onResultLoaded, resultsCount, isAllLoadComplete]);
  useEffect(() => {
    setOffset(0);
    setFetchedResults([]);
    setResultsCount(0);
  }, [activeSearch?.searchTerm]);

  return {
    results: fetchedResults,
    onContentVisible,
    isLoading: !isAllLoadComplete,
  };
};

export default useSearchGetResults;
