import Insight from './Insight';

export interface IManualEndPoint {
  id: string;
  serialNumber: string;
  prodDesc: string;
  terminalId: string;
  customerName: string;
  serviceInsights: Insight[];
  preventiveInsights: Insight[];
  userTag?: string;
  chronicDetails: string;
  generatedSummary: string;
  chronicIndicator: string;
  externallyNavigated?: boolean;
}

export class ManualEndPoint {
  id: string;
  serialNumber: string;
  prodDesc: string;
  terminalId: string;
  customerName: string;
  serviceInsights: Insight[];
  preventiveInsights: Insight[];
  userTag?: string;
  chronicDetails: string;
  generatedSummary: string;
  chronicIndicator: string;
  externallyNavigated?: boolean;

  constructor(
    id: string,
    serialNumber: string,
    prodDesc: string,
    terminalId: string,
    customerName: string,
    preventiveInsights: Insight[],
    serviceInsights: Insight[],
    chronicDetails: string,
    generatedSummary: string,
    chronicIndicator: string,
    userTag?: string,
    externallyNavigated?: boolean
  ) {
    this.id = id;
    this.serialNumber = serialNumber;
    this.prodDesc = prodDesc;
    this.terminalId = terminalId;
    this.customerName = customerName;
    this.serviceInsights = serviceInsights;
    this.preventiveInsights = preventiveInsights;
    this.userTag = userTag;
    this.chronicDetails = chronicDetails;
    this.generatedSummary = generatedSummary;
    this.chronicIndicator = chronicIndicator;
    this.externallyNavigated = externallyNavigated;
  }
}

export default ManualEndPoint;
