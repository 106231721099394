import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import RepairActionCompleteActionCard from './RepairActionCompleteActionCard';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import { FbType } from '../../enums/FbType';
import { useToast } from '../../components/toast/ToastProvider';
import { useState } from 'react';
import FeedbackScreen from '../Feedback/FeedbackScreen';
import { useTranslation } from 'react-i18next';

interface Props {
  raId: number;
  efcId: number;
  efcCode: string;
  raDescription: string;
  raName: string;
  moduleId: number;
  externalSource: string;
  endpointId: string;
  serialNumber?: string;
  onClose: (raPerformed: boolean, feedback: string) => void;
  closeFeedbackOptions: () => void;
}

function RepairActionComplete({
  raId,
  efcId,
  efcCode,
  raDescription,
  raName,
  moduleId,
  externalSource,
  endpointId,
  onClose,
  closeFeedbackOptions,
  serialNumber,
}: Props) {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const logging = useLogging();
  const { pushToast } = useToast();
  const { t } = useTranslation();

  function logFeedback(feedback: string) {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.FeedBack,
      },
      {
        key: LogType.FbType,
        value: FbType.RepairAction,
      },
      {
        key: LogType.Value,
        value: feedback,
      },
      {
        key: LogType.RaId,
        value: raId.toString(),
      },
      {
        key: LogType.EfcCode,
        value: efcCode ?? '',
      },
      {
        key: LogType.ExternalSource,
        value: externalSource,
      },
      {
        key: LogType.EndpointId,
        value: endpointId ?? '',
      },
      {
        key: LogType.SerialNumber,
        value: serialNumber ?? '',
      },
    ];
    logging('info', '', '', false, logParams, true);

    pushToast({
      message: t(`Your feedback has been submitted`),
      type: 'success',
    });
    onClose(true, feedback);
    closeFeedbackOptions();
  }

  return (
    <>
      <FeedbackScreen
        feedbackType="repairAction"
        isOpen={isFeedbackOpen}
        handleClose={() => {
          setIsFeedbackOpen(false);
          closeFeedbackOptions();
        }}
        handleSubmit={(raPerformed: boolean) => {
          setIsFeedbackOpen(false);
          onClose(raPerformed, 'Feedback');
          closeFeedbackOptions();
        }}
        repairActionId={raId}
        moduleId={moduleId}
        repairActionDescription={raDescription}
        repairActionName={raName}
        efcId={efcId}
        efcCode={efcCode}
      />

      <div className="flex divide-x">
        <RepairActionCompleteActionCard
          bgColor="bg-steel"
          cardText={t('Leave feedback')}
          icon={CommentOutlinedIcon}
          onClick={() => {
            setIsFeedbackOpen(true);
          }}
        />
        <RepairActionCompleteActionCard
          bgColor="bg-dnred"
          cardText={t('Not relevant')}
          icon={ThumbDownOutlinedIcon}
          onClick={() => logFeedback('Not relevant')}
        />
        <RepairActionCompleteActionCard
          bgColor="bg-cobalt"
          cardText={t('Relevant')}
          icon={ThumbUpOutlinedIcon}
          onClick={() => logFeedback('Relevant')}
        />
        <RepairActionCompleteActionCard
          bgColor="bg-purple"
          cardText={t('Fixed my issue')}
          icon={VerifiedOutlinedIcon}
          onClick={() => logFeedback('Fixed my issue')}
        />
      </div>
    </>
  );
}

export default RepairActionComplete;
