import { ICallToActionSettings } from '../Models/CallToActionSettings';
import EndPoint from '../Models/EndPoint';
import { ARE_CANCELLED_TICKETS_ALLOWED, cancelledTasks } from '../constants';

export const filterTimelineTasks = (endpoint: EndPoint) => {
  const areCancelledTasksAllowed =
    localStorage.getItem(ARE_CANCELLED_TICKETS_ALLOWED) ?? 'false';
  const updatedTimelineEvents = endpoint?.timelineEventDetails?.filter(
    (timelineItem) =>
      areCancelledTasksAllowed === 'false'
        ? !cancelledTasks.some(
            (taskStatus) =>
              taskStatus === timelineItem?.taskStatus?.toLowerCase()
          )
        : true
  );

  const updatedEndpoint = {
    ...endpoint,
    timelineEventDetails: updatedTimelineEvents,
  } as EndPoint;
  return updatedEndpoint;
};

export const uniqueIdConstructorCTA = (
  values: Omit<ICallToActionSettings, 'id'>
) => {
  const { endpointId, taskNumber, callToActionType, value } = values;
  return endpointId + taskNumber + callToActionType + value;
};
