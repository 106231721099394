import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import useLogging from '../../hooks/useLogging';
import { useEffect } from 'react';
import LogParams from '../../Models/LogParams';
import { useToast } from '../../components/toast/ToastProvider';
import { useTranslation } from 'react-i18next';

const regExpRedirects = (originurl: string, isExternal: boolean) => {
  const url = originurl.split('com')[1];

  const regexPatternModuleVariant2 = /\/EFC\/Index\/moduleabbrev=([^\s?]+)/;
  const regexPatternModuleVariant2Matches = url.match(
    regexPatternModuleVariant2
  );

  const efcQueryPatternVariant4 = /\/EFC\/Index\/efccode=([^\s?]+)/;
  const efcQueryPatternMatchesVariant4 = url.match(efcQueryPatternVariant4);

  const efcQueryPatternVariant5 =
    /\/EFCquery\/([^\/\s]+)\/(?!.*(?:moduleabbrev|efccode|module))/i;
  const efcQueryPatternMatchesVariant5 = url.match(efcQueryPatternVariant5);

  const regexPatternModule = /\/EFCQuery\/module\/([^&?]*)[&?](.*)/;
  const regexPatternModuleMatches = url.match(regexPatternModule);

  const regexPatternModuleVariant1 = /\/EFCQuery\/module\/(.+)/;
  const regexPatternModuleVariant1Matches = url.match(
    regexPatternModuleVariant1
  );

  const efcQueryPattern = /\/EFCQuery\/([^&;?]*)[&;?](.*)/;
  const efcQueryPatternMatches = url.match(efcQueryPattern);

  const efcQueryPatternVariant1 = /\/EFCQuery\/(\b\w{8}\b)\/(.+)/;
  const efcQueryPatternMatchesVariant1 = url.match(efcQueryPatternVariant1);

  const efcQueryPatternVariant2 = /\/EFCQuery\/(\b\w{8}\b)\/(.+)\/(.+)/;
  const efcQueryPatternMatchesVariant2 = url.match(efcQueryPatternVariant2);

  const efcQueryPatternVariant3 = /\/EFCQuery\/(\b\w{8}\b)/;
  const efcQueryPatternMatchesVariant3 = url.match(efcQueryPatternVariant3);

  if (
    regexPatternModuleMatches ||
    regexPatternModuleVariant1Matches ||
    regexPatternModuleVariant2Matches
  ) {
    const matches = (regexPatternModuleMatches ||
      regexPatternModuleVariant2Matches ||
      regexPatternModuleVariant1Matches) as RegExpMatchArray;
    return `/moduleResults?moduleabbrev=${matches[1]}`;
  } else if (
    efcQueryPatternMatchesVariant2 ||
    efcQueryPatternMatchesVariant1 ||
    efcQueryPatternMatches ||
    efcQueryPatternMatchesVariant3 ||
    efcQueryPatternMatchesVariant4 ||
    efcQueryPatternMatchesVariant5
  ) {
    const matches = (efcQueryPatternMatchesVariant2 ||
      efcQueryPatternMatchesVariant1 ||
      efcQueryPatternMatchesVariant3 ||
      efcQueryPatternMatchesVariant4 ||
      efcQueryPatternMatchesVariant5 ||
      efcQueryPatternMatches) as RegExpMatchArray;
    return `/efccode/${matches[1]}?externalSource=${isExternal}`;
  } else {
    return '';
  }
};

function ExternalSource() {
  const logging = useLogging();
  const navigate = useNavigate();
  const { state } = useLocation();
  let redirect_uri: string = '';
  const isExternal: boolean = state?.isExternal ?? true;
  const from: string = state?.from ?? 'external';
  //Escape ampersand in request query params
  const value = queryString.parse(
    decodeURI(window.location.search.replace(/&amp;/g, '&'))
  );
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(value).length) {
      const logParams: LogParams[] = [];
      Object.keys(value).forEach((a) => {
        logParams.push({
          key: a,
          value: value[a] as string,
        });
      });
      logging('info', '', '', true, logParams);
      const url_value: string = logParams.find((a) => a.key === 'URL')
        ?.value as string;
      const redirectList = url_value?.split('?')[1]?.split('=');

      if (redirectList) {
        switch (redirectList[0]) {
          case 'efccode':
            redirect_uri = `/efccode/${redirectList[1]}?externalSource=${isExternal}`;
            break;
          case 'moduleabbrev':
            let keyword: string = logParams.find((a) => a.key === 'keyword')
              ?.value as string;
            keyword = keyword === undefined ? '' : keyword;
            redirect_uri = `/moduleResults?moduleabbrev=${redirectList[1]}&keyword=${keyword}`;
            break;
          case 'keyword':
            redirect_uri = `/moduleResults?keyword=${
              redirectList[1] as string
            }`;
            break;
          case 'raid':
            const efcId: string = logParams.find((a) => a.key === 'efcid')
              ?.value as string;
            redirect_uri = `/raid/${redirectList[1]}?efcId=${efcId}&externalSource=${isExternal}`;
            break;
        }
      }
      if (!redirect_uri) {
        redirect_uri = regExpRedirects(url_value, isExternal);
      }

      if (redirect_uri === '') {
        const efccode_value: string = logParams.find((a) => a.key === 'efccode')
          ?.value as string;
        if (efccode_value) {
          redirect_uri = `/efccode/${efccode_value}?externalSource=${isExternal}`;
        }
      }

      if (redirect_uri === '') {
        const raid_value: string = logParams.find((a) => a.key === 'raid')
          ?.value as string;
        if (raid_value) {
          const efcId: string = logParams.find((a) => a.key === 'efcid')
            ?.value as string;
          redirect_uri = `/raid/${raid_value}?efcId=${efcId}&externalSource=${isExternal}`;
        }
      }

      if (redirect_uri === '') {
        redirect_uri = `/search`;
        toast.pushToast({
          message: t(
            'Not a valid Acfs search, please proceed with manual searching'
          ),
          type: 'info',
        });
      }

      navigate(redirect_uri, {
        replace: true,
        state: { isReplace: true, from },
      });
    }
  }, [redirect_uri]);

  return <></>;
}

export default ExternalSource;
