import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import { useCallback, useEffect, useRef, useState } from 'react';

import pako from 'pako';
import { useApi } from '../acfs-apis/dwar-api-provider';

import useLanguageTransformer from '../hooks/useLanguageTranformer';
import db from '../index-db';
import { stringToHash } from '../utils';
import useLogging from '../hooks/useLogging';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { audioAiSummaryActions } from '../features/audioAiSummary/audioAiSummarySlice';
import { LogType } from '../enums/LogType';
import ReadUnreadCta from './icons/ReadUreadCta';
import FetchingIcon from './icons/Fetching';

interface Props {
  summaryText: string;
  onSuccessfulPlay?: () => void;
  readState: 'primary' | 'secondary';
}

const checkSumAudio = async (str: string, audioUrl?: Response) => {
  const hashedKey = stringToHash(str);

  const data = await db.audioAiSummary.where('key').equals(hashedKey).toArray();

  if (!data?.length && audioUrl) {
    const gzipData = await audioUrl.arrayBuffer();
    const decompressedData = pako.ungzip(gzipData);
    const audioBlob = new Blob([decompressedData], { type: 'audio/wav' }); // Adjust type as per your audio format

    await db.audioAiSummary.add({
      key: hashedKey,
      value: audioBlob,
      lastLoadTime: new Date().valueOf(),
    });
  }
  return db.audioAiSummary.get(hashedKey);
};

const AudioAiSummary = (props: Props) => {
  const { summaryText, onSuccessfulPlay, readState } = props;
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const api = useApi();
  const [isPlaying, setIsPlaying] = useState(false);

  const langCode = useLanguageTransformer('secondary');
  const logging = useLogging();
  const dispatch = useAppDispatch();

  const fetchAudioAiSummary = useCallback(
    async (audioElement: HTMLAudioElement | null) => {
      if (summaryText && audioElement) {
        let returnedBlob = await checkSumAudio(summaryText);

        if (!returnedBlob) {
          let audioResponse;
          try {
            audioResponse = await api.postJson(
              '/dwar/api/acfs/SpeechService/convertTextToSpeech',
              JSON.stringify(summaryText),
              langCode
            );
          } catch (error) {
            logging(
              'warn',
              'Unable to fetch Audio Summary from back-end',
              error,
              true,
              null
            );
          }

          returnedBlob = await checkSumAudio(summaryText, audioResponse);
        }

        if (returnedBlob) {
          const url = URL.createObjectURL(returnedBlob.value);

          audioElement.src = url;
          setAudioUrl(url);
        }
      }
    },
    [summaryText, langCode]
  );
  useEffect(() => {
    const audioElement = audioRef.current;
    fetchAudioAiSummary(audioElement);
    return () => {
      audioElement?.pause();
    };
  }, [audioRef, fetchAudioAiSummary]);
  const currentActiveTrack = useAppSelector(
    (state) => state.aiAudioSummary.activeTrack
  );
  const activeTrack = stringToHash(summaryText);
  useEffect(() => {
    if (currentActiveTrack !== activeTrack && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [audioRef, currentActiveTrack, activeTrack]);

  const onAudioStart = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(audioAiSummaryActions.setActiveTrack({ activeTrack }));
    if (audioUrl && audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
      onSuccessfulPlay?.();
      logging(
        'info',
        'User clicked on Ai summary audio play button',
        undefined,
        true,
        [
          {
            key: LogType.AiSummary,
            value: 'User started playing Audio of the Ai summary',
          },
        ]
      );
    }
  };

  const onAudioStop = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      dispatch(audioAiSummaryActions.setActiveTrack({ activeTrack: null }));
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      logging(
        'info',
        'User clicked on Ai summary audio stop button',
        undefined,
        true,
        [
          {
            key: LogType.AiSummary,
            value: 'User stopped playing Audio of the Ai summary',
          },
        ]
      );
    }
  };
  if (!summaryText) {
    return null;
  }
  const onAudioEnd = () => {
    setIsPlaying(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {audioUrl && isPlaying ? (
        <ReadUnreadCta
          icon={VolumeOffOutlinedIcon}
          onClick={onAudioStop}
          iconSize="1.5rem"
          iconType={readState}
        />
      ) : (
        <div className="relative inline-block text-white">
          {!audioUrl && (
            <FetchingIcon
              contrastType={readState}
              component={CloudSyncIcon}
              sx={{
                fontSize: '15px',
                position: 'absolute',
                top: 1,
                right: 1,
                zIndex: 10,
              }}
            />
          )}
          <ReadUnreadCta
            icon={VolumeUpOutlinedIcon}
            onClick={onAudioStart}
            iconSize="1.5rem"
            iconType={readState}
          />
        </div>
      )}

      <audio ref={audioRef} preload={'auto'} onEnded={onAudioEnd}></audio>
    </div>
  );
};

export default AudioAiSummary;
