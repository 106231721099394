import { useAppDispatch } from '../../app/hooks';
import GenericModelCategory from '../../components/GenericModelCategory';
import { searchActions } from '../../features/search/searchSlice';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import GenericModelData from '../../Models/GenericModelData';

interface Props {
  savedItemsData: GenericModelData[];
  title: string;
  startCollapsed: boolean;
}

function Category(props: Props) {
  const dispatch = useAppDispatch();

  const handleClearAll = () => {
    if (props.savedItemsData.length > 0) {
      if (props.savedItemsData[0].IdType === IDType.SEARCH) {
        dispatch(searchActions.deleteAllSavedSearches());
      } else {
        db.savedItems.bulkDelete(props.savedItemsData.map((item) => item.Id));
      }
    }
  };

  return (
    <GenericModelCategory
      genericModelData={props.savedItemsData ?? []}
      title={props.title}
      startCollapsed={props.startCollapsed}
      onClearAll={handleClearAll}
    />
  );
}

export default Category;
