import { useApi } from '../acfs-apis/dwar-api-provider';
import Log from '../Models/Log';
import { LogType } from '../enums/LogType';
import LogParams from '../Models/LogParams';
import db from '../index-db';
import useGetDeviceId from './useGetDeviceId';
import { LAST_ACCESSED_ENDPOINT } from '../constants';
import { useCallback } from 'react';

const useLogging = () => {
  const api = useApi();
  const deviceId = useGetDeviceId() || '';
  const logging = useCallback(
    async (
      logType: string,
      message: string,
      err: unknown,
      addDeviceInfo: boolean,
      logParams: LogParams[] | null,
      addOpenTasks: boolean = false
    ) => {
      let deviceType = '';
      if (addDeviceInfo) {
        deviceType = window.navigator.userAgent;
      }
      if (addOpenTasks) {
        let openTasks: string[] = [];
        await db.tasks.each((k) => openTasks.push(k.id));
        if (openTasks.length > 0) {
          if (logParams == null) {
            logParams = [];
          }
          const lastAccessedEndpoint =
            window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';
          logParams = logParams.filter(
            (param) => param.key !== LogType.EndpointId
          );
          logParams.push({
            key: LogType.EndpointId,
            value: lastAccessedEndpoint,
          });
          logParams.push({
            key: LogType.OpenTasks,
            value: openTasks.join(';'),
          });
        }
      }
      const log = new Log(
        new Date().toISOString(),
        logType,
        'ACFS-UI',
        `${message} ${err ?? ''}`,
        deviceType,
        deviceId,
        '',
        '',
        '',
        logParams
      );
      let logMessages = new Array<Log>();
      logMessages.push(log);

      try {
        await api.postJson(
          '/dwar/api/feedback/Logger/log',
          JSON.stringify(logMessages)
        );
      } catch (error) {
        console.log(error);
      }
    },
    [api, deviceId]
  );
  return logging;
};

export default useLogging;
