import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { usePage } from '../../PageProvider';
import MyTasks from './MyTasks';
import RecentlyViewed from './RecentlyViewed';
import FeedbackScreen from '../Feedback/FeedbackScreen';
import db from '../../index-db';
import FailedFeedback from '../../Models/FailedFeedback';
import FailedCallToActionSettings from '../../Models/FailedCallToActionSettings'; // IFailedCallToActionSettings,
import { useApi } from '../../acfs-apis/dwar-api-provider';
import useCheckDeviceType from '../../hooks/useCheckDeviceType';
import { useToast } from '../../components/toast/ToastProvider';
import { LogType } from '../../enums/LogType';
import { FbType } from '../../enums/FbType';
import useCheckUserIsPartner from '../../hooks/useCheckUserIsPartner';

import NotificationPopups from './NotificationPopups';
import FailedTimeLineEvent from '../../Models/FailedTimeLineEvent';

function Home() {
  const { t } = useTranslation();
  const logging = useLogging();
  const api = useApi();
  const { pushToast } = useToast();
  const deviceType = useCheckDeviceType();

  const isPartner = useCheckUserIsPartner();

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Home,
      },
    ];
    logging('info', '', '', false, logParams);
    (async () => {
      const record = await db.failedFeedback.toArray();
      if (record?.length > 0) {
        let markedForDeletion: number[] = [];
        let isiOS = false;
        if (deviceType === 'iOS') {
          isiOS = true;
        }
        record.forEach(async (record: FailedFeedback) => {
          markedForDeletion.push(record.id);
          const result = await api.postFormData(
            '/dwar/api/feedback/FeedBack/saveFeedback',
            record.feedback,
            isiOS
          );
          if (result === true) {
            pushToast({
              message: t(`Your feedback has been sent`),
              type: 'success',
            });
          }
        });
        await db.table('failedFeedback').bulkDelete(markedForDeletion);
      }
    })();

    (async () => {
      const record = await db.failedCallToActionSettings.toArray();
      if (record?.length > 0) {
        let markedForDeletion: number[] = [];
        record.forEach(async (record: FailedCallToActionSettings) => {
          try {
            await api.saveCallToActionSettings(record.callToActionSettings);

            markedForDeletion.push(record.Id as number);
          } catch (err) {
            logging(
              'error',
              'Failed to sync call action from client to server',
              err,
              false,
              null
            );
          }
        });
        if (markedForDeletion?.length) {
          await db
            .table('failedCallToActionSettings')
            .bulkDelete(markedForDeletion);
        }
      }
    })();

    (async () => {
      const failedTimelineEvents = await db.failedTimeLineEvent.toArray();
      if (failedTimelineEvents?.length > 0) {
        failedTimelineEvents.forEach(async (record: FailedTimeLineEvent) => {
          try {
            await api.postJson(
              '/dwar/api/almanac/TimelineEvent/addTimelineEvent',
              JSON.stringify(record.timeLineEvent)
            );
            await db.table('failedTimeLineEvent').delete(record.id as number);
          } catch (err) {
            logging(
              'error',
              'Failed to sync timeline events from client to server',
              err,
              false,
              null
            );
          }
        });
      }
    })();
  }, [deviceType]);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  usePage(
    () => ({
      getTitle: () => t('Home'),
      belongsToNavBarItem: 'Home',
      showACFSLogo: true,
      rightButtons: [
        { icon: CommentOutlinedIcon, onClick: () => setFeedbackOpen(true) },
      ],
    }),
    [t]
  );

  return (
    <div>
      <FeedbackScreen
        feedbackType={FbType.General}
        isOpen={isFeedbackOpen}
        handleClose={() => setFeedbackOpen(false)}
        handleSubmit={() => setFeedbackOpen(false)}
      />
      <NotificationPopups />
      <div className="space-y-6 pt-6">
        {!isPartner && <MyTasks />}
        <RecentlyViewed />
      </div>
    </div>
  );
}
export default Home;
