import React from 'react';
import { useTranslation } from 'react-i18next';
import { TIcon } from '../types';
import Icon from './Icon';

export interface Props {
  icon: TIcon;
  message: string;
}

function NoItems({ icon, message }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center pt-10">
      <div className="relative mb-5 h-28 w-28 rounded-full bg-antartica">
        <Icon
          className="absolute right-0 bottom-0"
          is={icon}
          colorClass="text-white"
          size="6rem"
        />
      </div>

      <p className="text-lg font-bold text-steel">{t('Nothing here yet')}</p>
      <p className="text-lg text-steel">{t(message)}</p>
    </div>
  );
}

export default NoItems;
