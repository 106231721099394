import Modal from '@mui/material/Modal';
import { JSXElementConstructor, ReactElement } from 'react';

interface Props {
  isModalOpen: boolean;
  onModalClose?: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const PopupModal = (props: Props) => {
  const { isModalOpen, onModalClose, children } = props;

  return (
    <Modal
      open={isModalOpen}
      onClose={onModalClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Modal>
  );
};

export default PopupModal;
