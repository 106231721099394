import React from 'react';
import { Link, To } from 'react-router-dom';
import Icon from '../../../components/Icon';
import { TIcon } from '../../../types';

interface Props {
  icon: TIcon;
  title: string;
  to: To;
}

function CategoryButton(props: Props) {
  return (
    <Link
      className="flex items-center gap-x-4 rounded-lg border border-antartica bg-white px-4 py-3.5 shadow-dark "
      to={props.to}
    >
      <Icon is={props.icon} colorClass="text-cobalt" size={'2rem'} />
      <span className="text-lg font-bold text-cobalt">{props.title}</span>
    </Link>
  );
}

export default CategoryButton;
