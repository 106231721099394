import { useEffect, useState, useCallback } from 'react';
import useNavigateToEFC from '../../../hooks/useNavigateToEfc';
import EntitlementEntity from '../../../Models/Entitlement';
import SystemsCard from '../../../components/cards/SystemsCard';
import { EFC } from '../../../Models/EFC';
import db from '../../../index-db';
import useGetEntitlementIcon from '../../../hooks/useGetEntitlementIcon';

interface Props {
  entitlement: EntitlementEntity;
}

function Entitlement({ entitlement }: Props) {
  const navigateToEFC = useNavigateToEFC();
  const [entitlementEFC, setEntitlementEFC] = useState<EFC>();

  const fetchEFC = useCallback(
    async (efcCode: string): Promise<EFC | undefined> => {
      return await db.efcs
        .where('EfcCodeStr')
        .equalsIgnoreCase(efcCode)
        .first();
    },
    []
  );

  const efcCode = 'ENT' + entitlement.id + '0' + entitlement.status.toString();

  useEffect(() => {
    (async () => {
      const efc = await fetchEFC(efcCode);
      setEntitlementEFC(efc);
    })();
  }, [fetchEFC, efcCode]);

  const handleClick = () => {
    if (entitlementEFC) {
      navigateToEFC(entitlementEFC?.Id.toString());
    }
  };

  const { entitlementIcon, entitlementIconColor } = useGetEntitlementIcon(
    entitlement.status.toString()
  );

  const lastUpdateDate = new Date(entitlement.lastUpdate);
  const currentDate = new Date(Date.now());

  const diffInTime = currentDate.getTime() - lastUpdateDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  const hours =
    lastUpdateDate.getHours() < 10
      ? `0${lastUpdateDate.getHours()}`
      : lastUpdateDate.getHours().toString();
  const minutes =
    lastUpdateDate.getMinutes() < 10
      ? `0${lastUpdateDate.getMinutes()}`
      : lastUpdateDate.getMinutes().toString();

  return (
    <div className="mb-2">
      <SystemsCard
        iconColor={entitlementIconColor}
        clickableType="primary"
        onCardClick={handleClick}
        showEntitlementIcon={true}
        entitlementIcon={entitlementIcon}
      >
        <div className="min-w-0 flex-1">
          <div className="break-words text-lg">{entitlement.name}</div>
          {entitlement.message && (
            <div className="ml-2 break-words text-lg">
              {entitlement.message}
            </div>
          )}
          <div className="flex items-start justify-between">
            {entitlement?.lastUpdate && (
              <div>
                {diffInDays} {'days ago'} {hours}:{minutes}{' '}
                {
                  lastUpdateDate
                    .toDateString()
                    .split(lastUpdateDate.getFullYear().toString())[0]
                }
              </div>
            )}
          </div>
        </div>
      </SystemsCard>
    </div>
  );
}
export default Entitlement;
