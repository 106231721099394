import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import Collapsible from '../../components/collapsible/Collapsible';
import RALinks from '../../Models/RALinks';

interface Props {
  RALinksData: RALinks[];
  title: string;
}

function RepairActionLinks(props: Props) {
  const { t } = useTranslation();
  const launchRALink = (url: string) => {
    window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
  };

  return (
    <Collapsible headingTitle={props.title}>
      {props.RALinksData.map((item) => (
        <ActionableListItem
          key={item.Id}
          title={t(item.UrlType)}
          primaryAction="launch"
          appearance="nonBold"
          onClick={() => {
            launchRALink(item.Url);
          }}
        />
      ))}
    </Collapsible>
  );
}

export default RepairActionLinks;
