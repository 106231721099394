import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IDType } from '../Models/Enumerations';
import { OnlyExpand } from '../types';

const useInitiallyExpandedCategory = () => {
  const locationState = useLocation().state as OnlyExpand;

  const [allCollapsed, setAllCollapsed] = useState(
    typeof locationState?.onlyExpand === 'number'
  );

  const getStartCollapsed = (idType: IDType) => {
    if (typeof locationState?.onlyExpand !== 'number') {
      // onlyExpand was not passed? Then every category starts expanded
      return false;
    }
    // Every category BUT the "onlyExpand" one starts explicitly collapsed.
    return locationState?.onlyExpand !== idType;
  };

  return {
    allCollapsed,
    setAllCollapsed,
    getStartCollapsed,
  };
};

export default useInitiallyExpandedCategory;
