import DetailsEquipmentRow from './DetailsEquipmentRow';

interface Props {
  endpointId?: string;
  model?: string;
  serialNumber?: string;
  terminalId?: string;
  prodDesc?: string;
}

function EquipmentInfo(props: Props) {
  const DetailsRowEquipment = [
    { title: 'Equipment ID', value: props?.endpointId },
    { title: 'Equipment', value: props?.prodDesc },
    { title: 'Model', value: props?.model },
    { title: 'Terminal ID', value: props?.terminalId },
    { title: 'Device serial number', value: props?.serialNumber },
  ];
  return (
    <>
      {DetailsRowEquipment.map((data) => (
        <DetailsEquipmentRow {...data} key={data?.title} />
      ))}
    </>
  );
}

export default EquipmentInfo;
