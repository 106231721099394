import { useTranslation } from 'react-i18next';
import Button from './components/Button';
import { usePage } from './PageProvider';
import useCheckMobileScreen from './hooks/useCheckMobileScreen';
import { DATABASE_LOADED_STATUS_KEY } from './constants';

function PageNotFound() {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const SearchQuery = isMobile ? '/search/query/true' : '/search';

  const loadedStatusOfIndexedDB = window.localStorage.getItem(
    DATABASE_LOADED_STATUS_KEY
  );

  const dataLoading = loadedStatusOfIndexedDB === 'false';

  usePage(
    () => ({
      getTitle: () => t('Page not found'),
      belongsToNavBarItem: 'Home',
      showBackButton: true,
    }),
    [t]
  );

  return (
    <div className="flex h-full flex-col items-center gap-y-4  p-4">
      {dataLoading && (
        <span className="text-lg font-medium text-dnred">
          {t(
            'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.'
          )}
        </span>
      )}
      {!dataLoading && (
        <>
          <span className="text-lg font-medium text-dnred">
            {t(
              'We apologize for the inconvenience.  The link used is not a valid location in ACFS.'
            )}
          </span>
          <img
            src="./bell.png"
            alt="404"
            className="w-9 max-w-sm mix-blend-multiply"
          />
        </>
      )}

      <div className="flex  justify-center gap-x-1">
        <Button styling="primary" to={SearchQuery}>
          {t('Search')}
        </Button>
        <Button styling="primary" to="/">
          {t('Return home')}
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;
