import {
  RECENT_SEARCHES_LOCAL_STORAGE_KEY,
  SAVED_SEARCHES_LOCAL_STORAGE_KEY,
} from '../../constants';
import { Search, SearchScope } from '../../types';
import i18n from 'i18next';

export const getSearchTermPlaceholderByScope = (
  scope: SearchScope | undefined
): string => {
  if (scope === undefined) {
    return '';
  }

  const exhaustiveMap: Record<SearchScope, string> = {
    'everywhere': i18n.t('Search AllConnect Field Support'),
    'efc codes': i18n.t('Search all EFC codes'),
    'parts': i18n.t('Search all parts'),
    'products': i18n.t('Search all products'),
    'repairs': i18n.t('Search all repairs by part number or repair id'),
  };

  return exhaustiveMap[scope];
};

export const getScopeTitle = (scope: SearchScope): string => {
  const exhaustiveMap: Record<SearchScope, string> = {
    'everywhere': i18n.t('Everywhere'),
    'efc codes': i18n.t('EFC codes'),
    'parts': i18n.t('Parts'),
    'products': i18n.t('Products'),
    'repairs': i18n.t('Repairs'),
  };

  return exhaustiveMap[scope];
};

export const searchesAreEqual = (
  searchA: Search | null,
  searchB: Search | null
): boolean => {
  const a = { ...searchA };
  const b = { ...searchB };
  delete a.timestamp;
  delete b.timestamp;
  return JSON.stringify(a) === JSON.stringify(b);
};

export const updateSavedSearchesInStorage = (savedSearches: Search[]) => {
  // TODO: Running the side effect in the reducer is an anti-pattern. Use a better approach
  window.localStorage.setItem(
    SAVED_SEARCHES_LOCAL_STORAGE_KEY,
    JSON.stringify(savedSearches)
  );
};

export const updateRecentSearchesInStorage = (recentSearches: Search[]) => {
  // TODO: Running the side effect in the reducer is an anti-pattern. Use a better approach
  window.localStorage.setItem(
    RECENT_SEARCHES_LOCAL_STORAGE_KEY,
    JSON.stringify(recentSearches)
  );
};
