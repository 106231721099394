import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChildLevel } from '../../Models/Enumerations';
import Button from '../Button';
import Icon from '../Icon';

interface RequiredProps {
  title: string;
  collapsed: boolean;
  onToggle: () => void;
}

interface OptionalProps {
  subText?: string;
  onClear?: () => void;
  childLevel: number;
}

type Props = RequiredProps & OptionalProps;

const defaultProps: OptionalProps = {
  subText: undefined,
  onClear: undefined,
  childLevel: 0
};

function CollapsibleHeading(props: Props) {
  const { t } = useTranslation();
  var className = classNames({
    'ml-6': props.childLevel === ChildLevel.FirstGen
  });
  return (
    <div className={`${className} flex h-12 items-center border-b border-antartica`}>
      <button
        className="flex flex-1 items-center self-stretch pr-2.5"
        onClick={props.onToggle}
      >
        <Icon
          className="mx-2.5"
          is={props.collapsed ? ExpandMoreOutlinedIcon : ExpandLessOutlinedIcon}
        />
        <h3 className="text-lg font-bold">{props.title}</h3>
        {props.subText && (
          <span className="ml-auto mr-1.5">{props.subText}</span>
        )}
      </button>
      {!!props.onClear && (
        <Button styling="tertiary" onClick={props.onClear}>
          {t('Clear')}
        </Button>
      )}
    </div>
  );
}

CollapsibleHeading.defaultProps = defaultProps;

export default CollapsibleHeading;
