import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link, To } from 'react-router-dom';
import { TIcon } from '../types';
import Icon from './Icon';

type Styling = 'primary' | 'secondary' | 'tertiary';

interface RequiredProps {
  styling: Styling;
}

interface OptionalProps {
  className?: string;
  disabled?: boolean;
  icon?: TIcon;
}

type ConditionalProps =
  | {
      to: To;
      onClick?: never;
    }
  | {
      to?: never;
      onClick: () => void;
    };

type Props = RequiredProps & OptionalProps & ConditionalProps;

function Button(props: PropsWithChildren<Props>) {
  const buttonClasses: Record<Styling, string> = {
    primary:
      'h-10 px-3 font-medium text-white bg-cobalt border border-transparent rounded-lg shadow-common active:bg-dnblue active:shadow-none disabled:bg-sky disabled:text-ice disabled:shadow-none',
    secondary:
      'h-10 px-3 font-medium text-cobalt bg-white border border-cobalt rounded-lg shadow-common active:bg-nameless-lightblue active:shadow-none disabled:bg-ice disabled:border-sky disabled:text-sky disabled:shadow-none',
    tertiary:
      'h-[35px] px-3 font-medium text-cobalt bg-transparent border-2 border-transparent rounded-lg active:bg-nameless-lightblue active:shadow-common-inset disabled:text-sky disabled:bg-transparent disabled:shadow-none focus-visible:bg-nameless-lightblue focus-visible:border-olympic focus-visible:outline-none',
  };

  const iconClasses: Record<Styling, string> = {
    primary: 'text-white',
    secondary: 'text-cobalt',
    tertiary: 'text-cobalt',
  };

  const buttonClassName = classnames(
    'flex justify-center items-center',
    buttonClasses[props.styling],
    props.className
  );

  const renderContents = () => {
    return (
      <>
        {props.icon && (
          <Icon
            className="-translate-x-1"
            is={props.icon}
            colorClass={iconClasses[props.styling]}
          />
        )}
        {props.children}
      </>
    );
  };

  if (props.to) {
    return (
      <Link className={buttonClassName} to={props.to}>
        {renderContents()}
      </Link>
    );
  }

  return (
    <button
      className={buttonClassName}
      type="button"
      disabled={!!props.disabled}
      onClick={props.onClick}
    >
      {renderContents()}
    </button>
  );
}

export default Button;
