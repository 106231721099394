import TimeLineEvent from './TimeLineEvent';

export class FailedTimeLineEvent {
  id?: number;
  timeLineEvent: TimeLineEvent;

  constructor(id: number, timeLineEvent: TimeLineEvent) {
    this.id = id;
    this.timeLineEvent = timeLineEvent;
  }
}

export default FailedTimeLineEvent;
