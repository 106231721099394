import React from 'react';
import SubStep from '../../Models/SubStep';
import NumberedListItem from '../../components/NumberedListItem';
import Collapsible from '../../components/collapsible/Collapsible';

interface Props {
  substepsData: SubStep[];
  title: string;
}

function Substeps(props: Props) {
  return (
    <Collapsible headingTitle={`${props.title} (${props.substepsData.length})`}>
      {props.substepsData.map((item, index) => (
        <NumberedListItem
          key={item.Id}
          number={index + 1}
          description={item.Description}
          imageId={item.ImageId === null ? undefined : item.ImageId}
          imageType="subSteps"
        />
      ))}
    </Collapsible>
  );
}

export default Substeps;
