import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectActiveSearch } from '../app/selectors';
import { searchActions } from '../features/search/searchSlice';

interface ExplicitlyNamedOptions {
  awaitUpdatedSearchTerm: boolean; // Pass true if the search term in redux state has yet to get updated. Navigation happens immediately after that update occurs.
}

/**
 * This hook navigates to the search results, adding an entry to the recent searches.
 */
const useNavigateToSearchResults = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeSearch = useAppSelector(selectActiveSearch);
  const searchTerm = activeSearch?.searchTerm ?? '';

  const [navigateWhenSearchTermChanges, setNavigateWhenSearchTermChanges] =
    useState(false);
  const location = useLocation();
  const isReplace = location.state?.isReplace;

  useEffect(() => {
    if (navigateWhenSearchTermChanges) {
      setNavigateWhenSearchTermChanges(false);
      doNavigate();
    }
  }, [searchTerm, navigateWhenSearchTermChanges]);
  const tabActiveSubActive =
    activeSearch?.scope === 'everywhere' ? 'all' : activeSearch?.scope ?? 'all';

  const doNavigate = () => {
    if (activeSearch?.searchTerm?.trim()) {
      dispatch(searchActions.markActiveSearchRecent());
    }
    navigate(
      `/search/results/${tabActiveSubActive}/${tabActiveSubActive}/${activeSearch?.searchTerm?.trim()}`,
      {
        replace: isReplace,
      }
    );
  };

  return (options: ExplicitlyNamedOptions) => {
    if (options.awaitUpdatedSearchTerm) {
      setNavigateWhenSearchTermChanges(true);
    } else {
      doNavigate();
    }
  };
};

export default useNavigateToSearchResults;
