import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../app/hooks';
import Button from '../../../components/Button';
import CollapsibleCollection from '../../../components/collapsible/CollapsibleCollection';
import PageActions from '../../../components/PageActions';
import { searchActions } from '../../../features/search/searchSlice';
import useNavigateToSearchResults from '../../../hooks/useNavigateToSearchResults';
import { usePage } from '../../../PageProvider';
import SearchTermInput from '../components/SearchTermInput';
import Filter from './Filter';
import useSaveSearchButton from './useSaveSearchButton';
import useUpdateSearchInCollection from './useUpdateSearchInCollection';

function Filters() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigateToSearchResults = useNavigateToSearchResults();
  const saveSearchButton = useSaveSearchButton();
  const removeFilter = () => {
    dispatch(searchActions.removeFilter('Module'));
  };
  usePage(
    () => ({
      getTitle: () => t('Filters'),
      belongsToNavBarItem: 'Search',
      showBackButton: true,
      rightButtons: [saveSearchButton],
    }),
    [t, saveSearchButton]
  );

  useEffect(() => {
    dispatch(searchActions.initSearch());
  }, [dispatch]);

  useUpdateSearchInCollection('recentSearches');

  const [allCollapsed, setAllCollapsed] = useState(true);
  return (
    <div className="pb-3">
      <PageActions
        actionsProps={[
          {
            icon: allCollapsed
              ? UnfoldMoreOutlinedIcon
              : UnfoldLessOutlinedIcon,
            title: allCollapsed ? t('Expand all') : t('Collapse all'),
            onClick: () => setAllCollapsed(!allCollapsed),
          },
        ]}
      />

      <CollapsibleCollection collapsed={allCollapsed}>
        {/*<Filter title="Category" filterKey="category" />*/}
        <Filter title={t('Module(EFC only)')} filterKey="Module" />
      </CollapsibleCollection>

      <div className="flex h-12 items-center border-b border-antartica px-4 text-lg font-bold">
        {t('Keywords')}
      </div>
      <div className="box-content h-12 p-4">
        <SearchTermInput hideSearchIcon />
      </div>
      <div className="mt-1 flex justify-around">
        <Button
          styling="secondary"
          onClick={() => {
            removeFilter();
          }}
          className="w-36"
        >
          {t('Clear filters')}
        </Button>
        <Button
          styling="primary"
          onClick={() =>
            navigateToSearchResults({ awaitUpdatedSearchTerm: true })
          }
          className="w-36"
        >
          {t('Show results')}
        </Button>
      </div>
    </div>
  );
}

export default Filters;
