import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import SegmentedControl from '../../components/SegmentedControl';
import { searchActions } from '../../features/search/searchSlice';
import { usePage } from '../../PageProvider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import { useEffect } from 'react';
import SearchBarAsButton from '../Search/components/SearchBarAsButton';
import { getSearchTermPlaceholderByScope } from '../Search/searchUtils';
import RecentEFCItems from './RecentEFCItems';
import SavedEFCItems from './SavedEFCItems';
import UpdatedEFCItem from './UpdatedEFCItem';
import SearchQr from '../../components/feature/SearchQR';

function EFCCodes() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logging = useLogging();

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.EFCSearch,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('EFCs'),
      showBackButton: true,
      belongsToNavBarItem: 'Search',
      rightButtons: [
        {
          icon: FilterAltOutlinedIcon,
          onClick: () => navigate('/search/filters'),
        },
      ],
    }),
    [t]
  );

  const handleSearchBarClick = () => {
    dispatch(searchActions.setScope('efc codes'));
    navigate('/search/query');
  };

  const segments = [t('Recent'), t('Saved')];
  const [activeSegment, setActiveSegment] = useState(segments[0]);
  const [isScanning, setIsScanning] = useState(false);

  const onScanClick = () => {
    setIsScanning((state) => !state);
  };

  return (
    <div>
      <div className="mb-4 space-y-7">
        <SearchBarAsButton
          title={getSearchTermPlaceholderByScope('efc codes')}
          onClick={handleSearchBarClick}
          onScanClick={onScanClick}
        />

        <SearchQr isScanning={isScanning} setIsScanning={setIsScanning} />
        <SegmentedControl
          segments={segments}
          activeSegment={activeSegment}
          onChange={setActiveSegment}
        />
      </div>

      {activeSegment === t('Recent') && <RecentEFCItems />}
      {activeSegment === t('Saved') && <SavedEFCItems />}
      <UpdatedEFCItem />
    </div>
  );
}

export default EFCCodes;
