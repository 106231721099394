import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { PopoverMenuButton } from '../PageProvider';
import InteractiveIcon from './InteractiveIcon';
import PopoverMenu from './PopoverMenu';

interface Props {
  popoverMenuButtons: PopoverMenuButton[];
  onShow?: () => void;
  onHide?: () => void;
}

function CardPopoverButton(props: Props) {
  const [showPopoverMenu, setShowPopoverMenu] = useState(false);

  const root = useRef<HTMLDivElement>(null);
  const showPopoverIfCardIsCentered = () => {
    const swipeItemContent = root.current!.closest(
      '.swipeable-list-item__content'
    ) as HTMLElement;

    const translateX: number =
      parseInt(swipeItemContent.style.transform.replace('translateX(', '')) ||
      0;

    if (translateX !== 0) {
      // The card is swiped left or right. Opening the popover in this state will cause z-index problems, so ignore the click.
      // The popover cannot be opened when the card is swiped to either side.
      return;
    }

    setShowPopoverMenu(true);
  };

  useEffect(() => {
    if (showPopoverMenu) {
      props.onShow?.();
    } else {
      props.onHide?.();
    }
  }, [showPopoverMenu]);

  return (
    <div className="absolute top-0 right-0" ref={root}>
      <InteractiveIcon
        icon={MoreVertOutlinedIcon}
        onClick={showPopoverIfCardIsCentered}
      />
      {showPopoverMenu && (
        <PopoverMenu
          buttons={props.popoverMenuButtons}
          onClickOutside={() => setShowPopoverMenu(false)}
          inCard={true}
        />
      )}
    </div>
  );
}

export default CardPopoverButton;
