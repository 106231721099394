import TryOutlinedIcon from '@mui/icons-material/TryOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '../IconButton';
import PopupModal from '../PopupModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLogging from '../../hooks/useLogging';
import { LogType } from '../../enums/LogType';
import ReadUnreadCta from '../icons/ReadUreadCta';

interface Props {
  summary: string;
  onSuccefulSave: () => void;
  readState: 'primary' | 'secondary';
}

function AiSummary(props: Props) {
  const { summary, readState, onSuccefulSave } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t } = useTranslation();
  const logging = useLogging();

  const onIconClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onSuccefulSave();
    setIsPopupOpen(true);
    logging(
      'info',
      'User read Ai Summary from the homescreen tasks',
      undefined,
      true,
      [
        {
          key: LogType.AiSummary,
          value: 'Home screen tasks Ai summary text button clicked',
        },
      ]
    );
  };

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };
  const handleClose = (e: React.SyntheticEvent) => {
    stopPropagation(e);
    setIsPopupOpen(false);
  };

  if (!summary) {
    return null;
  }

  return (
    <>
      <ReadUnreadCta
        iconType={readState}
        icon={TryOutlinedIcon}
        iconSize="1.5rem"
        onClick={onIconClick}
      />

      <PopupModal isModalOpen={isPopupOpen} onModalClose={handleClose}>
        <div
          className={
            'relative flex max-h-[75%] w-11/12	 flex-col items-center rounded-lg bg-white p-5'
          }
          onClick={stopPropagation}
        >
          <div className="absolute left-0 top-1 right-0 flex justify-between">
            <IconButton
              className="py-0"
              icon={CloseOutlinedIcon}
              onClick={handleClose}
            />
            <h1 className="sticky text-xl font-bold ">{t('AI Summary')}</h1>
            <img
              src={'./acde-recommended.png'}
              alt="ACDE"
              className="mr-2.5 h-7"
            />
          </div>
          <div className=" mb-2 mt-2 h-full w-full overflow-y-auto  ">
            <p className="  break-words text-lg">{summary}</p>
          </div>
        </div>
      </PopupModal>
    </>
  );
}

export default AiSummary;
