import ActionableListItem from '../../components/ActionableListItem';
import useNavigateFromRecentlyViewedItem from '../../hooks/useNavigateFromRecentlyViewedItem';
import GenericModelData from '../../Models/GenericModelData';
import { IDType } from '../../Models/Enumerations';
import { useTranslation } from 'react-i18next';

interface Props {
  recentlyViewedInfo: GenericModelData;
}

function RecentlyViewedItem({ recentlyViewedInfo }: Props) {
  const navigate = useNavigateFromRecentlyViewedItem();
  const { t } = useTranslation();
  return (
    <ActionableListItem
      icon={recentlyViewedInfo.Icon}
      title={recentlyViewedInfo.Heading}
      subText={recentlyViewedInfo.Description}
      subTitle = {(recentlyViewedInfo.IdType == IDType.PARTS) ? (recentlyViewedInfo.SubTitle &&`(${t('mfg')}: ${recentlyViewedInfo.SubTitle})`) : recentlyViewedInfo.SubTitle}
      primaryAction="navigate"
      onClick={() => navigate(recentlyViewedInfo)}
    />
  );
}

export default RecentlyViewedItem;
