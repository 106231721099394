import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { usePage } from '../../PageProvider';
import useNotYetImplemented from '../../hooks/useNotYetImplemented';
import ActionableListItem from '../../components/ActionableListItem';
import { useNavigate } from 'react-router-dom';
import { LogType } from '../../enums/LogType';

function Settings() {
  const { t } = useTranslation();
  const logging = useLogging();
  const notYetImplemented = useNotYetImplemented();
  const navigate = useNavigate();

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Help,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('Help & Feedback'),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [t]
  );

  return (
    <>
      <ActionableListItem
        title={t('Training')}
        primaryAction="navigate"
        onClick={notYetImplemented}
        appearance="setting"
      />
      <ActionableListItem
        title={t('General Help')}
        primaryAction="navigate"
        onClick={notYetImplemented}
        appearance="setting"
      />
      <ActionableListItem
        title={t('Offline Disclaimer')}
        primaryAction="navigate"
        onClick={() => {
          navigate(`/helpFeedback/disclaimer`);
        }}
        appearance="setting"
      />
    </>
  );
}

export default Settings;
