import { useTranslation } from 'react-i18next';
import SectionHeading from '../../../components/SectionHeading';
import SystemsCard from '../../../components/cards/SystemsCard';
import { useState } from 'react';
import EndPoint from '../../../Models/EndPoint';
import LogParams from '../../../Models/LogParams';
import { LogType } from '../../../enums/LogType';
import useLogging from '../../../hooks/useLogging';
import GenericModalSheet from '../../../components/GenericModalSheet';
import { CallToActionType } from '../../../Models/Enumerations';
import useHandleCtaRead from '../../../hooks/useHandleCtaRead';
import Details from './Details';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Icon from '../../../components/Icon';
import { TIcon } from '../../../types';

interface Props {
  equipmentData: EndPoint;
  isRead: boolean;
  taskNumber?: string;
}

const Inventory = (props: Props) => {
  const { t } = useTranslation();
  const logging = useLogging();
  const [isBottomsheetOpen, setIsBottomsheetOpen] = useState(false);
  const [isBottomHelpsheetOpen, setIsBottomHelpsheetOpen] = useState(false);

  const { equipmentData, isRead, taskNumber } = props;
  const [isInventoryRead, saveCallToActionSetting] = useHandleCtaRead(isRead);

  const onCardClick = () => {
    let entitlementEFCCodes: string[] = [];
    equipmentData.entitlements.forEach((ent) => {
      const efcCode = 'ENT' + ent.id + '0' + ent.status.toString();
      entitlementEFCCodes.push(efcCode);
    });

    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.EntDetails,
      },
      {
        key: LogType.EndpointId,
        value: equipmentData.id,
      },
      {
        key: LogType.SerialNumber,
        value: equipmentData.serialNumber || '',
      },
      {
        key: LogType.ServiceTaskId,
        value: taskNumber || '',
      },
      {
        key: LogType.Entitlement,
        value: entitlementEFCCodes.join(','),
      },
    ];
    logging('info', '', '', false, logParams);

    if (equipmentData.id && isInventoryRead === 'primary') {
      saveCallToActionSetting(
        CallToActionType.Inventory,
        equipmentData.id,
        'NA',
        taskNumber
      );
    }
    setIsBottomsheetOpen(true);
  };
  const onSheetClose = () => {
    setIsBottomsheetOpen(false);
  };

  const onHelpSheetClose = () => {
    setIsBottomHelpsheetOpen(false);
  };

  const onHelpIconClick = () => {
    setIsBottomHelpsheetOpen(true);
  };

  const data = [
    {
      title: t('Entitlements'),
      value:
        equipmentData?.entitlements?.length > 0
          ? equipmentData.entitlements
          : undefined,
    },
    {
      title: t('Software'),
      value:
        equipmentData?.softwareInventory?.length > 0
          ? equipmentData.softwareInventory
          : undefined,
    },
  ];

  let entitlementStatusIconColor = '';
  let entitlementStatusIcon: TIcon;
  if (
    equipmentData.entitlements?.filter((ent) => ent.status === 2).length > 0
  ) {
    entitlementStatusIcon = CancelIcon;
    entitlementStatusIconColor = 'text-tangerine';
  } else if (
    equipmentData.entitlements?.filter((ent) => ent.status === 1).length > 0
  ) {
    entitlementStatusIcon = WarningIcon;
    entitlementStatusIconColor = 'text-yellow';
  }

  const helpData = [
    {
      title: t('Green'),
      value: t('Entitlement is connected and sending data as expected.'),
      iconName: 'Green',
    },
    {
      title: t('Yellow'),
      value: t(
        'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.'
      ),
      iconName: 'Yellow',
    },
    {
      title: t('Red'),
      value: t(
        'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.'
      ),
      iconName: 'Red',
    },
    {
      title: t('Empty'),
      value: t(
        'Entitlement does not send information as to the current status. No action is required.'
      ),
      iconName: 'Empty',
    },
  ];

  return (
    <div>
      <div className="mx-4 mb-4">
        <div className=" mb-2 mt-2">
          <SectionHeading title={t('Inventory')} withMargins={false} />
        </div>
        <SystemsCard
          onCardClick={onCardClick}
          iconColor="steel"
          clickableType="secondary"
          readUnreadIconType={isInventoryRead}
        >
          {data.map(({ title, value }) => {
            if (value) {
              return (
                <div className="flex flex-row gap-2" key={title}>
                  <div className="self-center">{t(title)}</div>
                  {entitlementStatusIconColor && title === t('Entitlements') && (
                    <div className="self-center">
                      <Icon
                        is={entitlementStatusIcon}
                        size="1.25rem"
                        colorClass={entitlementStatusIconColor}
                        className="mb-1"
                      />
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })}
        </SystemsCard>
      </div>
      <GenericModalSheet
        isBottomsheetOpen={isBottomsheetOpen}
        onSheetClose={onSheetClose}
        showHelpIcon={true}
        onHelpIconClick={onHelpIconClick}
      >
        <Details equipmentData={equipmentData} />
      </GenericModalSheet>
      <GenericModalSheet
        isBottomsheetOpen={isBottomHelpsheetOpen}
        onSheetClose={onHelpSheetClose}
        data={helpData}
      ></GenericModalSheet>
    </div>
  );
};

export default Inventory;
