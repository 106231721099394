import React, { PropsWithChildren, useMemo, useState } from 'react';

export type ToastType = 'success' | 'error' | 'info';

export interface IToast {
  message: string;
  type?: ToastType;
  key: string;
}

interface ProviderValue {
  toasts: IToast[];
  pushToast: (toast: Omit<IToast, 'key'>) => void;
  shiftToast: () => void;
}

const Context = React.createContext<ProviderValue | null>(null);

function ToastProvider({ children }: PropsWithChildren<{}>) {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const value: ProviderValue = useMemo(
    () => ({
      toasts,
      pushToast: (toast) => {
        setToasts([...toasts, { ...toast, key: Math.random().toString() }]);
      },
      shiftToast: () => {
        setToasts(toasts.slice(1));
      },
    }),
    [toasts]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const useToast = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export { useToast };

export default ToastProvider;
