import { useLiveQuery } from 'dexie-react-hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import SectionHeading from '../../components/SectionHeading';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import GenericModelData from '../../Models/GenericModelData';
import EFCItem from './EFCItem';

function SavedEFCItems() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const handleDelete = (id: number) => {
    db.savedItems.delete(id);
  };
  const handleClick = () => {
    navigate('/saved', {
      state: {
        onlyExpand: IDType.EFC,
      },
    });
  };
  const savedItems = useLiveQuery(async () => {
    const savedItemsRecords = await db.savedItems
      .where('IdType')
      .equals(IDType.EFC)
      .reverse()
      .sortBy('id');
    let savedItemsArray: GenericModelData[] = [];
    await Promise.resolve(
      Promise.all(
        savedItemsRecords.map(async (savedItemsRecord) => {
          const efcRecord = await db.efcs.get(savedItemsRecord.IdGeneric);
          if (efcRecord) {
            savedItemsArray.push(
              GenericModelData.CreateFromEFC(efcRecord, savedItemsRecord.id)
            );
          }
        })
      )
    );
    return savedItemsArray;
  });

  return (
    <>
      <div className="ml-4 mr-1 mb-0.5 flex items-center justify-between">
        <SectionHeading title={t('Saved codes')} withMargins={false} />
        <Button styling="tertiary" onClick={handleClick}>
          {t('View all')}
        </Button>
      </div>
      <div>
        {savedItems?.map((savedItem) => (
          <EFCItem
            key={`${savedItem?.IdType}_${savedItem?.Id}`}
            efcItem={savedItem}
            onClick={() => handleDelete(savedItem.Id)}
          />
        ))}
      </div>
    </>
  );
}

export default SavedEFCItems;
