import CenterFocusStrongOutlinedIcon from '@mui/icons-material/CenterFocusStrongOutlined';

import MicIcon from '@mui/icons-material/Mic';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React from 'react';
import Icon from '../../../components/Icon';
import IconButton from '../../../components/IconButton';
import useNotYetImplemented from '../../../hooks/useNotYetImplemented';

import useCheckDeviceType from '../../../hooks/useCheckDeviceType';

interface Props {
  title: string;
  onClick: () => void;
  onScanClick?: () => void;
}

function SearchBarAsButton(props: Props) {
  const notYetImplemented = useNotYetImplemented();

  const deviceType = useCheckDeviceType();

  const onQRScanClick = () => {
    if (deviceType === 'iOS') {
      notYetImplemented();
      return;
    }

    props.onScanClick?.();
  };
  return (
    <div className="relative flex h-12 items-center bg-white px-1">
      <button
        className="flex flex-1 items-center gap-x-2.5 self-stretch pl-2.5"
        onClick={props.onClick}
      >
        <Icon is={SearchOutlinedIcon}></Icon>
        <span className="text-lg text-steel">{props.title}</span>
      </button>
      <IconButton
        icon={CenterFocusStrongOutlinedIcon}
        onClick={onQRScanClick}
      />
      <IconButton icon={MicIcon} onClick={notYetImplemented} />

      <div className="absolute-shadow below" />
    </div>
  );
}

export default SearchBarAsButton;
