import classnames from 'classnames';
import React from 'react';
import { TIcon } from '../types';
import Icon from './Icon';

interface Props {
  icon: TIcon;
  className?: string;
  onClick: (e: React.MouseEvent) => void;
  iconColorClass?: string;
}

// TODO: Like the Button.tsx, accept either `onClick` or `to`, rendering a `button` or a `Link` respectively

function IconButton(props: Props) {
  const className = classnames(
    props.className,
    'group p-2.5',
    'focus-visible:p-2 focus-visible:outline-none focus-visible:bg-nameless-lightblue focus-visible:border-2 focus-visible:border-olympic focus-visible:rounded-lg'
  );

  return (
    <button type="button" className={className} onClick={props.onClick}>
      <Icon
        is={props.icon}
        colorClass={
          props.iconColorClass ?? 'text-steel group-focus-visible:text-cobalt'
        }
      />
    </button>
  );
}

export default IconButton;
