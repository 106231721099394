import React, { PropsWithChildren, useMemo } from 'react';

interface ProviderValue {
  collapsed: boolean;
}

interface Props {
  collapsed: boolean;
}

const Context = React.createContext<ProviderValue | null>(null);

function CollapsibleCollection({
  collapsed,
  children,
}: PropsWithChildren<Props>) {
  const value: ProviderValue = useMemo(() => ({ collapsed }), [collapsed]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const useCollapsibleCollection = () => {
  return React.useContext(Context);
};

export { useCollapsibleCollection };

export default CollapsibleCollection;
