import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RECENT_SEARCHES_LOCAL_STORAGE_KEY,
  SAVED_SEARCHES_LOCAL_STORAGE_KEY,
} from '../../constants';
import {
  searchesAreEqual,
  updateRecentSearchesInStorage,
  updateSavedSearchesInStorage,
} from '../../pages/Search/searchUtils';
import {
  Search,
  SearchFilter,
  SearchFilterKey,
  SearchScope,
} from '../../types';

export interface SearchState {
  activeSearch: Search | null;
  savedSearches: Search[];
  recentSearches: Search[];
}

const initialState: SearchState = {
  activeSearch: null,
  savedSearches: JSON.parse(
    window.localStorage.getItem(SAVED_SEARCHES_LOCAL_STORAGE_KEY) ?? '[]'
  ),
  recentSearches: JSON.parse(
    window.localStorage.getItem(RECENT_SEARCHES_LOCAL_STORAGE_KEY) ?? '[]'
  ),
};

const createSearch = (params: Partial<Search> = {}): Search => {
  return {
    scope: 'everywhere',
    searchTerm: '',
    filters: {},
    timestamp: Date.now(),
    ...params,
  };
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    initSearch: (state) => {
      state.activeSearch ??= createSearch();
    },
    setScope: (state, { payload }: PayloadAction<SearchScope>) => {
      state.activeSearch ??= createSearch();
      state.activeSearch.scope = payload;
    },
    changeSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.activeSearch ??= createSearch();
      state.activeSearch.searchTerm = payload;
    },
    resetWithSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.activeSearch = createSearch({ searchTerm: payload });
    },
    clearSearchTerm: (state) => {
      if (!state.activeSearch) {
        return;
      }
      state.activeSearch.searchTerm = '';
    },
    changeActiveSearch: (state, { payload }: PayloadAction<Search>) => {
      state.activeSearch = payload;
    },
    applyFilter: (
      state,
      {
        payload,
      }: PayloadAction<{ filterKey: SearchFilterKey; filter: SearchFilter }>
    ) => {
      state.activeSearch ??= createSearch();
      state.activeSearch.filters[payload.filterKey] = payload.filter;
    },
    removeFilter: (state, { payload }: PayloadAction<SearchFilterKey>) => {
      if (!state.activeSearch) {
        return;
      }
      delete state.activeSearch.filters[payload];
    },
    saveActiveSearch: (state) => {
      if (!state.activeSearch) {
        return;
      }

      const alreadySaved = state.savedSearches.some((savedSearch) =>
        searchesAreEqual(savedSearch, state.activeSearch)
      );
      if (!alreadySaved) {
        state.savedSearches.push(state.activeSearch);
      }

      updateSavedSearchesInStorage(state.savedSearches);
    },
    unsaveActiveSearch: (state) => {
      if (!state.activeSearch) {
        return;
      }

      const savedSearchIndex = state.savedSearches.findIndex((search) =>
        searchesAreEqual(search, state.activeSearch)
      );

      if (savedSearchIndex > -1) {
        state.savedSearches.splice(savedSearchIndex, 1);
      }

      updateSavedSearchesInStorage(state.savedSearches);
    },
    deleteAllSavedSearches: (state) => {
      state.savedSearches = [];
      updateSavedSearchesInStorage(state.savedSearches);
    },
    unsaveSearch: (state, { payload }: PayloadAction<Search>) => {
      const savedSearchIndex = state.savedSearches.findIndex((search) =>
        searchesAreEqual(search, payload)
      );

      if (savedSearchIndex > -1) {
        state.savedSearches.splice(savedSearchIndex, 1);
      }

      updateSavedSearchesInStorage(state.savedSearches);
    },
    markActiveSearchRecent: (state) => {
      if (!state.activeSearch) {
        return;
      }

      state.activeSearch.timestamp = Date.now();

      const existingRecentSearch = state.recentSearches.find((recentSearch) =>
        searchesAreEqual(recentSearch, state.activeSearch)
      );
      if (existingRecentSearch) {
        existingRecentSearch.timestamp = Date.now();
      } else {
        state.recentSearches.push(state.activeSearch);
      }

      updateRecentSearchesInStorage(state.recentSearches);
    },
    deleteRecentSearch: (state, { payload }: PayloadAction<Search>) => {
      const recentSearchIndex = state.recentSearches.findIndex((search) =>
        searchesAreEqual(search, payload)
      );

      if (recentSearchIndex > -1) {
        state.recentSearches.splice(recentSearchIndex, 1);
      }

      updateRecentSearchesInStorage(state.recentSearches);
    },
    deleteAllRecentSearches: (state) => {
      state.recentSearches = [];
      updateRecentSearchesInStorage(state.recentSearches);
    },
    replaceSearchInCollection: (
      state,
      {
        payload,
      }: PayloadAction<{
        collection: 'recentSearches' | 'savedSearches';
        index: number;
        search: Search;
      }>
    ) => {
      const alreadyExists = state[payload.collection].some((recentSearch) =>
        searchesAreEqual(recentSearch, state.activeSearch)
      );

      if (!alreadyExists) {
        state[payload.collection][payload.index] = payload.search;
      } else {
        state[payload.collection].splice(payload.index, 1);
      }

      if (payload.collection === 'recentSearches') {
        updateRecentSearchesInStorage(state.recentSearches);
      }

      if (payload.collection === 'savedSearches') {
        updateSavedSearchesInStorage(state.savedSearches);
      }
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
