import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberedListItem from '../../../components/NumberedListItem';
import RepairActionDescription from '../../RepairAction/RepairActionDescription';
import { usePage } from '../../../PageProvider';

function Disclaimer() {
  const { t } = useTranslation();

  usePage(
    () => ({
      getTitle: () => t('Help & Feedback'),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [t]
  );

  return (
    <div>
      <RepairActionDescription raDescription={t('OfflineMessage')} />
      <NumberedListItem key={1} number={1} description={t('OfflineMessage1')} />
      <NumberedListItem key={2} number={2} description={t('OfflineMessage2')} />
      <NumberedListItem key={3} number={3} description={t('OfflineMessage3')} />
      <NumberedListItem key={4} number={4} description={t('OfflineMessage4')} />
      <NumberedListItem key={5} number={5} description={t('OfflineMessage5')} />
    </div>
  );
}

export default Disclaimer;
