import { useTranslation } from 'react-i18next';

function NoSystem() {
  const { t } = useTranslation();
  return (
    <div className="mx-4 flex h-1/3 items-center justify-center rounded-lg border border-antartica bg-white">
      <p className="p-1 text-xl font-bold sm:text-2xl ">
        {t('No System is Selected')}.
      </p>
    </div>
  );
}

export default NoSystem;
