import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const useGetEntitlementIcon = (entitlementStatus: string) => {
  let entitlementIconColor: 'lime' | 'yellow' | 'tangerine' | 'steel';
  let entitlementIcon;
  switch (entitlementStatus) {
    case '0':
      entitlementIconColor = 'lime';
      entitlementIcon = CheckCircleIcon;
      break;
    case '1':
      entitlementIconColor = 'yellow';
      entitlementIcon = WarningIcon;
      break;
    case '2':
      entitlementIconColor = 'tangerine';
      entitlementIcon = CancelIcon;
      break;
    default:
      entitlementIconColor = 'steel';
      entitlementIcon = undefined;
      break;
  }

  return { entitlementIcon, entitlementIconColor };
};

export default useGetEntitlementIcon;
