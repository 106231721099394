import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { searchActions } from '../../features/search/searchSlice';
import { usePage } from '../../PageProvider';
import SearchBarAsButton from '../Search/components/SearchBarAsButton';
import { getSearchTermPlaceholderByScope } from '../Search/searchUtils';
import SearchQr from '../../components/feature/SearchQR';

function PartsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePage(() => ({
    belongsToNavBarItem: 'Search',
    showBackButton: true,
    getTitle: () => t('Parts'),
  }));

  const handleSearchBarClick = () => {
    dispatch(searchActions.setScope('parts'));
    navigate('/search/query');
  };
  const [isScanning, setIsScanning] = useState(false);
  const onScanClick = () => {
    setIsScanning((state) => !state);
  };

  return (
    <div className="mb-4 space-y-7">
      <SearchBarAsButton
        title={getSearchTermPlaceholderByScope('parts')}
        onClick={handleSearchBarClick}
        onScanClick={onScanClick}
      />
      <SearchQr isScanning={isScanning} setIsScanning={setIsScanning} />
    </div>
  );
}

export default PartsPage;
