import React from 'react';

interface RequiredProps {
  placeholder: string;
}

interface OptionalProps {
  onChoiceChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
}

type Props = RequiredProps & OptionalProps;

const defaultProps = {
  placeholder: 'placeholder',
};

function InputTextBox(props: Props) {
  return (
    <div className="mt-4 mb-4 ">
      <input
        type="text"
        value={props.defaultValue}
        placeholder={props.placeholder}
        onChange={props.onChoiceChange}
        className="mt-2 h-10 w-full rounded-lg border border-steel p-2"
      />
    </div>
  );
}

InputTextBox.defaultProps = defaultProps;

export default InputTextBox;
