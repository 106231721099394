import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import 'react-swipeable-list/dist/styles.css';

import { useToast } from '../../components/toast/ToastProvider';
import useLogging from '../../hooks/useLogging';
import useNavigateToRepairAction from '../../hooks/useNavigateToRepairAction';
import db from '../../index-db';
import { IDType, TimeLineEventType } from '../../Models/Enumerations';
import LogParams from '../../Models/LogParams';
import RepairAction from '../../Models/RepairAction';
import { PopoverMenuButton } from '../../PageProvider';
import { LogType } from '../../enums/LogType';
import { FbType } from '../../enums/FbType';
import EndPoint from '../../Models/EndPoint';
import TimelineEvent from '../../Models/TimeLineEvent';
import CardPrimary from '../../components/cards/CardPrimary';

interface Props {
  repairActionItem: RepairAction;
  efcId: number;
  efcCode: string;
  selectedEndpoint: string;
  endpointId: string;
  serialNumber: string;
}

function RepairActionCard({
  repairActionItem,
  efcId,
  efcCode,
  selectedEndpoint,
  endpointId,
  serialNumber,
}: Props) {
  const api = useApi();
  const { t } = useTranslation();
  const logging = useLogging();
  const { pushToast } = useToast();
  const navigateToRepairAction = useNavigateToRepairAction();
  const [displayTime, setDisplayTime] = useState('');

  function logFeedback(feedback: string) {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Fb,
      },
      {
        key: LogType.FbType,
        value: FbType.RepairAction,
      },
      {
        key: LogType.Value,
        value: feedback,
      },
      {
        key: LogType.RaId,
        value: repairActionItem.Id.toString(),
      },
      {
        key: LogType.EfcCode,
        value: efcCode ?? '',
      },
      {
        key: LogType.EndpointId,
        value: endpointId ?? '',
      },
      {
        key: LogType.SerialNumber,
        value: serialNumber ?? '',
      },
    ];
    logging('info', '', '', false, logParams, true);
    pushToast({
      message: t(`Your feedback has been submitted`),
      type: 'success',
    });
  }
  const addToSaved = async () => {
    //Check if already saved
    if (
      (
        await db.savedItems
          .where({
            IdGeneric: repairActionItem.Id,
            IdType: IDType.REPAIR_ACTION,
          })
          .toArray()
      ).length === 0
    ) {
      //Get the last saved id
      const lastSavedId = await db.savedItems
        .orderBy('id')
        .last()
        .then((lastItem) => {
          return lastItem?.id ?? 1;
        });
      db.savedItems
        .add({
          IdGeneric: repairActionItem.Id,
          IdType: IDType.REPAIR_ACTION,
          id: lastSavedId + 1,
          Context: efcId.toString(),
        })
        .then(() =>
          pushToast({
            message: t(`{{item}} has been saved`, {
              item: `${t('Repair')} ${repairActionItem.Id}`,
            }),
          })
        );
    } else {
      pushToast({
        message: t(`{{item}} is already saved`, {
          item: `${t('Repair')} ${repairActionItem.Id}`,
        }),
      });
    }
  };
  const popoverMenuButtons: PopoverMenuButton[] = [
    {
      title: t('This was relevant info'),
      onClick: () => logFeedback('Relevant'),
    },
    {
      title: t('This was not relevant info'),
      onClick: () => logFeedback('Not relevant'),
    },
    {
      title: t('Add to saved items'),
      onClick: addToSaved,
    },
  ];

  const root = useRef<HTMLDivElement>(null);
  const handlePopoverToggled = (visible: boolean) => {
    root
      .current!.closest('.swipeable-list-item')!
      .classList.toggle('showing-popover', visible);
  };

  const onCardClick = () => {
    navigateToRepairAction(repairActionItem.Id.toString(), efcId);
  };

  const onPopoverShow = () => {
    handlePopoverToggled(true);
  };

  const onPopoverHide = () => {
    handlePopoverToggled(false);
  };

  const fetchEndPoint = async (endpointId: string): Promise<EndPoint> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointById/?endPointId=${endpointId}`
    )) as EndPoint;
  };

  useEffect(() => {
    (async () => {
      let endpoint: EndPoint | undefined;

      try {
        endpoint = await fetchEndPoint(selectedEndpoint);
      } catch (error) {
        endpoint = await db.endPoints.get(selectedEndpoint);
      }
      let repairTimeLineEvents: TimelineEvent[] = [];
      if (endpoint && endpoint.timelineEventDetails) {
        repairTimeLineEvents = endpoint.timelineEventDetails.filter(
          (timelineEvent) =>
            timelineEvent.type ===
              TimeLineEventType[TimeLineEventType.Repair] &&
            timelineEvent.event === repairActionItem.Id.toString()
        );
      }

      if (repairTimeLineEvents && repairTimeLineEvents.length > 0) {
        repairTimeLineEvents.sort((a, b) => {
          return (
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        });

        const latestRATimelineEvent = repairTimeLineEvents[0];
        const currentDate = new Date(Date.now());
        const timestamp = new Date(latestRATimelineEvent.timestamp);

        const diffInTime = currentDate.getTime() - timestamp.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        const hours =
          timestamp.getHours() < 10
            ? `0${timestamp.getHours()}`
            : timestamp.getHours().toString();
        const minutes =
          timestamp.getMinutes() < 10
            ? `0${timestamp.getMinutes()}`
            : timestamp.getMinutes().toString();
        const seconds =
          timestamp.getSeconds() < 10
            ? `0${timestamp.getSeconds()}`
            : timestamp.getSeconds().toString();

        setDisplayTime(
          `${diffInDays} days ago ${hours}:${minutes}:${seconds} ${timestamp.toDateString()}`
        );
      } else {
        setDisplayTime('');
      }
    })();
  }, [selectedEndpoint]);

  return (
    <CardPrimary
      ref={root}
      onCardClick={onCardClick}
      title={repairActionItem.Name}
      description={repairActionItem.Description}
      blockLetter={repairActionItem.RepairComplexity}
      afterPartitionPrimaryTitle={t('Repair time')}
      afterPartitionSecondaryTitle={t('Repair Id')}
      afterPartitionPrimaryDescription={t('min', {
        count: Number(repairActionItem.TimeToFix.toFixed(2)),
      })}
      afterPartitionSecondaryDescription={repairActionItem.Id.toString()}
      rating={repairActionItem.Rating}
      ratingTitle={'Repair'}
      popoverMenuButtons={popoverMenuButtons}
      onPopoverShow={onPopoverShow}
      onPopoverHide={onPopoverHide}
      repairActionCompleteTime={displayTime}
    />
  );
}
export default RepairActionCard;
