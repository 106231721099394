import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import Icon from '../Icon';
import { IconButtonProps, styled } from '@mui/material';
import IconButtonVariant2 from '../IconButton/IconButtonVariant2';
import { TIcon } from '../../types';

interface StyledIconButton extends IconButtonProps {
  iconType?: 'primary' | 'secondary';
}

interface ReadUnreadProps extends IconButtonProps {
  iconType?: 'primary' | 'secondary';
  icon?: TIcon;
  iconSize?: string;
}

const CustomIconButton = styled(IconButtonVariant2, {
  shouldForwardProp: () => true,
})<StyledIconButton>(({ iconType, theme }) => ({
  'height': '40px',
  'width': '32px',
  'borderRadius': 8,
  'border-width': '1px',
  'border-color': 'rgb(229, 231, 235)',
  'border-style': 'solid',
  'padding': 0,
  'box-shadow':
    'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px',

  ...(iconType === 'secondary' && {
    '&': {
      'background-color': 'white',
      'color': 'black',
    },
  }),
  ...(iconType === 'primary' && {
    '&': {
      'background-color': 'rgb(166, 25, 46)',
      'color': 'white',
      '&:hover': { 'background-color': 'rgb(166, 25, 46)' },
    },
  }),
}));

const ReadUnreadCta = (props: ReadUnreadProps) => {
  const {
    iconType = 'primary',
    icon = KeyboardArrowDownOutlined,
    iconSize = '2rem',
  } = props;

  const iconClassName = iconType === 'secondary' ? 'text-black' : 'text-white';
  return (
    <CustomIconButton iconType={iconType} onClick={props.onClick}>
      <Icon
        is={icon}
        size={iconSize}
        colorClass={iconClassName}
        className="inline-block"
      />
    </CustomIconButton>
  );
};

export default ReadUnreadCta;
