import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { PopoverMenuButton } from '../PageProvider';

interface Props {
  buttons: PopoverMenuButton[];
  onClickOutside: () => void;
  inCard?: boolean;
}

function PopoverMenu(props: Props) {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    props.onClickOutside();
  });
  const noCardDisplayClasses: string =
    'absolute right-7 flex flex-col px-2 z-20 bg-white rounded-lg shadow-[0px_3px_6px_#7D878E29] divide-y divide-antartica ';
  const displayClasses: string = props.inCard
    ? noCardDisplayClasses + 'top-7'
    : noCardDisplayClasses;
  return (
    <div className={displayClasses} ref={ref}>
      {props.buttons.map((button) => (
        <button
          key={button.title}
          className="h-10 whitespace-nowrap px-2 text-lg"
          type="button"
          onClick={button.onClick}
        >
          {button.title}
        </button>
      ))}
    </div>
  );
}

export default PopoverMenu;
