import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import { TIcon } from '../types';

export const LANGUAGES = [
  { code: 'en', englishTitle: 'English' },
  { code: 'zh', englishTitle: 'Chinese' },
  { code: 'es', englishTitle: 'Espanol' },
  { code: 'fr', englishTitle: 'Francias' },
  { code: 'de', englishTitle: 'German' },
  { code: 'pt', englishTitle: 'Portuguese' },
  { code: 'tr', englishTitle: 'Turkish' },
  { code: 'pl', englishTitle: 'Polish' },
  { code: 'ru', englishTitle: 'Russian' },
  { code: 'id', englishTitle: 'Indonesian' },
  { code: 'vn', englishTitle: 'Vietnamese' },
  { code: 'th', englishTitle: 'Thai' },
];

export const cancelledTasks = [
  'ca',
  'cancelled',
  'cancelada pela filial',
  'cancelado',
  'canceled',
  'canceled by branch',
];

type TModel = 'repair' | 'efc code' | 'part' | 'product'; // TODO add others when needed

export const ICON_BY_MODEL_TYPE: Record<TModel, TIcon> = {
  'repair': BuildCircleOutlinedIcon,
  'efc code': TerminalOutlinedIcon,
  'part': ConstructionOutlinedIcon,
  'product': ProductionQuantityLimitsOutlinedIcon,
};

export const SAVED_SEARCHES_LOCAL_STORAGE_KEY = 'acde:saved-searches';
export const RECENT_SEARCHES_LOCAL_STORAGE_KEY = 'acde:recent-searches';

export const TASKS_LATEST_FETCH_TIMESTAMP = 'acde:tasks-latest-fetch-timestamp';
export const IS_USER_AUTHENTICATED_KEY = 'acde:user-is-authenticated';
export const IS_INITIAL_POPUP_NOTIFIED = 'acde:is-popup-notified';
export const USER_REALM_KEY = 'acde:user-realm';

export const REDIRECT_URL = 'redirect-url';
export const BUILD_VERSION = 'build-version';
export const ESC_URL = 'acde:esc-url';
export const B2C_REALM = 'diebold-nixdorf-b2c';

export const fixedEfcId = 'GEN00000';

export const lazyLoadLimit = 10;
export const LAST_ACCESSED_ENDPOINT = 'acde:last-accessed-endpoint';

export const notificationsFetchTime = 300000;
export const DATABASE_LOADED_STATUS_KEY = 'acde:loaded-status-of-indexed-db';
export const SHOULD_SINGLE_SEARCH_BE_PERFORMED = 'acde:single-search';
export const ARE_CANCELLED_TICKETS_ALLOWED =
  'acde:should-cancelled-tickets-be-shown';
export const ADDITIONAL_USERID_OR_GROUPID = 'DBD-UserGroupOrId';
