import { useAppDispatch } from '../app/hooks';
import { searchActions } from '../features/search/searchSlice';
import { IDType } from '../Models/Enumerations';
import GenericModelData from '../Models/GenericModelData';
import useNavigateToEfc from './useNavigateToEfc';
import useNavigateToPart from './useNavigateToPart';
import useNavigateToProduct from './useNavigateToProduct';
import useNavigateToRepairAction from './useNavigateToRepairAction';
import useNavigateToSearchResults from './useNavigateToSearchResults';

const useNavigateFromRecentlyViewedItem = () => {
  const dispatch = useAppDispatch();
  const navigateToEfc = useNavigateToEfc();
  const navigateToPart = useNavigateToPart();
  const navigateToProduct = useNavigateToProduct();
  const navigateToSearchResults = useNavigateToSearchResults();
  const navigateToRepairAction = useNavigateToRepairAction();

  return ({ IdType, IdGeneric, SearchItem, Context }: GenericModelData) => {
    switch (IdType) {
      case IDType.EFC:
        navigateToEfc(IdGeneric);
        break;
      case IDType.PARTS:
        navigateToPart(IdGeneric);
        break;
      case IDType.PRODUCTS:
        navigateToProduct(IdGeneric);
        break;
      case IDType.REPAIR_ACTION:
        navigateToRepairAction(IdGeneric, Number(Context));
        break;
      case IDType.SEARCH:
        if (SearchItem !== undefined) {
          dispatch(searchActions.setScope(SearchItem.scope));
          dispatch(searchActions.changeActiveSearch(SearchItem));
        }
        navigateToSearchResults({ awaitUpdatedSearchTerm: true });
        break;
    }
  };
};

export default useNavigateFromRecentlyViewedItem;
