import React from 'react';
import { Outlet } from 'react-router-dom';
import SearchTermInput from '../../pages/Search/components/SearchTermInput';
import Header from './Header';
import NavBar from './NavBar';

function LayoutForSearch() {
  return (
    <div className="flex h-screen flex-col">
      <Header>
        <div className="right-8 ml-[3.375rem] mr-24 h-10">
          <SearchTermInput />
        </div>
      </Header>
      <main>
        <Outlet />
      </main>
      <NavBar />
    </div>
  );
}

export default LayoutForSearch;
