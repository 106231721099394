import classnames from 'classnames';
import React from 'react';

interface Props {
  segments: string[];
  activeSegment: string;
  onChange: (segment: string) => void;
}

function SegmentedControl({ segments, activeSegment, onChange }: Props) {
  const activeClasses = 'bg-white shadow cursor-default';
  const inactiveClasses = 'cursor-pointer';

  return (
    <div className="mx-auto flex w-52 rounded border border-antartica bg-antartica">
      {segments.map((segment) => (
        <button
          key={segment}
          type="button"
          className={classnames(
            'flex-1 shrink-0  rounded  py-1.5 text-center font-medium transition-colors',
            {
              [activeClasses]: segment === activeSegment,
              [inactiveClasses]: segment !== activeSegment,
            }
          )}
          onClick={() => onChange(segment)}
        >
          {segment}
        </button>
      ))}
    </div>
  );
}

export default SegmentedControl;
