import { useTranslation } from 'react-i18next';
import SectionHeading from '../../../components/SectionHeading';
import RecommendedPart from '../../../Models/RecommendedPart';
import RecommendPart from './RecommendedPart';
import { DATABASE_LOADED_STATUS_KEY } from '../../../constants';

interface Props {
  recommendedParts: RecommendedPart[];
}

function RecommendedParts(props: Props) {
  const { recommendedParts } = props;
  const { t } = useTranslation();

  function isDbLoaded() {
    const indexDBLoadedKey =
      window.localStorage.getItem(DATABASE_LOADED_STATUS_KEY) ?? '';
    return indexDBLoadedKey === 'true';
  }

  return (
    <div className="mt-4">
      <div className=" mb-2 items-center justify-between">
        <SectionHeading
          title={`${t('Part Recommendations')}`}
          withMargins={false}
        />
      </div>
      {recommendedParts && recommendedParts.length > 0 && (
        <div className={`flex w-full flex-col`}>
          {recommendedParts.map((recommendedPart, index) => (
            <RecommendPart key={index} recommendedPart={recommendedPart} />
          ))}
        </div>
      )}
      {!isDbLoaded() && (
        <div className="mt-8 mb-2 items-center justify-between text-lg font-bold">
          {`${t(
            'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.'
          )}`}
        </div>
      )}
    </div>
  );
}

export default RecommendedParts;
