import { IIdentifiable } from './IIdentifiable';

export interface IEFC extends IIdentifiable {
  EfcCodeStr: string;
  ProbableCause: string;
  ModuleId: number;
  ImageId: number | null;
  Language: number;
  WeightDescription: string;
  WeightValue: number;
  Category: string;
  CallType: string;
  ThresholdLogic: string;
  RepairActionData: IRepairActionData[];
  LastUpdate: string;
  LastAction: number;
}
export interface IRepairActionData {
  RepairActionId: number;
  RecommendRating: number;
}
export class EFC implements IEFC {
  Id: number;
  EfcCodeStr: string;
  ProbableCause: string;
  ModuleId: number;
  ImageId: number | null;
  Language: number;
  WeightDescription: string;
  WeightValue: number;
  Category: string;
  CallType: string;
  ThresholdLogic: string;
  RepairActionData: IRepairActionData[];
  LastUpdate: string;
  LastAction: number;

  constructor(
    id: number,
    efcCodeStr: string,
    probableCause: string,
    moduleId: number,
    imageId: number,
    language: number,
    weightDescription: string,
    weightValue: number,
    category: string,
    callType: string,
    thresholdLogic: string,
    repairActionData: IRepairActionData[],
    lastUpdate: string,
    lastAction: number
  ) {
    this.Id = id;
    this.EfcCodeStr = efcCodeStr;
    this.ProbableCause = probableCause;
    this.ModuleId = moduleId;
    this.ImageId = imageId;
    this.Language = language;
    this.WeightDescription = weightDescription;
    this.WeightValue = weightValue;
    this.Category = category;
    this.CallType = callType;
    this.ThresholdLogic = thresholdLogic;
    this.RepairActionData = repairActionData;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
  }
}

export default EFC;
