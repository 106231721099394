import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  useAppDispatch,
  useAppSelector,
  useComponentWillUnmount,
} from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import Icon from '../../../components/Icon';
import IconButton from '../../../components/IconButton';
import { searchActions } from '../../../features/search/searchSlice';
import useNavigateToSearchResults from '../../../hooks/useNavigateToSearchResults';
import { getSearchTermPlaceholderByScope } from '../searchUtils';

interface OptionalProps {
  hideSearchIcon?: boolean;
}

type Props = OptionalProps;

const defaultProps: OptionalProps = {
  hideSearchIcon: false,
};

function SearchTermInput(props: Props) {
  const dispatch = useAppDispatch();

  const activeSearch = useAppSelector(selectActiveSearch);
  const searchTerm = activeSearch?.searchTerm?.trim() ?? '';

  const [input, setInput] = useState(searchTerm);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Respond to outside changes
    setInput(searchTerm);
  }, [searchTerm]);

  const dispatchInputDebounced = useDebouncedCallback((input: string) => {
    dispatch(searchActions.changeSearchTerm(input));
  }, 300);

  useComponentWillUnmount(() => {
    // Dispatch input immediately as to not lose it
    dispatchInputDebounced.flush();
  });

  const handleInput = (input: string) => {
    setInput(input);
    dispatchInputDebounced(input);
  };

  const clearInput = () => {
    setInput('');
    dispatch(searchActions.clearSearchTerm());

    dispatchInputDebounced.cancel();
    inputRef.current?.focus();
  };

  const navigateToSearchResults = useNavigateToSearchResults();
  const submit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      dispatchInputDebounced.flush();
      navigateToSearchResults({
        awaitUpdatedSearchTerm: searchTerm !== input,
      });
    }
  };

  return (
    <div className="relative flex h-full items-center">
      <input
        ref={inputRef}
        type="text"
        className={classnames(
          'pointer-events-auto w-full self-stretch rounded-lg border border-steel bg-white pr-10 text-lg text-steel outline-none focus-visible:border-cobalt',
          {
            'pl-10': !props.hideSearchIcon,
            'pl-4': props.hideSearchIcon,
          }
        )}
        autoFocus
        value={input}
        placeholder={getSearchTermPlaceholderByScope(activeSearch?.scope)}
        onChange={({ currentTarget }) => handleInput(currentTarget.value)}
        onKeyDown={submit}
      />
      {!props.hideSearchIcon && (
        <Icon className="absolute left-2.5" is={SearchOutlinedIcon}></Icon>
      )}
      {input !== '' && (
        <IconButton
          className="pointer-events-auto absolute right-0"
          icon={CancelOutlinedIcon}
          onClick={clearInput}
        />
      )}
    </div>
  );
}

SearchTermInput.defaultProps = defaultProps;

export default SearchTermInput;
