import { useCallback, useEffect, useState } from 'react';
import { useApi } from '../acfs-apis/dwar-api-provider';
import EndPoint from '../Models/EndPoint';
import { LAST_ACCESSED_ENDPOINT } from '../constants';
import db from '../index-db';

function useGetEndpointDetails(onlyEndpointId: boolean = false) {
  const [currentSerialNumber, setCurrentSerialNumber] = useState('');
  const api = useApi();
  const fetchEndPoint = async (endpointId: string): Promise<EndPoint> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointById/?endPointId=${endpointId}`
    )) as EndPoint;
  };
  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';
  const getSerialNumber = useCallback(async () => {
    if (lastAccessedEndpoint) {
      let serialNumberVal = '';
      try {
        const endpoint = await fetchEndPoint(lastAccessedEndpoint);
        serialNumberVal = endpoint.serialNumber;
      } catch (error) {
        const endpoint = await db.endPoints.get(lastAccessedEndpoint);
        serialNumberVal = endpoint?.serialNumber ?? '';
      }
      setCurrentSerialNumber(serialNumberVal);
    }
  }, [lastAccessedEndpoint]);
  useEffect(() => {
    !onlyEndpointId && getSerialNumber();
  }, [getSerialNumber, onlyEndpointId]);
  return [lastAccessedEndpoint, currentSerialNumber];
}

export default useGetEndpointDetails;
