import classnames from 'classnames';
import React from 'react';
import { TIcon } from '../types';
import { Badge } from '@mui/material';

interface Props {
  is: TIcon;
  size?: string; // Like "1rem" or "16px"
  colorClass?: string; // Tailwind color class, i.e. "text-cobalt" or "text-red-500"
  className?: string;
  badgeContent?: string | number;
  badgeColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

function Icon(props: Props) {
  const { is: MuiIcon } = props;
  const size = props.size ?? '1.5rem';

  const className = classnames(
    'block',
    props.colorClass ?? 'text-steel',
    props.className
  );

  return (
    <span className={className}>
      {props?.badgeContent ? (
        <Badge badgeContent={props.badgeContent} color={props?.badgeColor}>
          <MuiIcon sx={{ fontSize: size }} />
        </Badge>
      ) : (
        <MuiIcon sx={{ fontSize: size }} />
      )}
    </span>
  );
}

export default Icon;
