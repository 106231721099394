import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import SectionHeading from '../../components/SectionHeading';
import { ICON_BY_MODEL_TYPE } from '../../constants';
import { searchActions } from '../../features/search/searchSlice';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import { usePage } from '../../PageProvider';
import CategoryButton from './components/CategoryButton';
import SearchBarAsButton from './components/SearchBarAsButton';
import { getSearchTermPlaceholderByScope } from './searchUtils';
import useCheckUserIsPartner from '../../hooks/useCheckUserIsPartner';
import SearchQr from '../../components/feature/SearchQR';

function Search() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logging = useLogging();

  const isPartner = useCheckUserIsPartner();
  const [isScanning, setIsScanning] = useState(false);
  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Search,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('Search'),
      belongsToNavBarItem: 'Search',
      showBackButton: true,
      rightButtons: [
        {
          icon: FilterAltOutlinedIcon,
          onClick: () => navigate('/search/filters'),
        },
      ],
    }),
    [t]
  );

  const handleSearchBarClick = () => {
    dispatch(searchActions.setScope('everywhere'));
    navigate('/search/query');
  };
  const onScanClick = () => {
    setIsScanning((state) => !state);
  };
  return (
    <div>
      <SearchBarAsButton
        title={getSearchTermPlaceholderByScope('everywhere')}
        onClick={handleSearchBarClick}
        onScanClick={onScanClick}
      />
      <SearchQr isScanning={isScanning} setIsScanning={setIsScanning} />

      <SectionHeading title={t('Categories')} />
      <div className="mx-4 space-y-2">
        <CategoryButton
          icon={ICON_BY_MODEL_TYPE['efc code']}
          title={t('EFC codes')}
          to="/efccodes"
        />
        {!isPartner && (
          <CategoryButton
            icon={ICON_BY_MODEL_TYPE['part']}
            title={t('Parts')}
            to="/parts"
          />
        )}
        {!isPartner && (
          <CategoryButton
            icon={ICON_BY_MODEL_TYPE['product']}
            title={t('Products')}
            to="/products"
          />
        )}
        {!isPartner && (
          <CategoryButton
            icon={ICON_BY_MODEL_TYPE['repair']}
            title={t('Repairs')}
            to="/repairs"
          />
        )}
      </div>
    </div>
  );
}

export default Search;
