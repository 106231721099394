import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateToRepairAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isReplaceState = location.state?.isReplace;
  return (
    id: string,
    efcId: number,
    externalSource: boolean = false,
    isReplace?: boolean
  ) => {
    navigate(`/repair/${id}/${efcId}/${externalSource}`, {
      replace: isReplaceState || isReplace,
    });
  };
};

export default useNavigateToRepairAction;
