import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageSubMenu from '../../../components/PageSubMenu';
import { useTranslation } from 'react-i18next';
import EndPoint from '../../../Models/EndPoint';
import TimelineEvent from '../TimelineEvent';
import { TimeLineEventType } from '../../../Models/Enumerations';
import { TimelineEvent as TimelineInterface } from '../../../Models/TimeLineEvent';
import timelineModifier from './timeLineModifier';
import useLogging from '../../../hooks/useLogging';
import LogParams from '../../../Models/LogParams';
import { LogType } from '../../../enums/LogType';

interface Props {
  equipmentData: EndPoint;
  activeSubMenuItem: string;
}

function HistoryEquipment(props: Props) {
  const { t } = useTranslation();
  const logging = useLogging();
  const subMenuItems = useMemo(
    () => [t('All'), t('Tasks'), t('Errors'), t('Repairs'), t('Parts')],
    []
  );
  const [activeSubMenu, setActiveSubMenu] = useState<string>(
    t(props.activeSubMenuItem)
  );
  const [displayTimeLineEvents, setDisplayTimeLineEvents] = useState(
    props.equipmentData.timelineEventDetails
  );
  const [persistentTimeLineEvents, setPersistentTimeLineEvents] = useState(
    props.equipmentData.timelineEventDetails
  );
  useEffect(() => {
    setPersistentTimeLineEvents(props.equipmentData.timelineEventDetails);
  }, [props.equipmentData.timelineEventDetails]);

  const menuChangeHandler = useCallback(
    (subMenuItem: string) => {
      if (persistentTimeLineEvents?.length) {
        let filteredData: TimelineInterface[] | [] = [];

        switch (subMenuItem) {
          case subMenuItems[1]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type === TimeLineEventType[TimeLineEventType.Task]
            );
            break;
          case subMenuItems[2]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type ===
                  TimeLineEventType[TimeLineEventType.EFC] ||
                timeLineEvent.type ===
                  TimeLineEventType[TimeLineEventType.ServiceInsight]
            );
            break;
          case subMenuItems[3]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type ===
                TimeLineEventType[TimeLineEventType.Repair]
            );
            break;
          case subMenuItems[4]:
            filteredData = persistentTimeLineEvents.filter(
              (timeLineEvent) =>
                timeLineEvent.type === TimeLineEventType[TimeLineEventType.Part]
            );
            break;
          default:
            filteredData = persistentTimeLineEvents;
        }

        const calculatedData = timelineModifier(filteredData);

        setDisplayTimeLineEvents(calculatedData);
      }
    },
    [persistentTimeLineEvents, subMenuItems]
  );
  useEffect(() => {
    menuChangeHandler(activeSubMenu);
  }, [activeSubMenu, menuChangeHandler]);

  const handleActivePageSubMenuItemChange = (subMenuItem: string) => {
    setActiveSubMenu(subMenuItem);
    menuChangeHandler(subMenuItem);
  };

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.EquipmentHistory,
      },
      {
        key: LogType.EndpointId,
        value: props.equipmentData.id,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  return (
    <>
      <PageSubMenu
        menuItems={subMenuItems}
        activeMenuItem={activeSubMenu}
        onChange={handleActivePageSubMenuItemChange}
      />

      {props.equipmentData && (
        <div className="flex max-h-full w-full flex-wrap items-stretch gap-2 overflow-x-auto overflow-y-scroll px-4 pb-2 ">
          {displayTimeLineEvents &&
            displayTimeLineEvents?.length > 0 &&
            displayTimeLineEvents.map((displayEvent, index) => (
              <TimelineEvent
                key={index}
                timeLineEvent={displayEvent}
                dataSource={props.equipmentData.dataSource}
              />
            ))}
        </div>
      )}
    </>
  );
}

export default HistoryEquipment;
