import { useCallback, useMemo, useState } from 'react';
import { IDType } from '../../../Models/Enumerations';
import { ResultsTabButton } from './types';

const useResultsCount = () => {
  const [resultCounts, setResultCounts] = useState<
    Omit<Record<ResultsTabButton['key'], number>, 'all'>
  >({
    'efc codes': 0,
    'parts': 0,
    'products': 0,
    'repairs': 0,
  });
  const [areAllResultsLoaded, setAreAllResultsLoaded] = useState({
    'efc codes': false,
    'parts': false,
    'products': false,
    'repairs': false,
  });

  let categoryWiseCount = new Map<Number, Number>();

  categoryWiseCount.set(IDType.EFC, 0);
  categoryWiseCount.set(IDType.PRODUCTS, 0);
  categoryWiseCount.set(IDType.PARTS, 0);
  categoryWiseCount.set(IDType.REPAIR_ACTION, 0);

  const totalResultsCount = useMemo(() => {
    return Object.values(resultCounts).reduce((sum, value) => sum + value);
  }, [resultCounts]);

  categoryWiseCount = useMemo(() => {
    const count = new Map<Number, Number>();
    count.set(IDType.EFC, resultCounts['efc codes']);
    count.set(IDType.PARTS, resultCounts['parts']);
    count.set(IDType.PRODUCTS, resultCounts['products']);
    count.set(IDType.REPAIR_ACTION, resultCounts['repairs']);
    return count;
  }, [resultCounts]);

  const handleEFCResultsLoaded = useCallback((count: number) => {
    setResultCounts((current) => ({
      ...current,
      'efc codes': count,
    }));
    setAreAllResultsLoaded((result) => ({ ...result, 'efc codes': true }));
  }, []);

  const handlePartResultsLoaded = useCallback((count: number) => {
    setResultCounts((current) => ({
      ...current,
      parts: count,
    }));
    setAreAllResultsLoaded((result) => ({ ...result, parts: true }));
  }, []);

  const handleProductResultsLoaded = useCallback((count: number) => {
    setResultCounts((current) => ({
      ...current,
      products: count,
    }));
    setAreAllResultsLoaded((result) => ({ ...result, products: true }));
  }, []);

  const handleRepairsResultsLoaded = useCallback((count: number) => {
    setResultCounts((current) => ({
      ...current,
      repairs: count,
    }));
    setAreAllResultsLoaded((result) => ({ ...result, repairs: true }));
  }, []);

  return {
    totalResultsCount,
    areAllResultsLoaded,
    categoryWiseCount,
    handleEFCResultsLoaded,
    handlePartResultsLoaded,
    handleProductResultsLoaded,
    handleRepairsResultsLoaded,
  };
};

export default useResultsCount;
