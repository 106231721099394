const translation = {
  'You switched to {{ language }}': '切換 {{language}}',
  'Chinese': '中文',
  'Home': '主頁',
  'Search': '搜尋',
  'Saved': '儲存',
  'Me': '本人',
  'Menu': '選單',
  'English': '英文',
  'AllConnect Field Support': 'AllConnect Field Support',
  'Settings': '設定',
  'Language': '語言',
  'Saved Items': '儲存項目',
  'My tasks': '工作任務',
  'Parts': '料件',
  'Saved items': '已儲存項目',
  'Notifications': '通知',
  'Help & Feedback': '輔助 & 回饋',
  'Logout': '登出',
  'Add to equipment list': '新增至設備項目清單',
  'Download for offline': '下載取得',
  'Repair Action': '維修方式',
  'Repair action': '維修方式',
  'Part': '料件',
  'Refreshed {{time}} min ago': '前次更新距今時間 {{time}}',
  'Recently viewed': '近期查閱',
  'View all': '全部查閱',
  'Clear All': '全部清除',
  'Expand all': '全部展開',
  'Collapse all': '全部收攏',
  'Search results': '搜尋結果',
  'Repairs': '維修',
  'Downloading content...': '下載中內容',
  'Bookmarks': '標籤',
  'Downloads': '下載',
  'Recent': '近期',
  'Recent codes': '近期代碼',
  'Saved codes': '已儲存代碼',
  'Updates': '更新',
  'Clear': '清除',
  'Updated Content for {{item}}.': '已更新項目 {{item}}',
  '{{item}} has been saved': ' 已儲存完畢',
  '{{item}} could not be saved': '無法儲存',
  '{{item}} has been removed from saved': '已移除儲存',
  '{{item}} could not be removed from saved': '無法移除儲存',
  'Difficulty': '疑難困阻',
  'Repair time': '維修時間',
  'min_one': '1 分鐘',
  'min_other': '{{count}} 分鐘 ',
  'min_zero': '0 分鐘',
  'Rating': '評分',
  'Thank you for the feedback': '感謝您的回饋',
  'Not relevant': '無關',
  'Relevant': '相關',
  'Fixed my issue': '已排除問題',
  'Leave feedback': '停止回饋',
  'Save item': '儲存項目',
  'Low': '低',
  'Medium': '中',
  'High': '高',
  'This was relevant info': '相關信息',
  'This was not relevant info': '無關信息',
  'Add to saved items': '新增至已儲存項目',
  'Remove from downloads': '自下載項目中移除',
  'Search AllConnect Field Support': '搜尋 AllConnect Field Support',
  'Filters': '篩選',
  'Page not found': '此頁不存在或無法顯示',
  'Return home': '返回主頁',
  'Clear all bookmarks': '清除所有標籤',
  'Search all EFC codes': '搜尋所有EFC代碼',
  'Search all parts': '搜尋所有料件',
  'Results ({{resultsCount}})': '結果 {{resultsCount}}',
  '{{item}} is already saved': '{{item}} 已儲存',
  'Details': '細節',
  'Equipment': '設備',
  'Notes': '備註',
  'Preventive Insights': '預防性見解',
  'Service Insights': '服務觀點',
  'Equipment Information': '設備信息',
  'Equipment ID': '設備 ID',
  'Model': '模組',
  'Terminal ID': '終端系統ID',
  'Device serial number': '設備序號',
  'EFC codes': 'EFC代碼',
  'Steps': '步驟',
  'Module content': '模組內容',
  'Repair action content': '維修內容',
  'Fixed issue': '已排除問題',
  'Part Number {{partId}} copied to the clipboard':
    '料件序號複製至剪貼簿 {{partId}}',
  'Relationship tree': '關係樹狀圖',
  'Potential substitutions': '可能的替代選項',
  'Loading': '傳送中',
  'Removed from saved searches': '自搜尋紀錄中移除',
  'Added to saved searches': '新增至搜尋紀錄',
  'All bookmarks cleared': '所有標籤均已清除',
  'No bookmarks to clear': '已無可清除標籤',
  'All recent items cleared': '近期瀏覽項目均已清除',
  'No recent items to clear': '已無可清除項目',
  'You have no recently view history.': '並無近期瀏覽紀錄',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice.  Until content is downloaded the app will continue to show the previous language for content.':
    '語言切換功能將分兩階段達成,靜態資料例如各式欄位抬頭等將立刻完成切換,而動態資料內容必須連線狀態下才能進行,故所需時間將視您的網路連線與切換語種的差異略有不同,可能將耗時一分鐘或更長時間不等,直到完全翻譯完畢之前,各項動態資訊內容將維持以既有語種顯示',
  'Signing you in': '登入中',
  'Signing you out': '登出中',
  'Oops': '喔,不太對勁',
  'Data has been fetched in': '資料已獲取',
  'Failed to fetch data in': '資料無法獲取',
  'No results': '無適當結果',
  'URL': '網址',
  'User Name': '使用者名稱',
  'ACFS App': 'ACFS 應用程式',
  'ACFS UI': 'ACFS 操作介面',
  'Offline Data': '離線資料',
  'Info': '資料訊息',
  'Repair': '維修',
  'RepairActionCount 1': '1 維修作業',
  'RepairActionCount {{count}}': '{{count}} 維修作業',
  'RepairActionCount 0': '0 維修作業',
  'All': '全部',
  'Sync': '同步',
  'Last 30 days': '過去30天',
  'Not yet implemented': '尚未安裝',
  'Categories': '類別',
  'Repair actions': '維修作業',
  'Module': '模組',
  'Preferences': '偏好',
  'Service Manuals': '維護服務手冊',
  'Service Bulletins': '維護服務公告',
  'FCOs': 'FCOs',
  'FAQs': 'FAQs',
  'Training Content': '訓練教材',
  'Your feedback has been sent': '你的回饋已送出',
  'Failed to send your feedback': '傳送你的回饋失敗',
  'Comments are required': '需要評論',
  'Comments should not be more than 100 characters': '評論不超過100字',
  'Max {{numOfPhotos}} photos can be uploaded': '最多可上傳的照片張數',
  'Add this Task - {{taskNumber}}': '加入此工作 - {{taskNumber}}',
  "Other '{{inputValue}}'": '其它 {{inputValue}}',
  'Other (Type to add) / Blank': '其它 (輸入) / 空白',
  'General feedback': '一般回饋',
  'Feedback: EFC code - {{efcCode}}': '回饋: EFC代碼 {{efcCode}}',
  'Module: {{moduleName}}': '模組 {{moduleName}}',
  'Feedback for a specific EFC Code, Repair Action, or Part should be submitted using the feedback option available on those specific entries.':
    '應使用這些特定條目上可用的反饋選項提交針對特定 EFC 代碼、維修操作或零件的反饋',
  'Corrective action required': '需要採取修正措施',
  'Yes': '是',
  'No': '否',
  'Task number': '工作項目號碼',
  'Select or Type to add': '選擇或輸入新增',
  'Comments': '評論',
  'Delete': '刪除',
  'Open Camera': '開啟攝影機',
  'Upto {{maxFiles}} images can be selected': '最多可選擇照片張數 {{maxFiles}}',
  'Cancel': '取消',
  'Add': '新增',
  'Add equipment': '新增設備',
  'Serial number': '序列號',
  'Scan the equipment code or enter the serial number to view the insights and details for that piece of equipment.':
    '掃描設備代碼或輸入序列號以查看該設備的見解和詳細信息',
  'Last synced: {{time}} min ago': '上次同步 :{{time}} 幾分鐘前',
  'Send': '傳送',
  "Task Number must be provided if 'Corrective action required' is Yes":
    '如果“需要糾正措施”為“是”，則必須提供任務編號',
  'Time to fix (minutes)': '修復時間(分鐘)',
  'Time to fix should not be more than 999': '修復時間不應超過999',
  'Please enter positive numbers only': '請只輸入正數',
  'Please enter numbers only': '請只輸入數字',
  'Clear filters': '清除過濾器',
  'Show results': '顯示結果',
  'Your feedback has been submitted': '你的回饋已經提交',
  'This EFC is related to an enrichment link that is currently active.Please let us know if the enrichment is relevant (appropriate action to perform).If you want to leave additional feedback select "Leave feedback"':
    '此 EFC 與當前活動的擴充鏈接相關。請讓我們知道濃縮是否相關（要執行的適當操作）。如果您想留下其他反饋，請選擇“留下反饋',
  'Products': '產品',
  'Product Number {{productId}} copied to the clipboard':
    '產品編號已複製到剪貼板 {{productId}}',
  'View Groups': '檢視群組',
  'Search all products': '搜尋所有產品',
  'Current': '目前的',
  'History': '歷史紀錄',
  'No equipment data found for serial number {{number}}.':
    '此序號無設備資料 {{number}}',
  'Unable to retrieve equipment data for the serial number {{number}}.':
    '無法檢索序列號的設備資料 {{number}}',
  'Unable to retrieve equipment data. Falling back to offline data.':
    '無法檢索設備資料.回退到離線資料',
  'Unable to retrieve service task. Falling back to offline data.':
    '無法檢索工作項目.回退到離線資料',
  'Please wait while data is being loaded in {{language}}':
    '正在加載數據,請稍後 {{language}}',
  'Errors': '錯誤',
  'Tasks': '工作',
  'History Parts': '歷史料件',
  'Unable to retrieve Service Task history.': '無法檢索服務工作歷史',
  'Recommended Parts': '建議料件',
  'Service Task Details': '服務工作細節',
  'Task Number': '工作號碼',
  'Task Status': '工作狀態',
  'Problem': '問題',
  'Task Type': '工作類別',
  'Customer': '客戶',
  'Effective Date': '生效日期',
  'Device Description': '設備描述',
  'Device Serial Number': '設備序號',
  'EndPoint Id': '端點ID',
  'Service Task Notes {{length}}.': '服務任務說明 {{length}}.',
  'Part {{partId}} cannot be found in DNSpares data':
    '在DNSpare資料中找不到料件 {{partId}}',
  'Number': '號碼',
  'Site Number': '站點號碼',
  'Address': '地址',
  'Contact Date': '聯絡日期',
  'Contact Name': '聯絡名稱',
  'Contact Email': '聯絡電郵信箱',
  'Contact Phone': '聯絡電話',
  'Contract Hours': '合約小時',
  'Site Messages:': '站點消息:',
  'Type': '類別',
  'Product': '產品',
  'Desc': '項目',
  'Task Number should not be more than 30 characters': '任務編號不應超過30字',
  'Select Photos': '選擇照片',
  'Session Expired': '會話過期',
  'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.':
    '當前會話在離線時已過期。 請將設備恢復在線並選擇下面的登錄按鈕執行登錄並返回主屏幕',
  'Login': '登入',
  'Please provide your feedback in order to improve the content in the ACFS Site not related to a specific EFC or Repair Action.':
    '請提供您的反饋，以改進 ACFS 網站中與特定 EFC 或修復操作無關的內容',
  'Please provide your feedback in order to improve the content that follows this EFC Code.':
    '請提供您的反饋以改進遵循此 EFC 代碼的內容',
  'Is this Repair Action missing anything? For example, should the star rating or time to fix be adjusted? Please provide your feedback in order to improve the content of this specific Repair Action and its association with the EFC that prompted this.':
    '此修復操作是否缺少任何內容？ 例如，是否應該調整星級或修復時間？ 請提供您的反饋以改進此特定修復操作的內容及其與提示此操作的 EFC 的關聯',
  'Select "Yes" if you are recommending that a change needs to be made by the administrators.':
    '如果您建議管理員需要進行更改，請選擇“是”',
  'Feedback is not mandatory.Please use English if possible. Feedback is used to improve the content in ACFS. Feedback can be shared with management to ensure quality. Administrators may contact individuals for additional information to help clarify any information needed to improve content or other business purposes.':
    '反饋不是強制性的。請盡可能使用英語。 反饋用於改進 ACFS 中的內容。 可以與管理層共享反饋以確保質量。 管理員可能會聯繫個人以獲取更多信息，以幫助澄清改進內容或其他業務目的所需的任何信息',
  'OfflineMessage':
    '當 ACFS 處於離線模式時，由於內容的性質、更新頻率和終端設備內的存儲限制，某些內容將不可用。 由於那些',
  'OfflineMessage1':
    '在主頁和我的頁面上，我的任務部分將僅顯示設備中可用的打開任務',
  'OfflineMessage2':
    '在設備相關頁面（設備、詳細信息、零件），設備和服務任務數據將從設備中可用的數據中顯示',
  'OfflineMessage3':
    '備註頁面只會顯示設備對應的設備中可用的用戶開啟服務任務的備註',
  'OfflineMessage4':
    '我頁面和設備頁面的添加設備功能將無法使用，因為無法根據用戶提供的序列號獲取手動設備',
  'Feedback: Repair # {{repairActionId}}': '回饋: 維修# {{repairActionId}}',
  'Training': '教育訓練',
  'General Help': '一般協助',
  'Offline Disclaimer': '離線免責聲明',
  'Nothing here yet': '這裡沒有東西',
  'Unable to retrieve tasks. Falling back to offline data.':
    '無法檢索任務. 退回離線數據',
  'Category': '類別',
  'Family': '家庭',
  'Skill level required': '所需技能水平',
  'Sort by': '排序方式',
  'Subsystem': '子系統',
  'Recent records': '最近紀錄',
  'Systems': '系統',
  'Task - {{taskNumber}}': '任務 - {{taskNumber}}',
  'Add New': '新增',
  'Everywhere': '到處',
  'Keywords': '關鍵字',
  'The details page contains content only when there is an active call assigned to the current user for this equipment.':
    '詳細信息頁面僅在有分配給該設備的當前用戶的活動呼叫時包含內容',
  'OfflineMessage5':
    '應用程序內容中的圖像，如 EFC 圖像、修復操作步驟圖像、零件圖像，如果用戶處於離線狀態，將不會顯示',
  'Module(EFC only)': '模組(只有EFC)',
  'German': '德語',
  'Francias': '法語',
  'Polish': '波蘭語',
  'Espanol': '西班牙語',
  'Indonesian': '印尼語',
  'Thai': '泰語',
  'Vietnamese': '越南語',
  'Portuguese': '葡萄牙語',
  'Turkish': '土耳其語',
  'If no stock, replaces': '如果没有库存，则更换',
  'If no stock, replaced by': '如果没有库存，则替换为',
  'Replaces': '取代',
  'Replaced by': '替换为',
  'Repair Id': '維修編號',
  'View Child Ids': '查看子編號',
  'Device Id': '設備編號',
  'Equipment with serial number {{number}} added Succesfully.':
    '序列號為 {{number}} 的設備已成功添加。',
  'mfg': '工廠生產編號',
  'Reporting System': '回報系統.',
  'Update': '更新',
  'No Endpoint': '沒有終點',
  'Repair Action performed': '維修動作執行',
  'Update: User names are now visible in task history. You’ll see names of users, and others will see yours. This change helps identify previous users, making it easier to contact them for further information if needed.':
    '更新：用户名称现在在任务历史中可见。您将看到其他用户的名称，其他用户也会看到您的名称。此更改有助于识别先前的用户，如果需要，更容易与他们联系获取更多信息。',
  'The ACDE Knowledgebase content is currently being downloaded to this device.  We apologize for the inconvenience.  The page will automatically redirect when the content load is complete.':
    'ACDE 知識庫內容當前正下載到此設備。 對於給您帶來的不便，我們深表歉意。 內容載入完成後，頁面將自動重定向。',
  'We apologize for the inconvenience.  The link used is not a valid location in ACFS.':
    '對於給您帶來的不便，我們深表歉意。 使用的連結不是 ACFS 中的有效位置。',
  'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.':
    '“修復操作”頁上的選定終結點與分配的活動服務任務的某個終結點不匹配。選擇「確定」繼續。取消以更改。',
  'Select “Yes” if you are recommending that a new Repair Action needs to be created by the administrators.':
    '如果您建議管理員需要創建新的修復操作，請選擇“是”。',
  'Select this repair action template to specify the action completed':
    '選擇此修復操作範本以指定已完成的操作',
  'Custom Repair Performed': '執行自定義修復',
  'Submit': '提交',
  'Needs reviewed for new RA': '審查新RA的需求',
  'Title': '標題',
  'Title of repair': '維修名稱',
  'Description of repair': '維修說明',
  'Duration to complete repair (minutes)': '完成修復的持續時間（分鐘）',
  '3 or more Characters are required for the search.':
    '搜索需要 3 個或更多字元',
  'Title is required': '標題為必填項',
  'Title should not be more than 200 characters': '標題不得超過200個字',
  'Details is required': '需要詳細信息',
  'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.':
    '切換語言分兩個階段完成。 標籤和標題等靜態數據將立即切換。 內容數據需要在線連接，並且可能需要一分鐘或更長時間才能刷新新的語言選擇，具體取決於您的連接情況。 在下載內容之前，應用程序將繼續顯 示以前的內容語言。 最近查看和保存的項目將最後更新，並且最多可能需要一分鐘的時間。',
  'No results Or Multiple Results, please narrow your search':
    '沒有結果或有多個結果,請縮小搜尋範圍',
  'Enter the serial number to view the insights and details for that piece of equipment.':
    '輸入序號以查看該設備的見解和詳細資料',
  'Open': '打開',
  'Interrupt': '打斷',
  'Incomplete': '未完成的',
  'Closed': '閉合的',
  'Cancelled': '取消',
  'Scanned successfully, Redirecting to the scanned link':
    '掃描成功,正在重定向到掃描的連結',
  'Search Serial number': '搜尋序列號',
  'No System is Selected': '未選擇任何系統',
  'Cancelled Tasks': '取消的任务',
  'Allowed': '允许',
  'Not Allowed': '不允许',
  'Open Task UserId': '打开任务用户 ID',
  'Observations': '意见',
  'GroupName or UserID': '组名或用户 ID',
  'AI Summary': '人工智能概要',
  'Chronic / Worst Performer Details': '痼疾/表现最差者详情',
  'Triage Notes': '分诊说明',
  'Search By': '搜索',
  'Serial Number': '序列号',
  'Tag': '标签',
  'Temporarily add equipment': '临时增加设备',
  'System': '系统',
  'Machine Name': '机器名称',
  'No data is available': '无可用数据',
  'Connections': '连接',
  'Non-Connected': '未連接',
  'Part Recommendations': '零件推荐',
  'Please wait for the daily update.  The page will automatically refresh when completed.':
    '請等待每日更新。完成後頁面會自動刷新。',
  'Install Date': '安裝日期',
  'ACDE KB daily update is currently in progress. Part navigation is not available. RepairAction indicator is also not available. Please try again when the content has been downloaded.':
    'ACDE KB 每日更新目前正在進行中。部件導航不可用。 RepairAction 指示器也不可用。請在內容下載後重試。',
  'No Equipment data available for the Endpoint {{endPointId}}.':
    '端点 {{endPointId}} 无设备数据。',
  'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.':
    '已識別出重複的端點。歷史 EFC 可能來自多個端點。檢視此端點的歷史記錄時應小心。驗證 Harvester 設定是否正確，端點註冊是否正確。',
  'Duplicate Endpoint': '重複端點',
  'Inventory': '庫存',
  'Entitlements': '權利',
  'Green': '綠色',
  'Yellow': '黃色',
  'Red': '紅色',
  'Empty': '空',
  'Entitlement is connected and sending data as expected.':
    'Entitlement 已連線並傳送預期的資料。',
  'Entitlement is connected, but not sending data as expected.  Review the detailed message on the entitlement details page. Click on card to get details on how to resolve.':
    '權利已連接，但未如預期傳送資料。 檢視權利詳細頁面上的詳細訊息。按一下卡片以取得如何解決的詳細資訊。',
  'Entitlement is expected, but is not connected and no data.  Click on card to get details on how to resolve.':
    '預期會有權限，但未連接且無資料。 按一下卡片以取得解決方法的詳細資訊。',
  'Entitlement does not send information as to the current status. No action is required.':
    'Entitlement 不會傳送目前狀態的資訊。不需要採取任何行動。',
  'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.':
    '断开连接的系统之前已连接并向 ACDE 发送数据。但是，数据传输现已停止，超过了此连接的预期时限。请参阅 “最后数据 ”字段以确定最后一次接收数据的时间。',
  'Disconnected': '断开',
};

export default translation;
