import Flame from '../../components/icons/Flame';
import { ITask } from '../../Models/Task';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Icon from '../../components/Icon';
import useNavigateToEquipment from '../../hooks/useNavigateToEquipment';
import { EquipmentMenuItem } from '../../types';
import AiSummary from '../../components/feature/AiSummary';
import AudioAiSummary from '../../components/AudioAISummary';
import useGetCtaSettings from '../../hooks/useGetCtaSettings';
import { useEffect, useState } from 'react';
import CallToActionSettings from '../../Models/CallToActionSettings';
import { CallToActionType } from '../../Models/Enumerations';
import useHandleCtaRead from '../../hooks/useHandleCtaRead';

interface Props {
  task: ITask;
}

function MyTask({ task }: Props) {
  const navigateToEquipment = useNavigateToEquipment();
  const date = new Date(task.effectiveDate);

  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
  const minutes =
    date.getMinutes() < 10
      ? `0${date.getMinutes()}`
      : date.getMinutes().toString();
  const seconds =
    date.getSeconds() < 10
      ? `0${date.getSeconds()}`
      : date.getSeconds().toString();

  const handleClick = () => {
    navigateToEquipment(
      task.endpointId,
      'Systems' as EquipmentMenuItem,
      task.id
    );
  };
  const [ctaSettings, syncCtaFunction] = useGetCtaSettings(task.endpointId);
  const [aiCtaSettings, setAiCtaSettings] = useState<
    undefined | CallToActionSettings
  >(undefined);

  useEffect(() => {
    syncCtaFunction(task.taskNumber);
  }, [task.taskNumber, syncCtaFunction]);

  useEffect(() => {
    if (ctaSettings) {
      const recorderdData = ctaSettings.find(
        (setting) => setting.callToActionType === CallToActionType.AiSummary
      );
      setAiCtaSettings(recorderdData);
    }
  }, [ctaSettings]);
  const [readState, saveCallToActionSetting] = useHandleCtaRead(
    aiCtaSettings?.callToActionType === CallToActionType.AiSummary
  );
  const onSuccefulSave = () => {
    if (readState === 'primary') {
      saveCallToActionSetting(
        CallToActionType.AiSummary,
        task.endpointId,
        'Audio-Text',
        task.taskNumber
      );
    }
  };

  return (
    <div className="w-full text-left" onClick={handleClick}>
      <div className="home-task-container w-full">
        <div className="flex w-full shrink-0 items-center justify-center rounded-l-lg bg-steel">
          <Icon is={EventOutlinedIcon} size="1rem" colorClass="text-white" />
        </div>
        <div className="rounded-r-lg border border-antartica bg-white p-2">
          <div className="flex flex-wrap items-start justify-between">
            <div className="flex-grow overflow-ellipsis text-lg  font-bold">
              <div className="flex flex-row items-start">
                <div className="pr-1">{task.taskNumber}</div>
                {task?.hasEndPointServiceInsights ? (
                  <img
                    src="./lightBulbBrain.png"
                    width={30}
                    className="h-[30px]"
                    alt=""
                  />
                ) : task?.hasEndPointPreventiveInsights ? (
                  <img
                    src="./lightBulbGear.png"
                    width={30}
                    height={20}
                    alt=""
                  />
                ) : (
                  <></>
                )}
                {task?.chronicIndicator?.toLowerCase() === 'yes' && <Flame />}
                {ctaSettings && (
                  <>
                    <AiSummary
                      summary={task.generatedSummary}
                      onSuccefulSave={onSuccefulSave}
                      readState={readState}
                    />

                    <AudioAiSummary
                      summaryText={task.generatedSummary}
                      onSuccessfulPlay={onSuccefulSave}
                      readState={readState}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="rounded-full border border-tangerine bg-[#FFF9CC] px-2 font-medium text-tangerine">
              {task.taskStatus}
            </div>
          </div>
          <div className="overflow-ellipsis text-steel">
            {hours}:{minutes}:{seconds} {date.toDateString()} - {task.taskType}
          </div>
          <div className="flex items-start pt-2">
            <div className="w-full flex-1">
              <div className="overflow-ellipsis text-lg font-bold">
                {task.customer}
              </div>
              <div className="line-clamp-2 text-clip text-steel">
                {task.addressLine1} {task.addressLine2} {task.addressLine3}
              </div>
              <div className="overflow-ellipsis text-steel">
                {task.postalCode} {task.city} {task.state} {task.country}
              </div>
            </div>
          </div>
          <div className="flex items-start pt-2">
            <div className="w-full flex-1">
              <div className="text-clip text-lg font-bold">
                {task.deviceDescription}
              </div>
              <div className="overflow-ellipsis text-steel">
                {task.deviceSerialNumber}
              </div>
            </div>
          </div>
          <div className="flex items-start pt-2">
            <div className="w-full flex-1">
              <div className="line-clamp-3 text-lg font-bold">
                {task.problem}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MyTask;
