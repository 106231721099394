import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import PageMenu from '../../components/PageMenu';
import { useToast } from '../../components/toast/ToastProvider';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import useLogging from '../../hooks/useLogging';
import db from '../../index-db';
import EndPoint from '../../Models/EndPoint';
import LogParams from '../../Models/LogParams';
import Task from '../../Models/Task';
import { usePage } from '../../PageProvider';
import Details from './Details';
import { EquipmentMenuItem } from '../../types';
import { useParams } from 'react-router-dom';
import Notes from './Notes';
import useNavigateToEquipment from '../../hooks/useNavigateToEquipment';
import { LogType } from '../../enums/LogType';
import Equipment from './Equipment';
import { LAST_ACCESSED_ENDPOINT } from '../../constants';
import AddEquipmentModal from '../../components/feature/AddEquipmentModal';
import { filterTimelineTasks } from '../../utils/non-generic-utils';
import useLanguageTransformer from '../../hooks/useLanguageTranformer';
import SearchEquipmentModal from '../../components/feature/SearchEquipmentModal';
import NoSystem from '../../components/utility/NoSystem';
import useGetCtaSettings from '../../hooks/useGetCtaSettings';

interface EquipmentProps {
  displayLastSystem?: boolean;
}
const defaultProps: EquipmentProps = {
  displayLastSystem: false,
};

function EquipmentPage(props: EquipmentProps) {
  const page = usePage();
  const params = useParams();
  const { t } = useTranslation();
  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';
  const endPointId = props.displayLastSystem
    ? lastAccessedEndpoint
    : params.endpointId ?? '';
  const serviceTaskId = params.serviceTaskId ? params.serviceTaskId : '';
  const activeMenuItem = params.tab ? t(params.tab) : t('Systems');
  const navigateToEquipment = useNavigateToEquipment();
  const toast = useToast();
  const api = useApi();
  const logging = useLogging();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showChronicIndicaor, setShowChronicIndicaor] = useState(false);
  const langCode = useLanguageTransformer();
  const [callToActionSettings, syncCTASettings, setCallToActionSettings] =
    useGetCtaSettings(endPointId);

  const fetchEndPoint = async (endpointId: string): Promise<EndPoint> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointById/?endPointId=${endpointId}`,
      undefined,
      langCode
    )) as EndPoint;
  };

  const fetchOpenServiceTasks = async (): Promise<Task[]> => {
    return (await api.fetchJson(
      '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
    )) as Task[];
  };

  const fetchServiceTask = async (serviceTaskId: string): Promise<Task> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceTask/getServiceTask?serviceTaskId=${serviceTaskId}`
    )) as Task;
  };
  const fetchOpenTaskByEndpointId = async (
    endpointId: string
  ): Promise<Task> => {
    return await api.fetchJson(
      `/dwar/api/almanac/ServiceTask/getOpenTaskByEndpointId?endpointId=${endpointId}`
    );
  };
  const SearchLoadingStarted = (loading: boolean): void => {
    setIsEquipmentLoading(loading);
  };
  const [searchParams] = useSearchParams();
  const menuItems = [t('Details'), t('Systems'), t('Notes')];
  const [activeMenu, setActiveMenu] = useState(
    (searchParams.get('tab') ?? activeMenuItem) as string
  );
  const [equipmentData, setEquipmentData] = useState({} as EndPoint);
  const [serviceTaskData, setServiceTaskData] = useState({} as Task);
  const [isEquipmentLoading, setIsEquipmentLoading] = useState(true);

  useEffect(() => {
    setActiveMenu((prevState) => {
      if (params?.tab && prevState !== params?.tab) {
        return t(params.tab);
      }
      return t(prevState);
    });
  }, [params?.tab]);
  const callToActionStateManager = async () => {
    const dbCallToActionSettings = await db.callToActionSettings
      .where({
        endpointId: endPointId,
        taskNumber: serviceTaskData.taskNumber ?? '000000',
      })
      .toArray();
    setCallToActionSettings(dbCallToActionSettings);
  };

  const handleActivePageMenuItemChange = async (menuItem: string) => {
    setShowAddModal(false);
    setShowSearchModal(false);
    setActiveMenu(menuItem);

    if (menuItem === t('Systems')) {
      await callToActionStateManager();
    }

    navigateToEquipment(
      endPointId,
      menuItem as EquipmentMenuItem,
      serviceTaskId
    );
  };

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Equipment,
      },
      {
        key: LogType.EndpointId,
        value: endPointId,
      },
      {
        key: LogType.ServiceTaskId,
        value: serviceTaskId || '',
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  useEffect(() => {
    equipmentData?.chronicIndicator?.toLowerCase() === 'yes'
      ? setShowChronicIndicaor(true)
      : setShowChronicIndicaor(false);
  }, [equipmentData?.chronicIndicator]);

  // TODO: Use usePage({...}) instead
  const pageInit = useCallback(
    (endpoint: EndPoint) => {
      page.init({
        getTitle: () => endpoint.serialNumber || endpoint.id,
        belongsToNavBarItem: 'System',
        showBackButton: true,
        leftButton: showChronicIndicaor
          ? LocalFireDepartmentOutlinedIcon
          : undefined,
        rightButtons: [
          { icon: SyncOutlinedIcon, onClick: sync },
          {
            icon: MoreVertOutlinedIcon,
            popoverMenuButtons: [
              {
                title: t('Add to equipment list'),
                onClick: () => {
                  setShowSearchModal(false);
                  setShowAddModal(true);
                },
              },
              {
                title: t('Search Serial number'),
                onClick: () => {
                  setShowAddModal(false);
                  setShowSearchModal(true);
                },
              },
            ],
          },
        ],
      });
    },
    [showChronicIndicaor]
  );

  const syncInsights = () => {
    if (endPointId) {
      fetchEndPoint(endPointId)
        .then((endpoint) => {
          const updatedEndpoint = filterTimelineTasks(endpoint);
          setEquipmentData(updatedEndpoint);

          if (
            updatedEndpoint.serialNumber &&
            updatedEndpoint.serviceInsights?.length >= 0 &&
            updatedEndpoint.preventiveInsights?.length >= 0
          ) {
            logging(
              'info',
              `Serial=${updatedEndpoint.serialNumber} SI Count=${updatedEndpoint.serviceInsights.length}, PI Count=${updatedEndpoint.preventiveInsights.length}`,
              '',
              false,
              null
            );
            db.endPoints.put(endpoint);
            pageInit(updatedEndpoint);
          } else {
            db.tasks.count().then((count) => {
              const logParams: LogParams[] = [
                {
                  key: LogType.Page,
                  value: LogType.Equipment,
                },
              ];
              !count &&
                logParams.push({
                  key: LogType.EndpointId,
                  value: endPointId,
                });
              logging(
                'warn',
                `No equipment data returned for the Endpoint Id ${endPointId} from our backend.`,
                '',
                true,
                logParams,
                true
              );
              toast.pushToast({
                type: 'error',
                message: t(
                  `No Equipment data available for the Endpoint {{endPointId}}.`,
                  {
                    endPointId: endPointId,
                  }
                ),
              });
            });
          }
        })
        .catch(async (error) => {
          logging(
            'warn',
            'Unable to retrieve equipment data. Falling back to offline data',
            error,
            false,
            null
          );
          toast.pushToast({
            type: 'error',
            message: t(
              'Unable to retrieve equipment data. Falling back to offline data.'
            ),
          });
          const dbEndPoint = await db.endPoints.get(endPointId);
          if (dbEndPoint) {
            const updatedEndpoint = filterTimelineTasks(dbEndPoint);
            setEquipmentData(updatedEndpoint);
            logging(
              'info',
              `Serial=${updatedEndpoint.serialNumber} SI Count=${updatedEndpoint.serviceInsights.length}, PI Count=${updatedEndpoint.preventiveInsights.length}`,
              '',
              false,
              null
            );
            pageInit(updatedEndpoint);
          }
        })
        .finally(() => {
          setIsEquipmentLoading(false);
        });
    }
  };

  const syncServiceTask = () => {
    fetchOpenServiceTasks()
      .then((tasks) => {
        const filteredTasks = tasks.filter((task) => {
          if (task.endpointId === endPointId) {
            return task;
          }
        });
        if (filteredTasks && filteredTasks.length > 0) {
          filteredTasks.sort((a, b) => {
            return (
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
            );
          });

          fetchServiceTask(filteredTasks[0].id)
            .then((serviceTaskResult) => {
              setServiceTaskData(serviceTaskResult);
              syncCTASettings(serviceTaskResult.taskNumber);
            })
            .catch(async (error) => {
              logging(
                'warn',
                'Unable to retrieve service task. Falling back to offline data',
                error,
                false,
                null
              );
              toast.pushToast({
                type: 'error',
                message: t(
                  'Unable to retrieve service task. Falling back to offline data.'
                ),
              });

              const dbServiceTaskResult = await db.tasks.get(
                filteredTasks[0].id
              );
              setServiceTaskData(dbServiceTaskResult as Task);
              syncCTASettings(dbServiceTaskResult?.taskNumber);
            });
        } else {
          fetchOpenTaskByEndpointId(endPointId)
            .then((serviceTaskResult) => {
              setServiceTaskData(serviceTaskResult);
              syncCTASettings(serviceTaskResult.taskNumber);
            })
            .catch(async (error) => {
              logging(
                'warn',
                'Unable to retrieve service task. Falling back to offline data',
                error,
                false,
                null
              );
              toast.pushToast({
                type: 'error',
                message: t(
                  'Unable to retrieve service task. Falling back to offline data.'
                ),
              });

              const dbServiceTaskResult = await db.tasks.get({
                endpointId: endPointId,
              });
              dbServiceTaskResult && setServiceTaskData(dbServiceTaskResult);
              syncCTASettings(dbServiceTaskResult?.taskNumber);
            });
        }
      })
      .catch(async (error) => {
        logging(
          'warn',
          'Unable to retrieve open service tasks of the user',
          error,
          false,
          null
        );
        toast.pushToast({
          type: 'error',
          message: t(
            'Unable to retrieve open service tasks of the user. Falling back to offline mode.'
          ),
        });
        const tasks = await db.tasks.toArray();
        const filteredTasks = tasks.filter((task) => {
          if (task.endpointId === endPointId) {
            return task;
          }
        });

        if (filteredTasks && filteredTasks.length > 0) {
          filteredTasks.sort((a, b) => {
            return (
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
            );
          });
        }

        try {
          const dbServiceTaskResult = await db.tasks.get(filteredTasks[0].id);
          setServiceTaskData(dbServiceTaskResult as Task);
          syncCTASettings(dbServiceTaskResult?.taskNumber);
        } catch (e) {
          setCallToActionSettings([]);
        }
      });
  };

  const sync = () => {
    syncInsights();
    syncServiceTask();
  };

  usePage(
    () => ({
      getTitle: () => '',
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
      leftButton: showChronicIndicaor
        ? LocalFireDepartmentOutlinedIcon
        : undefined,
      rightButtons: [
        { icon: SyncOutlinedIcon, onClick: sync },
        {
          icon: MoreVertOutlinedIcon,
          popoverMenuButtons: [
            {
              title: t('Add to equipment list'),
              onClick: () => {
                setShowSearchModal(false);
                setShowAddModal(true);
              },
            },
            {
              title: t('Search Serial number'),
              onClick: () => {
                setShowAddModal(false);
                setShowSearchModal(true);
              },
            },
          ],
        },
      ],
    }),
    [t]
  );

  useEffect(() => {
    syncInsights();
    syncServiceTask();
  }, [endPointId, serviceTaskId, showChronicIndicaor]);

  window.localStorage.setItem(LAST_ACCESSED_ENDPOINT, endPointId);

  const handleCancel = () => {
    setShowAddModal(false);
  };
  const onModalClose = () => {
    setShowAddModal(false);
  };
  const handleSearchCancel = () => {
    setShowSearchModal(false);
  };
  const onSearchModalClose = () => {
    setShowSearchModal(false);
  };

  return (
    <div>
      <PageMenu
        menuItems={menuItems}
        activeMenuItem={activeMenu}
        onChange={handleActivePageMenuItemChange}
      />
      <AddEquipmentModal
        onDismiss={handleCancel}
        isModalOpen={showAddModal}
        onModalClose={onModalClose}
      />
      <SearchEquipmentModal
        onDismiss={handleSearchCancel}
        isModalOpen={showSearchModal}
        onModalClose={onSearchModalClose}
        isEquipmentLoading={isEquipmentLoading}
        SearchLoadingStarted={SearchLoadingStarted}
      />

      {endPointId && callToActionSettings && activeMenu === menuItems[1] && (
        <Equipment
          equipmentData={equipmentData}
          serviceTaskId={serviceTaskData.taskNumber}
          callToActionSettings={callToActionSettings}
          isEquipmentLoading={isEquipmentLoading}
          callToActionStateManager={callToActionStateManager}
        />
      )}
      {endPointId && activeMenu === menuItems[0] && (
        <Details serviceTask={serviceTaskData} equipmentData={equipmentData} />
      )}

      {endPointId && activeMenu === menuItems[2] && (
        <Notes endpointId={endPointId} />
      )}
      {!endPointId && <NoSystem />}
    </div>
  );
}

EquipmentPage.defaultProps = defaultProps;

export default EquipmentPage;
