import { useCallback } from 'react';
import CallToActionSettings from '../Models/CallToActionSettings';
import { CallToActionType } from '../Models/Enumerations';
import FailedCallToActionSettings from '../Models/FailedCallToActionSettings';
import { useApi } from '../acfs-apis/dwar-api-provider';
import db from '../index-db';
import { uniqueIdConstructorCTA } from '../utils/non-generic-utils';

const useSaveCallToActionSetting = (onSuccessfullSave?: () => void) => {
  const api = useApi();

  const SaveCallToActionSetting = useCallback(
    async (
      ctaType: CallToActionType,
      endpointId: string,
      value: string,
      serviceTaskId?: string
    ) => {
      serviceTaskId = serviceTaskId ?? '000000';
      const callToActionSetting: CallToActionSettings = {
        callToActionType: ctaType,
        endpointId: endpointId,
        taskNumber: serviceTaskId,
        value: value,
        id: uniqueIdConstructorCTA({
          endpointId,
          taskNumber: serviceTaskId,
          callToActionType: ctaType,
          value,
        }),
      };

      try {
        let { id, ...ctaSettings } = callToActionSetting;
        await api.saveCallToActionSettings(ctaSettings);
        onSuccessfullSave?.();
        db.callToActionSettings.add(callToActionSetting);
      } catch {
        const failedCallToActionSetting: FailedCallToActionSettings = {
          callToActionSettings: callToActionSetting,
        };

        db.failedCallToActionSettings.add(failedCallToActionSetting);
      }
    },
    [api]
  );
  return SaveCallToActionSetting;
};

export default useSaveCallToActionSetting;
