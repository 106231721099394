interface IAudioAiSummary {
  key: number;
  value: Blob;
  lastLoadTime: number;
}

export class AudioAiSummary implements IAudioAiSummary {
  key: number;
  value: Blob;
  lastLoadTime: number;
  constructor(key: number, value: Blob, lastLoadTime: number) {
    this.key = key;
    this.value = value;
    this.lastLoadTime = lastLoadTime;
  }
}

export default AudioAiSummary;
