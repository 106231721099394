import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import Collapsible from '../../../components/collapsible/Collapsible';
import SelectableListItem from '../../../components/SelectableListItem';
import { searchActions } from '../../../features/search/searchSlice';
import db from '../../../index-db';
import Module from '../../../Models/Module';
import { SearchFilter, SearchFilterKey } from '../../../types';

interface Props {
  title: string; // TODO: Instead of a prop, use a lookup based on the filterKey to determine the title. It'll be used in a few places so centralize it
  filterKey: SearchFilterKey;
}

function Filter(props: Props) {
  const dispatch = useAppDispatch();
  const activeSearch = useAppSelector(selectActiveSearch);
  const filter = activeSearch?.filters[props.filterKey] ?? null;
  const [availableFilters, setAvailableFilter] = useState<SearchFilter[]>([]);
  useEffect(() => {
    async function getModuleList() {
      const modules = await db
        .table('modules')
        .toArray()
        .then((modules: Module[]) => {
          const moduleList: SearchFilter[] = [];
          modules.sort(function (a, b) {
            return a.ModuleName.toLowerCase().localeCompare(
              b.ModuleName.toLowerCase()
            );
          });
          modules.forEach((module) => {
            moduleList.push({ value: module.Id, label: module.ModuleName });
          });
          return moduleList;
        });
      return modules;
    }
    (async () => {
      const filtersByKey: Partial<Record<SearchFilterKey, SearchFilter[]>> = {
        category: [
          {
            value: 1,
            label: 'Module',
          },
        ],
        Module: await getModuleList(),
      };
      setAvailableFilter(filtersByKey[props.filterKey] ?? []);
    })();
  }, []);

  const filterHasValue = (value: unknown) => {
    if (!filter) {
      return value === null;
    }
    return filter.value === value;
  };

  const applyFilter = (filter: SearchFilter) => {
    // TODO: If the filter is saved, override it with this update
    dispatch(
      searchActions.applyFilter({
        filterKey: props.filterKey,
        filter,
      })
    );
  };

  const removeFilter = () => {
    dispatch(searchActions.removeFilter(props.filterKey));
  };

  return (
    <Collapsible
      headingTitle={props.title}
      headingSubText={filter?.label ?? '-'}
    >
      <div className="max-h-52 overflow-y-auto">
        <SelectableListItem
          title="Do not filter"
          selected={filterHasValue(null)}
          onSelect={() => removeFilter()}
        />
        {availableFilters.map((filter) => (
          <SelectableListItem
            key={filter.value as any}
            title={filter.label}
            selected={filterHasValue(filter.value)}
            onSelect={() => applyFilter(filter)}
          />
        ))}
      </div>
    </Collapsible>
  );
}

export default Filter;
