import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { TIcon } from '../types';
import Icon from './Icon';

interface RequiredProps {
  title: string | JSX.Element;
}

interface OptionalProps {
  withMargins?: boolean;
  icon?: TIcon;
  trailingElement?: ReactNode;
}

type Props = RequiredProps & OptionalProps;

const defaultProps = {
  withMargins: true,
  icon: undefined,
};

function SectionHeading(props: Props) {
  return (
    <div
      className={classnames('flex items-center gap-x-2', {
        'mt-6 mb-2': props.withMargins,
        'px-4': props.withMargins && !props.trailingElement,
        'pl-4': props.withMargins && !!props.trailingElement,
      })}
    >
      {props.icon && (
        <Icon
          className="-translate-y-0.5"
          is={props.icon}
          colorClass="text-cobalt"
        />
      )}

      <h2 className={'flex-1 text-xl font-medium'}>{props.title}</h2>

      {props.trailingElement}
    </div>
  );
}

SectionHeading.defaultProps = defaultProps;

export default SectionHeading;
