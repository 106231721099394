import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import {
  BUILD_VERSION,
  IS_USER_AUTHENTICATED_KEY,
  REDIRECT_URL,
  USER_REALM_KEY,
  ESC_URL,
} from './constants';
import './i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { OIDCConfig } from './types';

const container = document.getElementById('root')!;
const root = createRoot(container);

const isUserAuthenticated = window.localStorage.getItem(
  IS_USER_AUTHENTICATED_KEY
);
const userRealm = window.localStorage.getItem(USER_REALM_KEY);

const search = window.location.search;
const realm = new URLSearchParams(search).get('realm');

(async () => {
  const oidcConfigFile = 'oidcconfig.json';
  const result = await fetch(oidcConfigFile);

  if (result.status === 200) {
    const config = (await result.json()) as OIDCConfig;
    const buildVersion = window.localStorage.getItem(BUILD_VERSION);
    if (
      buildVersion === null ||
      (config && config.BuildVersion > buildVersion)
    ) {
      clearCache();
    }
    window.localStorage.setItem(BUILD_VERSION, config?.BuildVersion);
    window.localStorage.setItem(ESC_URL, config?.ESCAppURL);
    if (isUserAuthenticated || realm) {
      if (isUserAuthenticated === 'true' && userRealm) {
        UpdateRedirectUrl(config);
        root.render(<App realm={userRealm} oidcConfig={config} />);
      } else if (realm) {
        config.RedirectDwarApiUrl = window.localStorage.getItem(REDIRECT_URL);
        window.localStorage.setItem(IS_USER_AUTHENTICATED_KEY, 'true');
        window.localStorage.setItem(USER_REALM_KEY, realm);
        root.render(<App realm={realm} oidcConfig={config} />);
      }
    } else {
      window.localStorage.setItem(REDIRECT_URL, window.location.href);
      window.location.replace(`${config.ESCLogin}${config.DwarApiUrl}`);
    }
  } else {
    throw Error(
      `Unable to load ${oidcConfigFile}, HTTP status ${result.status}`
    );
  }

  function clearCache() {
    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
  }

  function UpdateRedirectUrl(config: OIDCConfig) {
    let redirectUrl = window.localStorage.getItem(REDIRECT_URL);

    if (redirectUrl === null) {
      config.RedirectDwarApiUrl = window.location.href;
    } else {
      config.IsExternal = true;
      config.RedirectDwarApiUrl = window.localStorage.getItem(REDIRECT_URL);
      window.localStorage.removeItem(REDIRECT_URL);
    }
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log('New version available!  Ready to update?');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
