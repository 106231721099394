import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { PropsWithChildren } from 'react';
import { usePage } from '../../PageProvider';
import { publicPath } from '../../utils';
import InteractiveIcon from '../InteractiveIcon';
import HeaderButton from './HeaderButton';
import { ESC_URL } from '../../constants';
import { useTranslation } from 'react-i18next';

function Header(props: PropsWithChildren<{}>) {
  const page = usePage();
  const { t } = useTranslation();
  let esc_url = window.localStorage.getItem(ESC_URL);
  if (esc_url == null) {
    esc_url = '#';
  }

  return (
    <header className="relative flex h-14 shrink-0 items-center justify-between border-b border-gray-200 bg-white px-1">
      <div className="z-20 flex">
        {page.showBackButton && (
          <InteractiveIcon
            icon={ArrowBackIcon}
            onClick={() => window.history.go(-1)}
          />
        )}
        {page.showACFSLogo && (
          <a href={esc_url}>
            <img
              className="ml-3"
              src={publicPath('acfs.png')}
              alt="ACFS Logo"
              title={t('Open eServices-Connect')}
            />
          </a>
        )}
        {page.leftButton && (
          <span className="mt-1 flex h-8 w-8 items-center justify-between rounded-full border border-tangerine bg-[#FFF9CC]">
            <InteractiveIcon
              icon={page.leftButton}
              iconColor="text-tangerine"
            />
          </span>
        )}
      </div>

      <div className="relative z-20 flex">
        {page.rightButtons.map((button, index) => (
          <HeaderButton key={index} button={button} />
        ))}
      </div>

      {/* This element is absolutely positioned so the contents can be centered regardless of the total width of the left and right parts of the header (there can be one button, two, or none at all) */}
      <div className="absolute left-2/4 z-10  w-full -translate-x-2/4">
        {props.children}
      </div>

      <div className="absolute-shadow below" />
    </header>
  );
}

export default Header;
