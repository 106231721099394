import { useCallback, useState } from 'react';
import RecommendedPart from '../../../Models/RecommendedPart';
import GenericModalSheet from '../../../components/GenericModalSheet';
import SystemsCard from '../../../components/cards/SystemsCard';
import { useTranslation } from 'react-i18next';
import SectionHeading from '../../../components/SectionHeading';
import RecommendedParts from './RecommendedParts';
import useLogging from '../../../hooks/useLogging';
import { LogType } from '../../../enums/LogType';
import useHandleCtaRead from '../../../hooks/useHandleCtaRead';
import { CallToActionType } from '../../../Models/Enumerations';

interface PropsRecommendedParts {
  recommendedParts: RecommendedPart[];
  endpointId: string;
  taskNumber?: string;
  isRead: boolean;
}

const PartRecommendations = (props: PropsRecommendedParts) => {
  const { recommendedParts, endpointId, taskNumber, isRead } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const logging = useLogging();
  const [isPartRead, syncCtaSettings] = useHandleCtaRead(isRead);

  const RecommendedCardClick = useCallback(() => {
    logging('info', 'User Read Recommended parts', undefined, true, [
      { key: LogType.RecommendedParts, value: 'User Read Recommended parts' },
    ]);
    if (isPartRead === 'primary') {
      const partsData = recommendedParts
        .map((part) => part.partNumber)
        .join(',');

      syncCtaSettings(CallToActionType.Part, endpointId, partsData, taskNumber);
    }
    setIsModalOpen(true);
  }, [recommendedParts, endpointId, isPartRead, syncCtaSettings]);

  const onSheetClose = () => {
    setIsModalOpen(false);
  };
  if (!recommendedParts.length) {
    return null;
  }
  return (
    <>
      <div className="mt-6 mb-2">
        <SectionHeading title={t('Part Recommendations')} withMargins={false} />
      </div>
      <SystemsCard
        iconColor="steel"
        onCardClick={RecommendedCardClick}
        clickableType="secondary"
        readUnreadIconType={isPartRead}
      >{`${recommendedParts.length} ${t('Recommended Parts')}`}</SystemsCard>
      <GenericModalSheet
        isBottomsheetOpen={isModalOpen}
        onSheetClose={onSheetClose}
      >
        <RecommendedParts recommendedParts={recommendedParts} />
      </GenericModalSheet>
    </>
  );
};

export default PartRecommendations;
