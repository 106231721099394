import classnames from 'classnames';
import React from 'react';

interface RequiredProps {
  title: string;
  onClick: () => void;
}

interface OptionalProps {
  active?: boolean;
}

type Props = RequiredProps & OptionalProps;

const defaultProps: OptionalProps = {
  active: false,
};

function Tab(props: Props) {
  return (
    <button
      className="px-2 text-lg font-bold"
      type="button"
      onClick={props.onClick}
    >
      <span
        className={classnames('relative', {
          'text-cobalt': props.active,
          'text-steel': !props.active,
        })}
      >
        {props.title}
        {props.active && (
          <span className="absolute inset-x-0 -bottom-1.5 h-0.5 bg-cobalt" />
        )}
      </span>
    </button>
  );
}

Tab.defaultProps = defaultProps;

export default Tab;
