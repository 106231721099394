import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import CopyAllOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Collapsible from '../../components/collapsible/Collapsible';
import { useToast } from '../../components/toast/ToastProvider';
import useNavigateToPart from '../../hooks/useNavigateToPart';
import { ChildLevel } from '../../Models/Enumerations';
import { IProductPageData } from './ProductPage';
import useClipBoardCopy from '../../hooks/useClipBoardCopy';

interface Props {
  productsPageData: IProductPageData;
  isCollapsed: boolean;
  title: string;
}

function ProductGroupCategory(props: Props) {
  const { t } = useTranslation();
  const { pushToast } = useToast();
  const navigateToPart = useNavigateToPart();
  const clipboardCopy = useClipBoardCopy();
  const handleClick = (idGen: string) => {
    navigateToPart(idGen);
  };
  let onClear;
  return (
    <Collapsible headingTitle={props.title} onClear={onClear}>
         {props?.productsPageData?.productsData && (
        <ActionableListItem
          key={props.productsPageData.productsData.ProductId}
          icon={CopyAllOutlinedIcon}
          title={props.productsPageData.productsData.ProductId}
          subText={props.productsPageData.productsData.Description}
          primaryAction="copy"
          hidePrimaryActionIcon={true}
          onClick={() => {
            clipboardCopy(
              props.productsPageData?.productsData.ProductId
            );
            pushToast({
              message: t(`Product Number {{productId}} copied to the clipboard`, {
                productId: props.productsPageData?.productsData.ProductId,
              }),
            });
          }}
          onClickIcon={() => {
            clipboardCopy(
              props.productsPageData?.productsData.ProductId
            );
            pushToast({
              message: t(`Product Number {{productId}} copied to the clipboard`, {
                productId: props.productsPageData?.productsData.ProductId,
              }),
            });
          }}
          childLevel={ChildLevel.FirstGen}
          appearance={'colorInverted'}
        />
      )}
      {
       (props?.productsPageData?.productsData?.GroupParts?.length) ?
          <Collapsible headingTitle={t(`View Groups`)} startCollapsed={false}>
              {props?.productsPageData?.productsData?.GroupParts.map((item, index) => (   
                <Collapsible key={index} headingTitle={item.GroupName} startCollapsed={true} childLevel={ChildLevel.FirstGen} >
                  {item.Parts?.map((item) => (
                      <ActionableListItem
                        key={item.MaterialId}
                        icon={CopyAllOutlinedIcon}
                        title = {item.MaterialId}
                        subText={item.PartName}
                        primaryAction="navigate"
                        childLevel={ChildLevel.SecondGen}
                        onClick={() => {
                          handleClick(item.MaterialId);
                        } }
                        onClickIcon={() => {
                          clipboardCopy(
                            item.MaterialId
                          );
                          pushToast({
                            message: t(`Part Number {{partId}} copied to the clipboard`, {
                              partId: item.MaterialId,
                            }),
                          });
                        } }/>
                  ))}
                </Collapsible>
              ))}
          </Collapsible> : <></>      
      }     
    </Collapsible>
  );
}

export default ProductGroupCategory;
