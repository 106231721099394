export interface IFamily {
  Id: number;
  FamilyName: string;
  FamilyOwner: string;
  LastUpdate: string;
  LastAction: number;
}

export class Family {
  Id: number;
  FamilyName: string;
  FamilyOwner: string;
  LastUpdate: string;
  LastAction: number;

  constructor(
    id: number,
    familyName: string,
    familyOwner: string,
    lastUpdate: string,
    lastAction: number
  ) {
    this.Id = id;
    this.FamilyName = familyName;
    this.FamilyOwner = familyOwner;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
  }
}

export default Family;
