import useCheckDeviceType from "./useCheckDeviceType";
import copy from 'copy-to-clipboard';

const useClipBoardCopy = () => {
  const deviceType  = useCheckDeviceType();
  return (text: string) => {
    if(deviceType === "Android")
    {
      copy(text);
    }
    else
    {
      navigator.clipboard.writeText(text);
    }
  };
};

export default useClipBoardCopy;
