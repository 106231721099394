import React, { useEffect, useState } from 'react';
import Toast from './Toast';
import { IToast, useToast } from './ToastProvider';

function ToastQueue() {
  const { toasts, shiftToast } = useToast();
  const [currentToast, setCurrentToast] = useState<IToast | null>(null);

  useEffect(() => {
    setCurrentToast(toasts.length ? toasts[0] : null);
  }, [toasts]);

  return (
    <div>
      {currentToast && (
        <Toast
          key={currentToast.key}
          type={currentToast.type ?? 'info'}
          onHidden={shiftToast}
        >
          {currentToast.message}
        </Toast>
      )}
    </div>
  );
}

export default ToastQueue;
