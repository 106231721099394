import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionHeading from '../../components/SectionHeading';
import InsightEntity from '../../Models/Insight';
import EFC from '../../Models/EFC';
import Insight from './Insight';
import { InsightType, TimeLineEventType } from '../../Models/Enumerations';
import EndPoint from '../../Models/EndPoint';
import CallToActionSettings from '../../Models/CallToActionSettings';
import db from '../../index-db';
import useLogging from '../../hooks/useLogging';
import { DATABASE_LOADED_STATUS_KEY } from '../../constants';
import { useToast } from '../../components/toast/ToastProvider';

interface Props {
  insights: InsightEntity[];
  title: string;
  insightType: InsightType;
  equipmentData: EndPoint;
  serviceTaskId?: string;
  callToActionSettings?: CallToActionSettings[];
}

function Insights(props: Props) {
  const { t } = useTranslation();
  const toast = useToast();

  interface InsightView {
    insight: InsightEntity;
    showRAPerformedIcon: boolean;
    raPerformedFeedback: string;
    isCTARead: boolean;
  }

  const [insightViewEntries, setInsightViewEntries] = useState(
    [] as InsightView[]
  );

  const [isDBLoadingMsgShown, setIsDBLoadingMsgShown] = useState(false);
  const [isdelayOver, setIsDelayOver] = useState(false);

  const logging = useLogging();

  function isDbLoaded() {
    const indexDBLoadedKey =
      window.localStorage.getItem(DATABASE_LOADED_STATUS_KEY) ?? '';
    return indexDBLoadedKey === 'true';
  }

  useEffect(() => {
    if (!isDbLoaded()) {
      if (!isDBLoadingMsgShown) {
        toast.pushToast({
          type: 'info',
          message: t(
            'Please wait for the daily update.  The page will automatically refresh when completed.'
          ),
        });
        setIsDBLoadingMsgShown(true);
      }
    }
  }, []);

  const fetchEFC = async (efcCode: string): Promise<EFC | undefined> => {
    return (await db.efcs
      .where('EfcCodeStr')
      .equalsIgnoreCase(efcCode)
      .first()) as EFC | undefined;
  };

  const pushInsight = useCallback(
    (
      insight: InsightEntity,
      showRAPerformedIcon: boolean,
      raPerformedFeedback: string,
      isCTARead: boolean
    ): InsightView => {
      const insightView: InsightView = {
        insight: insight,
        showRAPerformedIcon: showRAPerformedIcon,
        raPerformedFeedback: raPerformedFeedback,
        isCTARead: isCTARead,
      };

      return insightView;
    },
    []
  );
  const filterInsightsView = useCallback(
    (efcs: EFC[] | []) => {
      setIsDelayOver(true);
      let insightViewArray: InsightView[] = [];
      if (props.equipmentData.timelineEventDetails) {
        const repairTimelineEvents =
          props.equipmentData.timelineEventDetails.filter(
            (timeLineEvent) =>
              timeLineEvent.type === TimeLineEventType[TimeLineEventType.Repair]
          );

        const taskTimelineEvents =
          props.equipmentData.timelineEventDetails.filter(
            (timeLineEvent) =>
              timeLineEvent.type === TimeLineEventType[TimeLineEventType.Task]
          );

        props.insights.forEach((insight) => {
          const isCTARead =
            props.callToActionSettings?.find(
              (setting) =>
                setting.value.toLocaleLowerCase() ===
                insight.code.toLocaleLowerCase()
            ) || !props.serviceTaskId
              ? true
              : false;
          const efc = efcs.find(
            (efc) => efc.EfcCodeStr.toUpperCase() === insight.code.toUpperCase()
          );

          if (efc) {
            const repairTimeLineEvent = repairTimelineEvents.find(
              (timelineevent) =>
                timelineevent.properties['efcId'] === efc.Id.toString() &&
                new Date(timelineevent.timestamp) > new Date(insight.timestamp)
            );
            if (repairTimeLineEvent) {
              const filteredTaskTimeLineEvents = taskTimelineEvents.filter(
                (timelineevent) =>
                  new Date(timelineevent.timestamp) >=
                  new Date(insight.timestamp)
              );
              if (filteredTaskTimeLineEvents.length) {
                const openTaskTimeLineEvent = filteredTaskTimeLineEvents.find(
                  (timelineevent) =>
                    timelineevent.sharedStatus !== 'Closed' &&
                    timelineevent.sharedStatus !== 'Incomplete' &&
                    timelineevent.sharedStatus !== 'Cancelled'
                );

                if (openTaskTimeLineEvent) {
                  insightViewArray.push(
                    pushInsight(
                      insight,
                      true,
                      repairTimeLineEvent.properties['feedback'],
                      isCTARead
                    )
                  );
                }
              } else {
                insightViewArray.push(
                  pushInsight(
                    insight,
                    true,
                    repairTimeLineEvent.properties['feedback'],
                    isCTARead
                  )
                );
              }
            } else {
              insightViewArray.push(pushInsight(insight, false, '', isCTARead));
            }
          } else {
            logging(
              'info',
              `EFC code ${insight.code} not found in index db for serial ${props.equipmentData.serialNumber}.`,
              '',
              false,
              null
            );
          }
        });

        const sortedInsightViewEntries = insightViewArray.sort((a, b) =>
          b.showRAPerformedIcon ? 1 : -1
        );
        setInsightViewEntries(sortedInsightViewEntries);
      } else {
        props.insights.forEach((insight) => {
          const isCTARead =
            props.callToActionSettings?.find(
              (setting) =>
                setting.value.toLowerCase() === insight.code.toLowerCase()
            ) || !props.serviceTaskId
              ? true
              : false;

          insightViewArray.push(pushInsight(insight, false, '', isCTARead));
          const sortedInsightViewEntries = insightViewArray.sort((a, b) =>
            b.showRAPerformedIcon ? 1 : -1
          );
          setInsightViewEntries(sortedInsightViewEntries);
        });
      }
    },
    [
      logging,
      props.callToActionSettings,
      props.equipmentData.serialNumber,
      props.equipmentData.timelineEventDetails,
      props.insights,
      props.serviceTaskId,
      pushInsight,
    ]
  );
  const populateEFCData = useCallback(async () => {
    const efc = await fetchEFC(props.insights[0].code);
    const efcsDataset: EFC[] = [];
    if (efc) {
      efcsDataset.push(efc);
    }
    if (props.insights.length > 1) {
      const efc1 = await fetchEFC(props.insights[1].code);
      if (efc1) {
        efcsDataset.push(efc1);
      }
    }
    return efcsDataset;
  }, [props.insights]);

  useEffect(() => {
    (async () => {
      const efcsDataset = await populateEFCData();

      if (efcsDataset.length < props.insights.length && !isDbLoaded()) {
        const interval = setInterval(function () {
          populateEFCData().then((efcs) => {
            if (isDbLoaded() || efcs.length === props.insights.length) {
              clearInterval(interval);
              filterInsightsView(efcs);
            }
          });
        }, 5000);
      } else {
        filterInsightsView(efcsDataset);
      }
    })();
  }, [filterInsightsView, populateEFCData, props.insights.length]);

  const insightCountStr =
    insightViewEntries.length === 0 && !isdelayOver
      ? `${t('Loading')}...`
      : insightViewEntries.length;

  return (
    <div>
      <div className="ml-4 mb-2 mt-4 flex items-center justify-between">
        <SectionHeading
          title={`${t(props.title)} (${insightCountStr})`}
          withMargins={false}
        />
      </div>
      {insightViewEntries.length > 0 && (
        <div className="flex w-full flex-wrap gap-2 px-4 pb-2">
          {insightViewEntries.map((insightView, index) => (
            <Insight
              key={index}
              insight={insightView.insight}
              insightType={props.insightType}
              endpointId={props.equipmentData.id}
              serviceTaskId={props.serviceTaskId}
              showRAPerformedIcon={insightView.showRAPerformedIcon}
              raPerformedFeedback={insightView.raPerformedFeedback}
              isCTARead={insightView.isCTARead}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Insights;
