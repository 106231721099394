import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../acfs-apis/dwar-api-provider';
import useLogging from '../hooks/useLogging';
import LogParams from '../Models/LogParams';
import { LogType } from '../enums/LogType';
import { DATABASE_INIT_TIMESTAMP_KEY } from '../index-db';
import { Language as ILanguage } from '../types';
import { useToast } from './toast/ToastProvider';
import useLanguageTransformer from '../hooks/useLanguageTranformer';

interface Props {
  language: ILanguage;
}

function ChangeLanguage({ language }: Props) {
  const api = useApi();
  const { t } = useTranslation();
  const { pushToast } = useToast();
  const logging = useLogging();
  const [didFinishLoading, setDidFinishLoading] = useState(false);
  const changedLangCode = useLanguageTransformer();
  useEffect(() => {
    (async function load() {
      pushToast({
        message: t(`Switching language to {{ language }}`, {
          language: t(language.englishTitle),
        }),
      });
      setDidFinishLoading(false);

      const fetchContentResult = await api.fetchContent(changedLangCode);
      if (
        fetchContentResult?.dsaStatus == 'OK' ||
        fetchContentResult?.partsStatus == 'OK'
      ) {
        window.localStorage.setItem(
          DATABASE_INIT_TIMESTAMP_KEY,
          window.Date.now().toString()
        );
        pushToast({
          type: 'success',
          message: `${t('Data has been fetched in')} ${t(
            language.englishTitle
          )}`,
        });
        const logParams: LogParams[] = [
          {
            key: LogType.Language,
            value: changedLangCode,
          },
          {
            key: LogType.PartsDlStatus,
            value: fetchContentResult.partsStatus,
          },
          {
            key: LogType.PartsDlTimeMs,
            value: fetchContentResult.partsDownloadTime.toString(),
          },
          {
            key: LogType.DsaDlStatus,
            value: fetchContentResult.dsaStatus,
          },
          {
            key: LogType.DsaDlTimeMs,
            value: fetchContentResult.dsaDownloadTime.toString(),
          },
        ];
        logging('info', 'Data load on lang change', '', false, logParams);
      } else {
        pushToast({
          type: 'error',
          message: `${t('Failed to fetch data in')} ${language.englishTitle}`,
        });
        logging(
          'error',
          'Error in loading data on lang change',
          '',
          false,
          null
        );
      }

      setDidFinishLoading(true);
    })();
  }, [language, changedLangCode]);

  if (!didFinishLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-center text-xl">
          {t(
            'Switching language is done in 2 phases. The static data such as labels and headings will be switched immediately. The content data requires an online connection and can take up to a minute or more depending on your connection to refresh the new language choice. Until content is downloaded the app will continue to show the previous language for content.  Recently viewed and Saved items will be the last to update and can take up to an additional minute.'
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default ChangeLanguage;
