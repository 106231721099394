import Sheet from 'react-modal-sheet';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  header?: JSX.Element;
  disableDrag?: boolean;
}
const BottomModalSheet = (props: Props) => {
  const { children, isOpen, onClose, header, disableDrag = false } = props;
  return (
    <Sheet isOpen={isOpen} onClose={onClose} disableDrag={disableDrag}>
      <Sheet.Container>
        <Sheet.Header disableDrag={disableDrag}>{header}</Sheet.Header>
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};

export default BottomModalSheet;
