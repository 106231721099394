import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useEffect, useState } from 'react';
import { useApi } from '../acfs-apis/dwar-api-provider';
import Icon from './Icon';

interface Props {
  imageId: string | number;
  imageType: string;
}

function Image(props: Props) {
  const api = useApi();

  const [imageURL, setImageURL] = useState<string | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    setImageURL(null);
    setError(false);
    (async () => {
      if (props.imageId && props.imageType) {
        try {
          const imageURLResult = await api.fetchImage(
            props.imageId,
            props.imageType
          );
          setImageURL(imageURLResult);
        } catch (error) {
          setError(true);
        }
      }
    })();
  }, [api, props.imageId, props.imageType]);

  return (
    <div className="h-64 bg-black text-center">
      {!error && imageURL === null && (
        <Icon
          className="pt-12"
          is={ImageOutlinedIcon}
          size="10rem"
          colorClass="text-iron"
        />
      )}
      {!error && imageURL !== null && (
        <img
          className="inline-block h-64 w-full object-contain"
          src={imageURL}
          alt=""
        />
      )}
      {error && (
        <Icon
          className="pt-12"
          is={BrokenImageOutlinedIcon}
          size="10rem"
          colorClass="text-iron"
        />
      )}
    </div>
  );
}

export default Image;
