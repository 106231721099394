import { CallToActionType } from './Enumerations';
export interface ICallToActionSettings {
  endpointId: string;
  taskNumber: string;
  callToActionType: CallToActionType;
  value: string;
  id: string;
}

export class CallToActionSettings {
  endpointId: string;
  taskNumber: string;
  callToActionType: CallToActionType;
  value: string;
  id: string;

  constructor(
    endpointId: string,
    taskNumber: string,
    callToActionType: CallToActionType,
    value: string,
    id: string
  ) {
    this.endpointId = endpointId;
    this.taskNumber = taskNumber;
    this.callToActionType = callToActionType;
    this.value = value;

    this.id = id;
  }
}

export default CallToActionSettings;
