import { useNavigate } from 'react-router-dom';

const useNavigateToProduct = () => {
  const navigate = useNavigate();
  return (id: string) => {
   navigate(`/products/${id}`);
  };
};

export default useNavigateToProduct;
