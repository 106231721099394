import { useState } from 'react';
import { modifiedTimeStamps } from '../../Models/TimeLineEvent';
import GenericCollapsible from '../../components/collapsible/GenericCollapsible';
import { TimeLineEventType } from '../../Models/Enumerations';

interface Props {
  timeStamps: modifiedTimeStamps[];
  type: string;
}

const TimelineCollapsibleManager = (props: Props) => {
  const { timeStamps, type } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const onExpand = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();

    setIsExpanded(true);
  };
  const onCollapse = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();

    setIsExpanded(false);
  };

  return (
    <>
      {TimeLineEventType[TimeLineEventType.EFC] === type ||
      TimeLineEventType[TimeLineEventType.ServiceInsight] === type ? (
        <>
          {timeStamps.slice(0, 3).map((stamp, i) => (
            <div className=" font-bold leading-none text-steel" key={i}>
              {stamp.value}
              <span className="ml-1 font-normal text-black">
                {stamp?.status}
              </span>
            </div>
          ))}
          {timeStamps.length > 3 && (
            <GenericCollapsible
              expanded={isExpanded}
              onExpand={onExpand}
              onCollapse={onCollapse}
            >
              {timeStamps.slice(3).map((stamp, i) => (
                <div className=" font-bold leading-none text-steel" key={i}>
                  {stamp.value}
                  <span className="ml-1 font-normal text-black">
                    {stamp?.status}
                  </span>
                </div>
              ))}
            </GenericCollapsible>
          )}
        </>
      ) : (
        timeStamps.map((stamp, i) => {
          return (
            <div className=" font-bold leading-none text-steel" key={i}>
              {stamp.value}
              <span className="ml-1 font-normal text-black">
                {stamp?.status}
              </span>
              {(stamp?.count ?? 0) > 1 && (
                <span className="my-[1px] ml-1 inline-block rounded-sm bg-cobalt px-1.5 py-1 text-white">
                  x{stamp?.count}
                </span>
              )}
            </div>
          );
        })
      )}
    </>
  );
};

export default TimelineCollapsibleManager;
