export interface IPart {
  MaterialId: string;
  ManufactureNumber: string;
  PartName: string;
  ReplacedBy: string[];
  Replaces: string[];
  StockReplacedBy: string[];
  StockReplaces: string[];
  SparePic: string;
  LastUpdate: string;
  LastAction: number;
  ChildIds: string[];
  ParentIds: string[];
  IsDsaPartMissing: boolean;
  HasRepairActions: boolean;
}

export class Part implements IPart {
  MaterialId: string;
  ManufactureNumber: string;
  PartName: string;
  ReplacedBy: string[];
  Replaces: string[];
  StockReplacedBy: string[];
  StockReplaces: string[];
  SparePic: string;
  LastUpdate: string;
  LastAction: number;
  ParentIds: string[];
  ChildIds: string[];
  IsDsaPartMissing: boolean;
  HasRepairActions: boolean;
  constructor(
    materialId: string,
    manufactureNumber: string,
    partName: string,
    replacedBy: string[],
    replaces: string[],
    stockReplacedBy: string[],
    stockReplaces: string[],
    sparePic: string,
    lastUpdate: string,
    lastAction: number,
    parentIds: string[],
    childIds: string[],
    isDsaPartMissing: boolean,
    hasRepairActions: boolean
  ) {
    this.MaterialId = materialId;
    this.ManufactureNumber = manufactureNumber;
    this.PartName = partName;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
    this.ReplacedBy = replacedBy;
    this.Replaces = replaces;
    this.StockReplacedBy = stockReplacedBy;
    this.StockReplaces = stockReplaces;
    this.SparePic = sparePic;
    this.ParentIds = parentIds;
    this.ChildIds = childIds;
    this.IsDsaPartMissing = isDsaPartMissing;
    this.HasRepairActions = hasRepairActions;
  }

  GetHeading(): string {
    return `${this.MaterialId}`;
  }
}

export default Part;
