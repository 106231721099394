import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { PropsWithChildren, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { ToastType } from './ToastProvider';

interface Props {
  onHidden: () => void;
  type: ToastType;
}

function Toast(props: PropsWithChildren<Props>) {
  const [show, setShow] = useState(true);

  const hideTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const onEntered = () => {
    hideTimeout.current = setTimeout(() => setShow(false), 5000);
  };

  const hideManually = () => {
    hideTimeout.current && clearTimeout(hideTimeout.current);
    setShow(false);
  };

  const swipeProps = useSwipeable({
    onSwipedDown: () => setShow(false),
    trackMouse: true,
  });

  return (
    <CSSTransition
      in={show}
      appear
      timeout={300}
      onEntered={onEntered}
      onExited={props.onHidden}
    >
      <div className="toast">
        <div
          className="flex touch-none select-none items-center gap-x-4 rounded-lg bg-iron p-1.5 pl-4 shadow-common"
          {...swipeProps}
        >
          {props.type === 'success' && (
            <Icon is={CheckCircleOutlinedIcon} colorClass="text-lime" />
          )}

          {props.type === 'error' && (
            <Icon is={CancelOutlinedIcon} colorClass="text-nameless-red" />
          )}
          {props.type === 'info' && (
            <Icon is={InfoOutlinedIcon} colorClass="text-nameless-red" />
          )}

          <span className="flex-1 py-2.5 font-medium text-white">
            {props.children}
          </span>

          <IconButton
            className=""
            onClick={hideManually}
            icon={CloseOutlinedIcon}
            iconColorClass="text-white"
          />
        </div>
      </div>
    </CSSTransition>
  );
}

export default Toast;
