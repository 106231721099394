import { RootState } from './store';

export const selectActiveSearch = (state: RootState) => {
  return state.search.activeSearch;
};

export const selectSavedSearches = (state: RootState) => {
  return state.search.savedSearches;
};

export const selectRecentSearches = (state: RootState) => {
  return state.search.recentSearches;
};
