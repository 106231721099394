import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import { Search, TIcon } from '../types';
import EFC from './EFC';
import { IDType } from './Enumerations';
import { Part } from './Part';
import Product from './Product';
import RepairAction from './RepairAction';

export interface IGenericModel {
  IdGeneric: string;
  Id: number;
  Heading: string;
  IdType: number;
  Description?: string;
  Icon: TIcon;
  SearchItem?: Search;
  Context?: string;
  SubTitle?: string;
}

export type TGenericModelDataFactory = (
  model: any,
  ownId: number,
  context?: string
) => GenericModelData;

class GenericModelData implements IGenericModel {
  IdGeneric: string;
  Id: number;
  Heading: string;
  IdType: number;
  Description?: string;
  Icon: TIcon;
  SearchItem?: Search;
  timestamp?: number;
  Context?: string;
  SubTitle?: string;

  constructor(
    idGeneric: string,
    id: number,
    heading: string,
    idType: number,
    icon: TIcon,
    description?: string,
    searchItem?: Search,
    timestamp?: number,
    context?: string,
    subTitle?: string,
  ) {
    this.IdGeneric = idGeneric;
    this.Id = id;
    this.Heading = heading;
    this.IdType = idType;
    this.Description = description;
    this.Icon = icon;
    this.SearchItem = searchItem;
    this.timestamp = timestamp;
    this.Context = context;
    this.SubTitle = subTitle;
  }

  static CreateFromPart(part: Part, ownId: number): GenericModelData {    
    return (
      new GenericModelData(
      part.MaterialId,
      ownId,
      part.GetHeading(),
      IDType.PARTS,
      ConstructionOutlinedIcon,
      part.PartName,
      undefined,
      undefined,
      undefined,
      part.ManufactureNumber
    ))
  }

  static CreateFromProduct(product: Product, ownId: number): GenericModelData {
    return new GenericModelData(
      product.ProductId,
      ownId,
      product.GetHeading(),
      IDType.PRODUCTS,
      ProductionQuantityLimitsOutlinedIcon,
      product.Description
    );
  }

  static CreateFromEFC(efc: EFC, ownId: number): GenericModelData {
    return new GenericModelData(
      efc.Id.toString(),
      ownId,
      efc.EfcCodeStr,
      IDType.EFC,
      TerminalOutlinedIcon,
      efc.ProbableCause
    );
  }

  static CreateFromRepairAction(
    repairAction: RepairAction,
    ownId: number,
    context?: string
  ): GenericModelData {
    return new GenericModelData(
      repairAction.Id.toString(),
      ownId,
      repairAction.GetHeading(),
      IDType.REPAIR_ACTION,
      BuildCircleOutlinedIcon,
      repairAction.Description,
      {} as Search,
      0,
      context ?? ''
    );
  }

  static CreateFromSearchItem(searchItem: Search): GenericModelData {
    return new GenericModelData(
      Math.random().toString(),
      Math.random(),
      searchItem.searchTerm,
      IDType.SEARCH,
      SearchOutlinedIcon,
      '',
      searchItem,
      searchItem.timestamp
    );
  }
}

export default GenericModelData;
