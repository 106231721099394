import Icon from '../../components/Icon';
import { TIcon } from '../../types';

interface Props {
  bgColor: string;
  cardText: string;
  icon: TIcon;
}

function SwipeActionCard({ bgColor, cardText, icon }: Props) {
  const classNames = 'px-3 mt-1 ' + bgColor;
  return (
    <div className={classNames}>
      <div className="mt-4 w-[3.625rem] break-words text-center tracking-wide cursor-pointer">
        <Icon is={icon} colorClass="text-white" />
        <div className="mt-3 text-white">{cardText}</div>
      </div>
    </div>
  );
}
export default SwipeActionCard;
