import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectActiveSearch,
  selectRecentSearches,
  selectSavedSearches,
} from '../../../app/selectors';
import ActionableListItem from '../../../components/ActionableListItem';
import NoItems from '../../../components/NoItems';
import { searchActions } from '../../../features/search/searchSlice';
import useNavigateToEfc from '../../../hooks/useNavigateToEfc';
import useNavigateToProduct from '../../../hooks/useNavigateToProduct';
import useNavigateToSearchResults from '../../../hooks/useNavigateToSearchResults';
import db from '../../../index-db';
import { usePage } from '../../../PageProvider';
import { Search } from '../../../types';
import filtersToString from './filtersToString';
import useMatchingSearches from './useMatchingSearches';
import usePredictiveSuggestions from './usePredictiveSuggestions';
import { SHOULD_SINGLE_SEARCH_BE_PERFORMED } from '../../../constants';
import useNavigateToPart from '../../../hooks/useNavigateToPart';
import CenterFocusStrongOutlinedIcon from '@mui/icons-material/CenterFocusStrongOutlined';
import SearchQr from '../../../components/feature/SearchQR';
import useCheckDeviceType from '../../../hooks/useCheckDeviceType';
import useNotYetImplemented from '../../../hooks/useNotYetImplemented';

const Query = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const activeSearch = useAppSelector(selectActiveSearch);
  const searchTerm = activeSearch?.searchTerm ?? '';
  const allSavedSearches = useAppSelector(selectSavedSearches);
  const allRecentSearches = useAppSelector(selectRecentSearches);

  useEffect(() => {
    localStorage.setItem(SHOULD_SINGLE_SEARCH_BE_PERFORMED, 'true');

    dispatch(searchActions.removeFilter('Module'));
    dispatch(searchActions.clearSearchTerm());
  }, []);
  const [isScanning, setIsScanning] = useState(false);
  const deviceType = useCheckDeviceType();
  const notYetImplemented = useNotYetImplemented();
  const onQrClick = () => {
    if (deviceType !== 'iOS') {
      setIsScanning((state) => !state);
    } else {
      notYetImplemented();
    }
  };
  usePage(
    () => ({
      showBackButton: true,
      belongsToNavBarItem: 'Search',
      rightButtons: [
        {
          icon: CenterFocusStrongOutlinedIcon,
          onClick: onQrClick,
        },
        {
          icon: FilterAltOutlinedIcon,
          onClick: () => navigate('/search/filters'),
        },
      ],
    }),
    []
  );

  useEffect(() => {
    if (!activeSearch || params?.everywhere) {
      dispatch(searchActions.setScope('everywhere'));
    }
  }, [activeSearch, dispatch]);

  const navigateToSearchResults = useNavigateToSearchResults();

  const applySearch = (search: Search) => {
    dispatch(searchActions.changeActiveSearch(search));
    navigateToSearchResults({ awaitUpdatedSearchTerm: true });
  };

  const applySuggestedSearchTerm = async (suggestion: string) => {
    dispatch(searchActions.resetWithSearchTerm(suggestion));
    navigateToSearchResults({ awaitUpdatedSearchTerm: true });
  };
  const navigateToEfc = useNavigateToEfc();
  const navigateToProduct = useNavigateToProduct();
  const navigateToPart = useNavigateToPart();
  const handleSuggestedSearchTermClick = async (suggestion: string) => {
    if (activeSearch?.scope === 'efc codes') {
      const efc = await db.efcs
        .where('EfcCodeStr')
        .equalsIgnoreCase(suggestion)
        .first();
      if (efc) {
        navigateToEfc(efc.Id.toString());
      }
    } else if (activeSearch?.scope === 'products') {
      const product = await db.products
        .where('Description')
        .equals(suggestion)
        .first();
      if (product) {
        navigateToProduct(product.ProductId);
      }
    } else if (activeSearch?.scope === 'parts') {
      const partId = await db.parts
        .where('MaterialId')
        .equals(suggestion)
        .first();
      const partIdFromMFnumber = await db.parts
        .where('ManufactureNumber')
        .equals(suggestion)
        .first();

      const materialId = partId?.MaterialId || partIdFromMFnumber?.MaterialId;

      if (materialId) {
        navigateToPart(materialId);
      }
    } else {
      applySuggestedSearchTerm(suggestion);
    }
  };

  const getRandomKey = () => {
    // TODO: Using a random key is inefficient. Use a meaningful key instead for the saved searches and recent searches
    return Math.random();
  };

  const savedSearches = useMatchingSearches(allSavedSearches);
  const recentSearches = useMatchingSearches(allRecentSearches);
  const [predictiveSuggestions, loadingPredictiveSuggestions] =
    usePredictiveSuggestions({
      'efc codes': [db.efcs, ['EfcCodeStr']],
      'parts': [db.parts, ['MaterialId', 'ManufactureNumber']],
      'products': [db.products, ['Description']],
      'repairs': [db.raactions, ['PartNumbers']],
    });

  const totalSuggestionsCount =
    savedSearches.length + recentSearches.length + predictiveSuggestions.length;

  const noItemsMessage = 'You have no recent search history';

  return (
    <div className="relative">
      <SearchQr isScanning={isScanning} setIsScanning={setIsScanning} />
      {totalSuggestionsCount === 0 ? (
        <NoItems icon={SearchOutlinedIcon} message={noItemsMessage} />
      ) : (
        <>
          {[...savedSearches].reverse().map((search) => (
            <ActionableListItem
              key={getRandomKey()}
              icon={BookmarkIcon}
              onClickIcon={() => dispatch(searchActions.unsaveSearch(search))}
              title={search.searchTerm}
              highlightedTitlePart={searchTerm}
              subText={filtersToString(search.filters)}
              onClick={() => applySearch(search)}
              primaryAction="applySearchTerm"
            />
          ))}

          {[...recentSearches].reverse().map((search) => (
            <ActionableListItem
              key={getRandomKey()}
              icon={CloseOutlinedIcon}
              onClickIcon={() =>
                dispatch(searchActions.deleteRecentSearch(search))
              }
              title={search.searchTerm}
              highlightedTitlePart={searchTerm}
              subText={filtersToString(search.filters)}
              onClick={() => applySearch(search)}
              primaryAction="applySearchTerm"
            />
          ))}

          {predictiveSuggestions.map((suggestion) => (
            <ActionableListItem
              key={suggestion}
              title={suggestion}
              highlightedTitlePart={searchTerm}
              onClick={() => handleSuggestedSearchTermClick(suggestion)}
              primaryAction={
                activeSearch?.scope === 'products'
                  ? 'navigate'
                  : 'applySearchTerm'
              }
            />
          ))}

          {loadingPredictiveSuggestions && (
            <div className="absolute inset-0 bg-white opacity-40" />
          )}
        </>
      )}
    </div>
  );
};

export default Query;
