import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-swipeable-list/dist/styles.css';
import CardPrimary from '../../components/cards/CardPrimary';

interface Props {
  handleClick: () => void;
}

function CustomRepairActionCard({ handleClick }: Props) {
  const { t } = useTranslation();

  const root = useRef<HTMLDivElement>(null);

  return (
    <CardPrimary
      ref={root}
      onCardClick={handleClick}
      title={t('Custom Repair Performed')}
      description={t(
        'Select this repair action template to specify the action completed'
      )}
    />
  );
}
export default CustomRepairActionCard;
