import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectActiveSearch } from '../../../app/selectors';
import Chip from '../../../components/Chip';
import SectionHeading from '../../../components/SectionHeading';
import Tabs from '../../../components/tabs/Tabs';
import { searchActions } from '../../../features/search/searchSlice';
import { IDType } from '../../../Models/Enumerations';
import { usePage } from '../../../PageProvider';
import { SearchFilterKey, SearchScope, TabButton } from '../../../types';
import useSaveSearchButton from '../Filters/useSaveSearchButton';
import useUpdateSearchInCollection from '../Filters/useUpdateSearchInCollection';
import { getScopeTitle } from '../searchUtils';
import EFCResults from './EFCResults';
import PartResults from './PartResults';
import ProductResults from './ProductResults';
import { ResultsTabButton } from './types';
import useResultsCount from './useResultsCount';
import useCheckUserIsPartner from '../../../hooks/useCheckUserIsPartner';
import RAResults from './RAResults';
import { useToast } from '../../../components/toast/ToastProvider';
import { useDebouncedCallback } from 'use-debounce';

function Results() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activeSearch = useAppSelector(selectActiveSearch);

  const filters = activeSearch?.filters ?? {};

  const saveSearchButton = useSaveSearchButton();

  const params = useParams();

  const isPartner = useCheckUserIsPartner();
  const [activeTab, setActiveTab] = useState({ title: t('All'), key: 'all' });
  const {
    totalResultsCount,
    categoryWiseCount,
    areAllResultsLoaded,
    handleEFCResultsLoaded,
    handlePartResultsLoaded,
    handleProductResultsLoaded,
    handleRepairsResultsLoaded,
  } = useResultsCount();

  usePage(
    () => ({
      showBackButton: true,
      belongsToNavBarItem: 'Search',
      getTitle: () =>
        t(`Results ({{resultsCount}})`, { resultsCount: totalResultsCount }),
      rightButtons: [
        saveSearchButton,
        {
          icon: FilterAltOutlinedIcon,
          onClick: () => navigate('/search/filters'),
        },
      ],
    }),
    [saveSearchButton, t, totalResultsCount]
  );

  useUpdateSearchInCollection('recentSearches');
  const tabs: ResultsTabButton[] = useMemo(
    () =>
      isPartner == true
        ? [
            { title: t('All'), key: 'all' },
            {
              title: `${t('EFC')}(${categoryWiseCount.get(IDType.EFC)})`,
              key: 'efc codes',
            },
            {
              title: `${t('Repairs')}(${categoryWiseCount.get(
                IDType.REPAIR_ACTION
              )})`,
              key: 'repairs',
            },
          ]
        : [
            { title: t('All'), key: 'all' },
            {
              title: `${t('EFC')}(${categoryWiseCount.get(IDType.EFC)})`,
              key: 'efc codes',
            },
            {
              title: `${t('Parts')}(${categoryWiseCount.get(IDType.PARTS)})`,
              key: 'parts',
            },
            {
              title: `${t('Products')}(${categoryWiseCount.get(
                IDType.PRODUCTS
              )})`,
              key: 'products',
            },
            {
              title: `${t('Repairs')}(${categoryWiseCount.get(
                IDType.REPAIR_ACTION
              )})`,
              key: 'repairs',
            },
          ],
    [categoryWiseCount, isPartner, t]
  );

  useEffect(() => {
    const foundTab =
      tabs.find((tab) => tab.key === params?.subActiveTab) ?? tabs[0];

    setActiveTab(foundTab);
  }, [params.activeTab, params?.subActiveTab, tabs]);

  const onRaIconClick = (activeTab: string, searchTerm: string) => {
    navigate(`all/repairs/${searchTerm}`);
  };

  const renderFilterChips = () => {
    if (!activeSearch) {
      return;
    }

    let chipsToRender: React.ReactNode[] = [];

    if (activeSearch.scope !== 'everywhere') {
      chipsToRender.push(
        <Chip key="scope" label={getScopeTitle(activeSearch.scope)} />
      );
    }

    if (activeSearch.searchTerm !== '') {
      chipsToRender.push(
        <Chip
          key="searchTerm"
          label={`"${activeSearch.searchTerm}"`}
          onClickCross={() => dispatch(searchActions.clearSearchTerm())}
        />
      );
    }

    chipsToRender = [
      ...chipsToRender,
      ...Object.entries(filters).map(([filterKey, filter]) => (
        <Chip
          key={filterKey}
          label={filter.label}
          onClickCross={() =>
            dispatch(searchActions.removeFilter(filterKey as SearchFilterKey))
          }
        />
      )),
    ];

    if (chipsToRender.length === 0) {
      return;
    }

    return (
      <div className="relative">
        <div className="flex h-14 items-center gap-x-1 overflow-x-auto bg-white px-4">
          {chipsToRender}
        </div>
        <div className="absolute-shadow below" />
      </div>
    );
  };

  const renderEfcResults =
    (activeSearch?.scope === 'everywhere' &&
      (activeTab.key === 'all' || activeTab.key === 'efc codes')) ||
    activeSearch?.scope === 'efc codes';

  const renderPartResults =
    (activeSearch?.scope === 'everywhere' &&
      (activeTab.key === 'all' || activeTab.key === 'parts')) ||
    activeSearch?.scope === 'parts';

  const renderProductResults =
    (activeSearch?.scope === 'everywhere' &&
      (activeTab.key === 'all' || activeTab.key === 'products')) ||
    activeSearch?.scope === 'products';

  const renderRepairResults =
    (activeSearch?.scope === 'everywhere' &&
      (activeTab.key === 'all' || activeTab.key === 'repairs')) ||
    activeSearch?.scope === 'repairs';

  const prependSectionHeading = (
    title: string,
    shouldShowHeading: boolean,
    children: React.ReactNode
  ) => {
    return (
      <>
        {activeTab.key === 'all' && !isPartner && shouldShowHeading ? (
          <SectionHeading title={title} />
        ) : (
          <div className="mb-4" />
        )}

        {children}
      </>
    );
  };

  const isFullSearchingComplete = Object.values(areAllResultsLoaded).every(
    (result) => result
  );
  const { pushToast } = useToast();
  const shouldEFCSearch =
    (activeSearch?.scope === 'efc codes' ||
      activeSearch?.scope === 'everywhere') &&
    filters?.Module?.value;
  const debouncedToast = useDebouncedCallback(() => {
    if (
      (activeSearch?.searchTerm?.trim()?.length ?? 0) < 1 &&
      !shouldEFCSearch
    ) {
      pushToast({
        message: t(`1 or more Characters are required for the search`),
        type: 'info',
      });
    }
  }, 1000);
  useEffect(() => {
    debouncedToast();
  }, [activeSearch?.searchTerm]);
  const onTabChange = (tab: TabButton) => {
    navigate(`all/${tab.key}/${activeSearch?.searchTerm}`);
  };
  useEffect(() => {
    if (params?.activeTab && params?.subActiveTab) {
      dispatch(
        searchActions.setScope(
          params?.activeTab === 'all'
            ? 'everywhere'
            : (params?.subActiveTab as SearchScope)
        )
      );
    }
    if (params?.searchTerm) {
      dispatch(searchActions.changeSearchTerm(params?.searchTerm ?? ''));
    }
  }, [dispatch, params?.activeTab, params?.searchTerm, params?.subActiveTab]);
  return (
    <>
      {renderFilterChips()}
      {activeSearch?.scope === 'everywhere' && (
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onChangeActiveTab={onTabChange}
        />
      )}

      {prependSectionHeading(
        t('EFC codes'),
        renderEfcResults,
        <EFCResults
          renderEfcResults={renderEfcResults}
          handleEFCResultsLoaded={handleEFCResultsLoaded}
          totalResultsCount={totalResultsCount}
          areAllResultsLoaded={isFullSearchingComplete}
        />
      )}

      {prependSectionHeading(
        t('Parts'),
        renderPartResults,
        <PartResults
          renderPartResults={renderPartResults && !isPartner}
          handlePartResultsLoaded={handlePartResultsLoaded}
          onRaIconClick={onRaIconClick}
          totalResultsCount={totalResultsCount}
          areAllResultsLoaded={isFullSearchingComplete}
        />
      )}

      {prependSectionHeading(
        t('Products'),
        renderProductResults,
        <ProductResults
          renderProductResults={!isPartner && renderProductResults}
          handleProductResultsLoaded={handleProductResultsLoaded}
          totalResultsCount={totalResultsCount}
          areAllResultsLoaded={isFullSearchingComplete}
        />
      )}
      {prependSectionHeading(
        t('Repair Action'),
        renderRepairResults,
        <RAResults
          renderRepairResults={renderRepairResults}
          handleRepairsResultsLoaded={handleRepairsResultsLoaded}
          totalResultsCount={totalResultsCount}
          areAllResultsLoaded={isFullSearchingComplete}
        />
      )}
    </>
  );
}

export default Results;
