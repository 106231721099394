import React from 'react';
import ActionableListItem from './ActionableListItem';

interface Props {
  title: string;
  subText?: string;
  selected: boolean;
  onSelect: () => void;
}

const SelectableListItem = (props: Props) => {
  const select = () => {
    if (!props.selected) {
      props.onSelect();
    }
  };

  return (
    <ActionableListItem
      title={props.title}
      appearance={['nonBold', 'cobaltTrailingIcon']}
      subText={props.subText}
      primaryAction="select"
      hidePrimaryActionIcon={!props.selected}
      onClick={select}
    />
  );
};

export default SelectableListItem;
