import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useCollapsibleCollection } from './CollapsibleCollection';
import CollapsibleHeading from './CollapsibleHeading';

interface RequiredProps {
  headingTitle: string;
}

interface OptionalProps {
  headingSubText?: string;
  onClear?: () => void;
  startCollapsed?: boolean;
  childLevel: number,
}

type Props = RequiredProps & OptionalProps;

const defaultProps: OptionalProps = {
  headingSubText: undefined,
  onClear: undefined,
  childLevel: 0
};

function Collapsible(props: PropsWithChildren<Props>) {
  const [collapsed, setCollapsed] = useState(props.startCollapsed ?? false);
  const collapse = useCollapsibleCollection();

  const ignoreNextCollectionUpdate = useRef(
    typeof props.startCollapsed === 'boolean'
  );
  useEffect(() => {
    if (ignoreNextCollectionUpdate.current) {
      ignoreNextCollectionUpdate.current = false;
      return;
    }
    if (!collapse) {
      return;
    }
    setCollapsed(collapse.collapsed);
  }, [collapse]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <CollapsibleHeading
        title={props.headingTitle}
        subText={props.headingSubText}
        collapsed={collapsed}
        onToggle={toggle}
        onClear={props.onClear ?? undefined}
        childLevel = {props.childLevel}
      />
      {!collapsed && props.children}
    </>
  );
}

Collapsible.defaultProps = defaultProps;

export default Collapsible;
