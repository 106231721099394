import { useTranslation } from 'react-i18next';
import SectionHeading from '../../../components/SectionHeading';
import SystemsCard from '../../../components/cards/SystemsCard';
import { useState } from 'react';
import moment from 'moment';
import EndPoint from '../../../Models/EndPoint';
import LogParams from '../../../Models/LogParams';
import { LogType } from '../../../enums/LogType';
import useLogging from '../../../hooks/useLogging';
import GenericModalSheet from '../../../components/GenericModalSheet';
import { CallToActionType } from '../../../Models/Enumerations';
import useHandleCtaRead from '../../../hooks/useHandleCtaRead';

interface Props {
  equipmentData: EndPoint;
  isRead: boolean;
  taskNumber?: string;
}

function converByteToMB(bytes: string) {
  if (bytes) {
    const megaBytes = parseInt(bytes) / (1024 * 1024);
    return parseFloat(megaBytes.toFixed(1));
  } else {
    return 0;
  }
}

const Observations = (props: Props) => {
  const { t } = useTranslation();
  const logging = useLogging();
  const [isBottomsheetOpen, setIsBottomsheetOpen] = useState(false);

  const { equipmentData, isRead, taskNumber } = props;
  const [isObservationRead, saveCallToActionSetting] = useHandleCtaRead(isRead);

  const onCardClick = () => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Observations,
      },
      {
        key: LogType.EndpointId,
        value: equipmentData.id,
      },
      {
        key: LogType.SerialNumber,
        value: equipmentData.serialNumber || '',
      },
    ];
    logging('info', '', '', false, logParams);

    if (equipmentData.id && isObservationRead === 'primary') {
      saveCallToActionSetting(
        CallToActionType.Observation,
        equipmentData.id,
        'NA',
        taskNumber
      );
    }
    setIsBottomsheetOpen(true);
  };
  const onSheetClose = () => {
    setIsBottomsheetOpen(false);
  };

  const data = [
    {
      title: t('Chronic / Worst Performer Details'),
      value: equipmentData?.chronicDetails,
    },
    {
      title: t('Triage Notes'),
      value: (equipmentData?.naTriageNotes ||
        equipmentData?.wpTrackTriageNotes) && (
        <div>
          {equipmentData?.wpTrackTriageNotes && (
            <p>- {equipmentData?.wpTrackTriageNotes}</p>
          )}
          {equipmentData?.naTriageNotes && (
            <p>- {equipmentData?.naTriageNotes}</p>
          )}
        </div>
      ),
    },
    {
      title: equipmentData.endpointStatistics
        ? t('Connections')
        : t('Non-Connected'),

      value: equipmentData.endpointStatistics ? (
        <>
          <div>ACDE</div>
          <div id="main" className="ml-3 flex flex-col">
            <div>
              Last Data:{' '}
              {moment(equipmentData.endpointStatistics.lastDataReceived)
                .format('HH:mm ddd MMM DD YYYY')
                .toLocaleString()}
            </div>
            <div>
              First Data:{' '}
              {moment(equipmentData.endpointStatistics.firstDataReceived)
                .format('HH:mm ddd MMM DD YYYY')
                .toLocaleString()}
            </div>
            <div>Data Origin: {equipmentData.endpointStatistics.origin}</div>
            <div>Transport: {equipmentData.endpointStatistics.transport}</div>
            <div>
              Avg Data Size:{' '}
              {converByteToMB(equipmentData.endpointStatistics.averageSize)}{' '}
              mb/day
            </div>
            <div>
              Harvester Version:{' '}
              {equipmentData.endpointStatistics.harvesterFileVersion}
            </div>
            <div>
              {equipmentData.endpointStatistics.managedService?.toLowerCase() ===
              'yes' ? (
                'Managed Services: True'
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        'N/A'
      ),
    },

    {
      title: equipmentData?.duplicate ? (
        <p className="text-dnred">{t('Duplicate Endpoint')}</p>
      ) : (
        <></>
      ),
      key: 'Duplicate',
      value: equipmentData?.duplicate ? (
        <>
          <div>
            {t(
              'Duplicate endpoint has been identified. Historical EFCs may have come from multiple endpoints. Caution should be taken when reviewing the history of this endpoints. Validate that Harvester is setup properly with the correct endpoint registration.'
            )}
          </div>
        </>
      ) : (
        ''
      ),
    },
    {
      title: equipmentData.endpointStatistics?.disconnected ? (
        <p className="text-dnred">{t('Disconnected')} </p>
      ) : (
        <></>
      ),
      key: 'Disconnected',
      value: equipmentData.endpointStatistics?.disconnected ? (
        <>
          {t(
            'A disconnected system was previously connected and sending data to ACDE. However, data transmission has now stopped, exceeding the expected timeframe for this connection. Please refer to the ‘Last Data’ field to determine when data was last received.'
          )}
        </>
      ) : (
        ''
      ),
    },
  ];

  if (
    !equipmentData?.endpointStatistics &&
    !equipmentData?.chronicDetails &&
    !equipmentData?.naTriageNotes &&
    !equipmentData?.wpTrackTriageNotes &&
    !equipmentData?.duplicate
  ) {
    return (
      <div>
        <div className="mx-4 mb-4">
          <div className=" mb-2 mt-2">
            <SectionHeading title={t('Observations')} withMargins={false} />
          </div>
          <SystemsCard iconColor="steel">
            <p key="Non-Connected">{t('Non-Connected')}</p>
          </SystemsCard>
        </div>
      </div>
    );
  }
  const className = 'text-tangerine font-bold';

  return (
    <div>
      <div className="mx-4 mb-4">
        <div className=" mb-2 mt-2">
          <SectionHeading title={t('Observations')} withMargins={false} />
        </div>
        <SystemsCard
          onCardClick={onCardClick}
          iconColor="steel"
          clickableType="secondary"
          readUnreadIconType={isObservationRead}
        >
          {data.map(({ title, value, key }) => {
            if (value) {
              return (
                <p
                  className={
                    title === t('Duplicate Endpoint') && value ? className : ''
                  }
                  key={key ?? title}
                >
                  {title}
                </p>
              );
            }
            return null;
          })}
        </SystemsCard>
      </div>
      <GenericModalSheet
        isBottomsheetOpen={isBottomsheetOpen}
        onSheetClose={onSheetClose}
        data={data}
      />
    </div>
  );
};

export default Observations;
