import Notification from '../Models/Notifications';
import db from '../index-db';

const populateNotificationsIndexdb = async (
  notificationsData: Notification[]
) => {
  const langCode = localStorage.getItem('i18nextLng')?.split('-')?.[0] ?? 'en';
  if (notificationsData.length) {
    const notifications = await db.notifications
      .where('languageCode')
      .equals(langCode)
      .toArray();

    await db.transaction('rw', db.notifications, async () => {
      let notificationsToBeDeleted: string[] = [];
      notifications.forEach((notification) => {
        const shouldNotificationDelete = !notificationsData.some(
          (apiNotification) => apiNotification.id === notification.id
        );
        if (shouldNotificationDelete) {
          notificationsToBeDeleted.push(notification.id);
        }
      });
      if (notificationsToBeDeleted.length > 0) {
        await db.notifications.bulkDelete(notificationsToBeDeleted);
      }

      for (const newNotification of notificationsData) {
        const existingData = await db.notifications.get(newNotification.id);
        if (existingData) {
          await db.notifications.update(newNotification.id, {
            ...newNotification,
          });
        } else {
          const lastActionTaken =
            new Date().valueOf() - 1000 * 60 * 60 * 24 * 2;
          await db.notifications.add({
            ...newNotification,
            isRead: 0,
            lastActionTaken: lastActionTaken,
          });
        }
      }
    });
  } else {
    if (langCode) {
      db.notifications
        .where('languageCode')
        .equals(langCode)
        .primaryKeys()
        .then((keys) => {
          db.notifications.bulkDelete(keys);
        });
    }
  }
};

export default populateNotificationsIndexdb;
