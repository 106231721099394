import { CircularProgress } from '@mui/material';

function Loading() {
  return (
    <div className="flex h-1/3 w-full items-center  justify-center">
      <CircularProgress />
    </div>
  );
}

export default Loading;
