import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import SectionHeading from '../../components/SectionHeading';
import {
  ADDITIONAL_USERID_OR_GROUPID,
  ARE_CANCELLED_TICKETS_ALLOWED,
  LANGUAGES,
} from '../../constants';
import { usePage } from '../../PageProvider';
import { Language } from '../../types';
import SettingRow from './SettingRow';

function Settings() {
  const { t, i18n } = useTranslation();
  const logging = useLogging();

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Settings,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);
  const RadioData = [
    { value: 'true', label: t('Allowed') },
    { value: 'false', label: t('Not Allowed') },
  ];
  usePage(
    () => ({
      getTitle: () => t('Settings'),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [t]
  );

  const activeLanguage: Language | null =
    LANGUAGES.find(({ code }) => code === i18n.resolvedLanguage) ?? null;
  const onChoiceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    localStorage.setItem(ARE_CANCELLED_TICKETS_ALLOWED, value);
  };

  const localUserIdOrGroup = localStorage.getItem(ADDITIONAL_USERID_OR_GROUPID);
  const [userIdOrGroup, setUserIdOrGroup] = useState(localUserIdOrGroup);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(ADDITIONAL_USERID_OR_GROUPID, event.target.value);
    setUserIdOrGroup(event.target.value);
  };

  const cancelledTasksAllowed = localStorage.getItem(
    ARE_CANCELLED_TICKETS_ALLOWED
  );
  useEffect(() => {
    if (!cancelledTasksAllowed) {
      localStorage.setItem(ARE_CANCELLED_TICKETS_ALLOWED, 'false');
    }
  }, [cancelledTasksAllowed]);
  const defaultChoice = cancelledTasksAllowed === 'true' ? 'true' : 'false';

  const radioButtonProps = {
    defaultChoice: defaultChoice,
    onChoiceChange: onChoiceChange,
    data: RadioData,
  };

  const inputTextProps = {
    defaultValue: userIdOrGroup,
    onChoiceChange: onInputChange,
    placeholder: t('GroupName or UserID'),
  };
  return (
    <>
      <SectionHeading title={t('Preferences')} />

      <SettingRow
        title={t('Language')}
        value={t(activeLanguage?.englishTitle ?? 'Unknown')}
        to="/settings/language"
      />
      <SettingRow
        title={t('Cancelled Tasks')}
        hideRightArrowIcon={true}
        radioButtons={radioButtonProps}
      />
      <SettingRow
        title={t('Open Task UserId')}
        hideRightArrowIcon={true}
        inputTextBox={inputTextProps}
      />
    </>
  );
}

export default Settings;
