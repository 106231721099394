import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TASKS_LATEST_FETCH_TIMESTAMP } from '../../constants';

export interface TasksState {
  latestFetchTimestamp: number;
}

const initialState: TasksState = {
  latestFetchTimestamp: Number(
    window.localStorage.getItem(TASKS_LATEST_FETCH_TIMESTAMP) ?? 0
  ),
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setLatestFetchTimestamp: (state, { payload }: PayloadAction<number>) => {
      state.latestFetchTimestamp = payload;

      // TODO: Running the side effect in the reducer is an anti-pattern. Use a better approach
      window.localStorage.setItem(
        TASKS_LATEST_FETCH_TIMESTAMP,
        payload.toString()
      );
    },
  },
});

export const tasksActions = tasksSlice.actions;

export default tasksSlice.reducer;
