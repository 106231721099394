import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useApi } from '../acfs-apis/dwar-api-provider';

interface RequiredProps {
  number: number;
  description: string;
}

interface OptionalProps {
  imageId?: string | number;
  imageType?: string;
  appearance?: 'regular' | 'setting' | 'selectable';
}

type Props = RequiredProps & OptionalProps;

const defaultProps: OptionalProps = {
  appearance: 'regular',
};

function NumberedListItem(props: Props) {
  const api = useApi();
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    (async () => {
      if (props.imageId && props.imageType) {
        const imageURLResult = await api.fetchImage(
          props.imageId,
          props.imageType
        );
        setImageURL(imageURLResult);
      }
    })();
  }, [api, props.imageId, props.imageType]);

  return (
    <div
      className={classnames('flex h-auto bg-white', {
        'border-b border-antartica': props.appearance !== 'setting',
        'mb-px': props.appearance === 'setting',
      })}
    >
      <div className="flex min-w-0 items-baseline gap-x-1.5 p-3">
        <div className="h-6 w-6 rounded-full border-2 border-dnblue text-center font-bold text-dnblue">
          {props.number}
        </div>

        <div className={classnames('min-w-0 flex-1')}>
          <p
            className={classnames('whitespace-pre-wrap break-words text-base', {
              'font-normal': props.appearance === 'regular',
            })}
          >
            {props.description}
          </p>
          {imageURL && <img className="pt-2" src={imageURL} alt="" />}
        </div>
      </div>
    </div>
  );
}

NumberedListItem.defaultProps = defaultProps;

export default NumberedListItem;
