import { useTranslation } from 'react-i18next';
import CopyAllOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from 'react-router-dom';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ActionableListItem from '../../components/ActionableListItem';
import Collapsible from '../../components/collapsible/Collapsible';
import { useToast } from '../../components/toast/ToastProvider';
import useClipBoardCopy from '../../hooks/useClipBoardCopy';
import useNavigateToPart from '../../hooks/useNavigateToPart';
import { searchActions } from '../../features/search/searchSlice';
import { useAppDispatch } from '../../app/hooks';

export interface IPartHeadingData {
  materialId: string;
  partName: string;
  heading: string;
  hasRepairActions: boolean;
}

interface Props {
  partsPageData: IPartHeadingData[];
  title: string;
  isCollapsed: boolean;
}

function Categories(props: Props) {
  const { t } = useTranslation();
  const { pushToast } = useToast();
  const navigateToPart = useNavigateToPart();
  const navigate = useNavigate();
  const clipboardCopy = useClipBoardCopy();
  const dispatch = useAppDispatch();
  const handleClick = (idGen: string) => {
    navigateToPart(idGen);
  };
  const onIconClick =
    (searchTerm: string) =>
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      dispatch(searchActions.changeSearchTerm(searchTerm));
      dispatch(searchActions.setScope('repairs'));
      navigate(`/search/results/repairs/repairs/${searchTerm}`);
    };

  return props?.partsPageData?.length ? (
    <Collapsible headingTitle={props.title} startCollapsed={props.isCollapsed}>
      {props.partsPageData.map((item) => (
        <ActionableListItem
          key={item.materialId}
          icon={CopyAllOutlinedIcon}
          title={item.materialId}
          subText={item.partName}
          primaryAction="navigate"
          enhancedCopying
          {...(item.hasRepairActions
            ? {
                intermediateIcon: BuildCircleOutlinedIcon,
                onClickIntermediateIcon: onIconClick(item.materialId),
              }
            : {})}
          onClick={() => {
            handleClick(item.materialId);
          }}
          onClickIcon={() => {
            clipboardCopy(item.materialId);
            pushToast({
              message: t(`Part Number {{partId}} copied to the clipboard`, {
                partId: item.materialId,
              }),
            });
          }}
        />
      ))}
    </Collapsible>
  ) : (
    <></>
  );
}

export default Categories;
