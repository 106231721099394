import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Icon from '../Icon';

interface Props {
  outerClassName?: string;
  innerClassName?: string;
}

const Flame = (props: Props) => {
  const { outerClassName, innerClassName } = props;

  return (
    <span
      className={`flex h-8 w-8 items-center justify-center rounded-full border border-tangerine bg-[#FFF9CC] ${outerClassName}`}
    >
      <Icon
        is={LocalFireDepartmentIcon}
        className={innerClassName}
        colorClass="text-tangerine"
      />
    </span>
  );
};

export default Flame;
