import { useTranslation } from 'react-i18next';
import db from '../index-db';
import { useLiveQuery } from 'dexie-react-hooks';

const useUnreadNotificationsCount = () => {
  const { i18n } = useTranslation();
  const langCode = i18n.language?.split('-')?.[0] ?? 'en';
  const unReadNotificationsCount = useLiveQuery(async () => {
    return db.notifications
      .where('isRead')
      .equals(0)
      .and((notification) => notification.languageCode === langCode)
      .count();
  }, [langCode]);

  return unReadNotificationsCount;
};

export default useUnreadNotificationsCount;
