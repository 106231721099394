import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Task from '../../Models/Task';
import SiteMessage from './SiteMessage';
import useCheckDeviceType from '../../hooks/useCheckDeviceType';
import EndPoint from '../../Models/EndPoint';
import EquipmentInfo from './EquipmentInfo';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';

interface Props {
  serviceTask: Task;
  equipmentData: EndPoint;
}

function Details({ serviceTask, equipmentData }: Props) {
  const { t } = useTranslation();
  const deviceType = useCheckDeviceType();
  const logging = useLogging();

  let effectiveDateTime = '';
  let contactDateTime = '';
  let phoneNumber = '';
  let address = '';
  let mapURL = '';
  let installDate = '';

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.EquipmentDetails,
      },
      {
        key: LogType.EndpointId,
        value: equipmentData.id,
      },
      {
        key: LogType.ServiceTaskId,
        value: serviceTask.id || '',
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  if (deviceType === 'iOS') {
    mapURL = 'https://maps.apple.com/maps?q=';
  } else {
    mapURL = 'https://maps.google.com/?q=';
  }

  if (serviceTask.contactPhone && serviceTask.contactPhone !== '') {
    phoneNumber = `tel:+${serviceTask.contactPhone}`;
  }

  if (serviceTask.addressLine1 && serviceTask.addressLine1 !== '') {
    address = address.concat(serviceTask.addressLine1, ', ');
  }

  if (serviceTask.addressLine2 && serviceTask.addressLine2 !== '') {
    address = address.concat(serviceTask.addressLine2, ', ');
  }

  if (serviceTask.city && serviceTask.city !== '') {
    address = address.concat(serviceTask.city, ', ');
  }

  if (serviceTask.state && serviceTask.state !== '') {
    address = address.concat(serviceTask.state, ', ');
  }

  if (serviceTask.postalCode && serviceTask.postalCode !== '') {
    address = address.concat(serviceTask.postalCode, ', ');
  }

  if (serviceTask.country && serviceTask.country !== '') {
    address = address.concat(serviceTask.country);
  }

  mapURL = mapURL.concat(address);

  if (serviceTask.effectiveDate && serviceTask.effectiveDate !== '') {
    const effdate = new Date(serviceTask.effectiveDate);
    const hours =
      effdate.getHours() < 10
        ? `0${effdate.getHours()}`
        : effdate.getHours().toString();
    const minutes =
      effdate.getMinutes() < 10
        ? `0${effdate.getMinutes()}`
        : effdate.getMinutes().toString();
    const seconds =
      effdate.getSeconds() < 10
        ? `0${effdate.getSeconds()}`
        : effdate.getSeconds().toString();

    effectiveDateTime = `${hours}:${minutes}:${seconds} ${effdate.toDateString()}`;
  }

  if (serviceTask.contactDate && serviceTask.contactDate !== '') {
    const conDate = new Date(serviceTask.contactDate);
    const conHours =
      conDate.getHours() < 10
        ? `0${conDate.getHours()}`
        : conDate.getHours().toString();
    const conMinutes =
      conDate.getMinutes() < 10
        ? `0${conDate.getMinutes()}`
        : conDate.getMinutes().toString();
    const conSeconds =
      conDate.getSeconds() < 10
        ? `0${conDate.getSeconds()}`
        : conDate.getSeconds().toString();

    contactDateTime = `${conHours}:${conMinutes}:${conSeconds} ${conDate.toDateString()}`;
  }

  if (equipmentData.installDate && equipmentData.installDate !== '') {
    const deviceInstallDate = new Date(equipmentData.installDate);
    const dateString = deviceInstallDate.toDateString();
    installDate = dateString.substring(dateString.indexOf(' ') + 1);
  }

  return (
    <>
      <div className="py-2">
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Number')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {serviceTask?.taskNumber}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Reporting System')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.dataSource)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Customer')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.customer)}
          </div>
        </div>
        <EquipmentInfo
          prodDesc={equipmentData?.prodDesc}
          model={equipmentData?.model}
          terminalId={equipmentData?.terminalId}
          endpointId={equipmentData?.id}
          serialNumber={equipmentData?.serialNumber}
        />
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Site Number')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.siteNumber)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Install Date')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(installDate)}
          </div>
        </div>
        <div className="mt-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Address')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            <a
              href={mapURL}
              target="_blank"
              className="text-cobalt underline"
              rel="noreferrer"
            >
              {t(serviceTask?.addressLine1)}
            </a>
          </div>
        </div>
        <div className="mt-1 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {' '}
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            <a
              href={mapURL}
              target="_blank"
              className="text-cobalt underline"
              rel="noreferrer"
            >
              {t(serviceTask?.addressLine2)}
            </a>
          </div>
        </div>
        <div className="mt-1 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {' '}
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {serviceTask?.city &&
              serviceTask?.state &&
              serviceTask?.postalCode && (
                <a
                  href={mapURL}
                  target="_blank"
                  className="text-cobalt underline"
                  rel="noreferrer"
                >
                  {t(
                    `${serviceTask?.city}, ${serviceTask?.state}, ${serviceTask?.postalCode}`
                  )}
                </a>
              )}
          </div>
        </div>
        <div className="mt-1 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {' '}
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            <a
              href={mapURL}
              target="_blank"
              className="text-cobalt underline"
              rel="noreferrer"
            >
              {t(serviceTask?.country)}
            </a>
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Contact Date')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(contactDateTime)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Effective Date')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(effectiveDateTime)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Contact Name')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.contactName)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Contact Email')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.contactEmail)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Contact Phone')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            <a href={phoneNumber} className="text-cobalt underline">
              {t(serviceTask?.contactPhone)}
            </a>
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Contract Hours')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {serviceTask?.contractCoverageHours}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Problem')}:
          </div>
          <div
            className={
              'w-1/2 overflow-auto text-left text-lg font-medium text-iron'
            }
          >
            {t(serviceTask?.problem)}
          </div>
        </div>
        {serviceTask.siteMessages &&
          serviceTask.siteMessages.length > 0 &&
          serviceTask.siteMessages.map((message, index) => (
            <SiteMessage
              key={index}
              siteMessage={message}
              title={index === 0 ? t('Site Messages:') : ' '}
              index={index}
            />
          ))}
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Type')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.deviceType)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Product')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.deviceCustomerProductId)}
          </div>
        </div>
        <div className="mt-4 mb-4 flex items-start justify-between px-4">
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t('Desc')}:
          </div>
          <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
            {t(serviceTask?.deviceDescription)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
