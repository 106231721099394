import SoftwareInventory from '../../../Models/SoftwareInventory';
import { useState, useEffect } from 'react';
import CustomTable from '../../../components/custom-table';

interface Props {
  softwares: SoftwareInventory[];
}

interface ISoftwareDetails {
  Software: string;
  Version: string;
  LastUpdate: string;
}

function Software({ softwares }: Props) {
  const [softwareInfo, setSoftwareInfo] = useState<ISoftwareDetails[]>([]);

  useEffect(() => {
    let formattedSoftwares: ISoftwareDetails[] = [];
    softwares.forEach((software) => {
      const lastUpdateDate = new Date(software.lastUpdate);
      const currentDate = new Date(Date.now());

      const diffInTime = currentDate.getTime() - lastUpdateDate.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

      const hours =
        lastUpdateDate.getHours() < 10
          ? `0${lastUpdateDate.getHours()}`
          : lastUpdateDate.getHours().toString();
      const minutes =
        lastUpdateDate.getMinutes() < 10
          ? `0${lastUpdateDate.getMinutes()}`
          : lastUpdateDate.getMinutes().toString();

      const lastUpdate = software.lastUpdate
        ? `${diffInDays} days ago ${hours}:${minutes} ${
            lastUpdateDate
              .toDateString()
              .split(lastUpdateDate.getFullYear().toString())[0]
          }`
        : '';

      formattedSoftwares.push({
        Software: software.name,
        Version: software.version,
        LastUpdate: lastUpdate,
      });
    });

    setSoftwareInfo(formattedSoftwares);
  }, [softwares]);

  const hookOptions = {
    enableColumnFilters: true,
    enableSorting: true,
    initialState: {
      showColumnFilters: true,
    },
  };

  return (
    <div className="mb-2">
      <CustomTable
        data={softwareInfo}
        columnOptions={{
          size: 109,
        }}
        hookOptions={hookOptions}
      />
    </div>
  );
}
export default Software;
