import React, { useState } from 'react';
import { IHeaderButton } from '../../PageProvider';
import InteractiveIcon from '../InteractiveIcon';
import PopoverMenu from '../PopoverMenu';

interface Props {
  button: IHeaderButton;
}

function HeaderButton(props: Props) {
  const [showPopoverMenu, setShowPopoverMenu] = useState(false);

  const handleClick = () => {
    if (props.button.popoverMenuButtons) {
      if (!showPopoverMenu) {
        setShowPopoverMenu(true);
      }
      return;
    }

    props.button.onClick?.();
  };

  return (
    <div>
      <InteractiveIcon
        icon={props.button.icon}
        iconColor={props.button.iconColor}
        onClick={handleClick}
      />

      {showPopoverMenu && (
        <PopoverMenu
          buttons={props.button.popoverMenuButtons!}
          onClickOutside={() => setShowPopoverMenu(false)}
        ></PopoverMenu>
      )}
    </div>
  );
}

export default HeaderButton;
