import { PropsWithChildren } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Collapse } from '@mui/material';
import IconButton from '../../components/IconButton';

interface Props {
  expanded: boolean;
  onExpand: (e: React.MouseEvent) => void;
  onCollapse: (e: React.MouseEvent) => void;
}
const GenericCollapsible = (props: PropsWithChildren<Props>) => {
  const { expanded, onExpand, onCollapse } = props;
  return (
    <>
      <div className="text-left">
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {props.children}
        </Collapse>
      </div>
      <div className="self-center">
        {!expanded ? (
          <IconButton icon={AddCircleOutlineOutlinedIcon} onClick={onExpand} />
        ) : (
          <IconButton
            icon={RemoveCircleOutlineOutlinedIcon}
            onClick={onCollapse}
          />
        )}
      </div>
    </>
  );
};

export default GenericCollapsible;
