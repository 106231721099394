import { useEffect } from 'react';

import useLogging from './useLogging';
import populateNotificationsIndexdb from '../indexdbQueries/populateNotificationsIndexdb';
import { notificationsFetchTime } from '../constants';

import Notification from '../Models/Notifications';
import { useApi } from '../acfs-apis/dwar-api-provider';

import { useTranslation } from 'react-i18next';

const useLoadNotifications = () => {
  const logging = useLogging();
  const api = useApi();
  const { i18n } = useTranslation();
  const langCode = i18n.language?.split('-')?.[0] ?? 'en';

  const fetchNotificationsData = async (): Promise<Notification[]> => {
    return (await api.fetchJson(
      '/dwar/api/acfs/Notification/getNotifications',
      {
        headers: { LanguageCode: langCode },
      }
    )) as Notification[];
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      let notificationsData: Notification[] | undefined = undefined;
      try {
        notificationsData = await fetchNotificationsData();
      } catch (error) {
        setTimeout(async () => {
          try {
            notificationsData = await fetchNotificationsData();
          } catch (error) {
            logging(
              'warn',
              'Unable to fetch notifications from back-end',
              error,
              false,
              null
            );
          }
        }, 10000);
      }
      try {
        if (notificationsData) {
          await populateNotificationsIndexdb(notificationsData);
          logging(
            'info',
            `Notifications added in the indexdb `,
            '',
            false,
            null
          );
        }
      } catch (error) {
        logging(
          'warn',
          'Unable to add notifications in the indexdb',
          error,
          false,
          null
        );
      }
    };
    fetchNotifications();
    const interval = setInterval(fetchNotifications, notificationsFetchTime);
    return () => clearInterval(interval);
  }, [langCode]);
};
export default useLoadNotifications;
