import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import React, { useState, useEffect, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import MuiButton from '@mui/material/Button';

import SectionHeading from '../../components/SectionHeading';
import useTasks from '../../hooks/useTasks';
import { usePage } from '../../PageProvider';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import TaskListItem from './TaskListItem';
import EquipmentListItem from './EquipmentListItem';
import EndPoint from '../../Models/EndPoint';
import ManualEndPoint from '../../Models/ManualEndPoint';
import db from '../../index-db';
import { useAppSelector } from '../../app/hooks';
import useNavigateToEquipment from '../../hooks/useNavigateToEquipment';
import { EquipmentMenuItem } from '../../types';
import PopupModal from '../../components/PopupModal';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import AddEquipmentModal from '../../components/feature/AddEquipmentModal';

function MePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToEquipment = useNavigateToEquipment();
  const logging = useLogging();

  const api = useApi();
  const [showModal, setShowModal] = useState(false);

  const [isManualModalOpen, setIsManualModalOpen] = useState(false);
  const [currentEndpointId, setCurrentEndpointId] = useState('');
  const [manualEquipments, setManualEquipments] = useState(
    [] as ManualEndPoint[]
  );
  const [ignored, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const latestFetchTimestamp = useAppSelector(
    (state) => state.tasks.latestFetchTimestamp
  );
  const [refreshTimeInterval, setRefreshTimeInterval] = useState(
    Math.floor(Number((Date.now() - latestFetchTimestamp) / 60000))
  );

  useEffect(() => {
    // Respond to outside changes
    setRefreshTimeInterval(
      Math.floor(Number((Date.now() - latestFetchTimestamp) / 60000))
    );
  }, [latestFetchTimestamp]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      modalTag: '',
    },
  });

  useEffect(() => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Me,
      },
    ];
    logging('info', '', '', false, logParams);
  }, []);

  usePage(
    () => ({
      getTitle: () => t('Me'),
      belongsToNavBarItem: 'Me',
      showBackButton: true,
      rightButtons: [
        {
          icon: SettingsOutlinedIcon,
          onClick: () => {
            navigate('/settings');
          },
        },
      ],
    }),
    [navigate, t]
  );

  const [tasks, loadingTasks, forceReloadTasks] = useTasks();

  const previousTasksCount = useRef(0);
  useEffect(() => {
    if (!!tasks) {
      previousTasksCount.current = tasks.length;
    }
  }, [tasks]);

  const syncTasks = () => {
    if (!loadingTasks) {
      forceReloadTasks();
    }
  };
  const fetchEndPoints = async (serialNumber: string): Promise<EndPoint[]> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointByFilter/?serialNumber=${serialNumber}`
    )) as EndPoint[];
  };
  useEffect(() => {
    (async () => {
      const equipments = await db.manualEndPoints
        .filter((endpoint) => !endpoint?.externallyNavigated)
        .toArray();

      setManualEquipments(equipments);
      await Promise.all([
        equipments &&
          equipments.forEach(async (equipmentEndPoint: ManualEndPoint) => {
            const endPoints = await fetchEndPoints(
              equipmentEndPoint.serialNumber
            );
            if (endPoints) {
              let manualEndPoints = [] as ManualEndPoint[];

              endPoints.forEach((endPoint) => {
                manualEndPoints.push(
                  new ManualEndPoint(
                    endPoint.id,
                    endPoint.serialNumber,
                    endPoint.prodDesc,
                    endPoint.terminalId,
                    endPoint.customerName,
                    endPoint.preventiveInsights,
                    endPoint.serviceInsights,
                    endPoint?.chronicDetails,
                    endPoint.generatedSummary,
                    endPoint?.chronicIndicator,
                    equipmentEndPoint.userTag
                  )
                );
              });
              await db.manualEndPoints.bulkPut(manualEndPoints);
              let updatedEquipments = await db.manualEndPoints
                .filter((endpoint) => !endpoint?.externallyNavigated)
                .toArray();
              setManualEquipments(updatedEquipments);
            }
          }),
      ]);
    })();
  }, [ignored]);

  const onEquipmentItemClick = (id: string) => () => {
    navigateToEquipment(id, 'Systems' as EquipmentMenuItem);
  };

  const onEditClick = (id: string) => (e: React.SyntheticEvent) => {
    db.manualEndPoints.get(id).then((endpoint) => {
      methods.setValue('modalTag', endpoint?.userTag ?? '');
    });
    e.stopPropagation();

    setIsManualModalOpen(true);
    setCurrentEndpointId(id);
  };

  const onOkClick = (data: { modalTag: string }) => {
    const { modalTag } = data;
    db.manualEndPoints.update(currentEndpointId, { userTag: modalTag });
    forceUpdate();
    methods.reset();
    setIsManualModalOpen(false);
  };
  const onCancelClick = () => {
    methods.reset();
    setIsManualModalOpen(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const onModalClose = () => {
    setShowModal(false);
  };
  const handleAdd = () => {
    forceUpdate();
    setShowModal(false);
  };

  return (
    <div>
      <div className="mt-4 text-center text-steel">
        {t('Last synced: {{time}} min ago', { time: refreshTimeInterval })}
      </div>

      <AddEquipmentModal
        onDismiss={handleCancel}
        onSubmit={handleAdd}
        isModalOpen={showModal}
        onModalClose={onModalClose}
      />

      <SectionHeading
        title={t('My tasks')}
        icon={EventOutlinedIcon}
        trailingElement={
          <Button
            styling="tertiary"
            icon={SyncOutlinedIcon}
            onClick={syncTasks}
          >
            {t('Sync')}
          </Button>
        }
      />

      {loadingTasks && (
        <>
          {Object.keys(Array(previousTasksCount.current || 1).fill('')).map(
            (index) => (
              <div
                key={index}
                className="h-14 border-b border-antartica bg-white"
              />
            )
          )}
        </>
      )}

      {!loadingTasks &&
        tasks?.map((task) => (
          <TaskListItem
            key={task.id}
            task={task}
            onClick={() => {
              navigateToEquipment(
                task.endpointId,
                'Systems' as EquipmentMenuItem,
                task.id
              );
            }}
          />
        ))}

      <SectionHeading
        title={t('Systems')}
        icon={StorageOutlinedIcon}
        trailingElement={
          <Button
            styling={'tertiary'}
            icon={AddOutlinedIcon}
            onClick={() => {
              setShowModal(true);
              const getMain = () => document.querySelector('main');
              getMain()?.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            {t('Add New')}
          </Button>
        }
      />

      {manualEquipments &&
        manualEquipments.map((endPoint: ManualEndPoint) => (
          <EquipmentListItem
            key={endPoint.id}
            manualEndPoint={endPoint}
            onClick={onEquipmentItemClick(endPoint.id)}
            onEditClick={onEditClick(endPoint.id)}
            onItemDeleted={() => forceUpdate()}
          />
        ))}
      <PopupModal isModalOpen={isManualModalOpen}>
        <form
          onSubmit={methods.handleSubmit(onOkClick)}
          className="w-11/12  rounded-lg bg-white px-2 py-4"
        >
          <div className="mb-4 flex items-center gap-1 px-4">
            <h2 className="text-lg font-bold">{t('Tag')}</h2>

            <TextField
              id="outlined-basic"
              variant="standard"
              multiline
              maxRows={1}
              fullWidth
              error={Boolean(methods.formState.errors?.modalTag)}
              helperText={methods.formState.errors?.modalTag?.message}
              {...methods.register('modalTag', {
                maxLength: {
                  value: 60,
                  message: 'Maximum number of characters allowed are 60.',
                },
              })}
            />
          </div>
          <div className="flex justify-center gap-1">
            <MuiButton
              variant="contained"
              type="submit"
              disabled={!methods.formState.isValid}
            >
              Update
            </MuiButton>
            <MuiButton variant="contained" onClick={onCancelClick}>
              Cancel
            </MuiButton>
          </div>
        </form>
      </PopupModal>
    </div>
  );
}

export default MePage;
