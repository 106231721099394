import React, { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';

interface Props {
  children: ReactNode;
  show: boolean;
  handleClose: () => void;
}

function Modal(props: Props) {
  const showHideModal = props.show ? 'block' : 'hidden';

  return (
    <div
      className={classnames(
        'h-auto w-full rounded-lg border-2 border-antartica bg-white',
        `${showHideModal}`
      )}
    >
      <section>{props.children}</section>
    </div>
  );
}

export default Modal;
