import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import useGetAllNotifications from '../../hooks/useGetAllNotifications';

import { usePage } from '../../PageProvider';
import db from '../../index-db';
import { useNavigate } from 'react-router-dom';

const NotificationsList = () => {
  const { notifications, isLoading } = useGetAllNotifications();

  const navigate = useNavigate();
  const { t } = useTranslation();
  usePage(
    () => ({
      getTitle: () => t('Notifications'),
      belongsToNavBarItem: 'Notification',
      showBackButton: true,
    }),
    [t]
  );
  if (isLoading) {
    return (
      <div className="py-4 text-center text-lg font-medium text-concrete">
        {t('Loading')}...
      </div>
    );
  }
  const onItemClick = (id: string) => () => {
    const lastActionTaken = new Date().valueOf();
    db.notifications.update(id, { isRead: 1, lastActionTaken });

    navigate(`/notifications/${id}`);
  };

  /**
   * 'setting'
  | 'nonBold'
  | 'cobaltTrailingIcon'
  | 'colorInverted';
   */

  return (
    <>
      {notifications.map((notification) => {
        const subtText = notification.description.replace(/<[^>]*>/g, ' ');

        return (
          <ActionableListItem
            key={notification.id}
            title={notification?.text}
            subText={subtText}
            primaryAction="navigate"
            onClick={onItemClick(notification?.id)}
            subTextPosition="below title"
            appearance={notification?.isRead ? 'setting' : 'colorInverted'}
          />
        );
      })}
    </>
  );
};

export default NotificationsList;
