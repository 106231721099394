import React, { useState } from 'react';
import Button from '../../components/Button';
import { usePage } from '../../PageProvider';

function TestPage() {
  const [count, setCount] = useState(0);

  usePage(
    () => ({
      getTitle: () => count.toString(),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [count]
  );

  return (
    <div className="p-4">
      <Button styling="primary" onClick={() => setCount(count + 1)}>
        Increment page title
      </Button>
      <p>
        This button changes the count in local state, combined with page.init
        getTitle (a function that returns a string) to update the title
        dynamically.
      </p>

      <p>NODE_ENV: {process.env.NODE_ENV}</p>
    </div>
  );
}

export default TestPage;
