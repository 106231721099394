import classNames from 'classnames';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Icon from '../Icon';
import ReadUnreadCta from '../icons/ReadUreadCta';
import useGetRAPerformedIcon from '../../hooks/useGetRAPerformedIcon';
import { ReactNode } from 'react';
import { TIcon } from '../../types';

interface Props {
  iconColor: 'red' | 'steel' | 'lime' | 'yellow' | 'tangerine';
  onCardClick?: () => void;
  children: React.ReactNode;
  clickableType?: 'primary' | 'secondary';
  readUnreadIconType?: 'primary' | 'secondary';
  audioSummaryContent?: string;
  showRAPerformedIcon?: boolean;
  raPerformedFeedback?: string;
  showEntitlementIcon?: boolean;
  entitlementIcon?: TIcon;
  ExtraComp?: ReactNode;
}

const SystemsCard = (props: Props) => {
  const {
    iconColor,
    onCardClick = undefined,
    children,
    clickableType = 'primary',
    readUnreadIconType = 'primary',
    showRAPerformedIcon = false,
    raPerformedFeedback = '',
    showEntitlementIcon = false,
    entitlementIcon,
    ExtraComp,
  } = props;
  const red = 'bg-dnred';
  const steel = 'bg-steel';
  const lime = 'bg-lime';
  const tangerine = 'bg-tangerine';
  const yellow = 'bg-yellow';

  let isCardClickable = true;
  if (typeof onCardClick === 'undefined') {
    isCardClickable = false;
  }
  const { fixedIcon, fixedIconColor } =
    useGetRAPerformedIcon(raPerformedFeedback);

  return (
    <button className=" w-full" type="button" onClick={onCardClick}>
      <div className="flex">
        <div
          className={classNames(
            'flex w-6 shrink-0 items-center justify-center rounded-l-lg',
            {
              [red]: 'red' === iconColor,
              [steel]: 'steel' === iconColor,
              [lime]: 'lime' === iconColor,
              [tangerine]: 'tangerine' === iconColor,
              [yellow]: 'yellow' === iconColor,
            }
          )}
        >
          {showEntitlementIcon && entitlementIcon && (
            <div className="self-center">
              <Icon is={entitlementIcon} size="1rem" colorClass="text-white" />
            </div>
          )}
          {!showEntitlementIcon && (
            <div className="self-center">
              <Icon
                is={EventOutlinedIcon}
                size="1rem"
                colorClass="text-white"
              />
            </div>
          )}
        </div>
        <div className="flex w-full min-w-0 gap-1  rounded-r-lg border border-antartica bg-white  p-2">
          <div className="max-w-[88%] flex-1 self-center  break-words text-left">
            {children}
          </div>
          <div className="flex cursor-pointer gap-2 self-center">
            {ExtraComp}
            {showRAPerformedIcon ? (
              <div className={`h-8 w-8 ${fixedIconColor}`}>
                <Icon
                  is={fixedIcon}
                  size="1rem"
                  colorClass="text-white"
                  className="mt-1"
                />
              </div>
            ) : (
              <></>
            )}
            {isCardClickable &&
              (clickableType === 'primary' ? (
                <Icon
                  is={ChevronRightOutlinedIcon}
                  className="flex items-center"
                />
              ) : (
                <ReadUnreadCta iconType={readUnreadIconType} />
              ))}
          </div>
        </div>
      </div>
    </button>
  );
};

export default SystemsCard;
