import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EndPoint from '../../Models/EndPoint';
import CallToActionSettings from '../../Models/CallToActionSettings';
import SegmentedControl from '../../components/SegmentedControl';
import CurrentEquipment from './CurrentEquipment';
import HistoryEquipment from './History/HistoryEquipment';

interface Props {
  equipmentData: EndPoint;
  serviceTaskId?: string;
  callToActionSettings?: CallToActionSettings[];
  isEquipmentLoading: boolean;
  callToActionStateManager: () => Promise<void>;
}

function Equipment(props: Props) {
  const { t } = useTranslation();

  const segments = [t('Current'), t('History')];
  const [activeSegment, setActiveSegment] = useState(segments[0]);
  const onSubMenuChange = (segment: string) => {
    props.callToActionStateManager();
    setActiveSegment(segment);
  };
  return (
    <>
      <div className="mb-4 mt-6 space-y-7">
        <SegmentedControl
          segments={segments}
          activeSegment={activeSegment}
          onChange={onSubMenuChange}
        />
      </div>

      {activeSegment === t('Current') && (
        <CurrentEquipment
          equipmentData={props.equipmentData}
          serviceTaskId={props.serviceTaskId}
          callToActionSettings={props.callToActionSettings}
          isEquipmentLoading={props.isEquipmentLoading}
        />
      )}
      {activeSegment === t('History') && (
        <HistoryEquipment
          equipmentData={props.equipmentData}
          activeSubMenuItem="All"
        />
      )}
    </>
  );
}

export default Equipment;
