import { useTranslation } from 'react-i18next';
import { useToast } from '../toast/ToastProvider';
import useLogging from '../../hooks/useLogging';
import { LogType } from '../../enums/LogType';
import QrScanner from '../../local-packages/Components/custom-qr-code/QrScanner';
import { useNavigate } from 'react-router';

interface Props {
  isScanning: boolean;
  setIsScanning: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchQr = (props: Props) => {
  const { isScanning, setIsScanning } = props;
  const toast = useToast();

  const { t } = useTranslation();
  const logging = useLogging();
  const navigate = useNavigate();
  const afterSuccessfulScan = (result: string) => {
    logging('info', 'EFC QR code Scanned successfully', null, false, [
      {
        key: LogType.QREFCSearch,
        value: result,
      },
    ]);

    toast.pushToast({
      message: `${t('Scanned successfully, Redirecting to the scanned link')} `,
    });
  };
  const onResult = (result: string) => {
    setIsScanning(false);

    if (result.includes('https')) {
      if (result.includes('acfs.dieboldnixdorf.com')) {
        const obtainedResult = result.match(/(dwar)(\/.*)/);
        if (obtainedResult?.[2]) {
          afterSuccessfulScan(result);
          navigate(obtainedResult[2]);
        }
      } else if (result.includes('dsa') || result.includes('dnsa')) {
        afterSuccessfulScan(result);
        navigate(`/externalSource?URL=${result}`, {
          state: { isExternal: false },
        });
      }
    }
  };
  const onQrError = (error: unknown) => {
    logging(
      'error',
      `Qr code scan error from EFCcodes scanner`,
      error,
      false,
      null
    );
  };

  return (
    <>
      {isScanning && (
        <div className="flex  items-center justify-center">
          <QrScanner
            stopDecoding={!isScanning}
            onResult={onResult}
            onError={onQrError}
            className="w-10/12"
          />
        </div>
      )}
    </>
  );
};

export default SearchQr;
