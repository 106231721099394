import React from 'react';
import { TIcon } from '../types';
import Icon from './Icon';

export interface Props {
  icon: TIcon;
  title: string;
  onClick: () => void;
}

function PageAction({ icon, title, onClick }: Props) {
  return (
    <button
      type="button"
      className="flex h-full w-full items-center justify-center gap-x-1 px-4"
      onClick={onClick}
    >
      <Icon is={icon} colorClass="text-cobalt" />
      <span className="font-medium text-cobalt">{title}</span>
    </button>
  );
}

export default PageAction;
