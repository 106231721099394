import { useMemo } from 'react';

import {
  MRT_ColumnDef,
  MRT_RowData,
  MRT_TableOptions,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

//nested data is ok, see accessorKeys in ColumnDef below
type hookOptions = Omit<MRT_TableOptions<MRT_RowData>, 'columns' | 'data'>;
interface IProps<T> {
  data: T[];

  headerDef?: MRT_ColumnDef<MRT_RowData, T>[];
  hookOptions?: hookOptions;
  columnOptions?: Omit<
    MRT_ColumnDef<MRT_RowData, T>,
    'header' | 'accessorKey' | 'id'
  >;
}

const defaultOptions: hookOptions = {
  enableColumnFilters: false,
  enablePagination: false,
  enableSorting: false,
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableTableFooter: false,
  enableDensityToggle: true,
  enableColumnActions: false,
  initialState: {
    density: 'compact',
  },

  muiTableContainerProps: {
    sx: {
      textWrap: 'wrap',
    },
  },
  muiTableHeadCellProps: {
    sx: {
      backgroundColor: (theme) => theme.palette.grey['100'],
      border: '1px solid',
      textWrap: 'wrap',
      fontSize: '0.8rem',
      margin: 0,
      padding: 0.5,
    },
  },
  muiTableBodyCellProps: {
    sx: {
      border: '1px solid',
      fontSize: '0.75rem',
      textWrap: 'wrap',
      wordBreak: 'break-word',
      margin: 0,
      padding: 0.5,
    },
  },
};
const CustomTable = <T extends MRT_RowData>(props: IProps<T>) => {
  const { data, headerDef, hookOptions, columnOptions } = props;

  //should be memoized or stable
  const columns = useMemo(
    () =>
      headerDef
        ? headerDef.map((column) => ({ ...column, ...columnOptions }))
        : data.length
        ? Object.keys(data[0]).map((columnId) => ({
            header: columnId,
            accessorKey: columnId,
            id: columnId,
            ...columnOptions,
          }))
        : [],
    [data, headerDef]
  );

  const columnOrder = columns.map((c) => c.accessorKey) as string[];

  const table = useMaterialReactTable({
    columns: columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: { columnOrder },
    ...defaultOptions,
    ...hookOptions,
    editDisplayMode: 'cell',
    enableStickyHeader: true,
  });

  return (
    <div>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default CustomTable;
