import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import NavBarButton from './NavBarButton';
import useCheckUserIsPartner from '../../hooks/useCheckUserIsPartner';
import useUnreadNotificationsCount from '../../hooks/useUnreadNotificationsCount';
import { useDispatch } from 'react-redux';
import { searchActions } from '../../features/search/searchSlice';

function NavBar() {
  const isMobile = useCheckMobileScreen();
  const SearchQuery = isMobile ? '/search/query/true' : '/search';
  const dispatch = useDispatch();
  const isPartner = useCheckUserIsPartner();
  const unReadNotificationsCount = useUnreadNotificationsCount();
  const onSearchClick = () => {
    dispatch(searchActions.setScope('everywhere'));
    dispatch(searchActions.clearSearchTerm());
    dispatch(searchActions.removeFilter('Module'));
  };
  return (
    <nav className="relative flex h-16 flex-none bg-white" role="navigation">
      <div className="absolute-shadow above" />

      <NavBarButton to="/" icon={HomeOutlinedIcon} titleTransKey="Home" />
      <NavBarButton
        to={SearchQuery}
        icon={SearchOutlinedIcon}
        titleTransKey="Search"
        onClick={onSearchClick}
      />
      <NavBarButton
        to="/equipment"
        icon={ViewCarouselOutlinedIcon}
        titleTransKey="System"
      />
      <NavBarButton
        to="/me"
        icon={AccountCircleOutlinedIcon}
        titleTransKey="Me"
        disabled={isPartner}
      />
      <NavBarButton
        to="/menu"
        icon={MenuOutlinedIcon}
        titleTransKey="Menu"
        badgeColor="primary"
        badgeContent={unReadNotificationsCount}
      />
    </nav>
  );
}

export default NavBar;
