import React, {
  DependencyList,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useClearCache from './hooks/useClearCache';
import { NavBarTitleTransKey, TIcon } from './types';
import db from './index-db';
import useLogging from './hooks/useLogging';

interface ProviderValue {
  getTitle: (() => string) | null;
  showBackButton?: boolean;
  showACFSLogo: boolean;
  leftButton?: TIcon;
  rightButtons: IHeaderButton[];
  activeNavBarItem: NavBarTitleTransKey;
  init: (options: InitOptions) => void;
}

export interface InitOptions {
  getTitle?: () => string;
  showBackButton?: boolean;
  showACFSLogo?: boolean;
  leftButton?: TIcon;
  rightButtons?: IHeaderButton[];
  belongsToNavBarItem: NavBarTitleTransKey;
}

export interface IHeaderButton {
  icon: TIcon;
  iconColor?: string; // Prefer not to use this - it's only for a very specific use case; the save search icon, which is an exception to the default styling.
  popoverMenuButtons?: PopoverMenuButton[];
  onClick?: () => void;
}

export interface PopoverMenuButton {
  title: string;
  onClick: (event: React.MouseEvent) => void;
}

const Context = React.createContext<ProviderValue | null>(null);

function PageProvider({ children }: PropsWithChildren<{}>) {
  const logging = useLogging();
  const [getTitle, setGetTitle] = useState<(() => string) | null>(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showACFSLogo, setshowACFSLogo] = useState(false);
  const [leftButton, setLeftButton] = useState<TIcon>();
  const [rightButtons, setRightButtons] = useState<IHeaderButton[]>([]);
  const [activeNavBarItem, setActiveNavBarItem] =
    useState<NavBarTitleTransKey>('Home');

  const value: ProviderValue = useMemo(() => {
    return {
      getTitle,
      showBackButton,
      showACFSLogo,
      leftButton,
      rightButtons,
      activeNavBarItem,
      init: (options) => {
        setGetTitle(options.getTitle ? () => options.getTitle! : null);
        setShowBackButton(options.showBackButton ?? false);
        setshowACFSLogo(options.showACFSLogo ?? false);
        setLeftButton(options.leftButton ?? undefined);
        setRightButtons(options.rightButtons ?? []);
        setActiveNavBarItem(options.belongsToNavBarItem);
      },
    };
  }, [
    getTitle,
    showBackButton,
    showACFSLogo,
    leftButton,
    rightButtons,
    activeNavBarItem,
  ]);

  db.on('versionchange', function (event) {
    logging('info', `Version changed indexdb`, '', false, null);
    window.location.reload();
  });

  useClearCache();
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const usePage = (optionsFactory?: () => InitOptions, deps?: DependencyList) => {
  const context = React.useContext(Context);
  if (!context) {
    throw Error('usePage must be used within a PageProvider');
  }

  useEffect(() => {
    if (optionsFactory) {
      context.init(optionsFactory());
    }
  }, deps ?? []); // eslint-disable-line react-hooks/exhaustive-deps

  return context;
};

export { usePage };

export default PageProvider;
