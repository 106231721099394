import Icon from '../../components/Icon';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

interface TaskStatus {
  taskStatus: string;
}

const TimelineTaskIcon = ({ taskStatus }: TaskStatus) => {
  switch (taskStatus.toLowerCase()) {
    case 'interrupt':
    case 'incomplete':
      return (
        <Icon is={DateRangeOutlinedIcon} size="1rem" colorClass="text-white" />
      );

    case 'closed':
      return (
        <Icon
          is={EventAvailableOutlinedIcon}
          size="1rem"
          colorClass="text-white"
        />
      );

    case 'cancelled':
      return (
        <Icon is={EventBusyOutlinedIcon} size="1rem" colorClass="text-white" />
      );

    default:
      return (
        <Icon
          is={CalendarTodayOutlinedIcon}
          size="1rem"
          colorClass="text-white"
        />
      );
  }
};

export default TimelineTaskIcon;
