import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  activeTrack: null,
};

export const audioAiSummarySlice = createSlice({
  name: 'audioAiSummary',
  initialState,
  reducers: {
    setActiveTrack: (state, { payload }) => {
      state.activeTrack = payload.activeTrack;
    },
  },
});
export const audioAiSummaryActions = audioAiSummarySlice.actions;

export default audioAiSummarySlice.reducer;
