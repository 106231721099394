import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useToast } from '../components/toast/ToastProvider';
import { useApi } from '../acfs-apis/dwar-api-provider';
import { tasksActions } from '../features/tasks/tasksSlice';
import useLogging from './useLogging';
import db from '../index-db';
import Task from '../Models/Task';
import useCheckUserIsPartner from './useCheckUserIsPartner';
import { USER_REALM_KEY } from '../constants';

const RELOAD_INTERVAL = 5 * 60_000;

const useTasks = (): [Task[], boolean, () => void] => {
  const dispatch = useAppDispatch();
  const latestFetchTimestamp = useAppSelector(
    (state) => state.tasks.latestFetchTimestamp
  );
  const toast = useToast();
  const { t } = useTranslation();
  const api = useApi();
  const logging = useLogging();

  const isPartner = useCheckUserIsPartner();

  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([] as Task[]);

  useEffect(() => {
    if (!isPartner) {
      (async () => {
        let initialTasks = await db.tasks.toArray();
        if (initialTasks.length === 0) {
          updateTasks();
        } else {
          setTasks(initialTasks);
          logging(
            'info',
            `Initial tasks fetched ${initialTasks.length}.`,
            '',
            false,
            null
          );
        }
      })();
    }
  }, []);

  const updateTasks = useCallback(async () => {
    setLoading(true);
    dispatch(tasksActions.setLatestFetchTimestamp(Date.now()));

    try {
      var result = (await api.fetchJson(
        '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
      )) as Task[];
      result = result.filter(
        (a) =>
          a.deviceSerialNumber != null &&
          a.deviceSerialNumber != '' &&
          a.deviceSerialNumber != 'N/A'
      );
      await db.tasks.clear();
      await db.tasks.bulkPut(result);
      let updatedTasks = await db.tasks.toArray();
      setTasks(updatedTasks);

      logging('info', `tasks fetched ${updatedTasks.length}`, '', false, null);
    } catch (error) {
      logging(
        'warn',
        'Unable to retrieve tasks. Using offline data',
        error,
        false,
        null
      );
      toast.pushToast({
        type: 'error',
        message: t('Unable to retrieve tasks. Falling back to offline data.'),
      });
    } finally {
      setLoading(false);
    }
  }, [api, dispatch, t, toast]);

  useEffect(
    function updateOnRefocusWindow() {
      const interval = setInterval(() => {
        if (
          !isPartner &&
          document.visibilityState === 'visible' &&
          Date.now() > latestFetchTimestamp + RELOAD_INTERVAL
        ) {
          updateTasks();
        }
      }, 1000);

      return () => clearInterval(interval);
    },
    [updateTasks, latestFetchTimestamp]
  );

  useEffect(
    function updateAtPageLoad() {
      if (!isPartner && Date.now() > latestFetchTimestamp + RELOAD_INTERVAL) {
        updateTasks();
      }
    },
    [latestFetchTimestamp, updateTasks]
  );

  return [tasks, loading, updateTasks];
};

export default useTasks;
