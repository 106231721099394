import { useLiveQuery } from 'dexie-react-hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import SectionHeading from '../../components/SectionHeading';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import GenericModelData from '../../Models/GenericModelData';
import EFCItem from './EFCItem';

function RecentEFCitems() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const handleClick = () => {
    navigate('/recent', {
      state: {
        onlyExpand: IDType.EFC,
      },
    });
  };
  const handleDelete = (id: number) => {
    db.recentlyViewed.delete(id);
  };
  const recentlyViewedItems = useLiveQuery(async () => {
    const recentlyViewedRecords = await db.recentlyViewed
      .where('IdType')
      .equals(IDType.EFC)
      .reverse()
      .sortBy('id');
    let recentlyViewedEFCsArray: GenericModelData[] = [];
    await Promise.resolve(
      Promise.all(
        recentlyViewedRecords.map(async (recentlyViewedRecord) => {
          const efcRecord = await db.efcs.get(recentlyViewedRecord.IdGeneric);
          if (efcRecord) {
            recentlyViewedEFCsArray.push(
              GenericModelData.CreateFromEFC(efcRecord, recentlyViewedRecord.id)
            );
          }
        })
      )
    );
    return recentlyViewedEFCsArray;
  });
  return (
    <>
      <div className="ml-4 mr-1 mb-0.5 flex items-center justify-between">
        <SectionHeading title={t('Recent codes')} withMargins={false} />
        <Button styling="tertiary" onClick={handleClick}>
          {t('View all')}
        </Button>
      </div>

      <div>
        {recentlyViewedItems?.map((recentlyViewedItem) => (
          <EFCItem
            key={`${recentlyViewedItem?.IdType}_${recentlyViewedItem?.Id}`}
            efcItem={recentlyViewedItem}
            onClick={() => handleDelete(recentlyViewedItem.Id)}
          />
        ))}
      </div>
    </>
  );
}

export default RecentEFCitems;
