import { SearchFilter, SearchFilters } from '../../../types';

const MAX_FILTERS_TO_DISPLAY = 3;

const filtersToString = (filters: SearchFilters) => {
  const getLabel = (filter: SearchFilter) => filter.label;

  const filtersCount = Object.entries(filters).length;

  if (filtersCount <= MAX_FILTERS_TO_DISPLAY) {
    return Object.values(filters).map(getLabel).join(', ');
  }

  return (
    Object.values(filters)
      .slice(0, MAX_FILTERS_TO_DISPLAY)
      .map(getLabel)
      .join(', ') + `, + ${filtersCount - MAX_FILTERS_TO_DISPLAY} more`
  );
};

export default filtersToString;
