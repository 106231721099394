import { useRef } from 'react';
import { useAuth } from 'react-oidc-context';

import useCheckUserIsPartner from '../hooks/useCheckUserIsPartner';
import { ADDITIONAL_USERID_OR_GROUPID } from '../constants/index';
import APIS from './apis';

export const useApi = () => {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  const isPartner = useCheckUserIsPartner();

  if (!accessToken) {
    throw Error('Access token is not set');
  }

  const localUserIdOrGroup =
    localStorage.getItem(ADDITIONAL_USERID_OR_GROUPID) ?? '';

  const getRequestHeaders = (language?: string): HeadersInit => ({
    'Authorization': `Bearer ${auth.user?.access_token}`,
    'LanguageCode': language ?? 'en',
    'DBD-UserGroupOrId': localUserIdOrGroup,
  });

  const api = useRef(
    APIS({
      auth,
      getRequestHeaders,
      isPartner,
      accessToken,
      localUserIdOrGroup,
    })
  );

  return api.current;
};
