import { useTranslation } from 'react-i18next';

interface DetailsProps {
  title: string;
  value?: string;
}

function DetailsEquipmentRow(props: DetailsProps) {
  const { title, value } = props;
  const { t } = useTranslation();
  return (
    <div className="mt-4 mb-4 flex items-start justify-between px-4">
      <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
        {t(title)}:
      </div>
      <div className={'w-1/2 text-left text-lg font-medium text-iron'}>
        {value}
      </div>
    </div>
  );
}

export default DetailsEquipmentRow;
