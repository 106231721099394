import { useTranslation } from 'react-i18next';
import ActionableListItem from '../../components/ActionableListItem';
import { usePage } from '../../PageProvider';
import { useAuth } from 'react-oidc-context';
import { t } from 'i18next';
import { OIDCConfig } from '../../types';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { useEffect, useState } from 'react';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import useGetDeviceId from '../../hooks/useGetDeviceId';
export const DATABASE_INIT_TIMESTAMP_KEY = 'acde:last-init-of-indexed-db';

interface IInfoData {
  title: string;
  value?: string;
}
function GetInfoData(
  lastUpdatedDate: Date,
  oidcConfig?: OIDCConfig,
  userName?: string
) {
  let infoData: IInfoData[] = [
    {
      title: t('URL'),
      value: oidcConfig?.DwarApiUrl,
    },
    {
      title: t('User Name'),
      value: userName,
    },
    {
      title: t('ACFS App'),
      value: navigator.userAgent,
    },
    {
      title: t('ACFS UI'),
      value: oidcConfig?.BuildVersion,
    },
    {
      title: t('Offline Data'),
      value: lastUpdatedDate.toISOString(),
    },
    {
      title: t('Device Id'),
      value: useGetDeviceId() || '',
    },
  ];
  return infoData;
}
function Info() {
  const { t } = useTranslation();
  const api = useApi();
  const logging = useLogging();
  const [infoData, setInfoData] = useState<IInfoData[]>([]);
  const userName = useAuth()?.user?.profile?.preferred_username?.toString();
  const lastInitOfIndexedDB: number = Number(
    window.localStorage.getItem(DATABASE_INIT_TIMESTAMP_KEY)
  );
  const lastUpdatedDate = new Date(lastInitOfIndexedDB);

  useEffect(() => {
    (async () => {
      const oidcConfig = await api.fetchOIDCConfig();
      const res: IInfoData[] = GetInfoData(
        lastUpdatedDate,
        oidcConfig,
        userName
      );
      setInfoData(res);
      const logParams: LogParams[] = [];
      res.forEach((info: IInfoData) => {
        if (info.title !== t('User Name')) {
          logParams.push({
            key: info.title,
            value: info?.value || '',
          });
        }
      });
      logParams.push({
        key: LogType.Page,
        value: LogType.Info,
      });
      logging('info', '', '', false, logParams);
    })();
  }, []);

  usePage(
    () => ({
      getTitle: () => t('Info'),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [t]
  );

  return (
    <div>
      {infoData?.map((prop: IInfoData, index) => {
        return (
          <ActionableListItem
            title={prop.title}
            key={index}
            subText={prop.value}
            hidePrimaryActionIcon={true}
            subTextPosition={'below title'}
            primaryAction={'select'}
          />
        );
      })}
    </div>
  );
}

export default Info;
