import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectableListItem from '../../../components/SelectableListItem';
import { useToast } from '../../../components/toast/ToastProvider';
import { LANGUAGES } from '../../../constants';
import { usePage } from '../../../PageProvider';
import type { Language as TLanguage } from '../../../types';
import ChangeLanguage from '../../../components/ChangeLanguage';

function Language() {
  const { t, i18n } = useTranslation();
  const [languageChanged, setLanguageChanged] = useState(false);
  const [language, setLanguage] = useState({ code: '', englishTitle: '' });
  usePage(
    () => ({
      getTitle: () => t('Language'),
      belongsToNavBarItem: 'Menu',
      showBackButton: true,
    }),
    [t]
  );

  const changeLanguage = async (language: TLanguage) => {
    if (i18n.language !== language.code) {
      await i18n.changeLanguage(language.code);
      setLanguageChanged(true);
      setLanguage(language);
    }
  };

  return (
    <div>
      {languageChanged && <ChangeLanguage language={language} />}
      {LANGUAGES.map((language) => (
        <SelectableListItem
          key={language.code}
          title={t(language.englishTitle)}
          subText={language.englishTitle}
          selected={language.code === i18n.resolvedLanguage}
          onSelect={() => changeLanguage(language)}
        />
      ))}
    </div>
  );
}

export default Language;
