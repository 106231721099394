import * as uuid from "uuid";

const useGetDeviceId = () => {
    if (localStorage.getItem('deviceId')) {
      return localStorage.getItem('deviceId');
    } else {
      const deviceId = uuid.v4();
      localStorage.setItem('deviceId', deviceId);
      return deviceId;
    }
  };

export default useGetDeviceId