export interface ISubSystem {
  Id: number;
  SubSystemName: string;
  SubSystemOwner: string;
  ReleasedDate: string;
  LastUpdate: string;
  LastAction: number;
}

export class SubSystem implements ISubSystem {
  Id: number;
  SubSystemName: string;
  SubSystemOwner: string;
  ReleasedDate: string;
  LastUpdate: string;
  LastAction: number;

  constructor(
    id: number,
    subSystemName: string,
    subSystemOwner: string,
    releasedDate: string,
    lastUpdate: string,
    lastAction: number
  ) {
    this.Id = id;
    this.SubSystemName = subSystemName;
    this.SubSystemOwner = subSystemOwner;
    this.ReleasedDate = releasedDate;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
  }
}

export default SubSystem;
