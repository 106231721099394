import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ActionableListItem from '../components/ActionableListItem';
import Collapsible from '../components/collapsible/Collapsible';
import useNavigateFromRecentlyViewedItem from '../hooks/useNavigateFromRecentlyViewedItem';
import GenericModelData from '../Models/GenericModelData';
import { IDType } from '../Models/Enumerations';
import { useTranslation } from 'react-i18next';

interface Props {
  genericModelData: GenericModelData[];
  title: string;
  startCollapsed: boolean;
  onDeleteOne?: (item: GenericModelData) => void;
  onClearAll: () => void;
}

function Category(props: Props) {
  const handleClear = () => {
    props.onClearAll();
  };
  const { t } = useTranslation();
  const navigate = useNavigateFromRecentlyViewedItem();

  const deleteItem = (item: GenericModelData) => {
    props.onDeleteOne?.(item);
  };

  return (
    <Collapsible
      headingTitle={`${props.title} (${props.genericModelData.length})`}
      onClear={handleClear}
      startCollapsed={props.startCollapsed}
    >
      {props.genericModelData.map((item) => (
        <ActionableListItem
          key={item.Id}
          icon={props.onDeleteOne ? CloseOutlinedIcon : item.Icon}
          title={item.Heading}
          subTitle = {(item.IdType == IDType.PARTS) ? (item.SubTitle &&`(${t('mfg')}: ${item.SubTitle})`) : item.SubTitle}
          subText={item.Description}
          primaryAction="navigate"
          onClick={() => navigate(item)}
          onClickIcon={props.onDeleteOne ? () => deleteItem(item) : undefined}
        />
      ))}
    </Collapsible>
  );
}

export default Category;
