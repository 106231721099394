export interface IModel {
  Id: number;
  ModelName: string;
  ModelOwner: string;
  ReleasedDate: string;
  LastUpdate: string;
  LastAction: number;
}

export class Model implements IModel {
  Id: number;
  ModelName: string;
  ModelOwner: string;
  ReleasedDate: string;
  LastUpdate: string;
  LastAction: number;

  constructor(
    id: number,
    modelName: string,
    modelOwner: string,
    releasedDate: string,
    lastUpdate: string,
    lastAction: number
  ) {
    this.Id = id;
    this.ModelName = modelName;
    this.ModelOwner = modelOwner;
    this.ReleasedDate = releasedDate;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
  }
}

export default Model;
