import { useTranslation } from 'react-i18next';
import Sheet from 'react-modal-sheet';
import TimeLineEvent from '../../Models/TimeLineEvent';

interface Props {
  timelineEvent: TimeLineEvent;
  isOpen: boolean;
  handleClose: () => void;
}

function CustomRepairModal(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Sheet
        isOpen={props.isOpen}
        onClose={props.handleClose}
        snapPoints={[670, 500, 100, 10]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="max-h-max overflow-y-auto">
              <div className="mt-4 flex items-start justify-between px-4">
                <h2
                  className={'text-left text-lg font-bold text-cobalt'}
                  onClick={() => props.handleClose()}
                >
                  {t('Cancel')}
                </h2>
              </div>
              <div className="mt-4 flex items-start justify-between px-4">
                <h2 className={'text-left text-xl font-bold text-iron'}>
                  {t('Custom Repair Performed')}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-bold text-iron'}>
                  {t('Title of repair')}:
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2
                  className={
                    'w-80 break-all text-left text-lg font-medium text-iron'
                  }
                >
                  {t(props.timelineEvent.description)}
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2 className={'w-80 text-left text-lg font-bold text-iron'}>
                  {t('Description of repair')}:
                </h2>
              </div>
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2
                  className={
                    'w-80 break-all text-left text-lg font-medium text-iron'
                  }
                >
                  {t(props.timelineEvent.properties['details'])}
                </h2>
              </div>
              {props.timelineEvent.properties['timeToFix'] && (
                <div className="mt-4 mb-4 flex items-start justify-between px-4">
                  <h2 className={'w-80 text-left text-lg font-bold text-iron'}>
                    {t('Duration to complete repair (minutes)')}:
                  </h2>
                </div>
              )}
              <div className="mt-4 mb-4 flex items-start justify-between px-4">
                <h2
                  className={
                    'w-80 break-all text-left text-lg font-medium text-iron'
                  }
                >
                  {t(props.timelineEvent.properties['timeToFix'])}
                </h2>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default CustomRepairModal;
