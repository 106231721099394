import ModuleLinks from './ModuleLinks';

export interface IModule {
  Id: number;
  ModuleIdentifier: string;
  ModuleName: string;
  Abbreviation: string;
  ModuleOwner: string;
  Released: number;
  ModuleLinks: ModuleLinks[] | null;
  LastUpdate: string;
  LastAction: number;
}

export class Module {
  Id: number;
  ModuleIdentifier: string;
  ModuleName: string;
  Abbreviation: string;
  ModuleOwner: string;
  Released: number;
  ModuleLinks: ModuleLinks[] | null;
  LastUpdate: string;
  LastAction: number;

  constructor(
    id: number,
    moduleIdentifier: string,
    moduleName: string,
    abbreviation: string,
    moduleOwner: string,
    released: number,
    moduleLinks: ModuleLinks[] | null,
    lastUpdate: string,
    lastAction: number
  ) {
    this.Id = id;
    this.ModuleIdentifier = moduleIdentifier;
    this.ModuleName = moduleName;
    this.Abbreviation = abbreviation;
    this.ModuleOwner = moduleOwner;
    this.Released = released;
    this.ModuleLinks = moduleLinks;
    this.LastUpdate = lastUpdate;
    this.LastAction = lastAction;
  }
}

export default Module;
