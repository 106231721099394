import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from './Icon';

interface Props {
  rating: number;
  formName: string;
}

function StarRatingForm(props: Props) {
  const { register, setValue } = useFormContext();
  const [stars, setStars] = useState(props.rating);

  useEffect(() => {
    register(props.formName);
  }, []);

  useEffect(() => {
    setValue(props?.formName, stars);
  }, [stars]);

  return (
    <div className="flex">
      <button type="button" onClick={() => setStars(1)}>
        <Icon
          is={StarOutlinedIcon}
          colorClass={stars >= 1 ? 'text-cobalt' : 'text-antartica'}
          size="1.5rem"
        />
      </button>
      <button type="button" onClick={() => setStars(2)}>
        <Icon
          is={StarOutlinedIcon}
          colorClass={stars >= 2 ? 'text-cobalt' : 'text-antartica'}
          size="1.5rem"
        />
      </button>
      <button type="button" onClick={() => setStars(3)}>
        <Icon
          is={StarOutlinedIcon}
          colorClass={stars >= 3 ? 'text-cobalt' : 'text-antartica'}
          size="1.5rem"
        />
      </button>
      <button type="button" onClick={() => setStars(4)}>
        <Icon
          is={StarOutlinedIcon}
          colorClass={stars >= 4 ? 'text-cobalt' : 'text-antartica'}
          size="1.5rem"
        />
      </button>
      <button type="button" onClick={() => setStars(5)}>
        <Icon
          is={StarOutlinedIcon}
          colorClass={stars >= 5 ? 'text-cobalt' : 'text-antartica'}
          size="1.5rem"
        />
      </button>
    </div>
  );
}

export default StarRatingForm;
