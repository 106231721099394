import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useApi } from '../acfs-apis/dwar-api-provider';
import CallToActionSettings from '../Models/CallToActionSettings';
import useLogging from './useLogging';
import useLanguageTransformer from './useLanguageTranformer';
import db from '../index-db';
import { uniqueIdConstructorCTA } from '../utils/non-generic-utils';

const useGetCtaSettings = (endpointId: string) => {
  const api = useApi();
  const [callToActionSettings, setCallToActionSettings] = useState<
    CallToActionSettings[] | undefined | []
  >(undefined);
  const langCode = useLanguageTransformer();
  const logging = useLogging();
  const syncCTASettings = useCallback(
    (taskNumber: string | undefined) => {
      taskNumber = taskNumber ?? '000000';
      api
        .fetchCallToActionSettings(endpointId, taskNumber, langCode)
        .then((callToActionSettings) => {
          setCallToActionSettings(callToActionSettings);
          const data = callToActionSettings.map((setting) => ({
            ...setting,
            id: uniqueIdConstructorCTA(setting),
          }));
          db.callToActionSettings.bulkAdd(data);
        })
        .catch(async (error) => {
          logging(
            'warn',
            'Unable to retrieve Call To Action settings. Falling back to offline data',
            error,
            false,
            null
          );

          const dbCallToActionSettings = await db.callToActionSettings
            .where({
              endpointId: endpointId,
              taskNumber: taskNumber,
            })
            .toArray();

          setCallToActionSettings(dbCallToActionSettings);
        });
    },
    [langCode, endpointId]
  );

  const returnVal = [
    callToActionSettings,
    syncCTASettings,
    setCallToActionSettings,
  ] as [
    CallToActionSettings[] | undefined,
    (taskNumber: string | undefined) => void,
    Dispatch<SetStateAction<CallToActionSettings[] | undefined>>
  ];
  return returnVal;
};
export default useGetCtaSettings;
