import { useLiveQuery } from 'dexie-react-hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionableListItem from '../../components/ActionableListItem';
import SectionHeading from '../../components/SectionHeading';
import useNavigateToEfc from '../../hooks/useNavigateToEfc';
import db from '../../index-db';

//TBD put it in a common config file?
const UPDATED_EFC_DAYS = 30;

function UpdatedEFCItem() {
  const { t } = useTranslation();
  const updatedEFCItems = useLiveQuery(async () => {
    let cutOffDate = new Date();
    cutOffDate.setDate(cutOffDate.getDate() - UPDATED_EFC_DAYS);
    return await db.efcs
      .where('LastUpdate')
      .aboveOrEqual(cutOffDate.toISOString())
      .reverse()
      .limit(10)
      .toArray();
  });
  const navigateToEfc = useNavigateToEfc();
  const handleClick = (idGen: string) => {
    navigateToEfc(idGen);
  };
  return (
    <>
      <div className="mr-1 mb-0.5 flex items-center justify-between">
        <SectionHeading
          title={`${t('Updates')} (${updatedEFCItems?.length})`}
        />
      </div>
      {updatedEFCItems?.map((updatedEFCItem) => (
        <ActionableListItem
          title={t('Updated Content for {{item}}.', {
            item: updatedEFCItem.EfcCodeStr,
          })}
          primaryAction="navigate"
          onClick={() => {
            handleClick(updatedEFCItem.Id.toString());
          }}
          key={updatedEFCItem.Id}
          appearance="nonBold"
        />
      ))}
    </>
  );
}

export default UpdatedEFCItem;
