import InsightEntity from '../../Models/Insight';
import db from '../../index-db';
import { useState } from 'react';
import EFC from '../../Models/EFC';
import { useEffect } from 'react';
import { InsightType, CallToActionType } from '../../Models/Enumerations';
import useNavigateToEfc from '../../hooks/useNavigateToEfc';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import { LogType } from '../../enums/LogType';
import Module from '../../Models/Module';
import SystemsCard from '../../components/cards/SystemsCard';
import useSaveCallToActionSetting from '../../hooks/useSaveCallToActionSetting';

interface Props {
  insight: InsightEntity;
  insightType: InsightType;
  endpointId: string;
  serviceTaskId?: string;
  showRAPerformedIcon: boolean;
  raPerformedFeedback: string;
  isCTARead: boolean;
}

function Insight({
  insight,
  insightType,
  endpointId,
  serviceTaskId,
  showRAPerformedIcon,
  raPerformedFeedback,
  isCTARead = true,
}: Props) {
  const [efcData, setEFCData] = useState({} as EFC);
  const [moduleData, setModuleData] = useState({} as Module);
  const logging = useLogging();
  const SaveCallToActionSetting = useSaveCallToActionSetting();
  useEffect(() => {
    (async () => {
      if (insight.code) {
        const efcRecord = await db.efcs
          .where('EfcCodeStr')
          .equalsIgnoreCase(insight.code)
          .first();

        if (efcRecord) {
          setEFCData(efcRecord);
          const moduleRecord = await db.modules
            .where({
              Id: efcRecord.ModuleId,
            })
            .first();
          if (moduleRecord) {
            setModuleData(moduleRecord);
          }
        }
      }
    })();
  }, [insight.code]);

  const navigateToEfc = useNavigateToEfc();
  const handleClick = () => {
    const logParams: LogParams[] = [
      {
        key: LogType.Page,
        value: LogType.Equipment,
      },
      {
        key: LogType.Insight,
        value: insightType === InsightType.Service ? 'Service' : 'Preventive',
      },
      {
        key: LogType.EndpointId,
        value: endpointId,
      },
      {
        key: LogType.ServiceTaskId,
        value: serviceTaskId || '',
      },
      {
        key: LogType.EfcCode,
        value: insight.code,
      },
    ];
    logging('info', '', '', false, logParams);

    const ctaType =
      insightType === InsightType.Service
        ? CallToActionType.ServiceInsight
        : CallToActionType.PreventiveInsight;
    if (efcData) {
      if (!isCTARead) {
        SaveCallToActionSetting(
          ctaType,
          endpointId,
          efcData.EfcCodeStr,
          serviceTaskId
        );
      }
      navigateToEfc(efcData.Id.toString(), true);
    }
  };

  let currentDate = new Date(Date.now());
  let timestamp = new Date(insight.timestamp);

  const diffInTime = currentDate.getTime() - timestamp.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  const hours =
    timestamp.getHours() < 10
      ? `0${timestamp.getHours()}`
      : timestamp.getHours().toString();
  const minutes =
    timestamp.getMinutes() < 10
      ? `0${timestamp.getMinutes()}`
      : timestamp.getMinutes().toString();

  return (
    <SystemsCard
      iconColor="steel"
      clickableType="secondary"
      onCardClick={handleClick}
      showRAPerformedIcon={showRAPerformedIcon}
      raPerformedFeedback={raPerformedFeedback}
      readUnreadIconType={isCTARead ? 'secondary' : 'primary'}
    >
      <div className="min-w-0 flex-1">
        <div className="break-words text-lg font-bold">
          {moduleData.ModuleName} EFC
        </div>
        {insight.fccUseCase && (
          <div className="flex items-start justify-between">
            <div className="break-all">
              <span className="font-bold text-purple">Use Case: </span>
              {insight.fccUseCase}
            </div>
          </div>
        )}
        {insight.fccPosition && (
          <div className="flex items-start justify-between">
            <div className="break-all">
              <span className="font-bold text-purple">Position: </span>
              {insight.fccPosition}
            </div>
          </div>
        )}
        {insight.fccData && (
          <div className="flex items-start justify-between">
            <div className="break-all">
              <span className="font-bold text-purple">Data: </span>
              {insight.fccData}
            </div>
          </div>
        )}
        <div className="pt-1 font-bold text-steel">
          {diffInDays} {'days ago'} {hours}:{minutes}{' '}
          {
            timestamp
              .toDateString()
              .split(timestamp.getFullYear().toString())[0]
          }
        </div>
      </div>
    </SystemsCard>
  );
}
export default Insight;
