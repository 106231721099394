import { TimeLineEventType } from '../../../Models/Enumerations';
import {
  ModifiedTimeline,
  modifiedTimeStamps,
} from '../../../Models/TimeLineEvent';

type consolidator = { [key: string]: number };

const statusDecider = (status?: string) => {
  switch (status?.toLowerCase()) {
    case 'interrupt':
      return 'Interrupt';
    case 'cancelled':
      return 'Cancelled';
    case 'closed':
      return 'Closed';
    case 'incomplete':
      return 'Incomplete';
    case undefined:
      return '';
    default:
      return 'Open';
  }
};
interface Args {
  currentTimeline: ModifiedTimeline;
  accumulatedArray: ModifiedTimeline[];
}
interface TaskModifierInterface extends Args {
  taskConsolidator: consolidator;
}

const taskModifier = ({
  accumulatedArray,
  currentTimeline,
  taskConsolidator,
}: TaskModifierInterface) => {
  if (TimeLineEventType[TimeLineEventType.Task] === currentTimeline.type) {
    if (taskConsolidator[currentTimeline?.event] === undefined) {
      taskConsolidator[currentTimeline.event] =
        accumulatedArray.push({
          ...currentTimeline,
          timestamps: [
            {
              value: currentTimeline.timestamp,
              status: statusDecider(currentTimeline.properties?.SharedStatus),
            },
          ],
        }) - 1;
    } else {
      const val = accumulatedArray[taskConsolidator[currentTimeline.event]]
        ?.timestamps
        ? accumulatedArray[taskConsolidator[currentTimeline.event]]?.timestamps
        : [
            {
              value:
                accumulatedArray[taskConsolidator[currentTimeline.event]]
                  .timestamp,
              status: statusDecider(
                accumulatedArray[taskConsolidator[currentTimeline.event]]
                  .properties?.SharedStatus
              ),
            },
          ];
      accumulatedArray[taskConsolidator[currentTimeline.event]].timestamps = [
        ...(val as [modifiedTimeStamps]),
        {
          value: currentTimeline.timestamp,
          status: statusDecider(currentTimeline.properties?.SharedStatus),
        },
      ];
    }
  }
};

interface PartsModifierInterface extends Args {
  sameDayCount: number;
  partsConsolidator: consolidator;
}

const partsModifier = ({
  accumulatedArray,
  currentTimeline,
  partsConsolidator,
  sameDayCount,
}: PartsModifierInterface) => {
  if (TimeLineEventType[TimeLineEventType.Part] === currentTimeline.type) {
    if (partsConsolidator[currentTimeline?.event] === undefined) {
      partsConsolidator[currentTimeline.event] =
        accumulatedArray.push({
          ...currentTimeline,
          timestamps: [
            {
              value: currentTimeline.timestamp,
              status: statusDecider(currentTimeline.properties?.SharedStatus),
            },
          ],
        }) - 1;
      sameDayCount = 0;
    } else {
      const val = (
        accumulatedArray[partsConsolidator[currentTimeline.event]]?.timestamps
          ? accumulatedArray[partsConsolidator[currentTimeline.event]]
              .timestamps
          : [
              {
                value:
                  accumulatedArray[partsConsolidator[currentTimeline.event]]
                    .timestamp,
                status: statusDecider(
                  accumulatedArray[partsConsolidator[currentTimeline.event]]
                    .properties?.SharedStatus
                ),
              },
            ]
      ) as modifiedTimeStamps[];

      const d1 = val[val.length - 1].value;
      const d2 = currentTimeline.timestamp;
      sameDayCount = val[val.length - 1].count ?? 1;

      const isSameDay =
        new Date(`${d1}`).getTime() - new Date(`${d2}`).getTime() <
        1000 * 3600 * 24;
      if (isSameDay) {
        sameDayCount = sameDayCount + 1;
        const mappedData = val.map((timestamp) => {
          if (d1 === timestamp.value) {
            return {
              value: d2,
              status: undefined,
              count: sameDayCount,
            };
          } else {
            return { ...timestamp };
          }
        });
        accumulatedArray[partsConsolidator[currentTimeline.event]].timestamps =
          mappedData;
      } else {
        sameDayCount = 0;
        accumulatedArray[partsConsolidator[currentTimeline.event]].timestamps =
          [
            ...(val as [modifiedTimeStamps]),
            {
              value: currentTimeline.timestamp,
              status: statusDecider(currentTimeline.properties?.SharedStatus),
            },
          ];
      }
    }
  }
};

const efcModifier = ({ accumulatedArray, currentTimeline }: Args) => {
  if (
    TimeLineEventType[TimeLineEventType.EFC] === currentTimeline.type ||
    TimeLineEventType[TimeLineEventType.ServiceInsight] === currentTimeline.type
  ) {
    const lastIndex = accumulatedArray.length - 1;
    if (
      accumulatedArray.length &&
      accumulatedArray[lastIndex].event === currentTimeline.event &&
      accumulatedArray[lastIndex].type === currentTimeline.type
    ) {
      accumulatedArray[lastIndex].timestamps = [
        ...(accumulatedArray[lastIndex]?.timestamps ?? [
          {
            value: accumulatedArray[lastIndex].timestamp,
            status: undefined,
          },
        ]),
        { value: currentTimeline.timestamp, status: undefined },
      ];
    } else {
      accumulatedArray.push({
        ...currentTimeline,
        timestamps: [{ value: currentTimeline.timestamp, status: undefined }],
      });
    }
  }
};

const repairsModifier = ({ accumulatedArray, currentTimeline }: Args) => {
  if (TimeLineEventType[TimeLineEventType.Repair] === currentTimeline.type) {
    const val = {
      ...currentTimeline,
      timestamps: [{ value: currentTimeline.timestamp, status: undefined }],
    };
    accumulatedArray.push(val);
  }
};

const timelineModifier = (timeline: ModifiedTimeline[]) => {
  let sameDayCount = 0;
  const taskConsolidator: consolidator = {};
  const partsConsolidator: consolidator = {};
  const accumulatedArray: ModifiedTimeline[] = [];
  const sortedTimeLine = [...timeline].sort((a, b) => {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  });

  sortedTimeLine.forEach((currentElement) => {
    const currentTimeline = { ...currentElement };

    taskModifier({ currentTimeline, accumulatedArray, taskConsolidator });
    efcModifier({ currentTimeline, accumulatedArray });
    partsModifier({
      currentTimeline,
      accumulatedArray,
      partsConsolidator,
      sameDayCount,
    });

    repairsModifier({ currentTimeline, accumulatedArray });
  });

  return accumulatedArray;
};

export default timelineModifier;
