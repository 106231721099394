import React, { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Provider as ReduxProvider } from 'react-redux/es/exports';
import { store } from './app/store';
import Button from './components/Button';
import DBInitialize from './components/DBInitialize';
import ErrorFallback from './components/ErrorFallback';
import ToastProvider from './components/toast/ToastProvider';
import ToastQueue from './components/toast/ToastQueue';
import { IS_USER_AUTHENTICATED_KEY, USER_REALM_KEY } from './constants';
import PageProvider from './PageProvider';

function AppProviders(props: PropsWithChildren<{}>) {
  const auth = useAuth();
  if(auth?.user?.id_token)
  {
    auth.settings.extraQueryParams= { 'id_token_hint': auth?.user?.id_token }
  }
  const { t } = useTranslation();
  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>{t('Signing you in')}...</div>;
    case 'signoutRedirect':
      return <div>{t('Signing you out')}...</div>;
  }

  if (auth.isLoading) {
    return <div>{t('Loading')}...</div>;
  }

  if (auth.error) {
    if (auth.error.message === 'No matching state found in storage') {
      console.log('No matching state found in storage');
      window.localStorage.removeItem(IS_USER_AUTHENTICATED_KEY);
      window.localStorage.removeItem(USER_REALM_KEY);
      auth.signoutRedirect();
      return <div>{t('Signing you out')}</div>;
    } else {
      return (
        <>
          <header className="relative flex h-14 shrink-0 items-center justify-between border-b border-gray-200 bg-white px-1">
            <div className="pointer-events-none absolute inset-x-0">
              <h1 className="text-center text-2xl font-medium text-black">
                {t('Session Expired')}
              </h1>
            </div>
            <div className="absolute-shadow below" />
          </header>
          <div className="flex flex-col items-start gap-y-4 p-4">
            <span className="text-lg font-medium text-dnred">
              {t(
                'The current session has expired while offline. Please bring the device back online and select the Login button below to perform the login and take you back to the home screen.'
              )}
            </span>
            <div className="inline-block flex">
              <Button
                styling="primary"
                onClick={async () => {
                  if (navigator.onLine) {
                    await auth.signoutRedirect();
                    await auth.signinRedirect();
                  }
                }}
              >
                {t('Login')}
              </Button>
            </div>
          </div>
        </>
      );
    }
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return null;
  }

  return (
    <ReduxProvider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DBInitialize>
          <ToastProvider>
            <PageProvider>{props.children}</PageProvider>
            <ToastQueue />
          </ToastProvider>
        </DBInitialize>
      </ErrorBoundary>
    </ReduxProvider>
  );
}

export default AppProviders;
