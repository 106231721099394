import { Controller, useForm } from 'react-hook-form';
import MuiButton from '@mui/material/Button';

import Modal from '../Modal';

import { useTranslation } from 'react-i18next';
import EndPoint from '../../Models/EndPoint';
import db from '../../index-db';
import ManualEndPoint from '../../Models/ManualEndPoint';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import { useToast } from '../toast/ToastProvider';
import useLogging from '../../hooks/useLogging';
import SwitchCheckbox from '../form/SwitchCheckbox';
import { useEffect, useState } from 'react';
import Dropdown from '../form/Dropdown';
import { useDebouncedCallback } from 'use-debounce';

interface OnSubmitArgs {
  serialNumber: string;
  tag: string;
}

interface OptionItem {
  address1: string;
  city: string;
  parentName: string;
  serialNumber: string;
  terminalId: string;
}

interface Props {
  onSubmit: () => void;
  onDismiss: () => void;
  onModalClose: () => void;
  isModalOpen: boolean;
}

const AddEquipmentModal = (props: Props) => {
  const { onSubmit, onDismiss, onModalClose, isModalOpen } = props;

  const { t } = useTranslation();
  const toast = useToast();
  const logging = useLogging();
  const api = useApi();
  const checkMapping = {
    true: t('Serial Number'),
    false: t('Machine Name'),
  };
  const {
    register,
    unregister,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    resetField,
  } = useForm({
    mode: 'all',
    defaultValues: {
      serialNumber: '',
      machineName: '',
      tag: '',
    },
  });
  const [options, setOptions] = useState<OptionItem[] | []>([]);

  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    setOptions([]);
    if (isChecked) {
      unregister('machineName');
      reset();
    } else {
      reset();
      register('machineName');
    }
  }, [isChecked]);
  const resetMachineForm = () => {
    setIsChecked(true);
    setOptions([]);
  };

  const fetchEndPoints = async (serialNumber: string): Promise<EndPoint[]> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointByFilter/?serialNumber=${serialNumber}`
    )) as EndPoint[];
  };

  const onAdd = async (data: OnSubmitArgs) => {
    const { serialNumber, tag } = data;
    try {
      const endPoints = await fetchEndPoints(serialNumber);

      if (endPoints) {
        let manualEndPoints = [] as ManualEndPoint[];
        endPoints.forEach((endPoint) => {
          manualEndPoints.push(
            new ManualEndPoint(
              endPoint.id,
              endPoint.serialNumber,
              endPoint.prodDesc,
              endPoint.terminalId,
              endPoint.customerName,
              endPoint.preventiveInsights,
              endPoint.serviceInsights,
              endPoint?.chronicDetails,
              endPoint.generatedSummary,
              endPoint?.chronicIndicator,
              tag
            )
          );
        });

        await db.manualEndPoints.bulkPut(manualEndPoints);

        if (manualEndPoints.length === 0) {
          logging(
            'error',
            `No equipment data for serial number ${serialNumber}`,
            '',
            false,
            null
          );

          toast.pushToast({
            type: 'error',
            message: t(
              `No equipment data found for serial number {{number}}.`,
              {
                number: serialNumber,
              }
            ),
          });
        }

        onSubmit();
        reset();
      }
    } catch (error) {
      logging(
        'error',
        `Unable to retrieve equipment data for serial number ${serialNumber}`,
        error,
        false,
        null
      );
      toast.pushToast({
        type: 'error',
        message: t(
          `Unable to retrieve equipment data for the serial number ${serialNumber}.`
        ),
      });
    }
    resetMachineForm();
  };

  const onReject = () => {
    onDismiss();
    reset();

    resetMachineForm();
  };
  const onPopupClose = () => {
    onModalClose();
  };

  const onSearialNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue('serialNumber', event.target.value.toUpperCase(), {
      shouldValidate: true,
    });
  };

  const onCheckChange = (status: boolean) => {
    setIsChecked(status);
  };

  const fetchSerialNumberLists = async (machineName: string) => {
    return api.fetchJson(
      `/dwar/api/acfs/InstallBase/getInstallBaseListByMachineName?machineName=${machineName}`
    );
  };

  const onNameChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        fetchSerialNumberLists(encodeURIComponent(event.target.value))
          .then((data) => {
            setOptions(data);

            if (data.length) {
              toast.pushToast({
                type: 'success',
                message: 'Serial Numbers fetched Successfully.',
              });
            } else {
              toast.pushToast({
                type: 'info',
                message: 'No Serial Numbers found related to the machine name.',
              });
            }
          })
          .catch((e) => {
            logging(
              'error',
              `No equipment data for Machine Name ${event.target.value}`,
              e,
              false,
              null
            );
          });
      }
    },
    3000
  );

  const onMachineNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('machineName', event.target.value, { shouldValidate: true });

    if (!event.target.value) {
      setValue('serialNumber', '');
      setOptions([]);
    }
    onNameChange(event);
  };
  useEffect(() => {
    if (!options.length) {
      resetField('serialNumber');
    }
  }, [options]);

  const dropDownRenderStructure = (val: OptionItem) =>
    `${val.serialNumber}--${val.parentName}--${val.address1}--${val.city}`;
  const dropdownRenderValue = (serialNumber: unknown) => {
    const foundValue = options.find(
      (option) => option.serialNumber === serialNumber
    );
    if (foundValue) {
      return <>{`${foundValue.serialNumber}--${foundValue.parentName}`}</>;
    }
    return <>{serialNumber}</>;
  };

  return (
    <>
      <Modal show={isModalOpen} handleClose={onPopupClose}>
        <form onSubmit={handleSubmit(onAdd)} className="px-4">
          <div className="mt-4 mb-2 flex items-center justify-between gap-x-2 ">
            <h2
              className={'text-left text-lg font-bold text-cobalt'}
              onClick={onReject}
            >
              {t('Cancel')}
            </h2>

            <MuiButton
              color="primary"
              disabled={!formState.isValid}
              type="submit"
              size="large"
              sx={{ fontWeight: 700 }}
              style={{ textTransform: 'none' }}
            >
              {t('Add')}
            </MuiButton>
          </div>
          <div className="mt-4 ">
            <h2 className={'text-left text-xl font-medium text-iron'}>
              {t('Add equipment')}
            </h2>
            <p className="mt-2 text-left text-lg font-normal text-iron">
              {t(
                'Enter the serial number to view the insights and details for that piece of equipment.'
              )}
            </p>
          </div>
          <div className="flex gap-2">
            <span className=" font-bold">{t('Search By')}</span>
            <span className="flex items-center gap-1">
              <span className="w-24">{checkMapping[`${isChecked}`]}</span>
              <SwitchCheckbox
                onCustomCheckChange={onCheckChange}
                value={isChecked}
              />
            </span>
          </div>

          {!isChecked && (
            <>
              <div className="mt-4 mb-4 ">
                <h2
                  className={'w-80 text-left text-base font-medium text-iron'}
                >
                  {t('Machine Name') + '*'}
                </h2>
                <input
                  type="text"
                  className="mt-2 h-10 w-full rounded-lg border border-steel p-2"
                  {...register('machineName', {
                    required: 'Machine Name is required',
                  })}
                  onChange={onMachineNameChange}
                />
                {formState.errors?.machineName && (
                  <div className="text-tangerine">
                    {formState.errors.machineName.message}
                  </div>
                )}
              </div>
              <div className=" mb-4">
                <h2
                  className={'w-80 text-left text-base font-medium text-iron'}
                >
                  {t('Serial Number') + '*'}
                </h2>
                <Controller
                  name="serialNumber"
                  control={control}
                  rules={{
                    required: 'Serial Number is required',
                  }}
                  render={({ field, formState }) => {
                    return (
                      <Dropdown
                        valueIdentifier="serialNumber"
                        options={options}
                        isDisabled={Boolean(!options.length)}
                        optionsRenderStructure={dropDownRenderStructure}
                        renderValue={dropdownRenderValue}
                        variant="outlined"
                        {...field}
                      />
                    );
                  }}
                />

                {formState.errors?.serialNumber && (
                  <div className="text-tangerine">
                    {formState.errors.serialNumber.message}
                  </div>
                )}
              </div>
            </>
          )}

          {isChecked && (
            <div className="mt-4 mb-4 ">
              <h2 className={'w-80 text-left text-base font-medium text-iron'}>
                {t('Serial Number') + '*'}
              </h2>
              <input
                type="text"
                className="mt-2 h-10 w-full rounded-lg border border-steel p-2"
                {...register('serialNumber', {
                  required: 'Serial Number is required',
                })}
                onChange={onSearialNumberChange}
              />
              {formState.errors?.serialNumber && (
                <div className="text-tangerine">
                  {formState.errors.serialNumber.message}
                </div>
              )}
            </div>
          )}
          <div className="mb-4 ">
            <h2>{t('Tag')}</h2>
            <input
              type="text"
              className="mt-2 h-10 w-full rounded-lg border border-steel p-2"
              {...register('tag', {
                maxLength: {
                  value: 60,
                  message: 'Maximum number of characters allowed are 60.',
                },
              })}
            />
            {formState.errors?.tag && (
              <div className="text-tangerine">
                {formState.errors.tag.message}
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

AddEquipmentModal.defaultProps = {
  onSubmit: () => {},
};

export default AddEquipmentModal;
