export interface ITimelineEvent {
  id: string;
  description: string;
  endpointId: string;
  event: string;
  timestamp: string;
  type: string;
  properties: Record<string, string>;
  taskStatus?: string;
  sharedStatus?: string;
}
export type modifiedTimeStamps = {
  value: string;
  status?: string;
  count?: number;
};

export class TimelineEvent {
  id: string;
  description: string;
  endpointId: string;
  event: string;
  timestamp: string;
  type: string;
  properties: Record<string, string>;
  taskStatus?: string;
  sharedStatus?: string;

  constructor(
    id: string,
    description: string,
    endpointId: string,
    event: string,
    timestamp: string,
    type: string,
    properties: Record<string, string>,
    taskStatus?: string,
    sharedStatus?: string
  ) {
    this.id = id;
    this.description = description;
    this.endpointId = endpointId;
    this.event = event;
    this.timestamp = timestamp;
    this.type = type;
    this.properties = properties;
    this.taskStatus = taskStatus;
    this.sharedStatus = sharedStatus;
  }
}
export interface ModifiedTimeline extends TimelineEvent {
  timestamps?: modifiedTimeStamps[];
}

export default TimelineEvent;
