import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../components/Button';
//import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Resizer from 'react-image-file-resizer';

export function MultipleFileSelect({
  name,
  maxFiles,
}: {
  name: string;
  maxFiles: number;
}) {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column' as 'column', // workaround to an known issue https://github.com/cssinjs/jss/issues/1344
    padding: '10px',
  };
  const { register, unregister, setValue } = useFormContext();
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [maxFileError, setMaxFileError] = useState(false);
  const { t } = useTranslation();
  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });
  const onDelete = (name: string) => {
    // find the index of the item
    // remove the item from array
    const validFileIndex = validFiles.findIndex((e) => e.name === name);
    validFiles.splice(validFileIndex, 1);
    setSelectedFiles([...validFiles]);
    if (validFiles.length <= maxFiles) {
      setMaxFileError(false);
    }
  };
  useEffect(() => {
    //To remove duplicate entries
    /*let filteredArray = selectedFiles.reduce((file: File[], current) => {
      const x = file.find((item) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);*/
    (async () => {
      if (selectedFiles.length <= maxFiles) {
        await Promise.all(
          [...selectedFiles].map((image: File) => {
            return resizeFile(image);
          })
        ).then((resized) => {
          setValidFiles(resized as File[]);
        });
      } else {
        await Promise.all(
          [...selectedFiles].slice(0, maxFiles).map((image: File) => {
            return resizeFile(image);
          })
        ).then((resized) => {
          setValidFiles(resized as File[]);
        });
        setMaxFileError(true);
      }
    })();
  }, [selectedFiles]);

  useEffect(() => {
    setValue(name, validFiles);
  }, [validFiles]);

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, name, unregister]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setMaxFileError(false);
    setSelectedFiles((currentFiles) => [...currentFiles, ...acceptedFiles]);
  }, []);

  const {
    getRootProps: getRootPropsCamera,
    getInputProps: getInputPropsCamera,
    fileRejections: fileRejectionsCamera,
  } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxSize: 10000 * 1024, // 10 MB
    multiple: true,
    noClick: true,
  });
  const {
    getRootProps: getRootPropsGallery,
    getInputProps: getInputPropsGallery,
    fileRejections: fileRejectionsGallery,
  } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxSize: 10000 * 1024, // 10 MB
    multiple: true,
  });
  const acceptedFileItems = validFiles.map((file, index) => (
    <div
      key={index}
      className="mt-1 items-center justify-center break-words font-medium"
    >
      <li>
        {file.name}
        <Button
          className=""
          styling="tertiary"
          onClick={() => onDelete(file.name)}
        >
          {t('Delete')}
        </Button>
      </li>
    </div>
  ));

  const fileRejectionItemsCamera = fileRejectionsCamera.map(
    ({ file, errors }, index) => (
      <>
        <div
          key={index}
          className="mt-1 flex items-center justify-center font-medium"
        >
          <li>{file.name}</li>
        </div>
        {errors.map((e, index) => (
          <div className="-mt-2 text-center text-tangerine" key={index}>
            File not uploaded: {e.message}
          </div>
        ))}
      </>
    )
  );
  const fileRejectionItemsGallery = fileRejectionsGallery.map(
    ({ file, errors }, index) => (
      <>
        <div
          key={index}
          className="mt-1 flex items-center justify-center font-medium"
        >
          <li>{file.name}</li>
        </div>
        {errors.map((e, index) => (
          <div className="-mt-2 text-center text-tangerine" key={index}>
            File not uploaded: {e.message}
          </div>
        ))}
      </>
    )
  );
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );
  return (
    <>
      <div {...getRootPropsCamera({ style })}>
        <label
          className="block flex items-center justify-center px-3 font-medium text-cobalt"
          htmlFor="upload-photo"
        >
          {t('Open Camera')}
        </label>
        <input
          id="upload-photo"
          multiple
          capture="environment"
          {...getInputPropsCamera()}
        />
      </div>
      <div {...getRootPropsGallery({ style })}>
        <input multiple {...getInputPropsGallery()} />
        <Button
          className=""
          styling="tertiary"
          to=""
          onClick={undefined}
          icon={AddPhotoAlternateOutlinedIcon}
        >
          {t('Select Photos')}
        </Button>
      </div>
      {acceptedFileItems}
      <br />
      {maxFileError && (
        <div className="-mt-2 text-center text-tangerine">
          {t('Upto {{maxFiles}} images can be selected', {
            maxFiles: maxFiles,
          })}
        </div>
      )}
      {fileRejectionItemsCamera}
      {fileRejectionItemsGallery}
    </>
  );
}
