import Insight from './Insight';
import { IServiceSiteMessage } from './ServiceSiteMessage';
import TaskNote from './TaskNote';

export interface ITask {
  id: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  chronicIndicator: string;
  city: string;
  closedDate: string;
  consumedParts: [] | null;
  contactDate: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  contractCoverageHours: string;
  country: string;
  creationDate: string;
  customer: string;
  dataSource: string;
  deviceCustomerProductId: string;
  deviceDescription: string;
  deviceSerialNumber: string;
  deviceType: string;
  effectiveDate: string;
  endpointId: string;
  enhanceable: boolean;
  lastUpdatedDate: string;
  partsRequired: [] | null;
  postalCode: string;
  preventiveInsights: Insight[] | null;
  hasEndPointPreventiveInsights: boolean;
  hasEndPointServiceInsights: boolean;
  priority: string;
  problem: string;
  serviceInsights: Insight[] | null;
  serviceRequestNumber: string;
  serviceTaskNotes: TaskNote[] | null;
  sharedStatus: string;
  siteMessages: IServiceSiteMessage[] | null;
  siteNumber: string;
  state: string;
  taskNumber: string;
  taskOwner: string;
  taskStatus: string;
  taskType: string;
  taskTypeCode: string;
  team: string;
  serviceBranch: string;
  generatedSummary: string;
}

export class Task {
  id: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  chronicIndicator: string;
  city: string;
  closedDate: string;
  consumedParts: [] | null;
  contactDate: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  contractCoverageHours: string;
  country: string;
  creationDate: string;
  customer: string;
  dataSource: string;
  deviceCustomerProductId: string;
  deviceDescription: string;
  deviceSerialNumber: string;
  deviceType: string;
  effectiveDate: string;
  endpointId: string;
  enhanceable: boolean;
  lastUpdatedDate: string;
  partsRequired: [] | null;
  postalCode: string;
  preventiveInsights: Insight[] | null;
  hasEndPointPreventiveInsights: boolean;
  hasEndPointServiceInsights: boolean;
  priority: string;
  problem: string;
  serviceInsights: Insight[] | null;
  serviceRequestNumber: string;
  serviceTaskNotes: TaskNote[] | null;
  sharedStatus: string;
  siteMessages: IServiceSiteMessage[] | null;
  siteNumber: string;
  state: string;
  taskNumber: string;
  taskOwner: string;
  taskStatus: string;
  taskType: string;
  taskTypeCode: string;
  team: string;
  serviceBranch: string;
  generatedSummary: string;

  constructor(
    id: string,
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    chronicIndicator: string,
    city: string,
    closedDate: string,
    consumedParts: [] | null,
    contactDate: string,
    contactEmail: string,
    contactName: string,
    contactPhone: string,
    contractCoverageHours: string,
    country: string,
    creationDate: string,
    customer: string,
    dataSource: string,
    deviceCustomerProductId: string,
    deviceDescription: string,
    deviceSerialNumber: string,
    deviceType: string,
    effectiveDate: string,
    endpointId: string,
    enhanceable: boolean,
    lastUpdatedDate: string,
    partsRequired: [] | null,
    postalCode: string,
    preventiveInsights: Insight[] | null,
    priority: string,
    problem: string,
    serviceInsights: Insight[] | null,
    serviceRequestNumber: string,
    serviceTaskNotes: TaskNote[] | null,
    sharedStatus: string,
    siteMessages: IServiceSiteMessage[] | null,
    siteNumber: string,
    state: string,
    taskNumber: string,
    taskOwner: string,
    taskStatus: string,
    taskType: string,
    taskTypeCode: string,
    team: string,
    serviceBranch: string,
    hasEndPointPreventiveInsights: boolean,
    hasEndPointServiceInsights: boolean,
    generatedSummary: string
  ) {
    this.id = id;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.addressLine3 = addressLine3;
    this.chronicIndicator = chronicIndicator;
    this.city = city;
    this.closedDate = closedDate;
    this.consumedParts = consumedParts;
    this.contactDate = contactDate;
    this.contactEmail = contactEmail;
    this.contactName = contactName;
    this.contactPhone = contactPhone;
    this.contractCoverageHours = contractCoverageHours;
    this.country = country;
    this.creationDate = creationDate;
    this.customer = customer;
    this.dataSource = dataSource;
    this.deviceCustomerProductId = deviceCustomerProductId;
    this.deviceDescription = deviceDescription;
    this.deviceSerialNumber = deviceSerialNumber;
    this.deviceType = deviceType;
    this.effectiveDate = effectiveDate;
    this.endpointId = endpointId;
    this.enhanceable = enhanceable;
    this.lastUpdatedDate = lastUpdatedDate;
    this.partsRequired = partsRequired;
    this.postalCode = postalCode;
    this.preventiveInsights = preventiveInsights;
    this.priority = priority;
    this.problem = problem;
    this.serviceInsights = serviceInsights;
    this.serviceRequestNumber = serviceRequestNumber;
    this.serviceTaskNotes = serviceTaskNotes;
    this.sharedStatus = sharedStatus;
    this.siteMessages = siteMessages;
    this.siteNumber = siteNumber;
    this.state = state;
    this.taskNumber = taskNumber;
    this.taskOwner = taskOwner;
    this.taskStatus = taskStatus;
    this.taskType = taskType;
    this.taskTypeCode = taskTypeCode;
    this.team = team;
    this.serviceBranch = serviceBranch;
    this.hasEndPointPreventiveInsights = hasEndPointPreventiveInsights;
    this.hasEndPointServiceInsights = hasEndPointServiceInsights;
    this.generatedSummary = generatedSummary;
  }
}

export default Task;
