import * as uuid from 'uuid';
import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import PageAction from '../../components/PageAction';
import db from '../../index-db';
import Dropdown, { IOption } from '../../components/EndpointDropdown';
import { useApi } from '../../acfs-apis/dwar-api-provider';
import Task from '../../Models/Task';
import EndPoint from '../../Models/EndPoint';
import TimelineEvent from '../../Models/TimeLineEvent';
import VerifiedOutlined from '@mui/icons-material/Verified';
import { useTranslation } from 'react-i18next';
import { TimeLineEventType } from '../../Models/Enumerations';
import RepairActionComplete from './RepairActionComplete';
import { LAST_ACCESSED_ENDPOINT } from '../../constants';
import Modal from '../../components/Modal';
import useSaveTimeLineEvent from '../../hooks/useSaveTimeLineEvent';

interface Props {
  raId: number;
  efcId: number;
  efcCode: string;
  raDescription: string;
  raName: string;
  moduleId: number;
  externalSource: string;
  raCompleteDisplayTime: string;
  serialNumber?: string;
  handleSelectedEndpoint: (selectedEndpoint?: EndPoint) => void;
}

function RepairPageAction(props: Props) {
  const api = useApi();
  const [userEndpoints, setUserEndpoints] = useState([] as IOption[]);
  const [selectedEndpoint, setSelectedEndpoint] = useState('');
  const [displayTime, setDisplayTime] = useState('');
  const [showFeedbackOptions, setShowFeedbackOptions] = useState(false);
  const [openServiceTasks, setOpenServiceTasks] = useState([] as Task[]);
  const [showFeedbackConfirmation, setShowFeedbackConfirmation] =
    useState(false);
  const { t } = useTranslation();
  const lastAccessedEndpoint =
    window.localStorage.getItem(LAST_ACCESSED_ENDPOINT) ?? '';

  const handleOk = () => {
    setShowFeedbackConfirmation(false);
    setShowFeedbackOptions(true);
  };

  const onSuccessfulSaveTimeLineEvent = (currentTime: string) => {
    const currentDate = new Date(Date.now());
    const timestamp = new Date(currentTime);

    const diffInTime = currentDate.getTime() - timestamp.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    const hours =
      timestamp.getHours() < 10
        ? `0${timestamp.getHours()}`
        : timestamp.getHours().toString();
    const minutes =
      timestamp.getMinutes() < 10
        ? `0${timestamp.getMinutes()}`
        : timestamp.getMinutes().toString();
    const seconds =
      timestamp.getSeconds() < 10
        ? `0${timestamp.getSeconds()}`
        : timestamp.getSeconds().toString();

    setDisplayTime(
      `${diffInDays} days ago ${hours}:${minutes}:${seconds} ${timestamp.toDateString()}`
    );
  };

  const SaveTimeLine = useSaveTimeLineEvent(onSuccessfulSaveTimeLineEvent);

  async function SaveTimeLineEvent(feedback: string) {
    const currentTime = new Date().toISOString();
    const timelineEventProperties: Record<string, string> = {
      efcId: props.efcId.toString(),
      feedback: feedback,
    };

    const timelineEvent = new TimelineEvent(
      uuid.v4().toString(),
      props.raDescription,
      lastAccessedEndpoint,
      props.raId.toString(),
      currentTime,
      TimeLineEventType[TimeLineEventType.Repair],
      timelineEventProperties
    );

    SaveTimeLine(timelineEvent, currentTime);
  }

  useEffect(() => {
    setDisplayTime(props.raCompleteDisplayTime);
  }, [props.raCompleteDisplayTime]);

  const actionsProps = {
    icon: VerifiedOutlined,
    title: `${t('Update')}`,
    onClick: () => {
      const serviceTaskEndpointId =
        getActiveServiceTaskEndpoint(openServiceTasks);

      if (serviceTaskEndpointId === '') {
        if (showFeedbackConfirmation || showFeedbackOptions) {
          setShowFeedbackOptions(false);
          setShowFeedbackConfirmation(false);
        } else {
          setShowFeedbackConfirmation(true);
        }
      } else {
        setShowFeedbackConfirmation(false);
        setShowFeedbackOptions((isOpen) => (isOpen ? false : true));
      }
    },
  };

  const fetchOpenServiceTasks = async (): Promise<Task[]> => {
    return (await api.fetchJson(
      '/dwar/api/almanac/ServiceTask/getOpenServiceTasks'
    )) as Task[];
  };

  const fetchManualEndpoints = async () => {
    let endpointOptions: IOption[] = [] as IOption[];
    const manualEndpoints = await db.manualEndPoints.toArray();
    manualEndpoints.forEach((endpoint) => {
      if (endpoint && endpoint.serialNumber && endpoint.id) {
        const option = {
          serialNumber: endpoint.serialNumber,
          terminalId: endpoint.terminalId,
          endpointId: endpoint.id,
          customerName: endpoint.customerName,
        };
        endpointOptions.push(option);
      }
    });
    return endpointOptions;
  };

  const fetchEndPoint = async (endpointId: string): Promise<EndPoint> => {
    return (await api.fetchJson(
      `/dwar/api/almanac/ServiceEndPoint/getEndpointById/?endPointId=${endpointId}`
    )) as EndPoint;
  };

  const getActiveServiceTaskEndpoint = (tasks: Task[]) => {
    const filteredTasksOnsite = tasks.find(
      (task) =>
        task.sharedStatus === 'On-site' && task.endpointId === selectedEndpoint
    );

    const filteredTasksTravel = tasks.find(
      (task) =>
        task.sharedStatus === 'Travel' && task.endpointId === selectedEndpoint
    );

    const filteredTasksCommitted = tasks.find(
      (task) =>
        task.sharedStatus === 'Committed' &&
        task.endpointId === selectedEndpoint
    );
    return (
      filteredTasksOnsite?.endpointId ||
      filteredTasksTravel?.endpointId ||
      filteredTasksCommitted?.endpointId ||
      selectedEndpoint
    );
  };

  const setEndpointDefaultValue = () => {
    if (lastAccessedEndpoint !== '') {
      setSelectedEndpoint(lastAccessedEndpoint);
    } else {
      setSelectedEndpoint(t('No Endpoint'));
    }
  };

  const populateEndpointOptions = async (tasks: Task[]) => {
    let endpointOptions: IOption[] = [];
    let endpoints: string[] = [];
    let promises: Promise<EndPoint>[] = [];
    setEndpointDefaultValue();
    tasks.forEach((task) => {
      if (endpoints.find((value) => task.endpointId === value) === undefined) {
        endpoints.push(task.endpointId);
        try {
          const promise = fetchEndPoint(task.endpointId);
          promises.push(promise);
        } catch (error) {
          const promise = db.endPoints.get(
            task.endpointId
          ) as Promise<EndPoint>;
          if (promise) {
            promises.push(promise);
          }
        }
      }
    });

    const results = await Promise.allSettled(promises);
    results.forEach((endpoint) => {
      if (
        endpoint.status === 'fulfilled' &&
        endpoint.value &&
        endpoint.value.serialNumber &&
        endpoint.value.id
      ) {
        const option = {
          serialNumber: endpoint.value.serialNumber,
          terminalId: endpoint.value.terminalId,
          endpointId: endpoint.value.id,
          customerName: endpoint.value.customerName,
        };
        endpointOptions.push(option);
      }
    });
    let manualEndpointOptions: IOption[] = await fetchManualEndpoints();
    manualEndpointOptions.forEach((option) => {
      if (
        endpoints.find((value) => option.endpointId === value) === undefined
      ) {
        endpoints.push(option.endpointId);
        endpointOptions.push(option);
      }
    });

    const option = {
      serialNumber: t('No Endpoint'),
      terminalId: '',
      endpointId: 'No Endpoint',
      customerName: '',
    };
    endpointOptions.push(option);

    setUserEndpoints(endpointOptions);
  };

  useEffect(() => {
    (async () => {
      try {
        const tasks = await fetchOpenServiceTasks();
        setOpenServiceTasks(tasks);
        await populateEndpointOptions(tasks);
      } catch (error) {
        const tasks = await db.tasks.toArray();
        setOpenServiceTasks(tasks);
        await populateEndpointOptions(tasks);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let endpoint: EndPoint | undefined;

      try {
        endpoint = await fetchEndPoint(selectedEndpoint);
        endpoint?.id
          ? props.handleSelectedEndpoint(endpoint)
          : props.handleSelectedEndpoint(undefined);
      } catch (error) {
        endpoint = await db.endPoints.get(selectedEndpoint);
        endpoint?.id
          ? props.handleSelectedEndpoint(endpoint)
          : props.handleSelectedEndpoint(undefined);
      }
      let repairTimeLineEvents: TimelineEvent[] = [];
      if (endpoint && endpoint.timelineEventDetails) {
        repairTimeLineEvents = endpoint.timelineEventDetails.filter(
          (timelineEvent) =>
            timelineEvent.type ===
              TimeLineEventType[TimeLineEventType.Repair] &&
            timelineEvent.event === props.raId.toString()
        );
      }

      if (repairTimeLineEvents && repairTimeLineEvents.length > 0) {
        repairTimeLineEvents.sort((a, b) => {
          return (
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        });

        const latestRATimelineEvent = repairTimeLineEvents[0];
        const currentDate = new Date(Date.now());
        const timestamp = new Date(latestRATimelineEvent.timestamp);

        const diffInTime = currentDate.getTime() - timestamp.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

        const hours =
          timestamp.getHours() < 10
            ? `0${timestamp.getHours()}`
            : timestamp.getHours().toString();
        const minutes =
          timestamp.getMinutes() < 10
            ? `0${timestamp.getMinutes()}`
            : timestamp.getMinutes().toString();
        const seconds =
          timestamp.getSeconds() < 10
            ? `0${timestamp.getSeconds()}`
            : timestamp.getSeconds().toString();

        setDisplayTime(
          `${diffInDays} days ago ${hours}:${minutes}:${seconds} ${timestamp.toDateString()}`
        );
      } else {
        setDisplayTime('Repair Action not completed');
      }
    })();
  }, [selectedEndpoint]);

  const handleChangeDisplayTime = (event: SelectChangeEvent) => {
    setSelectedEndpoint(event.target.value);

    window.localStorage.setItem(LAST_ACCESSED_ENDPOINT, event.target.value);
  };

  return (
    <div className="sticky top-0">
      {userEndpoints && userEndpoints.length > 0 && (
        <div className="h-18 flex bg-white">
          <div className="mt-2 flex-1">
            <Dropdown
              options={userEndpoints}
              value={selectedEndpoint}
              displayTime={displayTime}
              onChange={handleChangeDisplayTime}
              variant="standard"
            />
          </div>

          <div className="flex-1">
            <PageAction
              icon={actionsProps.icon}
              title={actionsProps.title}
              onClick={actionsProps.onClick}
            />
          </div>
        </div>
      )}
      <div className="relative">
        <div className="absolute-shadow below" />
      </div>
      <Modal
        show={showFeedbackConfirmation}
        handleClose={() => {
          setShowFeedbackConfirmation(false);
        }}
      >
        <div className="mt-4 px-4">
          <p className="mt-2 text-left text-lg font-normal text-iron">
            {t(
              'The selected endpoint on the Repair Action Page does not match one of the endpoint of the active service task assigned. Select OK to continue. Cancel to change.'
            )}
          </p>
        </div>
        <div className="mt-4 mb-2 flex items-center gap-x-2 px-4">
          <h2
            className={'flex-1 text-left text-lg font-bold text-cobalt'}
            onClick={() => {
              setShowFeedbackConfirmation(false);
            }}
          >
            {t('Cancel')}
          </h2>
          <h2
            className={
              'flex-1 cursor-pointer text-right text-lg font-bold text-cobalt'
            }
            onClick={handleOk}
          >
            {t('Ok')}
          </h2>
        </div>
      </Modal>

      {showFeedbackOptions && (
        <RepairActionComplete
          efcCode={props.efcCode}
          efcId={props.efcId}
          raId={props.raId}
          raDescription={props.raDescription}
          raName={props.raName}
          moduleId={props.moduleId}
          externalSource={props.externalSource}
          endpointId={selectedEndpoint}
          serialNumber={props?.serialNumber}
          closeFeedbackOptions={() => {
            setShowFeedbackOptions(false);
          }}
          onClose={(raPerformed: boolean, feedback: string) => {
            if (
              raPerformed &&
              lastAccessedEndpoint !== '' &&
              lastAccessedEndpoint !== 'No Endpoint'
            ) {
              SaveTimeLineEvent(feedback);
            }
          }}
        />
      )}
    </div>
  );
}

export default RepairPageAction;
