import React from 'react';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import Icon from './Icon';

interface Props {
  rating: number;
}

function StarRating(props: Props) {
  return (
    <div className="flex">
      <Icon
        is={StarOutlinedIcon}
        colorClass={props.rating >= 1 ? 'text-cobalt' : 'text-antartica'}
        size="1rem"
      />
      <Icon
        is={StarOutlinedIcon}
        colorClass={props.rating >= 2 ? 'text-cobalt' : 'text-antartica'}
        size="1rem"
      />
      <Icon
        is={StarOutlinedIcon}
        colorClass={props.rating >= 3 ? 'text-cobalt' : 'text-antartica'}
        size="1rem"
      />
      <Icon
        is={StarOutlinedIcon}
        colorClass={props.rating >= 4 ? 'text-cobalt' : 'text-antartica'}
        size="1rem"
      />
      <Icon
        is={StarOutlinedIcon}
        colorClass={props.rating >= 5 ? 'text-cobalt' : 'text-antartica'}
        size="1rem"
      />
    </div>
  );
}

export default StarRating;
