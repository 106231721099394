import classnames from 'classnames';
import React from 'react';
import { TIcon } from '../types';
import Icon from './Icon';

interface Props {
  icon: TIcon;
  iconColor?: string;
  onClick?: (event: React.MouseEvent) => void;
}

// TODO: Some of these buttons will be used for navigation. In that case, accept a href (or `to`) prop and render a react-router Link instead (so an actual anchor, not a button).
function InteractiveIcon(props: Props) {
  const hoverClasses = 'hover:bg-nameless-lightblue';
  const disabledClasses = 'disabled:bg-ice';
  const activeClasses = 'active:bg-dnblue';
  const focusVisibleClasses =
    'focus-visible:bg-nameless-lightblue focus-visible:border-2 focus-visible:border-[#72C1FF] focus-visible:outline-none';
  const displayClasses: string = 'group h-11 rounded-lg w-11';
  return (
    <button
      type="button"
      className={classnames(
        displayClasses,
        hoverClasses,
        disabledClasses,
        activeClasses,
        focusVisibleClasses
      )}
      onClick={props.onClick}
    >
      <Icon
        is={props.icon}
        colorClass={classnames(
          props.iconColor,
          'group-hover:text-cobalt group-disabled:text-concrete group-active:text-white group-focus-visible:text-cobalt'
        )}
      ></Icon>
    </button>
  );
}
export default InteractiveIcon;
