import { useTranslation } from 'react-i18next';
import db from '../index-db';
import { useLiveQuery } from 'dexie-react-hooks';

const useGetAllNotifications = () => {
  const { i18n } = useTranslation();
  const langCode = i18n.language?.split('-')?.[0] ?? 'en';
  const notifications = useLiveQuery(async () => {
    return db.notifications
      .where('languageCode')
      .equals(langCode)
      .toArray()
      .then((res) => {
        return res.sort((a, b) => {
          return (a.isRead as number) - (b.isRead as number);
        });
      });
  }, [langCode]);

  return {
    notifications: notifications ?? [],

    isLoading: notifications?.length === undefined,
  };
};

export default useGetAllNotifications;
