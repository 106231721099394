import { useLiveQuery } from 'dexie-react-hooks';
import { useAppSelector } from '../app/hooks';
import GenericModelData from '../Models/GenericModelData';

const useRecentSearchData = (): GenericModelData[] | undefined => {
  const recentSearches = useAppSelector((state) => state.search.recentSearches);

  return useLiveQuery(() => {
    return [...recentSearches]
      .reverse()
      .map((recentSearch) =>
        GenericModelData.CreateFromSearchItem(recentSearch)
      );
  }, [recentSearches]);
};

export default useRecentSearchData;
