import LogParams from './LogParams';

export interface ILog {
  timestamp: string;
  logType: string;
  source: string;
  logMessage: string;
  deviceType: string;
  deviceId: string;
  city: string;
  state: string;
  country: string;
  logParams: LogParams[] | null;
}

export class Log {
  timestamp: string;
  logType: string;
  source: string;
  logMessage: string;
  deviceType: string;
  deviceId: string;
  city: string;
  state: string;
  country: string;
  logParams: LogParams[] | null;

  constructor(
    timestamp: string,
    logType: string,
    source: string,
    logMessage: string,
    deviceType: string,
    deviceId: string,
    city: string,
    state: string,
    country: string,
    logParams: LogParams[] | null
  ) {
    this.timestamp = timestamp;
    this.logType = logType;
    this.source = source;
    this.logMessage = logMessage;
    this.deviceType = deviceType;
    this.deviceId = deviceId;
    this.city = city;
    this.state = state;
    this.country = country;
    this.logParams = logParams;
  }
}

export default Log;
