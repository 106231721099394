import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from './IconButton';
import BottomModalSheet from './utility/BottomModalSheet';
import SectionHeading from './SectionHeading';
import SystemsCard from './cards/SystemsCard';
import { useTranslation } from 'react-i18next';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TIcon } from '../types';

type checkOptional =
  | { title: string; key?: never }
  | { title: JSX.Element; key: string };
interface Props {
  isBottomsheetOpen: boolean;
  onSheetClose: () => void;
  showHelpIcon?: boolean;
  onHelpIconClick?: () => void;
  data?: ({
    value: string | JSX.Element;
    iconName?: string;
    extraComp?: React.ReactElement;
  } & checkOptional)[];
  children?: React.ReactNode;
}

const GenericModalSheet = (props: Props) => {
  const {
    isBottomsheetOpen,
    onSheetClose,
    data,
    showHelpIcon,
    onHelpIconClick,
  } = props;
  const { t } = useTranslation();
  const header = (
    <div className="m-4 flex items-center justify-between">
      <div className="flex justify-start">
        <IconButton
          icon={CloseOutlinedIcon}
          onClick={onSheetClose}
          className="p-0"
        />
      </div>
      {showHelpIcon && onHelpIconClick && (
        <div className="flex justify-end">
          <IconButton
            icon={HelpOutlinedIcon}
            onClick={onHelpIconClick}
            className=""
          />
        </div>
      )}
    </div>
  );
  let showEntitlementIcon = false;
  let entitlementIcon: TIcon | undefined;

  const getIconColor = (iconName?: string) => {
    let color: 'steel' | 'lime' | 'yellow' | 'tangerine';
    switch (iconName) {
      case 'Green':
        color = 'lime';
        showEntitlementIcon = true;
        entitlementIcon = CheckCircleIcon;
        break;
      case 'Yellow':
        color = 'yellow';
        showEntitlementIcon = true;
        entitlementIcon = WarningIcon;
        break;
      case 'Red':
        color = 'tangerine';
        showEntitlementIcon = true;
        entitlementIcon = CancelIcon;
        break;
      case 'Empty':
        color = 'steel';
        showEntitlementIcon = true;
        entitlementIcon = undefined;
        break;
      default:
        color = 'steel';
        showEntitlementIcon = false;
        break;
    }
    return color;
  };

  return (
    <BottomModalSheet
      isOpen={isBottomsheetOpen}
      onClose={onSheetClose}
      header={header}
      disableDrag={true}
    >
      <div className=" mx-4 h-full">
        {data?.map(({ title, value, extraComp, iconName, key }, i) => {
          if (value && value !== 'N/A') {
            return (
              <div key={key ?? title} className="mb-3">
                <div className="flex flex-row">
                  <SectionHeading title={title} withMargins={false} />
                  {extraComp}
                </div>
                <SystemsCard
                  iconColor={getIconColor(iconName)}
                  showEntitlementIcon={showEntitlementIcon}
                  entitlementIcon={entitlementIcon}
                >
                  {value}
                </SystemsCard>
              </div>
            );
          }
          return null;
        })}
        {props?.children}
      </div>
    </BottomModalSheet>
  );
};

export default GenericModalSheet;
