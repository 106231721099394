import { useLiveQuery } from 'dexie-react-hooks';
import db from '../../index-db';
import { useParams } from 'react-router-dom';
import { usePage } from '../../PageProvider';

const NotificationPage = () => {
  const { id } = useParams();
  const notification = useLiveQuery(() => {
    return db.notifications.get(id as string);
  });

  usePage(() => ({
    getTitle: () => 'Notifications',
    belongsToNavBarItem: 'Notification',
    showBackButton: true,
  }));

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold">{notification?.text}</h1>
      {notification?.description && (
        <p
          className="text-lg "
          dangerouslySetInnerHTML={{ __html: notification.description }}
        />
      )}
    </div>
  );
};

export default NotificationPage;
