import React from 'react';
import { TabButton } from '../../types';
import Tab from './Tab';

interface Props {
  tabs: TabButton[];
  activeTab: TabButton;
  onChangeActiveTab: (tab: TabButton) => void;
}

function Tabs(props: Props) {
  return (
    <div className="relative">
      <div className="flex h-14 gap-x-2 overflow-x-auto	bg-white py-1.5 px-4">
        {props.tabs.map((tab) => (
          <Tab
            key={tab.key}
            title={tab.title}
            active={tab.key === props.activeTab.key}
            onClick={() => props.onChangeActiveTab(tab)}
          />
        ))}
      </div>
      <div className="absolute-shadow below" />
    </div>
  );
}

export default Tabs;
