import React from 'react';
import classnames from 'classnames';
import { Link, To } from 'react-router-dom';
import Icon from '../../components/Icon';
import useNotYetImplemented from '../../hooks/useNotYetImplemented';
import { TIcon } from '../../types';

interface Props {
  to: To;
  icon: TIcon;
  title: string;
  notImplemented?: boolean; //temp prop
  disabled?: boolean;
  onClick?: () => void;
  badgeContent?: string | number;
  badgeColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

function MenuButton(props: Props) {
  const notYetImplemented = useNotYetImplemented();
  return (
    <Link
      className={classnames('flex h-12 items-center gap-x-2 px-6', {
        'text-gray-400': props.disabled,
      })}
      to={props.to}
      onClick={
        props.notImplemented
          ? notYetImplemented
          : props.disabled
          ? (event) => event.preventDefault()
          : props.onClick
      }
    >
      <Icon
        size="2rem"
        is={props.icon}
        badgeContent={props?.badgeContent}
        badgeColor={props?.badgeColor}
      />
      <span className="text-xl font-medium">{props.title}</span>
    </Link>
  );
}

export default MenuButton;
