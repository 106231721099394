import { useNavigate } from 'react-router-dom';
import { EquipmentMenuItem } from '../types';

const useNavigateToEquipment = () => {
  const navigate = useNavigate();
  return (
    endpointId: string,
    tab: EquipmentMenuItem,
    serviceTaskId?: string
  ) => {
    if (serviceTaskId) {
      navigate(`/equipment/${endpointId}/${tab}/${serviceTaskId}`);
    } else {
      navigate(`/equipment/${endpointId}/${tab}`);
    }
  };
};

export default useNavigateToEquipment;
