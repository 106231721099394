import { useAppDispatch } from '../../app/hooks';
import GenericModelCategory from '../../components/GenericModelCategory';
import { searchActions } from '../../features/search/searchSlice';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import GenericModelData from '../../Models/GenericModelData';

interface Props {
  recentlyViewedData: GenericModelData[];
  title: string;
  startCollapsed: boolean;
}

function Category(props: Props) {
  const dispatch = useAppDispatch();

  const handleDeleteOne = (item: GenericModelData) => {
    if (item.IdType === IDType.SEARCH) {
      if (item.SearchItem !== undefined) {
        dispatch(searchActions.deleteRecentSearch(item.SearchItem));
      }
    } else {
      db.recentlyViewed.delete(item.Id);
    }
  };

  const handleClearAll = () => {
    if (props.recentlyViewedData.length > 0) {
      if (props.recentlyViewedData[0].IdType === IDType.SEARCH) {
        dispatch(searchActions.deleteAllRecentSearches());
      } else {
        db.recentlyViewed.bulkDelete(
          props.recentlyViewedData.map((item) => item.Id)
        );
      }
    }
  };

  return (
    <GenericModelCategory
      genericModelData={props.recentlyViewedData ?? []}
      title={props.title}
      startCollapsed={props.startCollapsed}
      onDeleteOne={handleDeleteOne}
      onClearAll={handleClearAll}
    />
  );
}

export default Category;
