import { useCallback } from 'react';
import TimeLineEvent from '../Models/TimeLineEvent';
import FailedTimeLineEvent from '../Models/FailedTimeLineEvent';
import { useApi } from '../acfs-apis/dwar-api-provider';
import db from '../index-db';
import useLogging from './useLogging';

const useSaveTimeLineEvent = (
  onSuccessfullSave?: (currentTime: string) => void
) => {
  const api = useApi();
  const logging = useLogging();
  const SaveTimeLineEvent = useCallback(
    async (timeLineEvent: TimeLineEvent, currentTime?: string) => {
      try {
        await api.postJson(
          '/dwar/api/almanac/TimelineEvent/addTimelineEvent',
          JSON.stringify(timeLineEvent)
        );
        if (currentTime) {
          onSuccessfullSave?.(currentTime);
        }
      } catch (error) {
        logging(
          'error',
          'Unable to add RA timeline event.',
          error,
          false,
          null
        );

        const failedSaveTimeLineEvent: FailedTimeLineEvent = {
          timeLineEvent: timeLineEvent,
        };

        await db.failedTimeLineEvent.add(failedSaveTimeLineEvent);
      }
    },
    [api]
  );
  return SaveTimeLineEvent;
};

export default useSaveTimeLineEvent;
