import Icon from '../../components/Icon';
import IconButton from '../../components/IconButton';
import { TIcon } from '../../types';

interface Props {
  bgColor: string;
  cardText: string;
  icon: TIcon;
  onClick: () => void;
}

function RepairActionCompleteActionCard({
  bgColor,
  cardText,
  icon,
  onClick,
}: Props) {
  const classNames = 'px-3 mt-1 ' + bgColor + ' flex-1';
  return (
    <div className={classNames} onClick={onClick}>
      <div className="mt-4 cursor-pointer break-words text-center tracking-wide">
        <Icon is={icon} colorClass="text-white" />
        <div className="mt-3 text-white">{cardText}</div>
      </div>
    </div>
  );
}
export default RepairActionCompleteActionCard;
