import { useLiveQuery } from 'dexie-react-hooks';
import { useTranslation } from 'react-i18next';
import db from '../index-db';
import EFC from '../Models/EFC';

interface Props {
  efc: EFC;
  number: number;
  onClick: () => void;
}

function EFCCard(props: Props) {
  const { t } = useTranslation();

  const moduleName = useLiveQuery(async () => {
    const module = await db.modules.get(props.efc.ModuleId);
    return module?.ModuleName;
  });
  return (
    <button
      className="block w-full text-left"
      type="button"
      onClick={props.onClick}
    >
      <div className="flex h-11 items-center gap-x-2 bg-antartica">
        <div className="align-center flex w-[3.625rem] items-center justify-center self-stretch bg-cobalt text-xl text-white">
          {props.number}
        </div>
        <div className="text-xl font-bold">{props.efc.EfcCodeStr}</div>
      </div>
      <div className="bg-white px-4 pt-1 pb-6">
        <div className="flex justify-between border-b border-b-antartica pb-2">
          <div className="flex flex-col">
            <span className="font-medium text-steel">{t('Module')}</span>
            <span className="text-lg font-bold">{moduleName}</span>
          </div>
          <div className="flex flex-col">
            <span className="font-medium text-steel">
              {t('Repair actions')}
            </span>
            <span className="self-center text-lg font-bold">
              {props.efc.RepairActionData?.length ?? 0}
            </span>
          </div>
        </div>
        <div className="pt-2">{props.efc.ProbableCause}</div>
      </div>
    </button>
  );
}

export default EFCCard;
