import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import React, { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const getMain = () => document.querySelector('main');
  const scrollToTop = () => getMain()?.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    const main = getMain();
    if (!main) {
      return;
    }

    const handleScroll = () => setVisible(main.scrollTop > 200);
    main.addEventListener('scroll', handleScroll);

    return () => main.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <button
      type="button"
      className={visible ? 'floating-action-button' : 'hidden'}
      onClick={scrollToTop}
    >
      <ArrowUpwardOutlinedIcon style={{ width: 32, height: 32 }} />
    </button>
  );
};

export default ScrollToTopButton;
