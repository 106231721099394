import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import NoItems from '../../components/NoItems';
import SectionHeading from '../../components/SectionHeading';
import useRecentSearchData from '../../hooks/useRecentSearchData';
import db, { DATABASE_INIT_TIMESTAMP_KEY } from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import GenericModelData, {
  TGenericModelDataFactory,
} from '../../Models/GenericModelData';
import RecentlyViewedItem from './RecentlyViewedItem';

const factoryMethodsMap: Record<IDType, TGenericModelDataFactory> = {
  [IDType.EFC]: GenericModelData.CreateFromEFC,
  [IDType.SEARCH]: GenericModelData.CreateFromSearchItem,
  [IDType.PARTS]: GenericModelData.CreateFromPart,
  [IDType.PRODUCTS]: GenericModelData.CreateFromProduct,
  [IDType.REPAIR_ACTION]: GenericModelData.CreateFromRepairAction,
};

const dbTypeMap = {
  [IDType.EFC]: db.efcs,
  [IDType.SEARCH]: db.searchedItems,
  [IDType.PARTS]: db.parts,
  [IDType.PRODUCTS]: db.products,
  [IDType.REPAIR_ACTION]: db.raactions,
};

function RecentlyViewed() {
  const { t } = useTranslation();
  const recentSearchData = useRecentSearchData();

  const [recentlyViewedItems, setRecentlyViewedItems] = useState(
    [] as (GenericModelData | undefined)[]
  );

  const initTime = localStorage.getItem(DATABASE_INIT_TIMESTAMP_KEY);
  useEffect(() => {
    (async () => {
      let recentlyViewedRecords = await db.recentlyViewed.toArray();
      const items = await Promise.all(
        recentlyViewedRecords.map(async (recentlyViewedRecord) => {
          const idType: IDType = recentlyViewedRecord.IdType;
          const factoryMethod = factoryMethodsMap[idType];
          const res = await dbTypeMap[idType].get(
            recentlyViewedRecord.IdGeneric
          );
          if (res !== undefined) {
            const model = factoryMethod(
              res,
              recentlyViewedRecord.id,
              recentlyViewedRecord.Context
            );
            model.timestamp = recentlyViewedRecord.timestamp;
            return model;
          }
        })
      );
      setRecentlyViewedItems(items);
    })();
  }, [initTime]);

  const recentItemsOrdered = useMemo(() => {
    if (!recentSearchData || !recentlyViewedItems) {
      return [];
    } else {
      const result = [
        ...(recentSearchData ?? []),
        ...(recentlyViewedItems ?? []),
      ].sort((a, b) => (b?.timestamp ?? 0) - (a?.timestamp ?? 0));

      return result;
    }
  }, [recentSearchData, recentlyViewedItems]);

  let navigateTo = '';
  let inactiveClass = 'opacity-50';
  if (
    (recentlyViewedItems && recentlyViewedItems?.length > 0) ||
    (recentSearchData && recentSearchData?.length > 0)
  ) {
    navigateTo = '/recent';
    inactiveClass = '';
  }
  const noItemsMessage = t('You have no recently view history.');

  return (
    <div>
      <div className="ml-4 mr-1 mb-0.5 flex items-center justify-between">
        <SectionHeading title={t('Recently viewed')} withMargins={false} />
        <Button styling="tertiary" to={navigateTo} className={inactiveClass}>
          {t('View all')}
        </Button>
      </div>
      {recentlyViewedItems?.length === 0 && recentSearchData?.length === 0 && (
        <div>
          <NoItems icon={SearchOutlinedIcon} message={noItemsMessage} />
        </div>
      )}
      {recentItemsOrdered.map(
        (recentItem) =>
          recentItem && (
            <RecentlyViewedItem
              key={`${recentItem.IdType}_${recentItem.Id}`}
              recentlyViewedInfo={recentItem}
            />
          )
      )}
    </div>
  );
}

export default RecentlyViewed;
