import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectActiveSearch,
  selectSavedSearches,
} from '../../../app/selectors';
import { useToast } from '../../../components/toast/ToastProvider';
import { searchActions } from '../../../features/search/searchSlice';
import { IHeaderButton } from '../../../PageProvider';
import { searchesAreEqual } from '../searchUtils';
import useUpdateSearchInCollection from './useUpdateSearchInCollection';

/**
 * Returns a header button that either saves or unsaves the active search, depending
 * on whether it's currently saved. Also updates the saved search when it changes through
 * the useUpdateSavedSearch hook.
 */
const useSaveSearchButton = () => {
  const dispatch = useAppDispatch();
  const { pushToast } = useToast();
  const { t } = useTranslation();

  const activeSearch = useAppSelector(selectActiveSearch);
  const savedSearches = useAppSelector(selectSavedSearches);

  useUpdateSearchInCollection('savedSearches');

  const [saveButton, setSaveButton] = useState<IHeaderButton>({
    icon: BookmarkBorderOutlinedIcon, // Temp - immediately gets overwritten by the useEffect below.
  });

  useEffect(() => {
    const isSaved =
      activeSearch &&
      savedSearches.some((search) => searchesAreEqual(search, activeSearch));

    if (!isSaved) {
      setSaveButton({
        icon: BookmarkBorderOutlinedIcon,
        onClick: () => {
          if (activeSearch) {
            dispatch(searchActions.saveActiveSearch());
            pushToast({
              type: 'success',
              message: t('Added to saved searches'),
            });
          }
        },
      });
    }

    if (isSaved) {
      setSaveButton({
        icon: BookmarkIcon,
        iconColor: 'text-cobalt',
        onClick: () => {
          if (activeSearch) {
            dispatch(searchActions.unsaveActiveSearch());
            pushToast({
              type: 'success',
              message: t('Removed from saved searches'),
            });
          }
        },
      });
    }
  }, [activeSearch, dispatch, pushToast, savedSearches, t]);

  return saveButton;
};

export default useSaveSearchButton;
