import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Image from '../../components/Image';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import { usePage } from '../../PageProvider';
import Product from '../../Models/Product';
import ProductGroupCategory from './ProductGroupCategory';
import useAddToRecentlyViewed from '../../hooks/useAddToRecentlyViewed';
import useAddToSavedItems from '../../hooks/useAddToSavedItems';
import PageNotFound from '../../PageNotFound';
import { useToast } from '../../components/toast/ToastProvider';
import { LogType } from '../../enums/LogType';

export interface IProductPageData {
  productsData: Product;
  isCollapsed: boolean;
}

function ProductPage() {
  const { t } = useTranslation();
  const params = useParams();
  const addToSavedItems = useAddToSavedItems();
  const logging = useLogging();
  const { pushToast } = useToast();
  const addToRecentlyViewed = useAddToRecentlyViewed();
  const [isWrongURL, setIsWrongURL] = useState(false);

  const product = useLiveQuery(async () => {
    return params?.idGeneric ? db.products.get(params.idGeneric) : undefined;
  }, [params.idGeneric]);

  useEffect(() => {
    if (product?.ProductId) {
      const logParams: LogParams[] = [
        {
          key: LogType.Page,
          value: LogType.Product,
        },
        {
          key: LogType.ProductId,
          value: product?.ProductId,
        },
      ];
      logging('info', '', '', false, logParams);
    }
  }, [product?.ProductId]);

  const getProductHeading = useCallback(() => {
    return product?.GetHeading() ?? '';
  }, [product]);

  const isSaved = useLiveQuery(async () => {
    const productRecord = await db.savedItems
      .where({
        IdGeneric: params.idGeneric,
        IdType: IDType.PRODUCTS,
      })
      .toArray();
    return productRecord.length > 0;
  }, [params.idGeneric]);

  const [pageModel, setPageModel] = useState<IProductPageData | null>(null);
  useEffect(
    function updatePageModel() {
      if (!product) {
        setPageModel(null);
        return;
      }
      (async () => {
        const result = {} as IProductPageData;
        result.isCollapsed = true;
        result.productsData = product;
        setPageModel(result);
      })();
    },
    [product]
  );

  usePage(
    () => ({
      getTitle: getProductHeading,
      belongsToNavBarItem: 'Menu',
      rightButtons: [
        {
          icon: isSaved ? BookmarkIcon : BookmarkBorderOutlinedIcon,
          iconColor: isSaved ? 'text-cobalt' : undefined,
          onClick: () => {
            if (params.idGeneric && product) {
              addToSavedItems(
                params.idGeneric,
                IDType.PRODUCTS,
                getProductHeading(),
                pushToast
              );
            }
          },
        },
      ],
      showBackButton: true,
    }),
    [isSaved, params.idGeneric, pushToast]
  );

  useEffect(() => {
    (async () => {
      const productRecord = params.idGeneric
        ? await db.products.get(params.idGeneric)
        : undefined;
      if (productRecord && params.idGeneric) {
        setIsWrongURL(false);
        addToRecentlyViewed(params.idGeneric, IDType.PRODUCTS, '');
      } else {
        setIsWrongURL(true);
      }
    })();
  }, [params.idGeneric]);

  return (
    <>
      {isWrongURL && <PageNotFound />}
      {pageModel && (
        <>
          <Image
            imageId={pageModel.productsData.ProductId}
            imageType="products"
          />
          <ProductGroupCategory
            productsPageData={pageModel}
            title={t('Relationship tree')}
            isCollapsed={pageModel.isCollapsed}
          />
        </>
      )}
    </>
  );
}
export default ProductPage;
