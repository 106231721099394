import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import AppProviders from './AppProviders';
import AppRoutes from './AppRoutes';
import { OIDCConfig } from './types';
import CacheBuster from 'react-cache-buster';
import { WebStorageStateStore } from 'oidc-client-ts';

interface IUserSettings {
  realm: string;
  oidcConfig: OIDCConfig;
}

function App(props: IUserSettings) {
  if (props.oidcConfig) {
    const authProviderProps: AuthProviderProps = {
      authority: props.oidcConfig.RealmSettings.filter(
        (realmSetting) => realmSetting.RealmName === props.realm
      )[0].Authority,
      client_id: props.oidcConfig.ClientId,
      redirect_uri:
        props.oidcConfig.RedirectDwarApiUrl === null
          ? decodeURIComponent(props.oidcConfig.DwarApiUrl)
          : decodeURIComponent(props.oidcConfig.RedirectDwarApiUrl),
      post_logout_redirect_uri: decodeURIComponent(props.oidcConfig.DwarApiUrl),
      response_type: 'code',
      scope: 'openid profile email offline_access',
      automaticSilentRenew: true,
      userStore: new WebStorageStateStore({
        store: localStorage,
      }),
      onSigninCallback: () => {
        if (props.oidcConfig.IsExternal) {
          window.location.href =
            authProviderProps.redirect_uri || props.oidcConfig.DwarApiUrl;
        }
        UpdateRouteUrlState();
      },
    };

    return (
      <CacheBuster
        currentVersion={props.oidcConfig.BuildVersion}
        isEnabled={true}
        isVerboseMode={false}
        metaFileDirectory={
          process.env.NODE_ENV === 'production' ? './dwar' : '.'
        }
      >
        <AuthProvider {...authProviderProps}>
          <AppProviders>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <AppRoutes />
            </BrowserRouter>
          </AppProviders>
        </AuthProvider>
      </CacheBuster>
    );
  } else {
    return null;
  }

  function UpdateRouteUrlState() {
    let params = new URLSearchParams(window.location.search);
    params.delete('state');
    params.delete('session_state');
    params.delete('code');
    let url =
      params.toString() !== ''
        ? window.location.pathname + window.location.search
        : window.location.pathname;
    window.history.replaceState({}, '', url);
  }
}

export default App;
