import { useLiveQuery } from 'dexie-react-hooks';
import { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import useNavigateToEfc from '../../hooks/useNavigateToEfc';
import db from '../../index-db';
import PageNotFound from '../../PageNotFound';
import { useAppDispatch } from '../../app/hooks';
import { searchActions } from '../../features/search/searchSlice';
import useNavigateToSearchResults from '../../hooks/useNavigateToSearchResults';
import useSetLastAccessedEnpoint from '../Search/Results/useSetLastAccessedEnpoint';

function EFCByCode() {
  const [isWrongURL, setIsWrongURL] = useState(false);
  const navigateToEfc = useNavigateToEfc();
  const { state } = useLocation();

  const params = useParams();
  const efcCode = params.code;
  const [searchParams] = useSearchParams();
  const externalSource = searchParams.get('externalSource') === 'true';
  const fullFeedback = searchParams.get('fullFeedback') === 'true';
  const serialNumber = searchParams.get('ssnr');

  const dispatch = useAppDispatch();
  const navigate = useNavigateToSearchResults();
  useSetLastAccessedEnpoint(serialNumber || '');
  useLiveQuery(async () => {
    if (efcCode) {
      const efc = await db.efcs
        .where('EfcCodeStr')
        .equalsIgnoreCase(efcCode)
        .first();
      if (efc) {
        navigateToEfc(efc.Id.toString(), fullFeedback, externalSource, true);
      } else if (state?.from === 'internal') {
        dispatch(searchActions.changeSearchTerm(efcCode));
        navigate({
          awaitUpdatedSearchTerm: false,
        });
      } else {
        setIsWrongURL(true);
        return <>{isWrongURL && <PageNotFound />}</>;
      }
    }
  }, [efcCode]);
  return <>{isWrongURL && <PageNotFound />}</>;
}
export default EFCByCode;
