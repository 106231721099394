import { useTranslation } from 'react-i18next';
import SectionHeading from '../../../components/SectionHeading';
import EndPoint from '../../../Models/EndPoint';
import Entitlement from './Entitlement';
import Software from './Software';

interface Props {
  equipmentData: EndPoint;
}

function Details(props: Props) {
  const { equipmentData } = props;
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      {equipmentData?.entitlements?.length > 0 && (
        <div>
          <div className=" mb-2 items-center justify-between">
            <SectionHeading
              title={`${t('Entitlements')}`}
              withMargins={false}
            />
          </div>
          <div className={`flex w-full flex-col`}>
            {equipmentData?.entitlements.map((entitlement, index) => (
              <Entitlement key={entitlement.id} entitlement={entitlement} />
            ))}
          </div>
        </div>
      )}
      {equipmentData?.softwareInventory?.length > 0 && (
        <div>
          <div className=" mb-2 items-center justify-between">
            <SectionHeading title={`${t('Software')}`} withMargins={false} />
          </div>
          <div className={`flex w-full flex-col`}>
            <Software softwares={equipmentData?.softwareInventory} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;
