import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Image from '../../components/Image';
import useLogging from '../../hooks/useLogging';
import LogParams from '../../Models/LogParams';
import db from '../../index-db';
import { IDType } from '../../Models/Enumerations';
import Part from '../../Models/Part';
import { usePage } from '../../PageProvider';
import Categories from './Categories';
import CategoryWithTree from './CategoryWithTree';
import useAddToRecentlyViewed from '../../hooks/useAddToRecentlyViewed';
import useAddToSavedItems from '../../hooks/useAddToSavedItems';
import PageNotFound from '../../PageNotFound';
import { useToast } from '../../components/toast/ToastProvider';
import { LogType } from '../../enums/LogType';
import FeedbackScreen from '../Feedback/FeedbackScreen';

export interface IPartHeadingData {
  materialId: string;
  partName: string;
  heading: string;
  hasRepairActions: boolean;
}

export interface IPartsPageData {
  partsData: Part;
  replacedBy: IPartHeadingData[];
  replaces: IPartHeadingData[];
  stockReplacedBy: IPartHeadingData[];
  stockReplaces: IPartHeadingData[];
  partParentData: IPartHeadingData[];
  partChildData: IPartHeadingData[];
  isCollapsed: boolean;
}

function PartPage() {
  const { t } = useTranslation();
  const params = useParams();
  const logging = useLogging();
  const { pushToast } = useToast();
  const addToSavedItems = useAddToSavedItems();
  const addToRecentlyViewed = useAddToRecentlyViewed();
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const [isWrongURL, setIsWrongURL] = useState(false);

  const part = useLiveQuery(async () => {
    return params?.idGeneric ? db.parts.get(params.idGeneric) : undefined;
  }, [params.idGeneric]);

  const selectedPartId = useLiveQuery(async () => {
    return params?.selectedPartId ? params.selectedPartId : undefined;
  }, [params.selectedPartId]);

  useEffect(() => {
    if (part?.MaterialId) {
      const logParams: LogParams[] = [
        {
          key: LogType.Page,
          value: LogType.Part,
        },
        {
          key: LogType.PartNumber,
          value: part?.MaterialId,
        },
      ];
      logging('info', '', '', false, logParams);
    }
  }, [part?.MaterialId]);

  const getPartHeading = useCallback(() => {
    return part?.GetHeading() ?? '';
  }, [part]);

  const isSaved = useLiveQuery(async () => {
    const partRecord = await db.savedItems
      .where({
        IdGeneric: params.idGeneric,
        IdType: IDType.PARTS,
      })
      .toArray();
    return partRecord.length > 0;
  }, [params.idGeneric]);

  const [pageModel, setPageModel] = useState<IPartsPageData | null>(null);
  useEffect(
    function updatePageModel() {
      if (!part) {
        setPageModel(null);
        return;
      }

      (async () => {
        const result = {} as IPartsPageData;
        result.isCollapsed = true;
        result.partsData = part;
        if (part.ReplacedBy) {
          result.replacedBy = await getPartsByIds(part.ReplacedBy);
        }
        if (part.Replaces) {
          result.replaces = await getPartsByIds(part.Replaces);
        }
        if (part.StockReplacedBy) {
          result.stockReplacedBy = await getPartsByIds(part.StockReplacedBy);
        }
        if (part.StockReplaces) {
          result.stockReplaces = await getPartsByIds(part.StockReplaces);
        }
        if (part.ParentIds) {
          result.partParentData = await getPartsByIds(part.ParentIds);
          if (selectedPartId != undefined) {
            result.partParentData = result.partParentData.filter(
              (a) => a.materialId == selectedPartId
            );
            result.isCollapsed = false;
          }
        }
        if (part.ChildIds) {
          result.partChildData = await getPartsByIds(part.ChildIds);
        }
        setPageModel(result);
      })();
    },
    [part]
  );

  const getPartsByIds = async (materialIds: string[]) => {
    let result: IPartHeadingData[] = [];
    await db.parts
      .where('MaterialId')
      .anyOf(materialIds)
      .each((item) =>
        result.push({
          materialId: item.MaterialId,
          partName: item.PartName,
          heading: item.GetHeading(),
          hasRepairActions: item.HasRepairActions,
        })
      );
    return result;
  };

  usePage(
    () => ({
      getTitle: getPartHeading,
      belongsToNavBarItem: 'Menu',
      rightButtons: [
        { icon: CommentOutlinedIcon, onClick: () => setFeedbackOpen(true) },
        {
          icon: isSaved ? BookmarkIcon : BookmarkBorderOutlinedIcon,
          iconColor: isSaved ? 'text-cobalt' : undefined,
          onClick: () => {
            if (params.idGeneric && part) {
              addToSavedItems(
                params.idGeneric,
                IDType.PARTS,
                getPartHeading(),
                pushToast
              );
            }
          },
        },
      ],
      showBackButton: true,
    }),
    [isSaved, params.idGeneric, pushToast]
  );

  useEffect(() => {
    (async () => {
      const partRecord = params.idGeneric
        ? await db.parts.get(params.idGeneric)
        : undefined;
      if (partRecord && params.idGeneric) {
        setIsWrongURL(false);
        addToRecentlyViewed(params.idGeneric, IDType.PARTS, '');
      } else {
        setIsWrongURL(true);
      }
    })();
  }, [params.idGeneric]);

  return (
    <div>
      {isWrongURL && <PageNotFound />}
      {!isWrongURL && (
        <FeedbackScreen
          feedbackType="part"
          isOpen={isFeedbackOpen}
          handleClose={() => setFeedbackOpen(false)}
          handleSubmit={() => setFeedbackOpen(false)}
          partNumber={part?.MaterialId}
          partName={part?.PartName}
        />
      )}
      {pageModel && (
        <>
          <Image imageId={pageModel.partsData.MaterialId} imageType="parts" />
          <CategoryWithTree
            partsPageData={pageModel}
            title={t('Relationship tree')}
            isCollapsed={pageModel.isCollapsed}
          />
          <Categories
            partsPageData={pageModel.stockReplaces}
            title={t('If no stock, replaces')}
            isCollapsed={true}
          />
          <Categories
            partsPageData={pageModel.stockReplacedBy}
            title={t('If no stock, replaced by')}
            isCollapsed={true}
          />
          <Categories
            partsPageData={pageModel.replaces}
            title={t('Replaces')}
            isCollapsed={true}
          />
          <Categories
            partsPageData={pageModel.replacedBy}
            title={t('Replaced by')}
            isCollapsed={true}
          />
        </>
      )}
    </div>
  );
}
export default PartPage;
